import { useEffect } from "react";
import { create } from "zustand";
import { useParams } from "react-router-dom";

import { Deck } from "engine/types/decks";
import { PlayerStateTeamData, PlayerStateStats } from "engine/types/game-state";
import Globals from "Globals";
import { LoseReason } from "game-server/backend-interface/BackendInterface";

export enum LeftTab {
  MENU = 0,
  SETTINGS = 1,
  LOG = 2,
}

export type SolvedModalState = {
  roomId: string;
  puzName: string;
  roomPuzName: string;
  roomBattleGroupName: string;
  speedrunTime: number;
  cardUnlocks: string[];
  teamData: PlayerStateTeamData;
  stats: PlayerStateStats;
  isWinner: boolean;
  loseReason?: LoseReason;
};

export interface NavStoreState {
  /**
   * State to avoid showing stale state when switching out of a room
   * and back.
   */
  isPageReady: boolean;
  setIsPageReady: (isPageReady: boolean) => void;

  leftTabIndex: LeftTab;
  setLeftTabIndex: (leftTabIndex: LeftTab) => void;

  /**
   * The solved modal should persist with the same content even if
   * the game gets restarted in the background, so we need separate
   * state for it.
   */
  solvedModalState: SolvedModalState | null;
  setSolvedModalState: (solvedModalState: SolvedModalState | null) => void;

  /**
   * Whether the page has a React Router component. This allows us to
   * hook up navbar links to React Router without making them part of
   * the main React component.
   */
  hasRouter: boolean;
  setHasRouter: (hasRouter: boolean) => void;

  /**
   * The path that React Router should navigate to. Setting this causes
   * the app to navigate to that path, and then reset this state.
   */
  routerNavigatePath: string | null;
  setRouterNavigatePath: (routerRedirectPath: string | null) => void;

  /**
   * Navigation state, used to communicate router state to
   * non-router components.
   */
  navPuzName: string | null;
  setNavPuzName: (navPuzName: string | null) => void;
  navIsMasteryTreePage: boolean;
  setNavIsMasteryTreePage: (navIsMasteryTreePage: boolean) => void;

  /** The selected mastery to display the details of. */
  selectedMasteryId: string | null;
  setSelectedMasteryId: (selectedMasteryId: string | null) => void;
  /**
   * The hovered mastery; takes precedence over the selected mastery
   * when showing the details in the mastery details display.
   */
  hoveredMasteryId: string | null;
  setHoveredMasteryId: (hoveredMasteryId: string | null) => void;

  hoveredDeck: Deck | null;
  setHoveredDeck: (hoveredDeck: Deck | null) => void;

  isInstancerModalOpen: boolean;
  setIsInstancerModalOpen: (isInstancerModalOpen: boolean) => void;

  isPvPModalOpen: boolean;
  setIsPvPModalOpen: (isInstancerModalOpen: boolean) => void;

  isHotkeyModalOpen: boolean;
  setIsHotkeyModalOpen: (isHotkeyModalOpen: boolean) => void;

  battleUnlockModalPuzName: string | null;
  setBattleUnlockModalPuzName: (
    battleUnlockModalPuzName: string | null
  ) => void;

  isMasteryUnlockModalOpen: boolean;
  setIsMasteryUnlockModalOpen: (isMasteryUnlockModalOpen: boolean) => void;

  bigBoardTeamFilter: string;
  setBigBoardTeamFilter: (bigBoardTeamFilter: string) => void;
}

export const useNavStore = create<NavStoreState>((set) => ({
  isPageReady: false,
  setIsPageReady: (isPageReady: boolean) => set({ isPageReady }),

  leftTabIndex: LeftTab.MENU,
  setLeftTabIndex: (leftTabIndex) => set({ leftTabIndex }),

  solvedModalState: null,
  setSolvedModalState: (solvedModalState) => set({ solvedModalState }),

  hasRouter: false,
  setHasRouter: (hasRouter) => set({ hasRouter }),

  routerNavigatePath: null,
  setRouterNavigatePath: (routerNavigatePath) => set({ routerNavigatePath }),

  navPuzName: null,
  setNavPuzName: (navPuzName) => set({ navPuzName }),
  navIsMasteryTreePage: false,
  setNavIsMasteryTreePage: (navIsMasteryTreePage) =>
    set({ navIsMasteryTreePage }),

  selectedMasteryId: null,
  setSelectedMasteryId: (selectedMasteryId) => set({ selectedMasteryId }),
  hoveredMasteryId: null,
  setHoveredMasteryId: (hoveredMasteryId) => set({ hoveredMasteryId }),

  hoveredDeck: null,
  setHoveredDeck: (hoveredDeck) => set({ hoveredDeck }),

  isInstancerModalOpen: false,
  setIsInstancerModalOpen: (isInstancerModalOpen) =>
    set({ isInstancerModalOpen }),

  isPvPModalOpen: false,
  setIsPvPModalOpen: (isPvPModalOpen) => set({ isPvPModalOpen }),

  isHotkeyModalOpen: false,
  setIsHotkeyModalOpen: (isHotkeyModalOpen) => set({ isHotkeyModalOpen }),

  battleUnlockModalPuzName: null,
  setBattleUnlockModalPuzName: (battleUnlockModalPuzName) =>
    set({ battleUnlockModalPuzName }),

  isMasteryUnlockModalOpen: false,
  setIsMasteryUnlockModalOpen: (isMasteryUnlockModalOpen) =>
    set({ isMasteryUnlockModalOpen }),

  bigBoardTeamFilter: "",
  setBigBoardTeamFilter: (bigBoardTeamFilter) => set({ bigBoardTeamFilter }),
}));

export const usePuzNameIfExists = (): string | null => {
  const { puzName } = useParams<{
    puzName?: string;
  }>();

  if (Globals.puzName !== undefined) return Globals.puzName;
  if (puzName !== undefined) return puzName;

  return null;
};

export const usePuzName = (): string => {
  const puzName = usePuzNameIfExists();
  if (puzName === null)
    throw new Error(
      "this hook should only be called when we know puzName is defined"
    );
  return puzName;
};

export const usePrimaryPuzNameIfExists = (): string | null => {
  const puzName = usePuzNameIfExists();
  if (puzName === null) return null;
  return puzName.split(",")[0];
};

export const usePrimaryPuzName = (): string => {
  const puzName = usePrimaryPuzNameIfExists();
  if (puzName === null)
    throw new Error(
      "this hook should only be called when we know puzName is defined"
    );
  return puzName;
};

export const useSlot = (): number => {
  const { slot: slotStr } = useParams<{
    slot: string;
  }>();
  if (slotStr === undefined)
    throw new Error("expect slot parameter to be defined");
  return parseInt(slotStr);
};

// Any querySelector queries that might fail must be performed
// under a MutationObserver to prevent race conditions.
export const useMutationObserver = (
  selector: string | null,
  onUpd: (elem: HTMLElement | null) => void
) => {
  useEffect(() => {
    if (selector === null) {
      onUpd(null);
      return;
    }
    const onMutate = () => {
      onUpd(document.querySelector(selector) as HTMLElement | null);
    };
    const observer = new MutationObserver(() => {
      onMutate();
    });
    onMutate();
    observer.observe(document.body, { subtree: true, childList: true });
    return () => {
      observer.disconnect();
    };
  }, [selector, onUpd]);
};
