import { Check } from "engine/types/action-validation";
import { CounterType } from "engine/types/counters";
import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export type SharedExtraState = {
  canAttackMorgle: boolean;
};

export const SharedPuzzle46Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: [],
  },
  cardEffectsShared: {
    swargle: {
      computeText: (permanent, ctx) => {
        let blurgle = "";
        const amt = ctx.inspector.getCounterValSum(permanent, CounterType.DOOM);
        switch (amt) {
          case 7:
            blurgle = "sergle";
            break;
          case 6:
            blurgle = "sirgle";
            break;
          case 5:
            blurgle = "firgle";
            break;
          case 4:
            blurgle = "forgle";
            break;
          case 3:
            blurgle = "thrergle";
            break;
          case 2:
            blurgle = "tworgle";
            break;
          case 1:
            blurgle = "onergle";
            break;
          case 0:
            blurgle = "norgle";
            break;
        }
        return `If this Crergle targle Dargle, Dergle all Energle Unirgle.
This Crergle is Dergle autorgle in ${blurgle} turgle.`;
      },
    },
  },
  adjustAttackChecks: (failedChecks, attacker, defender, ctx) => {
    const { inspector } = ctx;
    if (inspector.getCardName(defender) === "morgle") {
      const sharedExt = inspector.getSharedExtraState() as SharedExtraState;

      // don't allow attacking morgle
      if (!sharedExt.canAttackMorgle) {
        failedChecks.add(Check.GENERIC);
      }
    }

    // Restore normal card effects check for everyone else
    const effects = inspector.getSharedEffects(attacker);
    const effectsAdjust = effects.adjustAttackChecks;
    if (effectsAdjust)
      effectsAdjust(failedChecks, attacker, defender, inspector.makeContext());
  },

  // just override
  modifySharedExtraState(updInfo, sharedExtraState) {
    Object.assign(
      sharedExtraState as SharedExtraState,
      updInfo as SharedExtraState
    );
  },
};
