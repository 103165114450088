import { Player, Slot, Permanent, Card } from "engine/types/game-state";
import { GemColor } from "engine/types/card-data";
import { Counter, CounterType, ValueCounterType } from "engine/types/counters";
import { UIElement, Keyframe, Speaker } from "engine/types/keyframes";
import { EngineContext } from "engine/types/game-specs";
import { AbilityType, EffectOpt } from "engine/types/effects";
import { Role, UpdateType } from "engine/types/updates";
import { Inspector } from "engine/Inspector";
import { Controller } from "engine/Controller";
import { Engine } from "engine/Engine";
import { StepMaker } from "engine/StepMaker";
import { PermanentQuery } from "engine/PermanentQuery";
import { LoseReason } from "game-server/backend-interface/BackendInterface";

/**
 * Events that a permanent can listen to.
 * Permanents can listen to events due to effects intrinsic to the cards
 * themselves, or when instructed to by counters.
 */
export enum PermanentEventType {
  /** After the permanent is created. */
  CREATED = "created",
  /** Before the permanent is destroyed. */
  BEFORE_DESTROYED = "before_destroyed",
  /** After the permanent is destroyed. */
  AFTER_DESTROYED = "after_destroyed",
  /** Start of the next player's turn. */
  START_TURN = "start_turn",
  /** End of the current player's turn. */
  END_TURN = "end_turn",

  // WARNING: Due to thorns, card effects using *_DESTROY_UNIT
  // must take into consideration the fact that the permanent that
  // this counter is attached to may have been destroyed by the time
  // the handler is called.
  /** Before the permanent destroys a unit. */
  BEFORE_DESTROY_UNIT = "before_destroy_unit",
  /** After the permanent destroys a unit. */
  AFTER_DESTROY_UNIT = "after_destroy_unit",

  // WARNING: Due to thorns, card effects using AFTER_DAMAGED
  // must take into consideration the fact that the permanent that
  // this counter is attached to may have been destroyed by the time
  // the handler is called.
  /**
   * After the permanent is damaged.
   * This is still called even if the permanent would be destroyed by
   * the damage. In this case, the handler is called while the permanent
   * is still on the board, potentially with negative health.
   */
  AFTER_DAMAGED = "after_damaged",
  /** After the permanent has its damage removed. */
  AFTER_DAMAGE_REMOVED = "after_damage_removed",

  /** After power or max health have been adjusted. */
  AFTER_STATS_CHANGED = "after_stats_changed",

  /** After the permanent deals damage. */
  AFTER_DEAL_DAMAGE = "after_deal_damage",
  /** When any other unit is destroyed. */
  OTHER_UNIT_DESTROYED = "other_unit_destroyed",
  /** When any other unit enters the field. */
  OTHER_UNIT_SPAWN = "other_unit_spawn",
}

type PermanentEventBase = {
  permanent: Permanent;
  /**
   * The counter that's handling the event, if this is a counter
   * event handler. If this is a game spec handler, then this
   * should be undefined.
   * The associated counterIndex will be defined iff counter is defined.
   */
  counter?: Counter;
  counterIndex?: number;
  ctx: EngineContext;
};

export type CreatedPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.CREATED;
};
type DestroyedPermanentEvent = PermanentEventBase & {
  type:
    | PermanentEventType.BEFORE_DESTROYED
    | PermanentEventType.AFTER_DESTROYED;
  /**
   * The attacker, if the destroy should be attributed to one.
   * WARNING: The attacker may have been destroyed by the time
   * this event is fired. Make sure that the attacker still
   * exists on the board before performing any actions on it.
   */
  attacker?: Permanent;
};
export type StartTurnPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.START_TURN;
};
export type EndTurnPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.END_TURN;
};
export type DestroyUnitPermanentEvent = PermanentEventBase & {
  type:
    | PermanentEventType.BEFORE_DESTROY_UNIT
    | PermanentEventType.AFTER_DESTROY_UNIT;
  defender: Permanent;
};
export type DamagedPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.AFTER_DAMAGED;
  damage: number;
  /**
   * The attacker, if the damage should be attributed to one.
   * WARNING: The attacker may have been destroyed by the time
   * this event is fired. Make sure that the attacker still
   * exists on the board before performing any actions on it.
   */
  attacker?: Permanent;
};
export type DamageRemovedPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.AFTER_DAMAGE_REMOVED;
  damage: number; // the real amount of damage removed, never more than current damage
};

type StatsChangedPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.AFTER_STATS_CHANGED;
  powerDelta: number;
  healthDelta: number;
};

type DealDamagePermanentEvent = PermanentEventBase & {
  type: PermanentEventType.AFTER_DEAL_DAMAGE;
  damage: number;
  defender: Permanent;
};
type OtherUnitDestroyedEvent = PermanentEventBase & {
  type: PermanentEventType.OTHER_UNIT_DESTROYED;
  destroyedPermanent: Permanent;
  attacker?: Permanent;
};
type OtherUnitSpawnPermanentEvent = PermanentEventBase & {
  type: PermanentEventType.OTHER_UNIT_SPAWN;
  spawnedPermanent: Permanent;
};

export type PermanentEvent =
  | CreatedPermanentEvent
  | DestroyedPermanentEvent
  | StartTurnPermanentEvent
  | EndTurnPermanentEvent
  | DestroyUnitPermanentEvent
  | DamagedPermanentEvent
  | DamageRemovedPermanentEvent
  | StatsChangedPermanentEvent
  | DealDamagePermanentEvent
  | OtherUnitDestroyedEvent
  | OtherUnitSpawnPermanentEvent;

export type PermanentEventHandlerResult = {
  /**
   * Only used for event handlers on counters.
   * If set to true, the counter that this event handler comes
   * from is removed after the event is handled, so that the
   * event handler is only run once.
   * By default, the counter will remain on the permanent and
   * its event handlers may be run multiple times.
   */
  expire?: boolean;
} | void;

export type PermanentEventHandlers = {
  [PermanentEventType.CREATED]?: (
    ev: CreatedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.BEFORE_DESTROYED]?: (
    ev: DestroyedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_DESTROYED]?: (
    ev: DestroyedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.START_TURN]?: (
    ev: StartTurnPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.END_TURN]?: (
    ev: EndTurnPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.BEFORE_DESTROY_UNIT]?: (
    ev: DestroyUnitPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_DESTROY_UNIT]?: (
    ev: DestroyUnitPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_DAMAGED]?: (
    ev: DamagedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_DAMAGE_REMOVED]?: (
    ev: DamageRemovedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_STATS_CHANGED]?: (
    ev: StatsChangedPermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.AFTER_DEAL_DAMAGE]?: (
    ev: DealDamagePermanentEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.OTHER_UNIT_DESTROYED]?: (
    ev: OtherUnitDestroyedEvent
  ) => PermanentEventHandlerResult;
  [PermanentEventType.OTHER_UNIT_SPAWN]?: (
    ev: OtherUnitSpawnPermanentEvent
  ) => PermanentEventHandlerResult;
};

/**
 * A safe wrapper around Engine for game spec and card effects hooks.
 * Authors should perform all game state changes only through this API.
 */
export class HooksEngineAPI {
  private inspector: Inspector;
  private controller: Controller;
  private engine: Engine;

  constructor(engine: Engine) {
    this.inspector = engine.inspector;
    this.controller = engine.controller;
    this.engine = engine;
  }

  /**
   * Get raw access to the Controller.
   * In author code, this should only be used at most temporarily to get
   * unblocked while waiting for web team to implement new API endpoints.
   */
  getBackdoorController(): Controller {
    return this.controller;
  }

  /**
   * Get raw access to the Engine.
   * In author code, this should only be used at most temporarily to get
   * unblocked while waiting for web team to implement new API endpoints.
   */
  getBackdoorEngine(): Engine {
    return this.engine;
  }

  /**
   * Initialize the engine-only battle-specific extra state.
   */
  initExtraState(val: object): void {
    this.engine.getEngineOnly().extraState = val;
  }

  /**
   * Get the engine-only battle-specific extra state.
   * The return value can (and should) be directly modified, since it
   * is only used by the engine.
   */
  getExtraState(): object {
    const extraState = this.engine.getEngineOnly().extraState;
    if (extraState === undefined) {
      throw new Error("extraState not initialized");
    }
    return extraState;
  }

  /** Initialize the shared extra state. */
  initSharedExtraState(sharedExtraState: object): void {
    this.controller.broadcastAndApply({
      type: UpdateType.INIT_SHARED_EXTRA_STATE,
      sharedExtraState,
    });
  }

  /**
   * Modify the battle-specific extra state shared between the server
   * and client. This modification must be encoded declaratively as
   * an object. The shared game spec should in turn declare the hook
   * modifySharedExtraState, which takes in this object and applies
   * the described update to the shared extra state.
   */
  modifySharedExtraState(updInfo: object): void {
    this.controller.broadcastAndApply({
      type: UpdateType.MODIFY_SHARED_EXTRA_STATE,
      updInfo,
    });
  }

  /**
   * Card text: (player) draws a card.
   * This does not deduct cost.
   */
  drawCard(player: Player): void {
    this.engine.drawCard(player);
  }

  /**
   * (player) adds (card) to their hand.
   * This does not modify player decks.
   */
  addCardToHand(player: Player, card: Card): void {
    this.engine.addCardToHand(player, card);
  }

  /**
   * (player) discards (card).
   */
  discardCard(player: Player, handCardId: string): void {
    this.controller.discardCard(player, handCardId);
  }

  /**
   * (player) discards (card).
   */
  discardRandomCard(player: Player): boolean {
    const hand = this.inspector.gameState[player].hand;
    if (hand.length === 0) {
      return false;
    }
    const idx = Math.floor(Math.random() * hand.length);
    this.discardCard(player, hand[idx].id);
    return true;
  }

  /** Remove a card by index from the player's deck. */
  removeCardIndex(player: Player, cardIndex: number): void {
    const deck = this.inspector.getDrawPile(player);
    if (cardIndex < 0 || cardIndex >= deck.length)
      throw new Error("cardIndex out of bounds for draw pile");

    this.engine.removeCardIndex(player, cardIndex);
  }

  /**
   * Card text: (player) gains (gems).
   */
  addGems(player: Player, gems: GemColor[]): void {
    if (
      this.inspector.hasAI() &&
      player === Player.P2 &&
      (this.inspector.gameSpec.aiDisableGemAccounting ?? false)
    )
      return;
    this.controller.addGems(player, gems);
  }

  /**
   * Card text: Spawn an instance of (cardName) in (slot).
   * The spawned permanent would be owned by (owner).
   * If the slot is already occupied, the spawn is aborted.
   * Returns whether the spawn was successful.
   */
  spawn(cardName: string, slot: Slot, owner: Player): boolean;

  /** (slot) may also be specified by its (row) and (col). */
  spawn(cardName: string, row: number, col: number, owner: Player): boolean;

  spawn(
    cardName: string,
    arg1: Slot | number,
    arg2: Player | number,
    arg3?: Player
  ): boolean {
    const slot = (() => {
      if (typeof arg1 === "number") {
        if (typeof arg2 !== "number")
          throw new Error("incorrect type signature");
        return { row: arg1, column: arg2 };
      } else {
        if (typeof arg2 === "number")
          throw new Error("incorrect type signature");
        return arg1;
      }
    })();
    const owner = typeof arg2 !== "number" ? arg2 : arg3;
    if (owner === undefined) throw new Error("incorrect type signature");

    if (!this.inspector.isSlotInBounds(slot)) return false;
    if (this.inspector.isSlotOccupied(slot)) return false;

    this.engine.spawnPermanent(cardName, owner, slot);
    return true;
  }

  /**
   * Remove (permanent) from play.
   * This does not add the permanent to the discard pile, and does
   * not trigger any effects.
   */
  despawn(permanent: Permanent): void {
    if (!this.inspector.doesPermanentExist(permanent)) return;

    this.controller.removePermanent(permanent.id);
  }

  /**
   * Swap the owner of (permanent) to its opponent.
   */
  changePermanentOwner(permanent: Permanent): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;

    this.controller.changePermanentOwner(
      permanent.id,
      this.inspector.getOpponentOf(permanent.owner)
    );
    return true;
  }

  /**
   * Ready (permanent). Returns whether successful.
   */
  ready(permanent: Permanent): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;

    this.controller.readyPermanent(permanent.id);
    return true;
  }

  /**
   * Unready (permanent). Returns whether successful.
   */
  unready(permanent: Permanent): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;

    // Unready even in the "always ready" dev mode, so that any
    // unready effects can still be tested in workshop mode.
    this.controller.unreadyPermanent(permanent.id);
    return true;
  }

  /**
   * Card text: (permanent) moves to (slot).
   * This does not unready (permanent).
   * Returns whether the move was successful.
   */
  move(permanent: Permanent, slot: Slot): boolean;

  /** (slot) may also be specified by its (row) and (col). */
  move(permanent: Permanent, row: number, col: number): boolean;

  move(permanent: Permanent, arg1: Slot | number, arg2?: number): boolean {
    const slot = (() => {
      if (typeof arg1 === "number") {
        if (typeof arg2 !== "number")
          throw new Error("incorrect type signature");
        return { row: arg1, column: arg2 };
      } else {
        if (arg2 !== undefined) throw new Error("incorrect type signature");
        return arg1;
      }
    })();

    if (!this.inspector.doesPermanentExist(permanent)) return false;
    if (!this.inspector.isSlotInBounds(slot)) return false;
    if (this.inspector.isSlotOccupied(slot)) return false;

    this.controller.move(permanent.id, slot);
    return true;
  }

  /**
   * Move (permanent) to a new slot, relative to its current position.
   * e.g. If the permanent starts in (col, row), this tries to move
   * it to (col + colDiff, row + rowDiff).
   * This does not unready (permanent).
   */
  moveRel(permanent: Permanent, rowDiff: number, colDiff: number): boolean {
    return this.move(
      permanent,
      permanent.slot.row + rowDiff,
      permanent.slot.column + colDiff
    );
  }

  /**
   * Card text: (permanent1) swaps places with (permanent2).
   * The swap does not happen if either permanent was destroyed
   * due to earlier effects.
   */
  swap(permanent1: Permanent, permanent2: Permanent): void {
    if (!this.inspector.doesPermanentExist(permanent1)) return;
    if (!this.inspector.doesPermanentExist(permanent2)) return;
    this.controller.swap(permanent1.id, permanent2.id);
  }

  /**
   * Card text: (attacker) attacks (defender) [for (damage) damage].
   * Damage is not dealt if (attacker) was destroyed due to earlier
   * effects. The damage is attributed to (attacker).
   * This does not deduct cost or unready (attacker).
   */
  attack(attacker: Permanent, defender: Permanent, damage?: number): void {
    if (!this.inspector.doesPermanentExist(attacker)) return;
    if (!this.inspector.doesPermanentExist(defender)) return;

    if (damage === undefined) {
      this.engine.attack(attacker, defender);
    } else {
      this.engine.damagePermanent(defender, damage, attacker);
    }
  }

  /**
   * Card text: (attacker) deals (damage) damage to (defender).
   * Damage is dealt even if (attacker) was destroyed due to earlier
   * effects. The damage is attributed to (attacker).
   */
  damage(attacker: Permanent, defender: Permanent, damage: number): void {
    if (!this.inspector.doesPermanentExist(defender)) return;

    this.engine.damagePermanent(defender, damage, attacker);
  }

  /**
   * Card text: (defender) receives (damage) damage.
   * Damage is not attributed to any permanent. On-damage effects
   * are still triggered.
   * Most of the time, damage should be attributed to an attacker.
   * If so, the `damage` function should be used instead.
   */
  damageUnattributed(
    defender: Permanent,
    damage: number,
    ignoreInvulnerability?: boolean
  ): void {
    if (!this.inspector.doesPermanentExist(defender)) return;

    this.engine.damagePermanent(
      defender,
      damage,
      undefined,
      ignoreInvulnerability
    );
  }

  /**
   * Card text: Remove (damage) damage from (permanent).
   * (damage) must be positive.
   * (permanent)'s health will not go above max health.
   * On-damage effects are not triggered, but after-damage-removed is.
   * Returns if successful.
   */
  removeDamage(permanent: Permanent, damage: number): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;
    if (damage <= 0) return false;

    this.engine.removeDamageFromPermanent(permanent, damage);
    return true;
  }

  adjustPermanentStats(
    permanent: Permanent,
    powerDelta: number,
    healthDelta: number
  ): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;
    if (powerDelta === 0 && healthDelta === 0) return false;

    this.engine.adjustPermanentStats(permanent, powerDelta, healthDelta);
    return true;
  }

  /**
   * Card text: (attacker) destroys (defender).
   * This destroys (defender) even if (attacker) was destroyed due to
   * earlier effects. The destroy is attributed to (attacker).
   * For the purposes of event triggers, (defender) would be considered
   * to have been destroyed without having received any damage.
   */
  destroy(attacker: Permanent, defender: Permanent): void {
    if (!this.inspector.doesPermanentExist(defender)) return;

    this.engine.destroyPermanent(defender, attacker);
  }

  /**
   * Add (counter) to (permanent).
   */
  addCounter(permanent: Permanent, counter: Counter): void {
    if (!this.inspector.doesPermanentExist(permanent)) return;

    this.controller.addCounter(permanent.id, counter);
  }

  /**
   * Remove the first counter of (CounterType) on (permanent).
   * Returns whether successful.
   */
  removeCounterType(permanent: Permanent, counterType: CounterType): boolean {
    if (!this.inspector.doesPermanentExist(permanent)) return false;
    return this.engine.removeCounterType(permanent, counterType);
  }

  /**
   * Set the value of the value counter at (counterIndex) on
   * (permanent) to (val).
   */
  updateCounterVal(
    permanent: Permanent,
    counterIndex: number,
    val: number,
    explanation?: (val: number) => string
  ): void {
    if (!this.inspector.doesPermanentExist(permanent)) return;

    this.controller.setCounter(
      permanent.id,
      counterIndex,
      val,
      explanation && explanation(val)
    );
  }

  /**
   * If it doesn't exist, add a value counter to (permanent) with (val), (explanation(val)) and (expiry).
   * If it exists, increment the value of the existing counter by (val) and set the explanation to (explanation(new value))
   */
  mergeValueCounter(
    permanent: Permanent,
    counterType: ValueCounterType,
    val: number,
    explanation?: (val: number) => string,
    expiry?: PermanentEventType
  ): void {
    if (!this.inspector.doesPermanentExist(permanent)) return;

    for (const [idx, counter] of permanent.counters.entries()) {
      if (counter.type === counterType && counter.expiry === expiry) {
        this.controller.setCounter(
          permanent.id,
          idx,
          counter.val + val,
          explanation && explanation(counter.val + val)
        );
        return;
      }
    }

    this.controller.addCounter(permanent.id, {
      type: counterType,
      val: val,
      explanation: explanation && explanation(val),
      expiry,
    });
  }

  /**
   * Announce a message to the user log.
   */
  announce(message: string): void {
    this.controller.announce(message);
  }

  clearKeyframe(): void {
    this.controller.clearKeyframe();
  }

  /**
   * End the game. By default, P1 wins and solves the puzzle.
   * isSolved overrides whether P1 gets the solve. If set to false,
   * P1 doesn't get the solve, and P2 wins by default.
   * winner overrides who wins.
   */
  endGame(isSolved?: boolean, winner?: Player): void {
    isSolved ??= true;
    this.engine.endGame(
      winner ?? (isSolved ? Player.P1 : Player.P2),
      LoseReason.NORMAL,
      isSolved ? { [Player.P1]: true } : {},
      {}
    );
  }
}

/**
 * A safe utility wrapper around Engine for performing actions as
 * an AI, primarily to simulate player actions.
 */
export class HooksAIAPI {
  private inspector: Inspector;
  private controller: Controller;
  private engine: Engine;
  private stepMaker: StepMaker;

  constructor(engine: Engine) {
    this.inspector = engine.inspector;
    this.controller = engine.controller;
    this.engine = engine;
    this.stepMaker = new StepMaker(this.inspector, Player.P2, Role.GOD);
  }

  /**
   * Make the AI character say (message).
   * The message would appear as a dialog bubble coming from the
   * AI character, and be cleared at the start of the next AI turn.
   * If "blocking", accept no input until user says "OK".
   */
  speak(
    message: string,
    opts: {
      blocking?: boolean;
      hideBattler?: boolean;
      speaker?: Speaker;
    } = {}
  ) {
    const engineOnly = this.engine.getEngineOnly();
    this.controller.setKeyframe({
      id: `dyn-keyframe-${engineOnly.nextKeyframeId}`,
      dialogue: message.trim(),
      selector: UIElement.AI_BATTLER,
      showNext: opts.blocking,
      ...opts,
    });
    engineOnly.nextKeyframeId++;
  }

  /**
   * Try to spawn (cardName) at (slot) for the AI.
   * If the slot is already occupied, the spawn is aborted.
   */
  trySpawn(cardName: string, slot: Slot): boolean;

  /** (slot) may also be specified by its (row) and (col). */
  trySpawn(cardName: string, row: number, col: number): boolean;

  trySpawn(cardName: string, arg1: Slot | number, arg2?: number): boolean {
    return this.engine.aiTryStep(this.stepMaker.spawn(cardName, arg1, arg2));
  }

  // TURN ACTIONS
  //
  // The following actions are subject to the same restrictions as an
  // ordinary player (gems are deducted, permanents cannot perform
  // actions if unready, etc.) unless explicitly disabled by the
  // game spec.

  /**
   * Try to summon the card with ID (handCardId) of the AI's hand to (slot).
   * AIs that don't manage a deck (most don't) should use trySpawn instead.
   */
  trySummon(handCardId: string, slot: Slot): boolean;

  /** (slot) may also be specified by its (row) and (col). */
  trySummon(handCardId: string, row: number, col: number): boolean;

  trySummon(handCardId: string, arg1: Slot | number, arg2?: number): boolean {
    return this.engine.aiTryStep(this.stepMaker.summon(handCardId, arg1, arg2));
  }

  /**
   * Try to move (permanent) to (slot).
   */
  tryMove(permanent: Permanent, slot: Slot): boolean;

  /** (slot) may also be specified by its (row) and (col). */
  tryMove(permanent: Permanent, row: number, col: number): boolean;

  tryMove(permanent: Permanent, arg1: Slot | number, arg2?: number): boolean {
    return this.engine.aiTryStep(this.stepMaker.move(permanent, arg1, arg2));
  }

  /**
   * Try to move (permanent), relative to its current location.
   * e.g. If the permanent starts in (col, row), this tries to move
   * it to (col + colDiff, row + rowDiff).
   */
  tryMoveRel(permanent: Permanent, rowDiff: number, colDiff: number): boolean {
    return this.tryMove(
      permanent,
      permanent.slot.row + rowDiff,
      permanent.slot.column + colDiff
    );
  }

  /**
   * Try to attack (defender) with (attacker).
   */
  tryAttack(attacker: Permanent, defender: Permanent): boolean {
    return this.engine.aiTryStep(this.stepMaker.attack(attacker, defender));
  }

  /**
   * Try to activate (permanent)'s (abilityType) ability.
   * If no parameters are needed, (effectOpts) may be omitted.
   */
  tryActivateAbility(
    permanent: Permanent,
    abilityType: AbilityType,
    effectOpts?: ReadonlyArray<EffectOpt>
  ): boolean {
    return this.engine.aiTryStep(
      this.stepMaker.activateAbility(permanent, abilityType, effectOpts)
    );
  }

  /**
   * Try to attack the first valid permanent from a filter/sort query.
   * See {@link PermanentQuery} for the query format.
   * If no query is supplied, uses a default query that should work
   * for most cases (see {@link StepMaker.makeDefaultAttackQuery})
   * (please help improve it)!
   */
  tryAttackFirst(attacker: Permanent, query?: PermanentQuery): boolean {
    const step = this.stepMaker.attackFirst(attacker, query);
    if (step === undefined) return false;
    return this.engine.aiTryStep(step);
  }

  /** Set keyframe to the given ID. */
  setKeyframe(id: string): void;

  /** Set keyframe. */
  setKeyframe(keyframe: Keyframe): void;

  setKeyframe(arg: string | Keyframe): void {
    const { keyframes } = this.engine;
    const keyframe =
      typeof arg === "string" ? keyframes.find(({ id }) => id === arg) : arg;
    if (!keyframe) {
      throw new Error("keyframe not found");
    }
    this.controller.setKeyframe(keyframe);
  }
}

/**
 * A safe wrapper to allow AIs to perform certain limited actions
 * out of turn, such as dialogue.
 */
export class HooksOutOfTurnAIAPI {
  private ai: HooksAIAPI;

  constructor(ai: HooksAIAPI) {
    this.ai = ai;
  }

  /**
   * Make the AI character say (message).
   * The message would appear as a dialog bubble coming from the
   * AI character, and be cleared at the start of the next AI turn.
   */
  speak(
    message: string,
    opts: {
      blocking?: boolean;
      hideBattler?: boolean;
      speaker?: Speaker;
    } = {}
  ) {
    this.ai.speak(message, opts);
  }

  /** Set keyframe to the given ID. */
  setKeyframe(id: string): void;

  /** Set keyframe. */
  setKeyframe(keyframe: Keyframe): void;

  setKeyframe(arg: string | Keyframe): void {
    if (typeof arg === "string") {
      this.ai.setKeyframe(arg);
    } else {
      this.ai.setKeyframe(arg);
    }
  }
}
