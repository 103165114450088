import { parse } from "csv-parse/sync";

export const REPUTATION_BOOST_MASTERY_ID = "m1";
export const SCRY_TOP_CARD_MASTERY_ID = "m2";
export const PRIORITY_CARD_MASTERY_ID = "m3";
export const FACTIONS_MASTERY_ID = "m4";
export const DRAW_CARD_MASTERY_ID = "m5";
export const REDRAW_MASTERY_ID = "m6";
export const PVP_MASTERY_ID = "m7";
export const SPEEDRUN_MASTERY_ID = "m8";
export const SOLVE_COUNTS_MASTERY_ID = "m9";

export type MasteryData = {
  masteryId: string;
  displayName: string;
  effectText: string;
  order: number;
  x: number;
  y: number;
};

const getColStartingWith = (
  row: { [colName: string]: string },
  colName: string
): string => {
  const matchedCols = Object.keys(row).filter((fullColName) =>
    fullColName.startsWith(colName)
  );
  if (matchedCols.length !== 1) {
    throw new Error("expected exactly one matching col");
  }
  return row[matchedCols[0]];
};

function parseMastery(
  row: { [colName: string]: string },
  order: number
): MasteryData {
  const masteryId = getColStartingWith(row, "id");
  return {
    masteryId,
    displayName: getColStartingWith(row, "name"),
    order,
    x: Number(getColStartingWith(row, "grid x")),
    y: Number(getColStartingWith(row, "grid y")),
    effectText: getColStartingWith(row, "effect text"),
  };
}

export const parseMasteries = (csvStr: string): ReadonlyArray<MasteryData> => {
  const rows = parse(csvStr, { columns: true });
  const masteries: MasteryData[] = [];
  for (const row of rows) {
    const masteryId = getColStartingWith(row, "id");
    if (masteryId === "") {
      // Skip empty rows.
      continue;
    }
    masteries.push(parseMastery(row, masteries.length + 1));
  }
  return masteries;
};

export type MasteriesDB = { [masteryId: string]: MasteryData };

export const makeMasteriesDB = (
  masteriesData: ReadonlyArray<MasteryData>
): MasteriesDB => {
  const masteriesDB: MasteriesDB = {};
  for (const mastery of masteriesData) {
    masteriesDB[mastery.masteryId] = mastery;
  }
  return masteriesDB;
};
