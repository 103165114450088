import { create } from "zustand";
import produce from "immer";
import { useParams } from "react-router-dom";

import {
  makeInitFactionScores,
  makeHuntEndFactionScores,
} from "engine/types/factions";
import { Deck, createNewDeck } from "engine/types/decks";
import { Player } from "engine/types/game-state";
import { Check, FailedChecks } from "engine/types/action-validation";
import {
  PUZ_NAME_KEROS_NOTES_UNLOCK,
  BATTLE_GROUP_NAME_TUTORIAL,
  BATTLE_GROUP_NAME_INSTANCER,
  PuzzleData,
  PuzzlesDB,
} from "engine/puzzles/puzzle-data";
import ALL_MASTERIES from "engine/puzzles/all-masteries-client";
import ALL_PUZZLES from "engine/puzzles/all-puzzles-client";
import { makeMasteriesDB } from "engine/puzzles/mastery-data";
import {
  GlobalStateBattleRoomStatus,
  ClientPuzzleGlobalState,
  ClientBattleGroupGlobalState,
  ClientTeamSpeedrunLeaderboardSummaryGlobalState,
  ClientGlobalState,
  GlobalUpdate,
  makeInitClientGlobalState,
  applyGlobalUpdate,
  ClientTeamGlobalState,
  RankedLeaderboard,
} from "game-server/global-updates";
import { MasteryTree } from "game-server/masteries";

import settings from "settings";
import { usePrimaryPuzNameIfExists, usePuzName } from "stores/NavStore";
import {
  useServerInteractionStore,
  useTeamId,
  useIsAdmin,
} from "stores/ServerInteractionStore";
import {
  useStaticInspector,
  useRoomPuzNameFromGameState,
} from "stores/ClientGameStore";
import { clientTeamGlobalStateToTeamNameDisplayData } from "components/TeamDisplayNameDisplay";
import { HuntNotification } from "game-server/notifications";

export interface ClientGlobalStateStoreState {
  globalState: ClientGlobalState;
  applyGlobalUpdate: (upd: GlobalUpdate) => void;
  playAudioNotif: ((upd: HuntNotification) => void) | null;
  setPlayAudioNotif: (
    playAudioNotif: ((upd: HuntNotification) => void) | null
  ) => void;
}

export const useClientGlobalStateStore = create<ClientGlobalStateStoreState>(
  (set) => ({
    globalState: makeInitClientGlobalState(),
    applyGlobalUpdate: (upd) =>
      set(
        produce((state) => {
          applyGlobalUpdate(state.globalState, upd);
        })
      ),
    playAudioNotif: null,
    setPlayAudioNotif: (playAudioNotif) => set({ playAudioNotif }),
  })
);

export const useSubscribedTeam = (
  overrideTeamId?: string | null
): ClientTeamGlobalState | null => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  const subscribedTeam = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeam
  );
  if (settings.isPublicAccess) {
    const masteriesDB = makeMasteriesDB(ALL_MASTERIES);
    const puzzlesDB = new PuzzlesDB(ALL_PUZZLES);
    return {
      teamId: "",
      displayName: "Public Access",
      faction: null,
      factionScoreContributions: makeInitFactionScores(),
      factionScores: makeHuntEndFactionScores(),
      puzzles: Object.fromEntries(
        [...puzzlesDB.puzzles.entries()].flatMap(([puzName, puzData]) => {
          const { answer, battleGroupName, order } = puzData;
          const unlockTime = new Date(2023, 11, 1).getTime();
          const solveTime = new Date(2023, 11, 1).getTime();
          return [
            [
              puzName,
              {
                puzName,
                battleGroupName,
                order,
                hasAnswer: answer !== null ? true : undefined,
                unlockTime,
                solveTime,
              },
            ],
          ];
        })
      ),
      battleGroups: Object.fromEntries(
        [...puzzlesDB.battleGroups.entries()].flatMap(
          ([battleGroupName, battleGroup]) => {
            const {
              displayName,
              mapPos,
              isLegendary,
              preBattleDialogue,
              postBattleDialogue,
              numPuzzles,
            } = puzzlesDB.getBattleGroup(battleGroupName);
            const puzData = puzzlesDB.getPuzzle(battleGroupName);
            const { isCutscene, answer } = puzData;
            return [
              [
                battleGroupName,
                {
                  battleGroupName,
                  displayName,
                  isCutscene: isCutscene ? true : undefined,
                  isLegendary: isLegendary ? true : undefined,
                  preBattleDialogue,
                  numPuzzles: numPuzzles !== 1 ? numPuzzles : undefined,
                  mapPos,
                },
              ],
            ];
          }
        )
      ),
      cardUnlocks: {},
      masteries: Object.fromEntries(
        Object.values(masteriesDB).map(
          ({ masteryId, order, x, y, displayName, effectText }) => {
            return [
              masteryId,
              {
                masteryId,
                order,
                x,
                y,
                isEnabled: false,
              },
            ];
          }
        )
      ),
      hintsInfo: {
        numHintsTotal: 0,
        numHintsUsed: 0,
      },
      // Note that months are zero-indexed, but days are one-indexed.
      huntEndTime: Date.UTC(2023, 10, 21, 0, 11, 11),
    };
  }
  if (serverInterfaceController === null) return null;
  const teamId = overrideTeamId ?? serverInterfaceController.getTeamId();
  if (teamId === null) return null;
  if (subscribedTeam === null) return null;
  if (subscribedTeam.teamId !== teamId) return null;
  return { ...subscribedTeam };
};

export const useSubscribedTeamMembers = (teamId: string | null) => {
  const subscribedTeamMembers = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamMembers
  );
  if (teamId === null) return null;
  const snapshot = settings.membersSnapshot[teamId];
  if (settings.isPosthunt) {
    return {
      teamId,
      members: snapshot ?? [],
    };
  }
  if (subscribedTeamMembers === null) return null;
  if (subscribedTeamMembers.teamId !== teamId) return null;
  return subscribedTeamMembers;
};

export const useSubscribedTeamDecks = () => {
  const teamId = useTeamId();
  const subscribedTeamDecks = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamDecks
  );
  if (subscribedTeamDecks === null) return null;
  if (subscribedTeamDecks.teamId !== teamId) return null;
  return subscribedTeamDecks;
};

export const useSubscribedMasteryTree = (): MasteryTree | null => {
  const teamId = useTeamId();
  const subscribedTeamMasteryTree = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamMasteryTree
  );
  if (subscribedTeamMasteryTree === null) return null;
  if (subscribedTeamMasteryTree.teamId !== teamId) return null;
  return subscribedTeamMasteryTree.masteryTree;
};

export const useSubscribedDeck = (slot: number | null): Deck | null => {
  const subscribedTeamDecks = useSubscribedTeamDecks();
  if (slot === null) return null;
  if (subscribedTeamDecks === null) return null;
  return subscribedTeamDecks.decks[slot] ?? createNewDeck(slot);
};

export const useSubscribedTeamFishPuzzle = (
  teamId: string | null,
  puzName: string | null
) => {
  const subscribedTeamFishPuzzle = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamFishPuzzle
  );
  if (teamId === null) return null;
  if (puzName === null) return null;
  if (subscribedTeamFishPuzzle === null) return null;
  if (subscribedTeamFishPuzzle.teamId !== teamId) return null;
  if (subscribedTeamFishPuzzle.puzName !== puzName) return null;
  return subscribedTeamFishPuzzle;
};

export const useSubscribedTeamBattle = () => {
  const teamId = useTeamId();
  const puzName = usePrimaryPuzNameIfExists();
  const subscribedTeamBattle = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamBattle
  );
  const { spectateTeamId } = useParams();
  if (puzName === null) return null;
  if (subscribedTeamBattle === null) return null;
  if (subscribedTeamBattle.teamId !== (spectateTeamId ?? teamId)) return null;
  if (subscribedTeamBattle.puzName !== puzName) return null;
  return subscribedTeamBattle;
};

export const useSelectedPuzName = (): string | null => {
  const puzName = usePrimaryPuzNameIfExists();
  const battleGroupName = useBattleGroupName();
  const subscribedTeamBattle = useSubscribedTeamBattle();

  // "Fast path" to prevent waiting for the new subscribed team
  // battle to be synced when switching between sub-battles outside
  // the instancer.
  if (battleGroupName !== BATTLE_GROUP_NAME_INSTANCER) return puzName;

  if (subscribedTeamBattle === null) return null;
  return subscribedTeamBattle.selectedPuzName;
};

export const useSubscribedTeamPvP = () => {
  const teamId = useTeamId();
  const subscribedTeamPvP = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamPvP
  );
  if (subscribedTeamPvP === null) return null;
  if (subscribedTeamPvP.teamId !== teamId) return null;
  return subscribedTeamPvP;
};

export const useSubscribedTeamSpeedrunLeaderboardSummary = (
  puzName: string | null
): ClientTeamSpeedrunLeaderboardSummaryGlobalState | null => {
  const teamId = useTeamId();
  const subscribedTeam = useSubscribedTeam();
  const subscribedTeamSpeedrunLeaderboardSummary = useClientGlobalStateStore(
    (state) => state.globalState.subscribedTeamSpeedrunLeaderboardSummary
  );
  if (puzName === null) return null;
  if (subscribedTeam === null) return null;
  if (subscribedTeamSpeedrunLeaderboardSummary === null) return null;
  if (subscribedTeamSpeedrunLeaderboardSummary.teamId !== teamId) return null;
  if (subscribedTeamSpeedrunLeaderboardSummary.puzName !== puzName) return null;
  const snapshot = settings.speedrunLeaderboardSummariesSnapshot[puzName];
  if (settings.isPosthunt && snapshot !== undefined) {
    const leaderboard = new RankedLeaderboard(
      (record) => record.teamData.teamId,
      (record1, record2) => record1.speedrunTime - record2.speedrunTime,
      snapshot
    );
    const ownTime = subscribedTeam.puzzles[puzName]?.speedrunTime;
    const speedrunRank =
      ownTime === undefined
        ? undefined
        : leaderboard.insert(
            {
              teamData:
                clientTeamGlobalStateToTeamNameDisplayData(subscribedTeam),
              speedrunTime: ownTime,
            },
            false
          ).newIndex;
    return {
      teamId,
      puzName,
      speedrunLeaderboardSummary: snapshot,
      speedrunRank,
    };
  }
  return subscribedTeamSpeedrunLeaderboardSummary;
};

export const useBattleGroup = (): ClientBattleGroupGlobalState | null => {
  const puzName = usePrimaryPuzNameIfExists();
  const subscribedTeam = useSubscribedTeam();
  return getBattleGroup(subscribedTeam, puzName);
};

export const useSelectedBattleGroup =
  (): ClientBattleGroupGlobalState | null => {
    const puzName = useSelectedPuzName();
    const subscribedTeam = useSubscribedTeam();
    return getBattleGroup(subscribedTeam, puzName);
  };

export function getBattleGroup(
  subscribedTeam: ClientTeamGlobalState | null,
  puzName: string | null
): ClientBattleGroupGlobalState | null {
  if (subscribedTeam === null || puzName === null) return null;
  const { puzzles, battleGroups } = subscribedTeam;
  const puzData = puzzles[puzName];
  if (puzData === undefined) return null;
  const battleGroup = battleGroups[puzData.battleGroupName];
  if (battleGroup === undefined)
    throw new Error("expect battle group to be unlocked");
  return battleGroup;
}

export const useBattleGroupName = (): string | null => {
  const battleGroup = useBattleGroup();
  if (battleGroup === null) return null;
  return battleGroup.battleGroupName;
};

export const useSelectedBattleGroupName = (): string | null => {
  const battleGroup = useSelectedBattleGroup();
  if (battleGroup === null) return null;
  return battleGroup.battleGroupName;
};

export const useIsInstancer = (): boolean | null => {
  const battleGroupName = useBattleGroupName();
  if (battleGroupName === null) return null;
  return battleGroupName === BATTLE_GROUP_NAME_INSTANCER;
};

export const usePuzzlesInBattleGroup = (): ClientPuzzleGlobalState[] | null => {
  const subscribedTeam = useSubscribedTeam();
  const battleGroupName = useBattleGroupName();
  if (subscribedTeam === null) return null;
  if (battleGroupName === null) return null;
  return getPuzzlesInBattleGroup(subscribedTeam, battleGroupName);
};

export const usePuzzlesInSelectedBattleGroup = ():
  | ClientPuzzleGlobalState[]
  | null => {
  const subscribedTeam = useSubscribedTeam();
  const battleGroupName = useSelectedBattleGroupName();
  if (subscribedTeam === null) return null;
  if (battleGroupName === null) return null;
  return getPuzzlesInBattleGroup(subscribedTeam, battleGroupName);
};

export const getPuzNamesInBattleGroupFromPuzzlesDict = (
  puzzles: { [puzName: string]: PuzzleData | ClientPuzzleGlobalState } | null,
  battleGroupName: string | null
) => {
  if (puzzles === null || battleGroupName === null) return null;
  const battleMatches = Object.values(puzzles)
    .filter(
      ({ battleGroupName: otherBattleGroupName }) =>
        otherBattleGroupName === battleGroupName
    )
    .sort(({ order: order1 }, { order: order2 }) => order1 - order2);
  const isMultiPart = battleMatches.length > 1;
  return battleMatches
    .filter(
      ({ puzName: otherPuzName }) =>
        !isMultiPart || otherPuzName !== battleGroupName
    )
    .map(({ puzName }) => puzName);
};

export const getPuzzlesInBattleGroup = (
  subscribedTeam: ClientTeamGlobalState | null,
  battleGroupName: string | null
) => {
  const puzNames = getPuzNamesInBattleGroupFromPuzzlesDict(
    subscribedTeam?.puzzles ?? null,
    battleGroupName
  );
  if (subscribedTeam === null || puzNames === null) return null;
  return puzNames.map((puzName) => subscribedTeam.puzzles[puzName]);
};

export const useIsValidBattle = (): boolean | null => {
  const subscribedTeam = useSubscribedTeam();
  const battleGroup = useBattleGroup();
  const battleGroupName = useBattleGroupName();
  const puzName = usePuzName();
  if (subscribedTeam === null) return null;
  if (battleGroup === null || battleGroupName === null) return false;
  const isDummy =
    (battleGroup.numPuzzles ?? 1) > 1 && puzName === battleGroupName;
  const isUnknownBattle =
    subscribedTeam.puzzles[battleGroupName]?.hasAnswer ?? false;
  return !isDummy && !isUnknownBattle;
};

export const useNeedRedirectToPrep = (): boolean | null => {
  const isValidBattle = useIsValidBattle();
  const selectedPuzName = useSelectedPuzName();
  const subscribedTeamBattle = useSubscribedTeamBattle();
  // Don't wait for subscribedTeamBattle if invalid, since
  // we will never subscribe to it if so.
  if (isValidBattle !== null && !isValidBattle) return true;
  if (subscribedTeamBattle === null) return null;
  return selectedPuzName === null;
};

export const useCardUnlocksForSelectedPuzzle = () => {
  const selectedPuzName = useSelectedPuzName();
  const selectedBattleGroupName = useSelectedBattleGroupName();
  const subscribedTeam = useSubscribedTeam();
  if (
    subscribedTeam === null ||
    selectedPuzName === null ||
    selectedBattleGroupName === null
  )
    return null;
  const { cardUnlocks, puzzles } = subscribedTeam;
  // Special case to ignore card unlock attributions if the
  // puzzle is locked or unsolved.
  if (puzzles[selectedPuzName]?.solveTime === undefined) return [];
  return Object.keys(cardUnlocks).filter((cardName) => {
    const { puzName: unlockPuzName } = cardUnlocks[cardName];
    if (unlockPuzName === null) return false;
    const puzData = puzzles[unlockPuzName];
    if (puzData === undefined) return false;
    const { battleGroupName: unlockBattleGroupName } = puzData;
    return unlockPuzName === unlockBattleGroupName
      ? selectedBattleGroupName === unlockBattleGroupName
      : selectedPuzName === unlockPuzName;
  });
};

export const useSubscribedBattleStatus = (
  puzName: string | null
): GlobalStateBattleRoomStatus | null => {
  const teamId = useTeamId();
  const subscribedTeam = useSubscribedTeam(teamId);
  if (puzName === null) return null;
  if (subscribedTeam === null) return null;
  if (subscribedTeam.teamId !== teamId) return null;
  const puzData = subscribedTeam.puzzles[puzName];
  if (puzData === undefined) return null;
  return puzData.roomStatus ?? GlobalStateBattleRoomStatus.NONE;
};

export const useSelectedDeckData = (
  player: Player
): {
  decks: (Deck | null)[];
  isDeckLocked: boolean;
  canSelectDeck: boolean;
  needSelectDeck: boolean;
  selectedDeckSlot: number | null;
  selectedDeck: Deck | null;
  selectedDeckFailedChecks: FailedChecks;
  isSelectedDeckValid: boolean | null;
} => {
  const inspector = useStaticInspector();
  const subscribedTeamBattle = useSubscribedTeamBattle();
  const subscribedTeamDecks = useSubscribedTeamDecks();
  const { lockedDeckSelection = null } = subscribedTeamBattle ?? {};
  const { decks = [] } = subscribedTeamDecks ?? {};
  const isDeckLocked = lockedDeckSelection !== null;
  const canSelectDeck = inspector.getDeckOverride(player) === null;
  const needSelectDeck = canSelectDeck && !inspector.isNoDeckAllowed();
  const selectedDeckSlot = canSelectDeck
    ? lockedDeckSelection?.slot ??
      subscribedTeamBattle?.selectedDeckSlot ??
      null
    : null;
  const selectedDeck =
    lockedDeckSelection?.deck ??
    (selectedDeckSlot !== null
      ? decks[selectedDeckSlot] ?? createNewDeck(selectedDeckSlot)
      : null);
  const selectedDeckFailedChecks: FailedChecks = new Set();
  if (selectedDeck === null) {
    if (needSelectDeck) selectedDeckFailedChecks.add(Check.NO_DECK);
  } else {
    inspector.validateDeck(selectedDeckFailedChecks, player, selectedDeck);
  }
  const isSelectedDeckValid = selectedDeckFailedChecks.size === 0;
  return {
    decks,
    isDeckLocked,
    canSelectDeck,
    needSelectDeck,
    selectedDeckSlot,
    selectedDeck,
    selectedDeckFailedChecks,
    isSelectedDeckValid,
  };
};

export const useRoomPuzName = (): string | null => {
  const subscribedTeamBattle = useSubscribedTeamBattle();
  if (subscribedTeamBattle === null) return null;
  return subscribedTeamBattle.roomPuzName;
};

export const useRoomBattleGroup = (): ClientBattleGroupGlobalState | null => {
  const roomPuzName = useRoomPuzName();
  const subscribedTeam = useSubscribedTeam();
  return getBattleGroup(subscribedTeam, roomPuzName);
};

export const useRoomBattleGroupName = (): string | null => {
  const roomBattleGroup = useRoomBattleGroup();
  if (roomBattleGroup === null) return null;
  return roomBattleGroup.battleGroupName;
};

export const usePuzzlesInRoomBattleGroup = ():
  | ClientPuzzleGlobalState[]
  | null => {
  const subscribedTeam = useSubscribedTeam();
  const battleGroupName = useRoomBattleGroupName();
  if (subscribedTeam === null) return null;
  if (battleGroupName === null) return null;
  return getPuzzlesInBattleGroup(subscribedTeam, battleGroupName);
};

export const getIsGameSettingsEnabled = (
  roomPuzName: string | null,
  roomBattleGroupName: string | null
): boolean => {
  if (roomPuzName === null) return false;
  if (roomBattleGroupName === null) return false;
  return !(
    roomBattleGroupName === BATTLE_GROUP_NAME_TUTORIAL &&
    roomPuzName !== PUZ_NAME_KEROS_NOTES_UNLOCK
  );
};

export const useIsGameSettingsEnabled = (): boolean => {
  const roomPuzName = useRoomPuzName();
  const roomBattleGroupName = useRoomBattleGroupName();
  return getIsGameSettingsEnabled(roomPuzName, roomBattleGroupName);
};

export const useRoomBattleGroupFromGameState =
  (): ClientBattleGroupGlobalState | null => {
    const roomPuzName = useRoomPuzNameFromGameState();
    const subscribedTeam = useSubscribedTeam();
    return getBattleGroup(subscribedTeam, roomPuzName);
  };

export const useRoomBattleGroupNameFromGameState = (): string | null => {
  const roomBattleGroup = useRoomBattleGroupFromGameState();
  if (roomBattleGroup === null) return null;
  return roomBattleGroup.battleGroupName;
};

export const usePuzzlesInRoomBattleGroupFromGameState = ():
  | ClientPuzzleGlobalState[]
  | null => {
  const subscribedTeam = useSubscribedTeam();
  const battleGroupName = useRoomBattleGroupNameFromGameState();
  if (subscribedTeam === null) return null;
  if (battleGroupName === null) return null;
  return getPuzzlesInBattleGroup(subscribedTeam, battleGroupName);
};

export const useRoomSubpuzzleIndexFromGameState = (): number | null => {
  const puzName = useRoomPuzNameFromGameState();
  const puzzlesInBattleGroup = usePuzzlesInRoomBattleGroupFromGameState();
  if (puzzlesInBattleGroup === null) return null;
  const subpuzzleIndex = puzzlesInBattleGroup.findIndex(
    (puzData) => puzData.puzName === puzName
  );
  if (subpuzzleIndex === -1) return null;
  return subpuzzleIndex;
};

export const useIsAdminOrHuntOver = (): boolean | null => {
  const subscribedTeam = useSubscribedTeam();
  const isAdmin = useIsAdmin();
  if (subscribedTeam === null) return null;
  // Don't care too much about not being reactive to the current
  // time, since the discrepancy only happens once and the user
  // can just refresh.
  return Date.now() >= subscribedTeam.huntEndTime || isAdmin;
};

export const useShouldShowInitMap = (): boolean | null => {
  const subscribedTeam = useSubscribedTeam();
  if (subscribedTeam === null) return null;
  const { puzzles } = subscribedTeam;
  return Object.keys(puzzles).length <= 1;
};
