import { FC, SVGProps } from "react";

/* eslint-disable unused-imports/no-unused-imports */
import BackPain from "images/back-pain.svg";
import Biceps from "images/biceps.svg";
import BroadDagger from "images/broad-dagger.svg";
import Cancel from "images/cancel.svg";
import CardDraw from "images/card-draw.svg";
import ClockwiseRotation from "images/clockwise-rotation.svg";
import Cog from "images/cog.svg";
import CrossedSwords from "images/crossed-swords.svg";
import Crown from "images/crown.svg";
import CutDiamond from "images/cut-diamond.svg";
import Deck from "images/deck.svg";
import Empty from "images/empty.svg";
import ExitDoor from "images/exit-door.svg";
import Fist from "images/fist.svg";
import Health from "images/health.svg";
import HealthDecrease from "images/health-decrease.svg";
import HealthIncrease from "images/health-increase.svg";
import KnockedOutStars from "images/knocked-out-stars.svg";
import Load from "images/load.svg";
import Meeple from "images/meeple.svg";
import Move from "images/move.svg";
import NightSleep from "images/night-sleep.svg";
import PointySword from "images/pointy-sword.svg";
import PoisonBottle from "images/poison-bottle.svg";
import RoundStar from "images/round-star.svg";
import RuleBook from "images/rule-book.svg";
import Save from "images/save.svg";
import Shield from "images/shield.svg";
import Snowflake from "images/snowflake-1.svg";
import Spikes from "images/spikes.svg";
import Splash from "images/splash.svg";
import SwordBreak from "images/sword-break.svg";

import Boarry from "images/foods/R-boarry.svg";
import Milk from "images/foods/BW-milk.svg";
import Butter from "images/foods/B-butter.svg";
import Cream from "images/foods/W-cream.svg";
import Egg from "images/foods/G-egg.svg";
import Flower from "images/foods/P-flower.svg";
import Honey from "images/foods/Y-honey.svg";
import Pie from "images/foods/pie.svg";
import Rock from "images/foods/rock.svg";
/* eslint-enable unused-imports/no-unused-imports */

export enum IconType {
  ATTACK = "attack",
  CANCEL = "cancel",
  CREATE = "create",
  DECK = "deck",
  DRAW = "draw",
  FLEX = "flex",
  HEALTH = "health",
  MOVE = "move",
  OPTION = "option",
  POWER = "power",
  READY = "ready",
  SHELL = "shell",
  SPECIAL = "special",
  SUMMON = "splash",

  BOARRY = "boarry",
  MILK = "milk",
  BUTTER = "butter",
  CREAM = "cream",
  EGG = "egg",
  FLOWER = "flower",
  HONEY = "honey",
  PIE = "pie",
  ROCK = "rock",
}

// TS believes it's a string, but it's actually a react component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const iconToSvg: { [key in IconType]: any } = {
  [IconType.ATTACK]: CrossedSwords,
  [IconType.CANCEL]: Cancel,
  [IconType.CREATE]: CutDiamond,
  [IconType.DECK]: Deck,
  [IconType.DRAW]: CardDraw,
  [IconType.FLEX]: Biceps,
  [IconType.HEALTH]: Health,
  [IconType.MOVE]: Move,
  [IconType.OPTION]: RoundStar,
  [IconType.POWER]: Fist,
  [IconType.READY]: Spikes,
  [IconType.SHELL]: Shield,
  [IconType.SPECIAL]: RoundStar,
  [IconType.SUMMON]: Splash,

  [IconType.BOARRY]: Boarry,
  [IconType.MILK]: Milk,
  [IconType.BUTTER]: Butter,
  [IconType.CREAM]: Cream,
  [IconType.EGG]: Egg,
  [IconType.FLOWER]: Flower,
  [IconType.HONEY]: Honey,
  [IconType.PIE]: Pie,
  [IconType.ROCK]: Rock,
} as const;

interface IconProps {
  icon: IconType;
  color?: string;
}

export function Icon(props: IconProps & SVGProps<SVGSVGElement>) {
  const { color, icon } = props;
  const svg = iconToSvg[icon] as FC<SVGProps<SVGSVGElement>>;

  return svg({
    style: {
      fill: color ?? "var(--primary-50)",
      ...props.style,
    },
    ...props,
  });
}

export default Icon;
