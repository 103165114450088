import {
  NavTeamName,
  PreviousGuessesDisplay,
  SiteNavDisplay,
  SubmitAnswerForm,
} from "components/HuntLib";
import MYOSBResponse from "components/HuntLib/MYOSBResponse";
import { setupCardEmbeds } from "components/HuntLib/CardEmbed";
import { WrapupGallery } from "components/HuntLib/WrapupGallery";
import { Spinner } from "components/Loading";
import { GlobalUpdateType } from "game-server/global-updates";
import { CaveBackground } from "pages/ArtCreditsPage";
import Globals from "Globals";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import settings from "settings";
import { useClientGlobalStateStore } from "stores/ClientGlobalStateStore";
import "./HuntLib.css";

export const defaultOverlayScrollbarsOptions = {
  showNativeOverlaidScrollbars: true,
  scrollbars: { theme: "os-theme-light" },
};

export const setup = () => {
  const previousGuessesList = document.getElementById("previous-guesses-list");
  if (previousGuessesList !== null) {
    const teamId = settings.teamId;
    const { puzName, previousGuesses = [], numGuessesRemaining = 0 } = Globals;
    if (
      puzName === undefined ||
      previousGuesses === undefined ||
      numGuessesRemaining === undefined
    )
      throw new Error("Bootstrap Django data not found or incomplete");
    useClientGlobalStateStore.getState().applyGlobalUpdate({
      type: GlobalUpdateType.TEAM_FISH_PUZZLE_OVERRIDE_STATE,
      state: {
        teamId,
        puzName,
        numGuessesRemaining,
        previousGuesses,
      },
    });

    ReactDOM.createRoot(previousGuessesList).render(
      <React.StrictMode>
        <PreviousGuessesDisplay />
      </React.StrictMode>
    );
  }

  const submitAnswerForm = document.getElementById("solve-root");
  if (submitAnswerForm !== null) {
    ReactDOM.createRoot(submitAnswerForm).render(
      <React.StrictMode>
        <SubmitAnswerForm />
      </React.StrictMode>
    );
  }

  const myosbResponse = document.getElementById("myosb-response");
  if (myosbResponse !== null) {
    ReactDOM.createRoot(myosbResponse).render(
      <React.StrictMode>
        <MYOSBResponse />
      </React.StrictMode>
    );
  }

  const siteNav = document.querySelector(".site-nav");
  if (siteNav !== null) {
    ReactDOM.createRoot(siteNav).render(
      <React.StrictMode>
        <SiteNavDisplay />
      </React.StrictMode>
    );
  }

  const navTeamName = document.getElementById("nav-team-name");
  if (navTeamName !== null) {
    ReactDOM.createRoot(navTeamName).render(
      <React.StrictMode>
        <NavTeamName />
      </React.StrictMode>
    );
  }

  setupCardEmbeds();

  const wrapupGallery0 = document.getElementById("wrapup-gallery-0");
  const wrapupGallery1 = document.getElementById("wrapup-gallery-1");
  if (wrapupGallery0 !== null && wrapupGallery1 !== null) {
    ReactDOM.createRoot(wrapupGallery0).render(<WrapupGallery data={0} />);
    ReactDOM.createRoot(wrapupGallery1).render(<WrapupGallery data={1} />);
  }
  const wrapupBackground = document.getElementById("wrapup-background");
  if (wrapupBackground !== null) {
    ReactDOM.createRoot(wrapupBackground).render(<CaveBackground />);
  }

  const gameContainer = document.getElementById("game-root");
  if (gameContainer !== null) {
    const App = React.lazy(() => import("./App"));
    ReactDOM.createRoot(gameContainer).render(
      <React.StrictMode>
        <Suspense
          fallback={<Spinner size={128} text="Everything is going smoothly." />}
        >
          <App />
        </Suspense>
      </React.StrictMode>
    );
  }
};
