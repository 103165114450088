import { z } from "zod";

import {
  Deck,
  createNewDeck,
  DeckUpdate,
  applyDeckUpdate,
  DeckSelection,
} from "engine/types/decks";
import { Faction } from "engine/types/factions";
import { CardData } from "engine/types/card-data";
import {
  MapPosition,
  PuzzleData,
  BattleGroupData,
  PUZ_NAME_FINAL_BATTLE,
} from "engine/puzzles/puzzle-data";

import {
  MasteryTree,
  MasteryTreeUpdate,
  applyMasteryTreeUpdate,
} from "game-server/masteries";
import { PvPRequest } from "game-server/server-controllers";
import {
  ServerDirtyState,
  ServerDirtyStateUpdate,
  updateServerDirtyState,
  IntervalMetricsSummary,
  BigBoardTeamState,
  BigBoardTeamUpdate,
  updateBigBoardTeamState,
  TeamSummaryState,
  TeamSummaryUpdate,
  updateTeamSummary,
} from "game-server/monitoring";

// Global updates are updates to global (non-room) server state.
// Currently, these are used for monitoring from the admin room.
// However, they can also be extended to push other types of updates
// to all clients (or all clients of a team), such as notifications.

/**
 * Number of entries to show in the speedrun leaderboard summary on
 * the prep page.
 */
export const SPEEDRUN_LEADERBOARD_SUMMARY_LENGTH = 3;

export enum GlobalUpdatesScopeType {
  SERVER = "server",
  SERVER_HEALTH = "server_health",
  BIG_BOARD = "big_board",
  PVP = "pvp",
  TEAM_LIST = "team_list",
  TEAM_MEMBERS = "team_members",
  FACTION_HISTORY = "faction_history",
  TEAM = "team",
  TEAM_FISH_PUZZLE = "team_fish_puzzle",
  TEAM_BATTLE = "team_battle",
  TEAM_DECKS = "team_decks",
  TEAM_MASTERY_TREE = "team_mastery_tree",
  TEAM_PVP = "team_pvp",
  TEAM_SPEEDRUN_LEADERBOARD_SUMMARY = "team_speedrun_leaderboard_summary",
}

const ServerGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.SERVER),
});
type ServerGlobalUpdatesScope = z.infer<typeof ServerGlobalUpdatesScopeZod>;

const ServerHealthGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.SERVER_HEALTH),
});
type ServerHealthGlobalUpdatesScope = z.infer<
  typeof ServerHealthGlobalUpdatesScopeZod
>;

const BigBoardGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.BIG_BOARD),
});
type BigBoardGlobalUpdatesScope = z.infer<typeof BigBoardGlobalUpdatesScopeZod>;

const PvPGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.PVP),
});
type PvPGlobalUpdatesScope = z.infer<typeof PvPGlobalUpdatesScopeZod>;

const TeamListGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_LIST),
});
type TeamListGlobalUpdatesScope = z.infer<typeof TeamListGlobalUpdatesScopeZod>;

const TeamMembersGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_MEMBERS),
  teamId: z.string(),
});
type TeamMembersGlobalUpdatesScope = z.infer<
  typeof TeamMembersGlobalUpdatesScopeZod
>;

const FactionHistoryUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.FACTION_HISTORY),
});
type FactionHistoryUpdatesScope = z.infer<typeof FactionHistoryUpdatesScopeZod>;

const TeamGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM),
  teamId: z.string(),
});
type TeamGlobalUpdatesScope = z.infer<typeof TeamGlobalUpdatesScopeZod>;

const TeamFishPuzzleGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_FISH_PUZZLE),
  teamId: z.string(),
  puzName: z.string(),
});
type TeamFishPuzzleGlobalUpdatesScope = z.infer<
  typeof TeamFishPuzzleGlobalUpdatesScopeZod
>;

const TeamBattleGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_BATTLE),
  teamId: z.string(),
  puzName: z.string(),
});
type TeamBattleGlobalUpdatesScope = z.infer<
  typeof TeamBattleGlobalUpdatesScopeZod
>;

const TeamDecksGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_DECKS),
  teamId: z.string(),
});
type TeamDecksGlobalUpdatesScope = z.infer<
  typeof TeamDecksGlobalUpdatesScopeZod
>;

const TeamMasteryTreeGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_MASTERY_TREE),
  teamId: z.string(),
});
type TeamMasteryTreeGlobalUpdatesScope = z.infer<
  typeof TeamMasteryTreeGlobalUpdatesScopeZod
>;

const TeamPvPGlobalUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_PVP),
  teamId: z.string(),
});
type TeamPvPGlobalUpdatesScope = z.infer<typeof TeamPvPGlobalUpdatesScopeZod>;

const TeamSpeedrunLeaderboardSummaryUpdatesScopeZod = z.object({
  type: z.literal(GlobalUpdatesScopeType.TEAM_SPEEDRUN_LEADERBOARD_SUMMARY),
  teamId: z.string(),
  puzName: z.string(),
});
type TeamSpeedrunLeaderboardSummaryUpdatesScope = z.infer<
  typeof TeamSpeedrunLeaderboardSummaryUpdatesScopeZod
>;

const TeamHintsGlobalStateZod = z.object({
  numHintsTotal: z.number(),
  numHintsUsed: z.number(),
});
type TeamHintsGlobalState = z.infer<typeof TeamHintsGlobalStateZod>;

export const GlobalUpdatesScopeZod = z.union([
  ServerGlobalUpdatesScopeZod,
  ServerHealthGlobalUpdatesScopeZod,
  BigBoardGlobalUpdatesScopeZod,
  PvPGlobalUpdatesScopeZod,
  TeamListGlobalUpdatesScopeZod,
  TeamMembersGlobalUpdatesScopeZod,
  FactionHistoryUpdatesScopeZod,
  TeamGlobalUpdatesScopeZod,
  TeamFishPuzzleGlobalUpdatesScopeZod,
  TeamBattleGlobalUpdatesScopeZod,
  TeamDecksGlobalUpdatesScopeZod,
  TeamMasteryTreeGlobalUpdatesScopeZod,
  TeamPvPGlobalUpdatesScopeZod,
  TeamSpeedrunLeaderboardSummaryUpdatesScopeZod,
]);
export type GlobalUpdatesScope = z.infer<typeof GlobalUpdatesScopeZod>;

export type ClientTeamAdminDataGlobalState = {
  userId: string;
  displayName: string;
  isHidden: boolean;
  isInactive: boolean;
  isTempBlocked: boolean;
};

export type ClientTeamSpeedrunInfoGlobalState = {
  numTimes: number;
  totalTime: number;
};

export enum GlobalStateBattleRoomStatus {
  NONE = "none",
  INACTIVE = "inactive",
  ACTIVE = "active",
}

export type GlobalStatePuzzleStats = {
  unlockCount: number;
  solveCount: number;
};

/** A prompt for a puzzle. Currently only used for the mastery tree. */
export type PuzzlePrompt = {
  header: string;
  text: string;
};

/**
 * Information about unlocked puzzles that a client might
 * need. Should not contain spoilers about puzzles not yet unlocked.
 */
export type ClientPuzzleGlobalState = {
  puzName: string;
  battleGroupName: string;
  order: number;
  /** Timestamp of when the team unlocked the puzzle. */
  unlockTime: number;
  /** If set, the puzzle has not been seen by the team. */
  isNew?: boolean;
  /**
   * Timestamp of when the team solved the puzzle. Should always be
   * set if solved, since the client uses whether or not this
   * is set to check if the puzzle is solved.
   */
  solveTime?: number;
  /**
   * Whether the puzzle accepts an answer submission. This is set whether
   * or not the puzzle is solved.
   */
  hasAnswer?: boolean;
  /**
   * The canonical answer to the puzzle. Only set for fish puzzles if
   * the team has solved the puzzle.
   */
  answer?: string;
  /**
   * The room status of the battle. Only set for battles, and if the
   * room status is not NONE.
   */
  roomStatus?: GlobalStateBattleRoomStatus;
  /**
   * The best speedrun time the team has achieved for this puzzle.
   * Only set if the team has solved the puzzle.
   */
  speedrunTime?: number;
  /** A prompt for the puzzle. Currently only used for the mastery tree. */
  puzPrompt?: PuzzlePrompt;
};

export type ClientBattleGroupGlobalState = {
  battleGroupName: string;
  displayName: string;
  isCutscene?: boolean;
  isLegendary?: boolean;
  mapPos?: MapPosition;
  /** Number of puzzles in battle group, if more than one. */
  numPuzzles?: number;
  stats?: GlobalStatePuzzleStats;
  preBattleDialogue?: string;
  postBattleDialogue?: string;
};

export type ClientCardUnlockGlobalState = {
  cardName: string;
  puzName: string | null;
};

export type ClientMasteryGlobalState = {
  masteryId: string;
  /**
   * Display name. May be omitted if the team hasn't unlocked the mastery.
   */
  displayName?: string;
  /**
   * Effect text. May be omitted if the team hasn't unlocked the mastery.
   */
  effectText?: string;
  order: number;
  x: number;
  y: number;
  isEnabled: boolean;
};

export type ClientTeamGlobalState = {
  teamId: string;
  displayName: string;
  faction: Faction | null;
  factionScoreContributions: {
    [faction in Faction]: number;
  };
  factionScores: {
    [faction in Faction]: number;
  };
  /** Details about puzzles unlocked by the team. */
  puzzles: { [puzName: string]: ClientPuzzleGlobalState };
  /** Battle groups unlocked by the team. */
  battleGroups: { [puzName: string]: ClientBattleGroupGlobalState };
  /** Cards unlocked by the team. */
  cardUnlocks: { [cardName: string]: ClientCardUnlockGlobalState };
  /** Secret card data that the client might unlock. */
  extraCards?: CardData[];
  masteries: { [masteryId: string]: ClientMasteryGlobalState };
  /** Number of hints remaining, directly from Django */
  hintsInfo: TeamHintsGlobalState;
  /** When the hunt ends. */
  huntEndTime: number;
};

export type ClientActiveGameGlobalState = {
  enabledMasteries: { [masteryId: string]: boolean };
  faction: Faction | null;
};

export type TeamNameDisplayData =
  | {
      teamId: string;
      displayName: string;
      faction: Faction | null;
      factionScoreContribution: number;
      hasReputationBoost?: true;
    }
  | TeamSummaryState;

export type TeamNameDisplayDataUpdate = {
  displayName?: string;
  faction?: Faction | null;
  factionScoreContribution?: number;
  hasReputationBoost?: boolean;
};

export const updateTeamNameDisplayData = (
  teamData: TeamNameDisplayData,
  upd: TeamNameDisplayDataUpdate
) => {
  const { hasReputationBoost, ...otherUpds } = upd;
  if (hasReputationBoost !== undefined) {
    if (hasReputationBoost) teamData.hasReputationBoost = true;
    else delete teamData.hasReputationBoost;
  }
  Object.assign(teamData, otherUpds);
};

export type ClientSpeedrunTimeRecord = {
  teamData: TeamNameDisplayData;
  /** Run time, in milliseconds. */
  speedrunTime: number;
};

export type ClientErratum = {
  erratumId: string;
  text: string;
  timestamp: number;
  published: boolean;
};

export type CheckpointSummary = {
  turnNumber: number;
  roomId: string;
  timestamp: number;
};

type ClientTeamBattleGlobalState = {
  teamId: string;
  puzName: string;
  /** The selected puzzle; only relevant for instancer rooms. */
  selectedPuzName: string | null;
  /**
   * The puzName of the current room; only relevant for instancer rooms.
   * This may differ from selectedPuzName since the selectedPuzName may
   * change while the client is viewing an ended battle.
   */
  roomPuzName: string | null;
  /** The deck selection on the prep page. */
  selectedDeckSlot: number | null;
  /**
   * The deck selection shown locked on the prep page, if any.
   * This overrides the selected deck slot in render.
   */
  lockedDeckSelection: DeckSelection | null;
  /**
   * Information about the active game, if there is an active game ongoing.
   */
  activeGame: ClientActiveGameGlobalState | null;
  errata: { [erratumId: string]: ClientErratum };
  checkpointSummaries: { [slot: number]: CheckpointSummary };
};

export type ClientAnswerSubmissionGlobalState = {
  submittedAnswer: string;
  timestamp: number;
};

export type ClientTeamFishPuzzleGlobalState = {
  teamId: string;
  puzName: string;
  numGuessesRemaining: number;
  previousGuesses: ClientAnswerSubmissionGlobalState[];
};

type ClientTeamDecksGlobalState = {
  teamId: string;
  decks: (Deck | null)[];
};

type ClientTeamMasteryTreeGlobalState = {
  teamId: string;
  masteryTree: MasteryTree;
};

type ClientTeamPvPGlobalState = {
  teamId: string;
  outgoingRequests: { [teamId: string]: PvPRequest };
  incomingRequests: { [teamId: string]: PvPRequest };
  blockedTeams: { [teamId: string]: true };
  blockedByTeams: { [teamId: string]: true };
};

export type ClientPvPGlobalState = {
  /** Team IDs of teams in the ring, from the least to most recent. */
  ring: string[];
};

export type ClientTeamSpeedrunLeaderboardSummaryGlobalState = {
  teamId: string;
  puzName: string;
  /**
   * Top N speedrun times. Only updated when the speedrun mastery is
   * enabled and the puzzle is solved.
   */
  speedrunLeaderboardSummary?: ClientSpeedrunTimeRecord[];
  /**
   * Rank in the speedrun leaderboard. Only updated when the speedrun
   * mastery is enabled and the puzzle is solved.
   */
  speedrunRank?: number;
};

export type ClientServerHealthGlobalState = {
  fastSyncMetrics: IntervalMetricsSummary;
  slowSyncMetrics: IntervalMetricsSummary;
  checkAnswerMetrics: IntervalMetricsSummary;
  numAuthsMetrics: IntervalMetricsSummary;
  numRequestsMetrics: IntervalMetricsSummary;

  teamNumAuthsMetrics: { [teamId: string]: IntervalMetricsSummary };
  teamNumRequestsMetrics: { [teamId: string]: IntervalMetricsSummary };

  fastSyncQueueLength: number;
  numActivePeriodicTasksHandlers: number;
  numPendingSubmissionTasks: number;
  numCompletedRoomsToSync: number;
  teamInitQueueLength: number;
  numActiveConns: number;
  userTimeUsage: number;
  systemTimeUsage: number;

  dirtyState: ServerDirtyState;
};

export type ClientBigBoardGlobalState = {
  teams: { [teamId: string]: BigBoardTeamState };
};

export type ClientTeamMembersGlobalState = {
  teamId: string;
  members: string[];
};

export type ClientGlobalState = {
  teamAdminData: { [teamId: string]: ClientTeamAdminDataGlobalState };
  teams: { [teamId: string]: TeamSummaryState };
  /** Full puzzle data; only accessible by admin. */
  puzzles: { [puzName: string]: PuzzleData };
  /** Full battle group data; only accessible by admin. */
  battleGroups: { [puzName: string]: BattleGroupData };
  /** Full mastery data; only accessible by admin. */
  masteries: { [masteryId: string]: { displayName: string } };
  factionHistory: { [key: string]: number };
  serverHealth: ClientServerHealthGlobalState | null;
  bigBoard: ClientBigBoardGlobalState | null;
  pvp: ClientPvPGlobalState | null;
  subscribedTeam: ClientTeamGlobalState | null;
  subscribedTeamBattle: ClientTeamBattleGlobalState | null;
  subscribedTeamFishPuzzle: ClientTeamFishPuzzleGlobalState | null;
  subscribedTeamDecks: ClientTeamDecksGlobalState | null;
  subscribedTeamMasteryTree: ClientTeamMasteryTreeGlobalState | null;
  subscribedTeamPvP: ClientTeamPvPGlobalState | null;
  subscribedTeamSpeedrunLeaderboardSummary: ClientTeamSpeedrunLeaderboardSummaryGlobalState | null;
  subscribedTeamMembers: ClientTeamMembersGlobalState | null;
  unclaimedHints: number;
};

export const makeInitClientGlobalState = (): ClientGlobalState => {
  return {
    teamAdminData: {},
    teams: {},
    puzzles: {},
    battleGroups: {},
    masteries: {},
    factionHistory: {},
    serverHealth: null,
    bigBoard: null,
    pvp: null,
    subscribedTeam: null,
    subscribedTeamBattle: null,
    subscribedTeamFishPuzzle: null,
    subscribedTeamDecks: null,
    subscribedTeamMasteryTree: null,
    subscribedTeamPvP: null,
    subscribedTeamSpeedrunLeaderboardSummary: null,
    subscribedTeamMembers: null,
    unclaimedHints: 0,
  };
};

export const getPuzzleStageNumbers = (
  puzzles: { [puzName: string]: PuzzleData },
  battleGroups: { [puzName: string]: BattleGroupData }
): { [puzName: string]: number } => {
  const stageNumbers: { [puzName: string]: number } = {};
  const numInBattleGroup: { [battleGroupName: string]: number } = {};
  for (const { puzName, battleGroupName } of Object.values(puzzles).sort(
    (p1, p2) => p1.order - p2.order
  )) {
    const battleGroup = battleGroups[battleGroupName];
    const stageNum = numInBattleGroup[battleGroupName] ?? 0;
    stageNumbers[puzName] = stageNum;
    numInBattleGroup[battleGroupName] = stageNum + 1;
  }
  return stageNumbers;
};

export enum GlobalUpdateType {
  SERVER_OVERRIDE_STATE = "server_override_state",
  SERVER_ADD_TEAM = "server_add_team",
  SERVER_UPDATE_TEAM = "server_update_team",
  SERVER_UNCLAIMED_HINTS = "server_unclaimed_hints",

  SERVER_HEALTH_OVERRIDE_STATE = "server_health_override_state",
  SERVER_HEALTH_UPDATE_STATE = "server_health_update_state",

  BIG_BOARD_OVERRIDE_STATE = "big_board_override_state",
  BIG_BOARD_UPDATE_STATE = "big_board_update_state",

  PVP_OVERRIDE_STATE = "pvp_override_state",
  PVP_UPDATE_STATE = "pvp_update_state",

  TEAM_LIST_OVERRIDE_STATE = "team_list_override_state",
  TEAM_LIST_UPDATE_STATE = "team_list_update_state",

  TEAM_MEMBERS_OVERRIDE_STATE = "team_members_override_state",
  TEAM_MEMBERS_UPDATE_STATE = "team_members_update_state",

  FACTION_HISTORY_OVERRIDE_STATE = "faction_history_override_state",
  FACTION_HISTORY_UPDATE_STATE = "faction_history_update_state",

  TEAM_OVERRIDE_STATE = "team_override_state",
  TEAM_UPDATE_STATE = "team_update_state",
  TEAM_UPDATE_PUZZLE = "team_update_puzzle",
  TEAM_UPDATE_PUZZLE_STATS = "team_update_puzzle_stats",
  TEAM_UPDATE_BATTLE_GROUP = "team_update_battle_group",

  TEAM_BATTLE_OVERRIDE_STATE = "team_battle_override_state",
  TEAM_BATTLE_UPDATE_STATE = "team_battle_update_state",

  TEAM_FISH_PUZZLE_OVERRIDE_STATE = "team_fish_puzzle_override_state",
  TEAM_FISH_PUZZLE_SET_NUM_GUESSES_REMAINING = "team_fish_puzzle_set_num_guesses_remaining",
  TEAM_FISH_PUZZLE_ADD_GUESS = "team_fish_puzzle_add_guess",

  TEAM_DECKS_OVERRIDE_STATE = "team_decks_override_state",
  TEAM_DECKS_UPDATE_DECK = "team_decks_update_deck",

  TEAM_MASTERY_TREE_OVERRIDE_STATE = "team_mastery_tree_override_state",
  TEAM_MASTERY_TREE_UPDATE = "team_mastery_tree_update",

  TEAM_PVP_OVERRIDE_STATE = "team_pvp_override_state",
  TEAM_PVP_UPDATE_STATE = "team_pvp_update_state",

  TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_OVERRIDE_STATE = "team_speedrun_leaderboard_summary_override_state",
  TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_UPDATE_STATE = "team_speedrun_leaderboard_summary_update_state",
}

type ServerOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.SERVER_OVERRIDE_STATE;
  teamAdminData: { [teamId: string]: ClientTeamAdminDataGlobalState };
  puzzles: { [puzName: string]: PuzzleData };
  battleGroups: { [battleGroupName: string]: BattleGroupData };
  masteries: { [masteryId: string]: { displayName: string } };
  unclaimedHints: number;
};

type ServerAddTeamGlobalUpdate = {
  type: GlobalUpdateType.SERVER_ADD_TEAM;
  teamId: string;
  teamAdminData: ClientTeamAdminDataGlobalState;
};

type ServerUpdateTeamGlobalUpdate = {
  type: GlobalUpdateType.SERVER_UPDATE_TEAM;
  teamId: string;
  isHidden?: boolean;
  isInactive?: boolean;
  displayName?: string;
};

type ServerUnclaimedHintsGlobalUpdate = {
  type: GlobalUpdateType.SERVER_UNCLAIMED_HINTS;
  unclaimedHints: number;
};

type ServerHealthOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.SERVER_HEALTH_OVERRIDE_STATE;
  state: ClientServerHealthGlobalState;
};

type ServerHealthUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.SERVER_HEALTH_UPDATE_STATE;
  fastSyncMetrics: IntervalMetricsSummary;
  slowSyncMetrics: IntervalMetricsSummary;
  checkAnswerMetrics: IntervalMetricsSummary;
  numAuthsMetrics: IntervalMetricsSummary;
  numRequestsMetrics: IntervalMetricsSummary;

  teamNumAuthsMetrics: { [teamId: string]: IntervalMetricsSummary };
  teamNumRequestsMetrics: { [teamId: string]: IntervalMetricsSummary };

  fastSyncQueueLength: number;
  numActivePeriodicTasksHandlers: number;
  numPendingSubmissionTasks: number;
  numCompletedRoomsToSync: number;
  teamInitQueueLength: number;
  numActiveConns: number;
  userTimeUsage: number;
  systemTimeUsage: number;
  dirtyStateUpd: ServerDirtyStateUpdate;
};

type BigBoardOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.BIG_BOARD_OVERRIDE_STATE;
  state: ClientBigBoardGlobalState;
};

type BigBoardUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.BIG_BOARD_UPDATE_STATE;
  addTeams?: { [teamId: string]: BigBoardTeamState };
  updTeams?: { [teamId: string]: BigBoardTeamUpdate };
};

type PvPOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.PVP_OVERRIDE_STATE;
  state: ClientPvPGlobalState;
};

type PvPUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.PVP_UPDATE_STATE;
  ringTeamsToAdd?: string[];
  ringTeamsToDelete?: string[];
};

type TeamListOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_LIST_OVERRIDE_STATE;
  teams: { [teamId: string]: TeamSummaryState };
};

type TeamListUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_LIST_UPDATE_STATE;
  addTeams?: { [teamId: string]: TeamSummaryState };
  removeTeams?: { [teamId: string]: true };
  updTeams?: { [teamId: string]: TeamSummaryUpdate };
};

type TeamMembersOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_MEMBERS_OVERRIDE_STATE;
  state: ClientTeamMembersGlobalState;
};

type TeamMembersUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_MEMBERS_UPDATE_STATE;
  members: string[];
};

type FactionHistoryOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.FACTION_HISTORY_OVERRIDE_STATE;
  factionHistory: { [key: string]: number };
};

type FactionHistoryUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.FACTION_HISTORY_UPDATE_STATE;
  factionHistory: { [key: string]: number };
};

type TeamOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_OVERRIDE_STATE;
  state: ClientTeamGlobalState;
};

type TeamUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_UPDATE_STATE;
  displayName?: string;
  faction?: Faction | null;
  factionScoreContributions?: {
    [faction in Faction]?: number;
  };
  factionScores?: {
    [faction in Faction]?: number;
  };
  /** Solve times to set/unset. */
  solveTimes?: { [puzName: string]: number | null };
  /** Canonical answers to reveal. */
  answers?: { [puzName: string]: string };
  unlocks?: { [puzName: string]: ClientPuzzleGlobalState | null };
  battleGroupUnlocks?: { [puzName: string]: ClientBattleGroupGlobalState };
  cardUnlocks?: { [cardName: string]: ClientCardUnlockGlobalState | null };
  speedrunTimes?: { [puzName: string]: number };
  masteries?: {
    [masteryId: string]: {
      displayName: string;
      effectText: string;
    } | null;
  };
  /** Masteries to enable or disable. */
  enabledMasteries?: { [masteryId: string]: boolean };
  hintsInfo?: TeamHintsGlobalState;
};

type TeamUpdatePuzzleGlobalUpdate = {
  type: GlobalUpdateType.TEAM_UPDATE_PUZZLE;
  puzName: string;
  roomStatus?: GlobalStateBattleRoomStatus;
  isNew?: boolean;
};

type TeamUpdatePuzzleStatsGlobalUpdate = {
  type: GlobalUpdateType.TEAM_UPDATE_PUZZLE_STATS;
  stats: { [battleGroupName: string]: GlobalStatePuzzleStats };
};

type TeamUpdateBattleGroupGlobalUpdate = {
  type: GlobalUpdateType.TEAM_UPDATE_BATTLE_GROUP;
  battleGroupName: string;
  postBattleDialogue?: string;
};

type TeamBattleOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_BATTLE_OVERRIDE_STATE;
  state: ClientTeamBattleGlobalState;
};

export type TeamBattleUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_BATTLE_UPDATE_STATE;
  selectedPuzName?: string | null;
  roomPuzName?: string | null;
  selectedDeckSlot?: number;
  lockedDeckSelection?: DeckSelection | null;
  activeGame?: ClientActiveGameGlobalState | null;
  errata?: { [erratumId: string]: ClientErratum };
  checkpointSummaries?: { [slot: number]: CheckpointSummary };
};

type TeamFishPuzzleOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_FISH_PUZZLE_OVERRIDE_STATE;
  state: ClientTeamFishPuzzleGlobalState;
};

type TeamFishPuzzleSetNumGuessesRemainingGlobalUpdate = {
  type: GlobalUpdateType.TEAM_FISH_PUZZLE_SET_NUM_GUESSES_REMAINING;
  numGuessesRemaining: number;
};

type TeamFishPuzzleAddSubmissionGlobalUpdate = {
  type: GlobalUpdateType.TEAM_FISH_PUZZLE_ADD_GUESS;
  submission: ClientAnswerSubmissionGlobalState;
};

type TeamDecksOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_DECKS_OVERRIDE_STATE;
  state: ClientTeamDecksGlobalState;
};

type TeamDecksUpdateDeckGlobalUpdate = {
  type: GlobalUpdateType.TEAM_DECKS_UPDATE_DECK;
  slot: number;
  deckUpd: DeckUpdate;
};

type TeamMasteryTreeOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_MASTERY_TREE_OVERRIDE_STATE;
  state: ClientTeamMasteryTreeGlobalState;
};

type TeamMasteryTreeUpdateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_MASTERY_TREE_UPDATE;
  masteryTreeUpd?: MasteryTreeUpdate;
};

type TeamPvPOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_PVP_OVERRIDE_STATE;
  state: ClientTeamPvPGlobalState;
};

type TeamPvPUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_PVP_UPDATE_STATE;
  outgoingRequestsToAdd?: PvPRequest[];
  outgoingRequestsToDelete?: string[];
  incomingRequestsToAdd?: PvPRequest[];
  incomingRequestsToDelete?: string[];
  blockedTeamsToAdd?: string[];
  blockedTeamsToDelete?: string[];
  blockedByTeamsToAdd?: string[];
  blockedByTeamsToDelete?: string[];
  clearOutgoingRequests?: boolean;
  clearIncomingRequests?: boolean;
};

type TeamSpeedrunLeaderboardSummaryOverrideStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_OVERRIDE_STATE;
  state: ClientTeamSpeedrunLeaderboardSummaryGlobalState;
};

type TeamSpeedrunLeaderboardSummaryUpdateStateGlobalUpdate = {
  type: GlobalUpdateType.TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_UPDATE_STATE;
  speedrunRecordsToAdd?: ClientSpeedrunTimeRecord[];
  /**
   * Team IDs of speedrun records to remove, e.g. for teams
   * getting hidden.
   */
  speedrunRecordsToRemove?: string[];
  teamNameDisplayUpds?: { [teamId: string]: TeamNameDisplayDataUpdate };
  speedrunRank?: number;
};

export type GlobalUpdate =
  | ServerOverrideStateGlobalUpdate
  | ServerAddTeamGlobalUpdate
  | ServerUpdateTeamGlobalUpdate
  | ServerUnclaimedHintsGlobalUpdate
  | ServerHealthOverrideStateGlobalUpdate
  | ServerHealthUpdateStateGlobalUpdate
  | BigBoardOverrideStateGlobalUpdate
  | BigBoardUpdateStateGlobalUpdate
  | PvPOverrideStateGlobalUpdate
  | PvPUpdateStateGlobalUpdate
  | TeamListOverrideStateGlobalUpdate
  | TeamListUpdateStateGlobalUpdate
  | TeamMembersOverrideStateGlobalUpdate
  | TeamMembersUpdateStateGlobalUpdate
  | FactionHistoryOverrideStateGlobalUpdate
  | FactionHistoryUpdateStateGlobalUpdate
  | TeamOverrideStateGlobalUpdate
  | TeamUpdateStateGlobalUpdate
  | TeamUpdatePuzzleGlobalUpdate
  | TeamUpdatePuzzleStatsGlobalUpdate
  | TeamUpdateBattleGroupGlobalUpdate
  | TeamBattleOverrideStateGlobalUpdate
  | TeamBattleUpdateStateGlobalUpdate
  | TeamFishPuzzleOverrideStateGlobalUpdate
  | TeamFishPuzzleSetNumGuessesRemainingGlobalUpdate
  | TeamFishPuzzleAddSubmissionGlobalUpdate
  | TeamDecksOverrideStateGlobalUpdate
  | TeamDecksUpdateDeckGlobalUpdate
  | TeamMasteryTreeOverrideStateGlobalUpdate
  | TeamMasteryTreeUpdateGlobalUpdate
  | TeamPvPOverrideStateGlobalUpdate
  | TeamPvPUpdateStateGlobalUpdate
  | TeamSpeedrunLeaderboardSummaryOverrideStateGlobalUpdate
  | TeamSpeedrunLeaderboardSummaryUpdateStateGlobalUpdate;

/** The verb to use to communicate that a battle group was completed. */
export const getCompletionVerb = (
  battleGroup: ClientBattleGroupGlobalState
): string => {
  if (battleGroup.battleGroupName === PUZ_NAME_FINAL_BATTLE) return "Defeated";
  if (battleGroup.isLegendary ?? false) return "Befriended";
  return "Completed";
};

export const speedrunTimeToString = (
  speedrunTime: number,
  omitMs?: boolean
): string => {
  const hours = Math.floor(speedrunTime / 1000 / 60 / 60);
  const minutes = Math.floor(speedrunTime / 1000 / 60) % 60;
  const seconds = Math.floor(speedrunTime / 1000) % 60;
  const ms = Math.floor(speedrunTime) % 1000;
  const omitHours = hours === 0;
  const omitMinutes = omitHours && minutes === 0;
  omitMs ??= !omitHours;
  const hoursStr = hours.toString();
  const minutesStr = minutes.toString().padStart(omitHours ? 1 : 2, "0");
  const secondsStr = seconds.toString().padStart(omitMinutes ? 1 : 2, "0");
  const msStr = ms.toString().padStart(3, "0");
  return `${omitHours ? "" : `${hoursStr}h`}${
    omitMinutes ? "" : `${minutesStr}m`
  }${secondsStr}${omitMs ? "" : `.${msStr}`}s`;
};

/**
 * Utility to help with maintaining leaderboard lists.
 * Each team should only be present in the leaderboard at most once.
 */
export class RankedLeaderboard<T> {
  /**
   * Get the key for obj, which is used to check if a new entry
   * should overwrite an old one, or to remove entries.
   */
  getKeyFunc: (obj: T) => string;
  compareFunc: (obj1: T, obj2: T) => number;
  /** Sorted list of records from best to worst. */
  sortedList: T[];

  constructor(
    getKeyFunc: (obj: T) => string,
    compareFunc: (obj1: T, obj2: T) => number,
    sortedList?: T[]
  ) {
    this.getKeyFunc = getKeyFunc;
    this.compareFunc = compareFunc;
    this.sortedList = sortedList ?? [];
  }

  /**
   * Removes an entry with the provided key, if present.
   * If an object was removed, returns the removed object and its
   * original position.
   */
  remove(objKey: string): {
    obj: T;
    oldIndex: number;
  } | null {
    const oldIndex = this.sortedList.findIndex(
      (listObj) => objKey === this.getKeyFunc(listObj)
    );
    if (oldIndex === -1) return null;
    const obj = this.sortedList[oldIndex];
    this.sortedList.splice(oldIndex, 1);
    return { obj, oldIndex };
  }

  /**
   * Insert a new record. If warnOnRegress is set, an error message will
   * be printed if we attempt to insert a regression.
   */
  insert(
    obj: T,
    warnOnRegress: boolean
  ): {
    /** The index of the removed record, if any. */
    oldIndex: number | null;
    /** The index of the inserted record. */
    newIndex: number;
  } {
    const objKey = this.getKeyFunc(obj);
    // Remove any old object from the list.
    const removeRes = this.remove(objKey);
    if (removeRes !== null) {
      const { oldIndex, obj: oldObj } = removeRes;
      // We shouldn't be trying to insert regressions, but check
      // to be safe anyway.
      if (this.compareFunc(obj, oldObj) >= 0) {
        if (warnOnRegress)
          console.error(
            `tried to insert a regression: old ${JSON.stringify(
              oldObj
            )}, new ${JSON.stringify(obj)}`
          );
        // Put the old object back in.
        this.sortedList.splice(oldIndex, 0, oldObj);
        return {
          oldIndex,
          newIndex: oldIndex,
        };
      }
    }

    const newIndex = ((): number => {
      for (let i = 0; i < this.sortedList.length; i++) {
        // Break ties by inserting at the end of the group
        if (this.compareFunc(obj, this.sortedList[i]) < 0) {
          this.sortedList.splice(i, 0, obj);
          return i;
        }
      }

      // If we get here, then the element goes at the end of the list.
      this.sortedList.push(obj);
      return this.sortedList.length - 1;
    })();

    return {
      oldIndex: removeRes === null ? null : removeRes.oldIndex,
      newIndex,
    };
  }
}

export const applyGlobalUpdate = (
  globalState: ClientGlobalState,
  upd: GlobalUpdate
): void => {
  switch (upd.type) {
    case GlobalUpdateType.SERVER_OVERRIDE_STATE: {
      const {
        puzzles,
        battleGroups,
        masteries,
        teamAdminData,
        unclaimedHints,
      } = upd;
      globalState.puzzles = puzzles;
      globalState.battleGroups = battleGroups;
      globalState.masteries = masteries;
      globalState.teamAdminData = JSON.parse(JSON.stringify(teamAdminData)) as {
        [teamId: string]: ClientTeamAdminDataGlobalState;
      };
      globalState.unclaimedHints = unclaimedHints;
      break;
    }
    case GlobalUpdateType.SERVER_ADD_TEAM: {
      const { teamId, teamAdminData } = upd;
      globalState.teamAdminData[teamId] = teamAdminData;
      break;
    }
    case GlobalUpdateType.SERVER_UPDATE_TEAM: {
      const { teamId, isHidden, isInactive, displayName } = upd;
      const teamAdminData = globalState.teamAdminData[teamId];
      if (teamAdminData === undefined)
        throw new Error("received admin data update for non-existent team");
      if (isHidden !== undefined) teamAdminData.isHidden = isHidden;
      if (isInactive !== undefined) teamAdminData.isInactive = isInactive;
      if (displayName !== undefined) teamAdminData.displayName = displayName;
      break;
    }
    case GlobalUpdateType.SERVER_UNCLAIMED_HINTS: {
      globalState.unclaimedHints = upd.unclaimedHints;
      break;
    }
    case GlobalUpdateType.SERVER_HEALTH_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.serverHealth = JSON.parse(
        JSON.stringify(state)
      ) as ClientServerHealthGlobalState;
      break;
    }
    case GlobalUpdateType.SERVER_HEALTH_UPDATE_STATE: {
      const {
        fastSyncMetrics,
        slowSyncMetrics,
        checkAnswerMetrics,
        numAuthsMetrics,
        numRequestsMetrics,
        teamNumAuthsMetrics,
        teamNumRequestsMetrics,
        fastSyncQueueLength,
        numActivePeriodicTasksHandlers,
        numPendingSubmissionTasks,
        numCompletedRoomsToSync,
        teamInitQueueLength,
        numActiveConns,
        userTimeUsage,
        systemTimeUsage,
        dirtyStateUpd,
      } = upd;
      const { serverHealth } = globalState;
      if (serverHealth === null)
        throw new Error("expect to have subscribed server health state");
      serverHealth.fastSyncMetrics = fastSyncMetrics;
      serverHealth.slowSyncMetrics = slowSyncMetrics;
      serverHealth.checkAnswerMetrics = checkAnswerMetrics;
      serverHealth.numAuthsMetrics = numAuthsMetrics;
      serverHealth.numRequestsMetrics = numRequestsMetrics;
      Object.assign(serverHealth.teamNumAuthsMetrics, teamNumAuthsMetrics);
      Object.assign(
        serverHealth.teamNumRequestsMetrics,
        teamNumRequestsMetrics
      );
      serverHealth.fastSyncQueueLength = fastSyncQueueLength;
      serverHealth.numActivePeriodicTasksHandlers =
        numActivePeriodicTasksHandlers;
      serverHealth.numPendingSubmissionTasks = numPendingSubmissionTasks;
      serverHealth.numCompletedRoomsToSync = numCompletedRoomsToSync;
      serverHealth.teamInitQueueLength = teamInitQueueLength;
      serverHealth.numActiveConns = numActiveConns;
      serverHealth.userTimeUsage = userTimeUsage;
      serverHealth.systemTimeUsage = systemTimeUsage;
      updateServerDirtyState(serverHealth.dirtyState, dirtyStateUpd);
      break;
    }
    case GlobalUpdateType.BIG_BOARD_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.bigBoard = JSON.parse(
        JSON.stringify(state)
      ) as ClientBigBoardGlobalState;
      break;
    }
    case GlobalUpdateType.BIG_BOARD_UPDATE_STATE: {
      const { addTeams, updTeams } = upd;
      const { bigBoard } = globalState;
      if (bigBoard === null)
        throw new Error("expect to have subscribed big board state");
      Object.assign(bigBoard.teams, addTeams ?? {});
      for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
        const teamState = bigBoard.teams[teamId];
        if (teamState === undefined)
          throw new Error("received big board update for non-existent team");
        updateBigBoardTeamState(teamState, teamUpd);
      }
      break;
    }
    case GlobalUpdateType.PVP_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.pvp = JSON.parse(
        JSON.stringify(state)
      ) as ClientPvPGlobalState;
      break;
    }
    case GlobalUpdateType.PVP_UPDATE_STATE: {
      const { ringTeamsToAdd, ringTeamsToDelete } = upd;
      const { pvp } = globalState;
      if (pvp === null) throw new Error("expect to have subscribed pvp state");
      pvp.ring.push(...(ringTeamsToAdd ?? []));
      if (ringTeamsToDelete !== undefined) {
        const ringTeamsToDeleteSet = new Set(ringTeamsToDelete);
        pvp.ring = pvp.ring.filter(
          (teamId) => !ringTeamsToDeleteSet.has(teamId)
        );
      }
      break;
    }
    case GlobalUpdateType.TEAM_LIST_OVERRIDE_STATE: {
      const { teams } = upd;
      globalState.teams = JSON.parse(JSON.stringify(teams)) as {
        [teamId: string]: TeamSummaryState;
      };
      break;
    }
    case GlobalUpdateType.TEAM_LIST_UPDATE_STATE: {
      const { addTeams, removeTeams, updTeams } = upd;
      const { teams } = globalState;
      Object.assign(teams, addTeams ?? {});
      for (const teamId of Object.keys(removeTeams ?? {})) {
        delete teams[teamId];
      }
      for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
        const teamState = teams[teamId];
        if (teamState === undefined)
          throw new Error("received team list update for non-existent team");
        updateTeamSummary(teamState, teamUpd);
      }
      break;
    }
    case GlobalUpdateType.TEAM_MEMBERS_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamMembers = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamMembersGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_MEMBERS_UPDATE_STATE: {
      const { members } = upd;
      const { subscribedTeamMembers } = globalState;
      if (subscribedTeamMembers === null)
        throw new Error("expect to have subscribed team members state");
      subscribedTeamMembers.members = members;
      break;
    }
    case GlobalUpdateType.FACTION_HISTORY_OVERRIDE_STATE: {
      globalState.factionHistory = { ...upd.factionHistory };
      break;
    }
    case GlobalUpdateType.FACTION_HISTORY_UPDATE_STATE: {
      Object.assign(globalState.factionHistory, upd.factionHistory);
      break;
    }
    case GlobalUpdateType.TEAM_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeam = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_UPDATE_STATE: {
      const {
        displayName,
        faction,
        factionScoreContributions,
        factionScores,
        solveTimes,
        answers,
        unlocks,
        battleGroupUnlocks,
        cardUnlocks: cardUnlocksUpdates,
        speedrunTimes: speedrunTimesUpdates,
        masteries: masteriesUpdates,
        enabledMasteries,
        hintsInfo,
      } = upd;
      const { subscribedTeam } = globalState;
      if (subscribedTeam === null)
        throw new Error("expect to have subscribed team state");
      const { puzzles, battleGroups, cardUnlocks, masteries } = subscribedTeam;
      if (displayName !== undefined) subscribedTeam.displayName = displayName;
      if (faction !== undefined) subscribedTeam.faction = faction;
      Object.assign(
        subscribedTeam.factionScoreContributions,
        factionScoreContributions ?? {}
      );
      if (factionScores !== undefined) {
        Object.assign(subscribedTeam.factionScores, factionScores);
      }
      for (const [puzName, puzData] of Object.entries(unlocks ?? {})) {
        if (puzData === null) delete puzzles[puzName];
        else puzzles[puzName] = puzData;
      }
      for (const battleGroup of Object.values(battleGroupUnlocks ?? {})) {
        battleGroups[battleGroup.battleGroupName] = battleGroup;
      }
      for (const [puzName, solveTime] of Object.entries(solveTimes ?? {})) {
        if (solveTime === null) {
          if (puzzles[puzName] !== undefined) {
            delete puzzles[puzName].solveTime;
            // Also delete the answer if any, since this is effectively
            // telling us to unsolve the puzzle.
            delete puzzles[puzName].answer;
          }
        } else {
          if (puzzles[puzName] !== undefined)
            puzzles[puzName].solveTime = solveTime;
        }
      }
      for (const [puzName, answer] of Object.entries(answers ?? {})) {
        if (puzzles[puzName] !== undefined) puzzles[puzName].answer = answer;
      }
      for (const [cardName, cardUnlock] of Object.entries(
        cardUnlocksUpdates ?? {}
      )) {
        if (cardUnlock === null) delete cardUnlocks[cardName];
        else cardUnlocks[cardName] = cardUnlock;
      }
      for (const [puzName, speedrunTime] of Object.entries(
        speedrunTimesUpdates ?? {}
      )) {
        puzzles[puzName].speedrunTime = speedrunTime;
      }
      for (const [masteryId, mastery] of Object.entries(
        masteriesUpdates ?? {}
      )) {
        if (mastery === null) {
          delete masteries[masteryId].displayName;
          delete masteries[masteryId].effectText;
        } else {
          const { displayName, effectText } = mastery;
          masteries[masteryId] = {
            ...masteries[masteryId],
            displayName,
            effectText,
          };
        }
      }
      for (const [masteryId, isEnabled] of Object.entries(
        enabledMasteries ?? {}
      )) {
        masteries[masteryId].isEnabled = isEnabled;
      }
      if (hintsInfo !== undefined)
        Object.assign(subscribedTeam.hintsInfo, hintsInfo);
      break;
    }
    case GlobalUpdateType.TEAM_UPDATE_PUZZLE: {
      const { puzName, roomStatus, isNew } = upd;
      const { subscribedTeam } = globalState;
      if (subscribedTeam === null)
        throw new Error("expect to have subscribed team state");
      const puzData = subscribedTeam.puzzles[puzName];
      if (puzData === undefined)
        throw new Error(`trying to update ${puzName} but it isn't unlocked`);
      if (roomStatus !== undefined) {
        if (roomStatus === GlobalStateBattleRoomStatus.NONE)
          delete puzData.roomStatus;
        else puzData.roomStatus = roomStatus;
      }
      if (isNew !== undefined) {
        if (isNew) puzData.isNew = true;
        else delete puzData.isNew;
      }
      break;
    }
    case GlobalUpdateType.TEAM_UPDATE_PUZZLE_STATS: {
      const { stats } = upd;
      const { subscribedTeam } = globalState;
      if (subscribedTeam === null)
        throw new Error("expect to have subscribed team state");
      for (const [battleGroupName, puzStats] of Object.entries(stats)) {
        const battleGroup = subscribedTeam.battleGroups[battleGroupName];
        if (battleGroup === undefined)
          throw new Error("expect to only get updates for unlocked puzzles");
        battleGroup.stats = puzStats;
      }
      break;
    }
    case GlobalUpdateType.TEAM_UPDATE_BATTLE_GROUP: {
      const { battleGroupName, postBattleDialogue } = upd;
      const { subscribedTeam } = globalState;
      if (subscribedTeam === null)
        throw new Error("expect to have subscribed team state");
      const battleGroup = subscribedTeam.battleGroups[battleGroupName];
      if (battleGroup === undefined)
        throw new Error(
          `trying to update ${battleGroup} but it isn't unlocked`
        );
      if (postBattleDialogue !== undefined) {
        battleGroup.postBattleDialogue = postBattleDialogue;
      }
      break;
    }
    case GlobalUpdateType.TEAM_BATTLE_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamBattle = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamBattleGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_BATTLE_UPDATE_STATE: {
      const {
        selectedPuzName,
        roomPuzName,
        selectedDeckSlot,
        lockedDeckSelection,
        activeGame,
        errata,
        checkpointSummaries,
      } = upd;
      const subscribedTeamBattle = globalState.subscribedTeamBattle;
      if (subscribedTeamBattle === null)
        throw new Error("expect to have subscribed team battle state");
      if (selectedPuzName !== undefined)
        subscribedTeamBattle.selectedPuzName = selectedPuzName;
      if (roomPuzName !== undefined)
        subscribedTeamBattle.roomPuzName = roomPuzName;
      if (selectedDeckSlot !== undefined)
        subscribedTeamBattle.selectedDeckSlot = selectedDeckSlot;
      if (lockedDeckSelection !== undefined)
        subscribedTeamBattle.lockedDeckSelection = lockedDeckSelection;
      if (activeGame !== undefined)
        subscribedTeamBattle.activeGame = activeGame;
      if (errata !== undefined) subscribedTeamBattle.errata = errata;
      if (checkpointSummaries !== undefined)
        subscribedTeamBattle.checkpointSummaries = checkpointSummaries;
      break;
    }
    case GlobalUpdateType.TEAM_FISH_PUZZLE_OVERRIDE_STATE: {
      const { state } = upd;
      const { teamId, puzName, numGuessesRemaining, previousGuesses } = state;
      // This is not a true override state, since we need to
      // merge with data from Django.
      const allGuesses =
        globalState.subscribedTeamFishPuzzle !== null &&
        globalState.subscribedTeamFishPuzzle.teamId === teamId &&
        globalState.subscribedTeamFishPuzzle.puzName === puzName
          ? globalState.subscribedTeamFishPuzzle.previousGuesses
          : [];
      for (const guess of previousGuesses) {
        if (
          !allGuesses.some(
            (existingSubmission) =>
              existingSubmission.submittedAnswer === guess.submittedAnswer
          )
        ) {
          allGuesses.push(guess);
        }
      }
      allGuesses.sort((guess1, guess2) => guess2.timestamp - guess1.timestamp);
      globalState.subscribedTeamFishPuzzle = {
        ...state,
        previousGuesses: allGuesses,
      };
      break;
    }
    case GlobalUpdateType.TEAM_FISH_PUZZLE_SET_NUM_GUESSES_REMAINING: {
      const subscribedTeamFishPuzzle = globalState.subscribedTeamFishPuzzle;
      if (subscribedTeamFishPuzzle === null)
        throw new Error("expect to have subscribed team fish puzzle state");
      subscribedTeamFishPuzzle.numGuessesRemaining = upd.numGuessesRemaining;
      break;
    }
    case GlobalUpdateType.TEAM_FISH_PUZZLE_ADD_GUESS: {
      const { submission } = upd;
      const subscribedTeamFishPuzzle = globalState.subscribedTeamFishPuzzle;
      if (subscribedTeamFishPuzzle === null)
        throw new Error("expect to have subscribed team fish puzzle state");
      const { previousGuesses } = subscribedTeamFishPuzzle;
      if (
        !previousGuesses.some(
          (existingSubmission) =>
            existingSubmission.submittedAnswer === submission.submittedAnswer
        )
      ) {
        previousGuesses.push(submission);
      }
      previousGuesses.sort(
        (guess1, guess2) => guess2.timestamp - guess1.timestamp
      );
      break;
    }
    case GlobalUpdateType.TEAM_DECKS_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamDecks = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamDecksGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_DECKS_UPDATE_DECK: {
      const { slot, deckUpd } = upd;
      const { subscribedTeamDecks } = globalState;
      if (subscribedTeamDecks === null)
        throw new Error("expect to have subscribed team decks state");
      const deck = (() => {
        const existingDeck = subscribedTeamDecks.decks[slot];
        if (existingDeck !== null) return existingDeck;
        const newDeck = createNewDeck(slot);
        subscribedTeamDecks.decks[slot] = newDeck;
        return newDeck;
      })();
      applyDeckUpdate(deck, deckUpd);
      break;
    }
    case GlobalUpdateType.TEAM_MASTERY_TREE_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamMasteryTree = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamMasteryTreeGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_MASTERY_TREE_UPDATE: {
      const { masteryTreeUpd } = upd;
      const { subscribedTeamMasteryTree } = globalState;
      if (subscribedTeamMasteryTree === null)
        throw new Error("expect to have subscribed team mastery tree state");
      if (masteryTreeUpd !== undefined) {
        const { masteryTree } = subscribedTeamMasteryTree;
        applyMasteryTreeUpdate(masteryTree, masteryTreeUpd);
      }
      break;
    }
    case GlobalUpdateType.TEAM_PVP_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamPvP = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamPvPGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_PVP_UPDATE_STATE: {
      const {
        outgoingRequestsToAdd,
        outgoingRequestsToDelete,
        incomingRequestsToAdd,
        incomingRequestsToDelete,
        blockedTeamsToAdd,
        blockedTeamsToDelete,
        blockedByTeamsToAdd,
        blockedByTeamsToDelete,
        clearOutgoingRequests = false,
        clearIncomingRequests = false,
      } = upd;
      const { subscribedTeamPvP } = globalState;
      if (subscribedTeamPvP === null)
        throw new Error("expect to have subscribed team pvp state");

      for (const pvpReq of outgoingRequestsToAdd ?? []) {
        subscribedTeamPvP.outgoingRequests[pvpReq.toTeamId] = pvpReq;
      }
      for (const teamId of outgoingRequestsToDelete ?? []) {
        delete subscribedTeamPvP.outgoingRequests[teamId];
      }
      for (const pvpReq of incomingRequestsToAdd ?? []) {
        subscribedTeamPvP.incomingRequests[pvpReq.fromTeamId] = pvpReq;
      }
      for (const teamId of incomingRequestsToDelete ?? []) {
        delete subscribedTeamPvP.incomingRequests[teamId];
      }
      for (const teamId of blockedTeamsToAdd ?? []) {
        subscribedTeamPvP.blockedTeams[teamId] = true;
      }
      for (const teamId of blockedTeamsToDelete ?? []) {
        delete subscribedTeamPvP.blockedTeams[teamId];
      }
      for (const teamId of blockedByTeamsToAdd ?? []) {
        subscribedTeamPvP.blockedByTeams[teamId] = true;
      }
      for (const teamId of blockedByTeamsToDelete ?? []) {
        delete subscribedTeamPvP.blockedByTeams[teamId];
      }
      if (clearOutgoingRequests) subscribedTeamPvP.outgoingRequests = {};
      if (clearIncomingRequests) subscribedTeamPvP.incomingRequests = {};
      break;
    }
    case GlobalUpdateType.TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_OVERRIDE_STATE: {
      const { state } = upd;
      globalState.subscribedTeamSpeedrunLeaderboardSummary = JSON.parse(
        JSON.stringify(state)
      ) as ClientTeamSpeedrunLeaderboardSummaryGlobalState;
      break;
    }
    case GlobalUpdateType.TEAM_SPEEDRUN_LEADERBOARD_SUMMARY_UPDATE_STATE: {
      const {
        speedrunRecordsToAdd,
        speedrunRecordsToRemove,
        teamNameDisplayUpds,
        speedrunRank,
      } = upd;

      const subscribedTeamSpeedrunLeaderboardSummary =
        globalState.subscribedTeamSpeedrunLeaderboardSummary;
      if (subscribedTeamSpeedrunLeaderboardSummary === null)
        throw new Error(
          "expect to have subscribed speedrun leaderboard summary state"
        );

      const leaderboard = new RankedLeaderboard(
        (record) => record.teamData.teamId,
        (record1, record2) => record1.speedrunTime - record2.speedrunTime,
        subscribedTeamSpeedrunLeaderboardSummary.speedrunLeaderboardSummary ??
          []
      );
      for (const teamId of speedrunRecordsToRemove ?? []) {
        leaderboard.remove(teamId);
      }
      for (const record of speedrunRecordsToAdd ?? []) {
        leaderboard.insert(record, false);
      }
      for (const [teamId, teamNameDisplayUpd] of Object.entries(
        teamNameDisplayUpds ?? {}
      )) {
        for (const record of leaderboard.sortedList) {
          if (record.teamData.teamId === teamId) {
            updateTeamNameDisplayData(record.teamData, teamNameDisplayUpd);
            break;
          }
        }
      }
      subscribedTeamSpeedrunLeaderboardSummary.speedrunLeaderboardSummary =
        leaderboard.sortedList;

      if (speedrunRank !== undefined)
        subscribedTeamSpeedrunLeaderboardSummary.speedrunRank = speedrunRank;
    }
  }
};
