export enum HuntNotificationType {
  INTERNAL = "internal",
  HINT_ANSWERED = "hint_answered",
  UNLOCK = "unlock",
  SOLVE = "solve",
  ERRATUM = "erratum",
  VICTORY = "victory",
  PVP_REQUEST_RECEIVED = "pvp_request_received",
  PVP_REQUEST_ACCEPTED = "pvp_request_accepted",
}

export type HuntNotification =
  | {
      type: HuntNotificationType.INTERNAL;
      message: string;
    }
  | {
      type:
        | HuntNotificationType.ERRATUM
        | HuntNotificationType.VICTORY
        | HuntNotificationType.PVP_REQUEST_RECEIVED
        | HuntNotificationType.PVP_REQUEST_ACCEPTED;
    }
  | {
      type:
        | HuntNotificationType.HINT_ANSWERED
        | HuntNotificationType.UNLOCK
        | HuntNotificationType.SOLVE;
      puzName: string;
    };
