/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { WSReqType } from "game-server/ws";
import { PUZ_NAME_MYOSB } from "engine/puzzles/puzzle-data";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import Globals from "Globals";
import "./MYOSBResponse.css";

const MYOSBResponse = () => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  const teamId = serverInterfaceController?.getTeamId() ?? null;
  const { puzName } = Globals;
  const [lock, setLock] = useState(false);
  const [resp, setResp] = useState("");
  const isLoading = lock || !serverInterfaceController || !teamId;

  useEffect(() => {
    if (!serverInterfaceController) return;
    return serverInterfaceController.onMYOSBResponseEvent.addListener(
      (resp) => {
        setResp(resp);
        setLock(false);
      }
    );
  }, [serverInterfaceController]);

  const onCheck = () => {
    const puzzle = (window as any).myosbPuzzle;

    if (!puzzle || isLoading) return;

    if (puzzle.check(false)[0] === "bdDeadEnd") {
      setResp("Your grid isn’t divided into regions.");
      return;
    }

    const components = Array(5)
      .fill(null)
      .map((_) => Array(5).fill(0));

    const nComponents = puzzle.board.roommgr.components.map(
      (component: any, i: number) =>
        component.clist
          .map((cell: any) => [cell.bx, cell.by].map((c) => Math.floor(c / 2)))
          .map(([x, y]: [number, number]) => {
            components[x][y] = i;
          })
    ).length;

    if (nComponents !== 5) {
      setResp(`Your grid needs to have 5 regions, but it has ${nComponents}.`);
      return;
    }

    serverInterfaceController.sendReq({
      type: WSReqType.MYOSB_REQUEST,
      teamId,
      components,
    });
    setLock(true);
  };

  if (puzName !== PUZ_NAME_MYOSB) return null;

  return (
    <>
      <p>{resp}</p>
      <div>
        <button onClick={onCheck} disabled={isLoading}>
          Check
        </button>
        <button
          onClick={() => (window as any).myosbPuzzle.ansclear()}
          disabled={isLoading}
        >
          Clear
        </button>
      </div>
    </>
  );
};

export default MYOSBResponse;
