import { create } from "zustand";

import { ServerInterfaceController } from "game-server/server-interface/ServerInterfaceController";

export interface ServerInteractionStoreState {
  /**
   * HACK: wrap access to server interface controller in a function
   * so that react doesn't try to manage its state.
   */
  getServerInterfaceController: () => ServerInterfaceController | null;
  setServerInterfaceController: (
    serverInterfaceController: ServerInterfaceController
  ) => void;

  /** If authenticated, tracks whether we're authenticated as admin. */
  isAdmin: boolean | null;
  /**
   * If we should display admin interfaces.
   * Similar to isAdmin, but does not get cleared on disconnect.
   */
  shouldDisplayAdmin: boolean;
  setIsAdmin: (isAdmin: boolean | null) => void;
}

export const useServerInteractionStore = create<ServerInteractionStoreState>(
  (set, get) => ({
    getServerInterfaceController: () => {
      return null;
    },
    setServerInterfaceController: (
      serverInterfaceController: ServerInterfaceController
    ) => {
      set({
        getServerInterfaceController: () => {
          return serverInterfaceController;
        },
      });
    },
    isAdmin: null,
    shouldDisplayAdmin: false,
    setIsAdmin: (isAdmin) =>
      set({
        isAdmin,
        shouldDisplayAdmin: isAdmin === null ? undefined : isAdmin,
      }),
  })
);

/**
 * Get the server interface controller directly, asserting that it has
 * been initialized. Should only be used in components that are only
 * created after server interface controller has been initialized.
 */
export const useServerInterfaceController = (): ServerInterfaceController => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  if (serverInterfaceController === null)
    throw new Error("server interface controller not initialized");
  return serverInterfaceController;
};

export const useTeamId = (): string | null => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  if (serverInterfaceController === null) return null;
  return serverInterfaceController.getTeamId();
};

export const useIsAdmin = (): boolean | null => {
  return useServerInteractionStore((state) => state.isAdmin);
};

export const useShouldDisplayAdmin = (): boolean => {
  return useServerInteractionStore((state) => state.shouldDisplayAdmin);
};
