import { useAnimate } from "framer-motion";
import produce from "immer";
import { CSSProperties, useCallback, useEffect, useRef } from "react";
import { create } from "zustand";

import { Slot, areSlotsEqual } from "engine/types/game-state";
import { Update } from "engine/types/updates";
import { useUserPreferencesStore } from "stores/UserPreferencesStore";
import { useIsGameSettingsEnabled } from "./ClientGlobalStateStore";

type UpdateListener = (upd: Update, isStale: () => boolean) => Promise<void>;
const slotToId = (slot: Slot) => `${slot.row}-${slot.column}`;
type Offset = { offsetTop: number; offsetLeft: number };
type SlotTracker = { slot: Slot; node: HTMLDivElement };

/**
 * Store used for both animation and slot location info.
 *
 * Animation flow:
 * 1. update is on queue
 * 2. update is popped and placed in animationstore
 *    animationstore calls every listener and Promise.alls it
 * 3. once that promise resolves, we resolve it
 *
 * (Animations are a misnomer---any asynchronous, cosmetic effect
 * that happens on game updates will go here.)
 */
export interface AnimationState {
  /** Next ID to hand out. */
  lastListenerId: number;
  /** All animation listeners. */
  listeners: { [id: number]: UpdateListener };
  /** Register a listener; returns a callback to unregister. */
  register: (listener: UpdateListener) => () => void;
  /** Animate the update, resolves when done. */
  animateUpdate: UpdateListener;
  slotTrackers: SlotTracker[];
  addSlotTracker: (tracker: SlotTracker) => void;
  removeSlotTracker: (slot: Slot) => void;
  /** Stores refs to each slot. Don't access directly! */
  slotOffsets: { [id: string]: Offset };
  /** Reset all slot refs. */
  resetSlotOffsets: () => void;
  /** Update all slot offsets. */
  updateSlotOffsets: () => void;
}

export const useAnimationStore = create<AnimationState>((set, get) => ({
  lastListenerId: -1,
  listeners: {},
  register: (listener: UpdateListener) => {
    const id = get().lastListenerId + 1;
    set(
      produce((state) => {
        state.lastListenerId = id;
        state.listeners[id] = listener;
      })
    );
    return () => {
      set(
        produce((state) => {
          delete state.listeners[id];
        })
      );
    };
  },
  animateUpdate: async (upd: Update, isStale: () => boolean) => {
    await Promise.all(
      Object.values(get().listeners).map((listener) => listener(upd, isStale))
    );
  },
  slotTrackers: [],
  addSlotTracker: (tracker) =>
    set(
      produce((state) => {
        state.slotTrackers.push(tracker);
      })
    ),
  removeSlotTracker: (slot) =>
    set(
      produce((state) => {
        const slotIndex = state.slotTrackers.findIndex((tracker: SlotTracker) =>
          areSlotsEqual(tracker.slot, slot)
        );
        if (slotIndex === -1)
          throw new Error("could not find requested slot tracker");
        state.slotTrackers.splice(slotIndex, 1);
      })
    ),
  slotOffsets: {},
  resetSlotOffsets: () => set({ slotOffsets: {} }),
  updateSlotOffsets: () =>
    set(
      produce((state) => {
        for (const { slot, node } of state.slotTrackers) {
          const { offsetTop, offsetLeft } = node;
          state.slotOffsets[slotToId(slot)] = { offsetTop, offsetLeft };
        }
      })
    ),
}));

/** Get a CSS translate that brings something over a slot. */
export const useGetTranslate = () => {
  const slotOffsets = useAnimationStore((state) => state.slotOffsets);
  return useCallback(
    (slot: Slot | undefined): CSSProperties => {
      if (!slot) return {};
      const slotOffset = slotOffsets[slotToId(slot)];
      if (slotOffset === undefined) return {};
      const { offsetLeft, offsetTop } = slotOffset;
      return { transform: `translate(${offsetLeft}px, ${offsetTop}px)` };
    },
    [slotOffsets]
  );
};

/** Get a ref that updates a slot's offset. */
export const useOffsetRef = (slot: Slot) => {
  const addSlotTracker = useAnimationStore((state) => state.addSlotTracker);
  const removeSlotTracker = useAnimationStore(
    (state) => state.removeSlotTracker
  );
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (node.current === null) return;
    addSlotTracker({ slot, node: node.current });
    return () => removeSlotTracker(slot);
  }, [slot, addSlotTracker, removeSlotTracker]);

  return node;
};

export const useAnimationSpeed = () => {
  const speed = useUserPreferencesStore((state) => state.speed);
  const isGameSettingsEnabled = useIsGameSettingsEnabled();
  if (!isGameSettingsEnabled) return 1;
  return speed;
};

export const useAdjustedAnimate: typeof useAnimate = () => {
  const [scope, animate] = useAnimate();
  const speed = useAnimationSpeed();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const adjustOptions = (arg: any, isKeyframed = false) => ({
    ...(arg ?? {}),
    duration:
      speed === 0 ? 0 : arg?.duration ?? (isKeyframed ? 0.8 : 0.3) / speed,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isKeyframed = (arg: any) => {
    if (Array.isArray(arg)) {
      try {
        return Object.values(arg[1]).some((value) => Array.isArray(value));
      } catch {
        // do nothing
      }
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const animateWrapped = (...args: any[]) => {
    if (Array.isArray(args[0])) {
      return animate(
        args[0].map((arg) => [
          arg[0],
          arg[1],
          adjustOptions(arg[2], isKeyframed(arg)),
        ]),
        ...args.slice(1).map((arg) => adjustOptions(arg))
      );
    }
    return animate(args[0], args[1], adjustOptions(args[2], isKeyframed(args)));
  };

  return [scope, animateWrapped];
};
