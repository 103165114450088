import { useEffect } from "react";
import { Link } from "react-router-dom";
import Twemoji from "react-twemoji";
import ALL_CARDS from "engine/cards/all-cards-client";
import { makeCardsDB } from "engine/cards/CardsDB";
import { ColorSymbol } from "engine/types/card-data";
import { useCardsDBStore } from "stores/CardsDBStore";
import "./ArtCreditsPage.css";
import BattlerImage from "components/BattlerImage";
import CardDisplay, { CardSize } from "components/CardDisplay";
import ColorSymbolIcon from "components/ColorSymbolIcon";
import Keepsake from "components/HuntLib/Keepsake";
import Image from "components/Image";
import { getBattlerSliceArtPaths, getMapBgSliceArtPaths } from "images";
import settings from "settings";

export function CaveBackground() {
  return (
    <div className="cave-background">
      <style
        dangerouslySetInnerHTML={{
          __html: "html { position: relative } body { position: static }",
        }}
      />
      {getMapBgSliceArtPaths("2-cave-full").map((src, i) => (
        <Image key={i} src={src} />
      ))}
    </div>
  );
}

function ArtCreditsPage() {
  const setCardsDB = useCardsDBStore((state) => state.setCardsDB);
  useEffect(() => {
    setCardsDB(makeCardsDB(ALL_CARDS));
  }, [setCardsDB]);
  return (
    <>
      <CaveBackground />
      <h1>Art Credits</h1>
      <div className="credits">
        <Link to="/game" className="map-puz-title">
          <div>Map</div>
          <Twemoji tag="span">🗺️</Twemoji>
          <b>DD</b>
        </Link>
        <a href={`${settings.djangoBaseUrl}victory`} className="map-puz-title">
          <div>Victory</div>
          <Twemoji tag="span">🥧️</Twemoji>
          <b>Kat</b>
        </a>
        <div className="map-puz-title">
          <div>Cutscenes</div>
          <Twemoji tag="span">🎥️</Twemoji>
          <b>Alan, Brian, Kat</b>
        </div>
        <Link to="/game/keros_notes" className="map-puz-title">
          <div>Food icons </div>
          <span className="food-icons">
            <ColorSymbolIcon symbol={ColorSymbol.RED} />
            <ColorSymbolIcon symbol={ColorSymbol.YELLOW} />
            <ColorSymbolIcon symbol={ColorSymbol.GREEN} />
            <ColorSymbolIcon symbol={ColorSymbol.PURPLE} />
            <ColorSymbolIcon symbol={ColorSymbol.RAINBOW} />
            <ColorSymbolIcon symbol={ColorSymbol.WHITE} />
            <ColorSymbolIcon symbol={ColorSymbol.ROCK} />
            <ColorSymbolIcon symbol={ColorSymbol.BLACK} />
            <ColorSymbolIcon symbol={ColorSymbol.WHITEBLACK} />
          </span>
          <b>Amon</b>
        </Link>
        <a href={`${settings.djangoBaseUrl}donate`} className="map-puz-title">
          <div>Keepsake</div>
          <Keepsake />
          <b>DD & Amon</b>
        </a>
      </div>
      <BattlerCredits />
      <h3>Legendaries</h3>
      <div>
        <CardTable cards={LEGENDARIES} />
      </div>
      <h3>Other collectibles</h3>
      <div>
        <CardTable cards={COLLECTIBLES} />
      </div>
      <h3>Puzzle exclusives</h3>
      <div>
        <CardTable cards={PUZZLE_EXCLUSIVES} />
      </div>
    </>
  );
}
export default ArtCreditsPage;

const BATTLERS = [
  ["treasure_chest", "The Treasure Chest", "Alan", "💬"],
  ["tutorial", "Tutorial", "DD", "️⚔️"],
  ["instancer", "Instancer", "DD", "🕸️"],
  ["angry_boarry_farmer", "Angry Boarry Farmer", "Jenna", "️⚔️"],
  ["dargle", "Dargle", "Chris", "👑"],
  ["beebay_fulfillment_center", "beeBay Fulfillment Center", "Jenna", "️⚔️"],
  ["pvp", "PvP Arena", "DD", "🤼"],
  ["miss_yu", "Miss Yu", "Amon", "👑"],
  ["gnutmeg_tree", "Gnutmeg Tree", "Amon", "️⚔️"],
  ["coloring", "Coloring", "DD", "👑"],
  ["generic-dryad", "Dryad Answerable", "Amon", "🧩"],
  ["mastery_tree", "Mastery Tree", "DD", "🌲"],
  ["spirit_of_the_vines", "Spirit of the Vines", "Brian", "️⚔️"],
  ["othello", "Othello", "Jenna", "👑"],
  ["generic-dino", "Dino Answerable", "Amon", "🧩"],
  ["jabberwock", "Jabberwock", "Chris", "👑"],
  ["asteroid", "Asteroid", "Brian & Mark", "️⚔️"],
  ["bb_b", "bb b", "dd", "👑"],
  ["moonick-clickable", "Moonick", "Chris", "👑"],
  ["generic-bee", "Bee Answerable", "Amon", "🧩"],
  ["the_swarm", "The Swarm", "Kat, Brian, Mark", "️⚔️"],
  ["spelling_bee", "Spelling Bee", "Chris", "👑"],
  ["blancmange", "Blancmagne", "Chris", "👑"],
  ["generic-boar", "Boar Answerable", "Amon", "🧩"],
  [
    "infinitely_many_hogs_and_logs",
    "Infinitely Many Hogs (and Logs)",
    "Lewis & DD",
    "️⚔️",
  ],
  ["slime", "Slime", "Nathan & Brian", "👑"],
  ["mister_penny", "Mister Penny", "Amon", "👑"],
  ["kero", "Kero", "DD", "❓"],
];

function BattlerCredits() {
  return (
    <div className="battlers">
      {Object.values(BATTLERS).map(
        ([battleGroupName, displayName, artist, emoji]) => (
          <div className="battler" key={battleGroupName}>
            {battleGroupName === "infinitely_many_hogs_and_logs" ? (
              <Image
                className="battler-image"
                src={getBattlerSliceArtPaths(battleGroupName).at(-1) ?? ""}
              />
            ) : (
              <BattlerImage battleGroupName={battleGroupName} />
            )}
            <div className="map-puz-label">
              <div>
                <Twemoji>{emoji}</Twemoji>
              </div>
              <div className="map-puz-title">
                <div>{displayName}</div>
                <b>{artist}</b>
              </div>
              <div>
                <Twemoji>🎨️</Twemoji>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

// card name, artist
type CardData = [string, string];

interface CardTableProps {
  cards: CardData[];
}
function CardTable(props: CardTableProps) {
  const rows: { [artist: string]: string[] } = {};
  for (const [name, artist] of props.cards) (rows[artist] ||= []).push(name);
  return (
    <div className="card-table">
      {Object.entries(rows)
        .sort()
        .map(([artist, names]) => (
          <CardRow key={artist} names={names} artist={artist} />
        ))}
    </div>
  );
}

interface CardRowProps {
  names: string[];
  artist: string;
}
function CardRow(props: CardRowProps) {
  return (
    <div className="cutscene-dialog-box">
      <div className="cutscene-speaker">
        <h4>{props.artist}</h4>
      </div>
      <div className="cutscene-dialog-main">
        {props.names.map((name) => (
          <div key={name} style={{ display: "inline-block" }}>
            <CardDisplay
              cardName={name}
              size={CardSize.NORMAL}
              canZoom={false}
              showCost={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const LEGENDARIES = [
  ["dargle", "Chris"],
  ["miss-yu", "Amon"],
  ["coloring", "DD"],
  ["othello", "Jenna"],
  ["jabberwock", "Chris"],
  ["bb-b", "DD"],
  ["moonick", "Chris"],
  ["spelling-bee", "Chris"],
  ["blancmange", "Chris"],
  ["slime--slime", "Nathan & Brian"],
  ["mister-penny", "Amon"],
  ["captain-pi", "Chris"],
] as CardData[];

const COLLECTIBLES = [
  ["bee-rider", "Kat & Brian"],
  ["beeowulf", "Kat & Brian"],
  ["beethoven", "Andy"],
  ["bell-flower", "Jinna & DD"],
  ["boarnana-tree", "Andy"],
  ["boarry-farmer", "CJ"],
  ["chicken", "Chris"],
  ["chocolate-calf", "DD"],
  ["dino-nugget", "Kat"],
  ["eager-beever", "Kat"],
  ["flora-the-explora", "Kat"],
  ["giraffatitan", "Chris"],
  ["glass-of-moo", "Chris"],
  ["glass-of-moocha", "Chris"],
  ["hamlet", "Jenna"],
  ["hog", "Lewis"],
  ["hog-hog", "Lewis"],
  ["hog-on-a-log", "Lewis"],
  ["king-bee", "Chris"],
  ["king-of-bison", "Andy"],
  ["lady-jane", "Kat"],
  ["log", "Lewis"],
  ["log-on-a-hog", "Lewis"],
  ["murray", "Jenna"],
  ["nam-pam-bee", "Jenna"],
  ["nepeta-legion", "Wayne"],
  ["new-boarn", "CJ"],
  ["nice-cow", "Jenna"],
  ["nightshade", "Brian"],
  ["o", "DD"],
  ["oklahoma-tusks", "Kat"],
  ["payne", "Wayne"],
  ["pbee---j", "Kat & Brian"],
  ["petroleum-jelly", "Wayne"],
  ["quail-aboard-fiery-steeds", "Kat"],
  ["quantum-butter-churner", "Chris"],
  ["queen-of-bison", "Violet"],
  ["re", "DD"],
  ["realistic-rex", "DD"],
  ["roe-doe-dendron", "Kat"],
  ["rude-cow", "Kat"],
  ["sapphire-rose", "Wayne & DD"],
  ["swarm-tactics", "Chris"],
  ["switch-boar-d", "Mark"],
  ["thesaurus", "Wayne"],
  ["touch-grass", "Brian"],
  ["tusk-enhancement", "Brian"],
  ["two-bees", "Andy"],
  ["ultra-violet", "Andy"],
  ["vanilla-calf", "Kat & Chris"],
  ["yoshisaur-munchakoopas", "Andy"],
] as CardData[];

const PUZZLE_EXCLUSIVES = [
  ["camp", "Chris"],
  ["pepper-rich-farm", "Alan"],
  ["train-boar-ding-platform", "Alan"],
  ["angry-boarry-farmer", "Jenna"],
  ["cargle", "Chris"],
  ["dorgle", "Chris"],
  ["morgle", "Chris"],
  ["corgle", "Chris"],
  ["bargle", "Chris"],
  ["durgle", "Chris"],
  ["gorgle", "Chris"],
  ["swargle", "Chris"],
  ["worker-bot", "Brian"],
  ["zap-worker-bot", "Brian"],
  ["box", "Brian"],
  ["explosives", "Brian"],
  ["friendly-battle-bot", "Brian"],
  ["enemy-battle-bot", "Brian"],
  ["armored-enemy-battle-bot", "Brian"],
  ["armored-camp", "Brian"],
  ["pothole", "Brian"],
  ["barn", "Amon"],
  ["chocolate-cyclow--sleeping-", "Amon"],
  ["chocolate-cyclow--awake-", "Amon"],
  ["chocolate-cyclow--tired-", "Amon"],
  ["chocolate-cyclow--weak-", "Amon"],
  ["chocolate-cyclow--angry-", "Amon"],
  ["vanilla-cyclow--sleeping-", "Amon"],
  ["vanilla-cyclow--awake-", "Amon"],
  ["vanilla-cyclow--tired-", "Amon"],
  ["vanilla-cyclow--weak-", "Amon"],
  ["vanilla-cyclow--angry-", "Amon"],
  ["arvada", "Alan"],
  ["burlington", "Alan"],
  ["centennial", "Alan"],
  ["grover", "Chris"],
  ["cow-butte", "Chris"],
  ["ramsey-peak", "Chris"],
  ["saddle-mountain", "Chris"],
  ["finger-rock", "Chris"],
  ["miracle-growth", "Brian"],
  ["wild-weed", "Brian"],
  ["leech-seed", "Brian"],
  ["venus-bee-trap", "Brian"],
  ["spirit-of-the-vines", "Brian"],
  ["overgrown-vines", "Brian"],
  ["light-piece", "Alan"],
  ["dark-piece", "Alan"],
  ["gostak1", "Brian"],
  ["gostak2", "Brian"],
  ["gostak3", "Brian"],
  ["gostak4", "Brian"],
  ["gostak5", "Brian"],
  ["gostak6", "Brian"],
  ["gostak7", "Brian"],
  ["apocalyptic-asteroid", "Brian"],
  ["asteroid-fragment", "Brian"],
  ["meaty-ore", "Brian"],
  ["beeflector", "Chris"],
  ["reckless-medisaur", "Brian"],
  ["b-w-copier", "Alan"],
  ["pasture-planner", "Alan"],
  ["puzzle-50-global-1", "Alan"],
  ["puzzle-50-global-2", "Alan"],
  ["puzzle-50-global-3", "Alan"],
  ["puzzle-50-global-4", "Alan"],
  ["puzzle-50-global-5", "Alan"],
  ["puzzle-50-global-6", "Alan"],
  ["puzzle-50-global-7", "Alan"],
  ["puzzle-50-global-8", "Alan"],
  ["puzzle-50-global-9", "Alan"],
  ["puzzle-50-global-10", "Alan"],
  ["puzzle-50-global-11", "Alan"],
  ["puzzle-50-global-12", "Alan"],
  ["puzzle-50-global-13", "Alan"],
  ["puzzle-50-global-14", "Alan"],
  ["puzzle-50-global-17", "Alan"],
  ["puzzle-50-global-18", "Alan"],
  ["puzzle-50-global-19", "Alan"],
  ["puzzle-50-global-20", "Alan"],
  ["puzzle-50-global-21", "Alan"],
  ["puzzle-50-global-22", "Alan"],
  ["puzzle-50-local-1", "Alan"],
  ["puzzle-50-local-2", "Alan"],
  ["puzzle-50-local-3", "Alan"],
  ["puzzle-50-local-4", "Alan"],
  ["puzzle-50-local-5", "Alan"],
  ["puzzle-50-local-6", "Alan"],
  ["puzzle-50-local-7", "Alan"],
  ["puzzle-50-local-8", "Alan"],
  ["puzzle-50-local-9", "Alan"],
  ["puzzle-50-local-10", "Alan"],
  ["puzzle-50-local-11", "Alan"],
  ["puzzle-50-local-12", "Alan"],
  ["puzzle-50-local-13", "Alan"],
  ["puzzle-50-local-14", "Alan"],
  ["puzzle-50-local-15", "Alan"],
  ["puzzle-50-local-16", "Alan"],
  ["puzzle-50-local-17", "Alan"],
  ["puzzle-50-local-18", "Alan"],
  ["yurt", "Alan"],
  ["beehive", "Chris"],
  ["larva", "Alan"],
  ["bearhive", "Chris"],
  ["luge-bear", "CJ & Brian"],
  ["stufful-bear", "CJ & Brian"],
  ["black-bear", "CJ & Brian"],
  ["banana-bear", "CJ & Brian"],
  ["sun-bear", "CJ & Brian"],
  ["ord-bear", "CJ"],
  ["bears-bear", "CJ"],
  ["confused-bear", "CJ"],
  ["eur-bear", "CJ"],
  ["three-bear", "CJ"],
  ["bludgeon-bear", "CJ"],
  ["four-bear", "CJ"],
  ["grism-bear", "CJ & Brian"],
  ["assault-bear", "CJ"],
  ["archery-bear", "CJ"],
  ["oak-bear", "CJ & Brian"],
  ["december-bear", "CJ"],
  ["alabama-bear", "CJ"],
  ["rammus-bear", "CJ & Brian"],
  ["apple-bee", "Brian"],
  ["bumblebee", "Brian"],
  ["carpenter-bee", "Brian"],
  ["drone-bee", "Brian"],
  ["edward-al-bee", "Brian"],
  ["fluffy-bee", "Brian"],
  ["gardening-bee", "Brian"],
  ["honey-bee", "Brian"],
  ["iridescent-bee", "Brian"],
  ["jasmine-bee", "Brian"],
  ["kombucha-bee", "Brian"],
  ["lavender-bee", "Brian"],
  ["mason-bee", "Brian"],
  ["nectar-bee", "Brian"],
  ["orange-bee", "Brian"],
  ["pollinator-bee", "Brian"],
  ["queen-bee", "Brian"],
  ["rosebud-bee", "Brian"],
  ["sunshine-bee", "Brian"],
  ["tangerine-bee", "Brian"],
  ["ultimatefris-bee", "Brian"],
  ["velvet-bee", "Brian"],
  ["worker-bee", "Brian"],
  ["xylophone-bee", "Brian"],
  ["yellow-jacket-bee", "Brian"],
  ["zinger-bee", "Brian"],
  ["keep", "Brian"],
  ["hog-on-a-log-on-a", "Lewis"],
  ["log-on-a-hog-on-a", "Lewis"],
  ["hog-factory", "Lewis"],
  ["log-factory", "Lewis"],
  ["hog-factorytory", "Lewis"],
  ["hog-factorytorytory", "Lewis"],
  ["log-factorytory", "Lewis"],
  ["log-factorytorytory", "Lewis"],
  ["stacktory", "Lewis"],
  ["stackstack-tory", "Lewis"],
  ["slime", "Nathan"],
  ["amber-slime", "Nathan"],
  ["apricot-slime", "Nathan"],
  ["auburn-slime", "Nathan"],
  ["ebony-slime", "Nathan"],
  ["ecru-slime", "Nathan"],
  ["eggplant-slime", "Nathan"],
  ["emerald-slime", "Nathan"],
  ["harlequin-slime", "Nathan"],
  ["indigo-slime", "Nathan"],
  ["ivory-slime", "Nathan"],
  ["khaki-slime", "Nathan"],
  ["lava-slime", "Nathan"],
  ["lavender-slime", "Nathan"],
  ["lemon-slime", "Nathan"],
  ["lilac-slime", "Nathan"],
  ["mauve-slime", "Nathan"],
  ["orange-slime", "Nathan"],
  ["red-slime", "Nathan"],
  ["rust-slime", "Nathan"],
  ["saffron-slime", "Nathan"],
  ["sepia-slime", "Nathan"],
  ["silver-slime", "Nathan"],
  ["teal-slime", "Nathan"],
  ["turquoise-slime", "Nathan"],
  ["white-slime", "Nathan"],
  ["yellow-slime", "Nathan"],
  ["mint", "Amon"],
  ["mint--overdrive-", "Amon"],
  ["goose", "Amon"],
  ["poutine", "Amon"],
  ["wisent", "Amon"],
  ["twelve-stars", "Amon"],
  ["consort-on-a-bull-deity", "Amon"],
  ["spicy-fermented-cabbage", "Amon"],
  ["hallyu", "Amon"],
  ["wombat-mob", "Amon"],
  ["jar-of-vegemite", "Amon"],
  ["didgeridoo", "Amon"],
  ["toblerone-dipped-in-fondue", "Amon"],
  ["plus-sign", "Amon"],
  ["army-knife", "Amon"],
  ["pungent-canned-herring", "Amon"],
  ["dala-horse", "Amon"],
  ["green-curry", "Amon"],
  ["red-curry", "Amon"],
  ["ka-api-ara", "Amon"],
  ["toasted-cassava", "Amon"],
  ["order-and-progress", "Amon"],
  ["kero-dos", "DD"],
  ["dinosaur-tail", "DD"],
  ["boar-tusks", "DD"],
  ["dryad-horns", "DD"],
  ["cow-udder", "DD"],
  ["cow-leg-white", "DD"],
  ["cow-leg-black", "DD"],
  ["bee-wings", "DD"],
  ["kero", "DD"],
] as CardData[];
