import { useEffect } from "react";
import Modal from "components/Modal";
import { WSReqType } from "game-server/ws";
import {
  useClientGlobalStateStore,
  useSubscribedTeam,
  getBattleGroup,
  getPuzNamesInBattleGroupFromPuzzlesDict,
} from "stores/ClientGlobalStateStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import { useNavStore } from "stores/NavStore";
import { useServerInterfaceController } from "stores/ServerInteractionStore";

export default function BattleUnlockModal() {
  const { subscribedTeamId } = useGlobalSubscriptionStore();
  const serverInterfaceController = useServerInterfaceController();
  const subscribedTeam = useSubscribedTeam(subscribedTeamId);

  const allPuzzles = useClientGlobalStateStore(
    (state) => state.globalState.puzzles
  );

  const battleUnlockModalPuzName = useNavStore(
    (state) => state.battleUnlockModalPuzName
  );
  const setBattleUnlockModalPuzName = useNavStore(
    (state) => state.setBattleUnlockModalPuzName
  );
  const setRouterNavigatePath = useNavStore(
    (state) => state.setRouterNavigatePath
  );

  const puzName = useNavStore((state) => state.navPuzName);
  const { battleGroupName = null, numPuzzles = 1 } =
    getBattleGroup(subscribedTeam, puzName) ?? {};
  const { setNeedAdmin } = useGlobalSubscriptionStore();

  useEffect(() => {
    setNeedAdmin(true);
  }, [setNeedAdmin]);

  // If we navigate out of the page, reset the modal.
  useEffect(() => {
    if (puzName !== battleUnlockModalPuzName) {
      setBattleUnlockModalPuzName(null);
    }
  }, [puzName, setBattleUnlockModalPuzName, battleUnlockModalPuzName]);

  const puzNamesInBattleGroup = getPuzNamesInBattleGroupFromPuzzlesDict(
    allPuzzles,
    battleGroupName
  );
  const puzIndex =
    puzNamesInBattleGroup === null
      ? null
      : puzNamesInBattleGroup.findIndex(
          (otherPuzName) => otherPuzName === puzName
        );

  if (
    subscribedTeam === null ||
    puzName === null ||
    numPuzzles === null ||
    puzNamesInBattleGroup === null ||
    puzIndex === null ||
    Object.keys(allPuzzles).length === 0
  )
    return null;

  const { teamId } = subscribedTeam;

  const resetBattle = () => {
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideUnlocked:
            numPuzzles <= 1
              ? undefined
              : Object.fromEntries(
                  puzNamesInBattleGroup.map((puzName) => [puzName, false])
                ),
          overrideSolved: Object.fromEntries(
            [
              ...puzNamesInBattleGroup.map((puzName) => puzName),
              ...(numPuzzles > 1 ? [battleGroupName] : []),
            ].map((puzName) => [puzName, false])
          ),
        },
      },
    });
  };

  const solveBattle = () =>
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideSolved: { [puzName]: true },
        },
      },
    });

  const unlockAllStages = () =>
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideUnlocked: Object.fromEntries(
            puzNamesInBattleGroup.map((puzName) => [puzName, true])
          ),
        },
      },
    });

  const solveAllStages = () =>
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideSolved: Object.fromEntries(
            puzNamesInBattleGroup.map((puzName) => [puzName, true])
          ),
        },
      },
    });

  return (
    <Modal
      isOpen={
        battleUnlockModalPuzName !== null &&
        puzName === battleUnlockModalPuzName
      }
      title="Battle Unlocks"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          marginTop: "0.5em",
          maxWidth: "40em",
        }}
      >
        <div>
          <b>Note to postsolvers:</b> This panel did not exist during the hunt.
          It is only included for your convenience.
        </div>
        <button onClick={resetBattle}>Reset battle</button>
        <button
          onClick={solveBattle}
          disabled={subscribedTeam.puzzles[puzName].solveTime !== undefined}
        >
          Solve{" "}
          {numPuzzles > 1 ? `current stage (stage ${puzIndex + 1})` : "battle"}
        </button>
        {numPuzzles > 1 ? (
          <>
            <button
              onClick={unlockAllStages}
              disabled={puzNamesInBattleGroup.every(
                (puzName) => subscribedTeam.puzzles[puzName] !== undefined
              )}
            >
              Unlock all stages
            </button>
            <button
              onClick={solveAllStages}
              disabled={puzNamesInBattleGroup.every(
                (puzName) =>
                  subscribedTeam.puzzles[puzName]?.solveTime !== undefined
              )}
            >
              Solve all stages
            </button>
          </>
        ) : null}
        <button
          onClick={() => {
            setBattleUnlockModalPuzName(null);
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
