import { memo, useMemo } from "react";

import "./EffectOptOverlays.css";

import { describeForm } from "components/BattleHelpText";
import HoverNotifier from "components/HoverNotifier";
import Icon, { IconType } from "components/Icon";
import {
  FailedChecks,
  getFailText,
  isPassthrough,
} from "engine/types/action-validation";
import { EffectOpt, EffectOptType } from "engine/types/effects";
import { getAllSlots, Slot } from "engine/types/game-state";
import {
  useInspector,
  useStepMaker,
  useClientGameStore,
} from "stores/ClientGameStore";
import {
  useSelectedPermanent,
  useTurnStore,
  usePendingForm,
  useEffectOpts,
} from "stores/TurnStore";

import TranslateToSlot from "./TranslateToSlot";
import { useSelectEffectOptAndResetIfDone } from "stores/UserGameActionHandlers";

interface EffectOptButtonProps {
  failedChecks: FailedChecks;
  onClick: () => void;
  slot: Slot;
  helpText: string;
}

function EffectOptOverlayNoMemo(props: EffectOptButtonProps) {
  const { failedChecks, onClick, slot, helpText } = props;

  const isValid = failedChecks.size === 0;
  const failText = getFailText(failedChecks);
  const text = failText ?? helpText;

  return (
    <TranslateToSlot row={slot.row} column={slot.column}>
      <HoverNotifier help={text}>
        <div
          className={`opt-overlay ${isValid ? "opt-valid" : "opt-invalid"}`}
          onClick={(e) => {
            if (isValid) {
              e.stopPropagation();
              onClick();
            }
          }}
        >
          <Icon className="opt-icon" icon={IconType.OPTION} />
        </div>
      </HoverNotifier>
    </TranslateToSlot>
  );
}

/**
 * A button that covers a slot, that when clicked, chooses that as an effect
 * opt. Assumes it's *not* passthrough; it's the caller's responsibility to
 * check that.
 */
const EffectOptOverlay = memo(EffectOptOverlayNoMemo);

function EffectOptOverlays() {
  const inspector = useInspector();
  const stepMaker = useStepMaker();
  const selectedPermanent = useSelectedPermanent();
  const effectOpts = useTurnStore((state) => state.effectOpts);
  const pendingForm = usePendingForm();
  const pendingEffect = useTurnStore((state) => state.pendingEffect);

  const sendStep = useClientGameStore((state) => state.sendStep);
  const selectEffectOptAndResetIfDone = useSelectEffectOptAndResetIfDone();

  const rows = inspector.getNumRows();
  const columns = inspector.getNumColumns();
  const slots = useMemo(() => getAllSlots(rows, columns), [columns, rows]);

  const { getFailedChecks } = useEffectOpts();

  const selectAndSend = (effectOpt: EffectOpt) => {
    // If the server is down, don't allow the user to select more opts.
    if (!sendStep || !pendingEffect || !selectedPermanent) return;
    const effectOpts = selectEffectOptAndResetIfDone(effectOpt);
    if (effectOpts) {
      sendStep(
        stepMaker.activateAbility(selectedPermanent, pendingEffect, effectOpts)
      );
    }
  };

  const effectOptToDom = (
    {
      effectOpt,
      slot,
    }: {
      effectOpt: EffectOpt;
      slot: Slot;
    },
    i: number
  ) => {
    if (pendingForm === null || pendingForm.type !== effectOpt.type) {
      return null;
    }

    const failedChecks = getFailedChecks(effectOpt);

    if (isPassthrough(failedChecks)) {
      return null;
    }

    return (
      <EffectOptOverlay
        key={i}
        failedChecks={failedChecks}
        onClick={() => selectAndSend(effectOpt)}
        slot={slot}
        helpText={describeForm(inspector, pendingForm, pendingEffect)}
      />
    );
  };

  const permanentEffectOpts =
    pendingForm === null || pendingForm.type !== EffectOptType.PERMANENT
      ? []
      : inspector.getAllPermanents().map((permanent) => ({
          effectOpt: {
            type: EffectOptType.PERMANENT,
            permanentId: permanent.id,
          } as EffectOpt,
          slot: permanent.slot,
        }));

  const slotEffectOpts =
    pendingForm === null || pendingForm.type !== EffectOptType.SLOT
      ? []
      : slots.map((slot) => ({
          effectOpt: {
            type: EffectOptType.SLOT,
            slot,
          } as EffectOpt,
          slot,
        }));

  return (
    <>
      {permanentEffectOpts.map(effectOptToDom)}
      {slotEffectOpts.map(effectOptToDom)}
    </>
  );
}

export default EffectOptOverlays;
