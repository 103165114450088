import { useEffect, useRef } from "react";
import keepsakePng from "images/keepsake-preview.png";
import "./Keepsake.css";

export default function Keepsake() {
  const cardRef = useRef<HTMLDivElement>(null);

  const isMouseOver = (mouseX: number, mouseY: number, rect: DOMRect) => {
    return (
      mouseX >= rect.left &&
      mouseX <= rect.right &&
      mouseY >= rect.top &&
      mouseY <= rect.bottom
    );
  };

  // WE ARE SO BACK
  const BALATRO_DAMPEN = 10;
  const BALATRO_SHINY_OPACITY = 0.4;

  // lerp a value from ranges x to ranges y
  function interpolate(input: number, x: number[], y: number[]): number {
    if (x.length !== y.length) {
      throw new Error("Input and output arrays must have the same length");
    }

    if (input <= x[0]) {
      return y[0];
    }

    if (input >= x[x.length - 1]) {
      return y[y.length - 1];
    }

    let startIndex = 0;
    while (input > x[startIndex + 1]) {
      startIndex++;
    }

    const endIndex = startIndex + 1;

    const x0 = x[startIndex];
    const x1 = x[endIndex];
    const y0 = y[startIndex];
    const y1 = y[endIndex];

    const interpolatedValue = y0 + ((input - x0) / (x1 - x0)) * (y1 - y0);

    return interpolatedValue;
  }

  // setup BALATRO listener
  useEffect(() => {
    if (!cardRef.current) return;
    const card = cardRef.current;

    const resetStyle = () => {
      card.style.backgroundImage = "";
      card.style.transform = "rotateX(0deg) rotateY(0deg)";
    };
    resetStyle();

    // BALATRO animation
    const drawHolo = (mouseX: number, mouseY: number, rect: DOMRect) => {
      const rotateX = -(mouseY - rect.top - rect.height / 2) / BALATRO_DAMPEN;
      const rotateY = (mouseX - rect.left - rect.width / 2) / BALATRO_DAMPEN;
      const diagonalMovement = rotateX + rotateY;

      const sheenPosition = interpolate(diagonalMovement, [-5, 5], [-100, 200]);
      const sheenOpacity = interpolate(
        sheenPosition,
        [-100, 49, 50, 51, 200],
        [0, BALATRO_SHINY_OPACITY, 0, BALATRO_SHINY_OPACITY, 0]
      );
      // const sheenOpacity = BALATRO_SHINY_OPACITY;

      const sheenPosition1 = sheenPosition - 50;
      const sheenPosition2 = sheenPosition - 30;
      const sheenPosition3 = sheenPosition - 10;
      const sheenPosition4 = sheenPosition + 10;
      const sheenPosition5 = sheenPosition + 30;
      const sheenPosition6 = sheenPosition + 50;
      const sheenGradient = `linear-gradient(55deg, transparent, rgba(255, 100, 100, ${sheenOpacity}) ${sheenPosition1}%, rgba(255, 255, 100, ${sheenOpacity}) ${sheenPosition2}%, rgba(100, 255, 100, ${sheenOpacity}) ${sheenPosition3}%, rgba(100, 255, 255, ${sheenOpacity}) ${sheenPosition}%, rgba(100, 100, 255, ${sheenOpacity}) ${sheenPosition4}%, rgba(255, 100, 255, ${sheenOpacity}) ${sheenPosition5}%, rgba(255, 100, 100, ${sheenOpacity}) ${sheenPosition6}%, transparent)`;
      card.style.backgroundImage = sheenGradient;
      card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const AUTO_MOVE_RADIUS = 50;
    const AUTO_MOVE_SPEED = 0.01;
    let autoMoveAngle = 0;

    let animationId: number | null = null;

    const autoMove = () => {
      const rect = card.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const mouseX = centerX + AUTO_MOVE_RADIUS * Math.cos(autoMoveAngle);
      const mouseY = centerY + AUTO_MOVE_RADIUS * Math.sin(autoMoveAngle);
      autoMoveAngle = (autoMoveAngle + AUTO_MOVE_SPEED) % (Math.PI * 2);

      drawHolo(mouseX, mouseY, rect);
      animationId = requestAnimationFrame(autoMove);
    };

    const startAnimation = () => {
      if (!animationId) {
        animationId = requestAnimationFrame(autoMove);
      }
    };

    const stopAnimation = () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
        animationId = null;
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      const rect = card.getBoundingClientRect();
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      if (!isMouseOver(mouseX, mouseY, rect)) {
        startAnimation();
        return;
      }
      stopAnimation();
      drawHolo(mouseX, mouseY, rect);
    };

    document.addEventListener("mousemove", handleMouseMove);
    animationId = requestAnimationFrame(autoMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      stopAnimation();
    };
  }, []);

  return (
    <div className="keepsake-holo" ref={cardRef}>
      <img src={keepsakePng} className="keepsake-img" />
    </div>
  );
}
