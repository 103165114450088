import { useMemo } from "react";
import { create } from "zustand";
import produce from "immer";
import { useParams } from "react-router-dom";

import { Player, GameState, makeInitGameState } from "engine/types/game-state";
import { Step } from "engine/types/steps";
import { Role, Update } from "engine/types/updates";
import { ClientGameSpec } from "engine/types/client-game-specs";
import { CardsDB } from "engine/cards/CardsDB";
import { applyUpdate } from "engine/Reducer";
import { StaticInspector, Inspector } from "engine/Inspector";
import { StepMaker } from "engine/StepMaker";
import { PUZ_NAME_COMMON } from "engine/game-specs/common-shared";
import { getClientGameSpec } from "engine/all-client-game-specs";

import { useCardsDBStore } from "stores/CardsDBStore";
import {
  ClientEffectsDB,
  getClientEffectsDB,
} from "engine/cards/card-effects-client";

import { useSelectedPuzName } from "stores/ClientGlobalStateStore";
import { useIsAdminEnabledStore } from "stores/UserPreferencesStore";
import Globals from "Globals";

export const getAllClientSpecs = (
  puzName: string
): ReadonlyArray<ClientGameSpec> => {
  return [PUZ_NAME_COMMON, ...(puzName ?? "").split(",")].map((specName) =>
    getClientGameSpec(specName)
  );
};

// Manually memoize allSpecs and effectsDB since we might want to
// access them outside components.

let allClientSpecsMemoState: {
  puzName: string;
  allSpecs: ReadonlyArray<ClientGameSpec>;
} | null = null;
export const getAllClientSpecsMemo = (
  puzName: string
): ReadonlyArray<ClientGameSpec> => {
  if (
    allClientSpecsMemoState !== null &&
    allClientSpecsMemoState.puzName === puzName
  )
    return allClientSpecsMemoState.allSpecs;
  const allSpecs = getAllClientSpecs(puzName);
  allClientSpecsMemoState = { puzName, allSpecs };
  return allSpecs;
};

let clientEffectsDBMemoState: {
  puzName: string;
  effectsDB: ClientEffectsDB;
} | null = null;
export const getClientEffectsDBMemo = (
  puzName: string,
  cardsDB: CardsDB
): ClientEffectsDB => {
  if (
    clientEffectsDBMemoState !== null &&
    clientEffectsDBMemoState.puzName === puzName
  )
    return clientEffectsDBMemoState.effectsDB;
  const effectsDB = getClientEffectsDB(getAllClientSpecsMemo(puzName), cardsDB);
  clientEffectsDBMemoState = { puzName, effectsDB };
  return effectsDB;
};

export interface ClientGameStoreState {
  /**
   * Function to send a step to the server.
   * This also acts as a proxy to indicate if the server is connected.
   * If sendStep is null, then the server is not connected, and all
   * game inputs should be disabled.
   */
  sendStep: ((step: Step) => void) | null;
  setSendStep: (sendStep: ((step: Step) => void) | null) => void;

  role: Role;
  gameState: GameState;
  setGameState: (val: GameState) => void;
  /** Apply an update from the server. */
  applyUpdate: (upd: Update, role: Role) => void;

  roomId: string | null;
  setRoomId: (roomId: string | null) => void;

  /** Locks for whether we accept input from the user. */
  stepLock: boolean;
  updateLock: boolean;
  setStepLock: (stepLock: boolean) => void;
  setUpdateLock: (updateLock: boolean) => void;

  isEndCutsceneRedirectHandled: boolean;
  setIsEndCutsceneRedirectHandled: (
    isEndCutsceneRedirectHandled: boolean
  ) => void;
}

export const useClientGameStore = create<ClientGameStoreState>((set, get) => ({
  sendStep: null,
  setSendStep: (sendStep) => {
    set({ sendStep });
  },
  role: Role.GOD,
  gameState: makeInitGameState("vanilla", {}),
  setGameState: (val: GameState) => set({ gameState: val }),
  applyUpdate: (upd, role) =>
    set(
      produce((state) => {
        const { gameState } = state;
        // The role needs to be updated atomically with processing
        // an update, if it changes.
        state.role = role;
        const allSpecs = getAllClientSpecs(gameState.puzName);
        applyUpdate(allSpecs[1], allSpecs, gameState, upd);
      })
    ),
  roomId: null,
  setRoomId: (roomId) => set({ roomId }),
  stepLock: false,
  updateLock: false,
  setStepLock: (stepLock: boolean) => set({ stepLock }),
  setUpdateLock: (updateLock: boolean) => set({ updateLock }),
  isEndCutsceneRedirectHandled: false,
  setIsEndCutsceneRedirectHandled: (isEndCutsceneRedirectHandled) =>
    set({ isEndCutsceneRedirectHandled }),
}));

export const makeInspector = (cardsDB: CardsDB, gameState: GameState) => {
  const { puzName } = gameState;
  const allSpecs = getAllClientSpecsMemo(puzName);
  const gameSpec = allSpecs[1];
  const effectsDB = getClientEffectsDBMemo(puzName, cardsDB);
  return new Inspector(gameSpec, allSpecs, cardsDB, effectsDB, gameState);
};

/** Allows readonly access to the game state. */
export const useInspector = () => {
  const gameState = useClientGameStore((state) => state.gameState);
  const cardsDB = useCardsDBStore((state) => state.cardsDB);
  const inspector = useMemo(
    () => makeInspector(cardsDB, gameState),
    [cardsDB, gameState]
  );

  // Leave this enabled all the time so we can do prod debugging.
  Globals.inspector = inspector;
  return inspector;
};

export const makeStaticInspector = (puzName: string, cardsDB: CardsDB) => {
  const effectsDB = getClientEffectsDBMemo(puzName, cardsDB);
  const allSpecs = getAllClientSpecsMemo(puzName);
  return new StaticInspector(allSpecs, cardsDB, effectsDB);
};

export const useStaticInspector = () => {
  const puzName = useSelectedPuzName() || "vanilla";
  const cardsDB = useCardsDBStore((state) => state.cardsDB);
  return makeStaticInspector(puzName, cardsDB);
};

export const useRole = (): Role => {
  return useClientGameStore((state) => state.role);
};

export const useStepMaker = (): StepMaker => {
  const inspector = useInspector();
  const role = useRole();
  const player = usePlayerPerspective();
  return new StepMaker(inspector, player, role);
};

export const useIsLockedBesidesKeyframe = () => {
  const sendStep = useClientGameStore((state) => state.sendStep);
  const stepLock = useClientGameStore((state) => state.stepLock);
  const updateLock = useClientGameStore((state) => state.updateLock);
  const isSpectating = useIsAdminEnabledStore((state) => state.isSpectating);
  const { spectateTeamId } = useParams();
  return (
    sendStep === null ||
    stepLock ||
    updateLock ||
    spectateTeamId !== undefined ||
    isSpectating
  );
};

export const useIsLocked = () => {
  const inspector = useInspector();
  const keyframeLock =
    inspector.isGameActive() &&
    (inspector.gameState.keyframe?.blocking ?? false);
  const isLockedBesidesKeyframe = useIsLockedBesidesKeyframe();
  return keyframeLock || isLockedBesidesKeyframe;
};

export const getPlayerPerspectiveForRole = (role: Role): Player => {
  switch (role) {
    case Role.GOD:
    case Role.P1:
      return Player.P1;
    case Role.P2:
      return Player.P2;
  }
};

/** The player perspective that the UI takes or simulates. */
export const usePlayerPerspective = (): Player => {
  return getPlayerPerspectiveForRole(useRole());
};

/**
 * The roomPuzName from the game state. Should only be used in the
 * battle page, where the game state is kept synced. Other pages like
 * the prep page should use the roomPuzName from subscribedTeamBattle
 * instead.
 */
export const useRoomPuzNameFromGameState = (): string => {
  const gameState = useClientGameStore((state) => state.gameState);
  return gameState.puzName;
};
