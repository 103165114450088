import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { HTMLProps, PropsWithChildren, ReactNode } from "react";
import "./Modal.css";

interface ModalProps {
  isOpen?: boolean;
  title: string;
  footerChildren?: ReactNode;
}

function Modal(
  props: PropsWithChildren<ModalProps> & HTMLProps<HTMLDivElement>
) {
  const {
    children,
    footerChildren,
    isOpen = true,
    title,
    className,
    ...otherProps
  } = props;

  const { refs, context } = useFloating({ open: isOpen });

  const dismiss = useDismiss(context, { bubbles: true });
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([dismiss, role]);

  if (!isOpen) return null;

  return (
    <FloatingPortal root={document.getElementById("game-root")}>
      <FloatingOverlay className="modal-wrapper">
        <FloatingFocusManager context={context}>
          <div
            className={`modal ${className}`}
            ref={refs.setFloating}
            {...getFloatingProps(otherProps)}
          >
            <div className="modal-header">
              <h2>{title}</h2>
            </div>
            <div className="modal-body">{children}</div>
            {footerChildren === undefined ? null : (
              <div className="modal-footer">{footerChildren}</div>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
}

function ConfirmModal(
  props: PropsWithChildren<
    ModalProps & {
      onClose: () => void;
      onConfirm: () => void;
      confirmLabel: string;
    }
  >
) {
  const { onClose, onConfirm, confirmLabel, ...otherProps } = props;
  useHotkeys("esc", onClose);
  const buttons = (
    <>
      <button onClick={onClose}>Cancel</button>
      <button
        onClick={() => {
          onConfirm();
          onClose();
        }}
      >
        {confirmLabel}
      </button>
    </>
  );
  return <Modal {...otherProps} footerChildren={buttons} />;
}

export default Modal;
export { ConfirmModal };
