import { Player } from "engine/types/game-state";
import { Check } from "engine/types/action-validation";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedTutorial1Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  numColumns: 1,
  numRows: 2,
  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  // no bases
  basesOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  adjustEndTurnChecks: (failedChecks, player, ctx) => {
    const { inspector } = ctx;
    if (player !== Player.P1) return;
    const { keyframe } = inspector.gameState;
    if (keyframe === undefined || keyframe.id !== "tutorial1-end-turn-1")
      failedChecks.add(Check.HIDDEN);
  },
};
