import { useEffect, useState } from "react";
import Twemoji from "react-twemoji";
import { isUsedForSolveCount } from "engine/puzzles/puzzle-data";
import settings from "settings";
import FactionIcon from "components/FactionIcon";
import TeamDisplayNameDisplay, {
  clientTeamGlobalStateToTeamNameDisplayData,
} from "components/TeamDisplayNameDisplay";
import Tooltip from "components/Tooltip";
import { useSubscribedTeam } from "stores/ClientGlobalStateStore";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import "./NavTeamName.css";

function NavTeamName() {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  const subscribedTeam = useSubscribedTeam();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    return serverInterfaceController?.addAuthEffect(() => {
      setConnected(true);
      return () => {
        setConnected(false);
      };
    });
  }, [serverInterfaceController]);

  if (subscribedTeam === null) return null;

  const { faction, factionScores, puzzles, battleGroups, hintsInfo } =
    subscribedTeam;
  const { numHintsUsed, numHintsTotal } = hintsInfo;
  const numSolves = Object.values(puzzles).filter(
    ({ puzName, battleGroupName, solveTime }) =>
      isUsedForSolveCount(
        puzName,
        battleGroupName,
        battleGroups?.[battleGroupName]?.isCutscene ?? false
      ) && solveTime !== undefined
  ).length;

  return (
    <>
      {settings.isPublicAccess ? null : (
        <Tooltip
          text={
            connected
              ? "Connected."
              : "Disconnected. Attempting to reconnect..."
          }
        >
          <div className="connected-marker-wrapper">
            <div
              className={`connected-marker ${
                connected ? "connected" : "disconnected"
              }`}
            />
            <div className="connected-marker-text">
              &nbsp;{connected ? "Connected" : "Disconnected"}
            </div>
          </div>
        </Tooltip>
      )}
      <TeamDisplayNameDisplay
        teamData={clientTeamGlobalStateToTeamNameDisplayData(subscribedTeam)}
      />
      {!faction ? undefined : (
        <div className="nav-stat">
          <Tooltip text="Faction score">{factionScores[faction]}</Tooltip>
          <FactionIcon faction={faction} />
        </div>
      )}
      {numHintsTotal === 0 ? undefined : (
        <Tooltip text="Hints available">
          <div className="nav-stat">
            {numHintsTotal - numHintsUsed}
            <Twemoji>💡</Twemoji>
          </div>
        </Tooltip>
      )}
      {numSolves === 0 ? null : (
        <Tooltip text="Puzzles solved">
          <div className="nav-stat">
            {numSolves}
            <Twemoji>✅</Twemoji>
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default NavTeamName;
