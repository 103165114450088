import { PropsWithChildren } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { PUZ_NAME_MASTERY_TREE } from "engine/puzzles/puzzle-data";
import { useNavStore } from "stores/NavStore";

// A RoutedLink is a link that works both for in-app navigation with a router,
// and for browser navigation on Django pages. This is specifically true for
// navbar links. Note: `path` must be valid in both cases; if not, set `href`.
interface RoutedLinkProps {
  path: string;
  href?: string;
  className?: string;
}

export function RoutedLink({
  children,
  path,
  href,
  className,
}: PropsWithChildren<RoutedLinkProps>) {
  const hasRouter = useNavStore((state) => state.hasRouter);
  const setRouterNavigatePath = useNavStore(
    (state) => state.setRouterNavigatePath
  );
  return (
    <a
      href={href ?? path}
      onClick={(e) => {
        if (!hasRouter) return;
        setRouterNavigatePath(path);
        e.preventDefault();
      }}
      className={className}
    >
      {children}
    </a>
  );
}

// A PuzzleLink is a link to a puzzle. It uses in-app navigation for battles
// and browser navigation for fish puzzles, with special casing for masteries.
interface PuzzleLinkProps {
  battleGroupName: string;
  hasAnswer: boolean;
  linkTargetOverride?: string;
}

export function PuzzleLink({
  children,
  battleGroupName,
  hasAnswer,
  linkTargetOverride,
}: PropsWithChildren<PuzzleLinkProps>) {
  const [searchParams] = useSearchParams();
  const isMasteryTree = battleGroupName === PUZ_NAME_MASTERY_TREE;
  const isLinkExternal = !isMasteryTree && hasAnswer;
  const linkTarget = (() => {
    if (linkTargetOverride) return linkTargetOverride;
    if (isMasteryTree) return "/game/mastery";
    if (isLinkExternal) return `/puzzle/${battleGroupName}`;
    return `/game/prep/${battleGroupName}`;
  })();
  if (isLinkExternal) return <a href={linkTarget}>{children}</a>;
  return (
    <Link
      to={{
        pathname: linkTarget,
        search: searchParams.toString(),
      }}
    >
      {children}
    </Link>
  );
}
