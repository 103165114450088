import { Player } from "engine/types/game-state";
import { Check } from "engine/types/action-validation";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedTutorial6Spec: SharedGameSpec = {
  allowCheckpoints: true,
  aiDisableGemAccounting: true,
  hasAI: true,
  allowNoDeck: true,
  basesOverrides: {
    [Player.P2]: ["camp"],
  },
  adjustDeckChecks: (failedChecks) => {
    failedChecks.delete(Check.DECK_TOO_SMALL);
  },
};
