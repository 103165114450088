import { PUZ_NAME_FINAL_BATTLE } from "engine/puzzles/puzzle-data";
import { memo } from "react";
import Twemoji from "react-twemoji";
import { useSelectedPuzName } from "stores/ClientGlobalStateStore";

import "./FormattedText.css";

import {
  FormatOpts,
  parseTokens,
  Token,
  TokenType,
} from "./FormattedText.util";
import { GemsDisplay } from "./GemDisplay";
import Tooltip from "./Tooltip";

const Token = memo(function Token(props: { token: Token }) {
  const { token } = props;

  switch (token.type) {
    case TokenType.FACE: {
      return <span className="inline-face">{token.raw}</span>;
    }
    case TokenType.FLAVOR: {
      return <span className="inline-flavor">{token.raw}</span>;
    }
    case TokenType.GEMS: {
      return <GemsDisplay gems={token.gems} inline={true} />;
    }
    case TokenType.KEYWORD: {
      return (
        <Tooltip
          text={
            <FormattedText
              text={token.text}
              opts={{ ignoredKeyword: token.base }}
            />
          }
          format={true}
        >
          <span className="inline-keyword">{token.raw}</span>
        </Tooltip>
      );
    }
    case TokenType.RAWS: {
      if (token.raws.some((s) => s.includes("♠"))) {
        return <>{token.raws.join("")}</>;
      } else {
        return <Twemoji tag="span">{token.raws.join("")}</Twemoji>;
      }
    }
  }
});

interface FormattedTextProps {
  text: string;
  opts?: FormatOpts;
}

/**
 * Parses and formats a string nicely:
 * - New lines become new paragraphs.
 * - Things in *asterisks* become italicized.
 * - Things in {braces} become gem displays.
 * - Keywords will be parsed and get a tooltip.
 */
function FormattedText(props: FormattedTextProps) {
  const { text, opts } = props;
  const lines = text.split("\n");
  const selectedPuzName = useSelectedPuzName();
  const isFinalBattle =
    selectedPuzName !== null && selectedPuzName === PUZ_NAME_FINAL_BATTLE;

  return (
    <div
      className="formatted-text"
      style={{
        gap: opts?.lineGap,
      }}
    >
      {lines.map((line, i) => (
        <div
          key={i}
          className={
            line.startsWith("#")
              ? ((line = line.slice(1)), "formatted-context")
              : ""
          }
        >
          {parseTokens(line, { ...(opts ?? {}), isFinalBattle }).map(
            (token, j) => (
              <span key={j}>
                <Token token={token} />
              </span>
            )
          )}
        </div>
      ))}
    </div>
  );
}

export default memo(FormattedText);
