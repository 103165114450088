import { Check } from "engine/types/action-validation";
import { Player } from "engine/types/game-state";
import { EffectOptType } from "engine/types/effects";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedPuzzle53Spec: SharedGameSpec = {
  allowCheckpoints: true,
  cardEffectsShared: {
    yurt: {
      isBase: true,
      isStructure: true,
    },
  },
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: ["yurt", "yurt", "yurt"],
  },
  numRows: 16,
  adjustSummonChecks: (failedChecks, player, _cardName, slot) => {
    // player can only summon in first three rows
    if (player === Player.P1 && slot.row <= 12) {
      failedChecks.add(Check.TERRAIN);
    }
  },
  adjustAttackChecks: (failedChecks, attacker, defender, ctx) => {
    const { inspector } = ctx;
    if (Math.abs(attacker.slot.row - defender.slot.row) > 6)
      failedChecks.add(Check.TOO_FAR);
  },
  adjustEffectOptChecks: (failedChecks, effectOpt, form, ctx) => {
    const { permanent } = ctx;
    switch (effectOpt.type) {
      case EffectOptType.PERMANENT: {
        const { permanent: targetPermanent } = effectOpt;
        if (Math.abs(permanent.slot.row - targetPermanent.slot.row) > 6)
          failedChecks.add(Check.TOO_FAR);
        break;
      }
      case EffectOptType.SLOT: {
        const { slot } = effectOpt;
        if (Math.abs(permanent.slot.row - slot.row) > 6)
          failedChecks.add(Check.TOO_FAR);
        break;
      }
    }
  },
};
