import { z } from "zod";

import {
  Card,
  Permanent,
  Slot,
  Player,
  DevKnobs,
  GamePhase,
  GameState,
} from "engine/types/game-state";
import { Counter } from "engine/types/counters";
import { GemColor } from "engine/types/card-data";
import { Keyframe } from "engine/types/keyframes";
import { DeckUpdate } from "engine/types/decks";
import { MasteryTreeUpdate } from "game-server/masteries";
import { WSError } from "game-server/ws";
import { RoomMember } from "game-server/Room";
import {
  LoseReason,
  TeamCard,
} from "game-server/backend-interface/BackendInterface";

/** Roles that a team may play in a game. Should be a superset of Player. */
export enum Role {
  P1 = "p1",
  P2 = "p2",
  // GOD: Has access to the entire game state.
  GOD = "god",
}
export const RoleZod = z.nativeEnum(Role);

export const canRoleControlPlayer = (role: Role, player: Player): boolean => {
  switch (role) {
    case Role.GOD:
      return true;
    case Role.P1:
      return player === Player.P1;
    case Role.P2:
      return player === Player.P2;
  }
};

export enum UpdateType {
  // System updates, intended for the server and not clients.
  SYSTEM_ENABLE_MEMBER = "system_enable_member",
  SYSTEM_UPDATE_DECK = "system_update_deck",
  SYSTEM_UPDATE_MASTERY_TREE = "system_update_mastery_tree",
  /** Signal that a room's activity is complete. */
  SYSTEM_ROOM_COMPLETE = "system_room_complete",

  /**
   * Acknowledge a step. This is only sent to the client requesting
   * the step, so that it can update the UI state appropriately.
   */
  STEP_ACK = "step_ack",
  /** Kick a client from the room. */
  KICK = "kick",

  OVERRIDE_STATE = "state",
  RESET = "reset",
  ADVANCE_PHASE = "advance_phase",
  SET_GAME_RESULT = "set_game_result",
  START_TURN = "start_turn",
  END_TURN = "end_turn",

  SET_DRAW_PILE = "set_draw_pile",
  SET_DEV_KNOBS = "set_dev_knobs",
  ADD_GEMS = "add_gems",
  REMOVE_GEMS = "remove_gems",
  CLEAR_GEMS = "clear_gems",
  CREATE_PERMANENT = "create_permanent",
  REMOVE_PERMANENT = "remove_permanent",
  READY_PERMANENT = "ready_permanent",
  UNREADY_PERMANENT = "unready_permanent",
  TRANSFORM_PERMANENT = "transform_permanent",
  CHANGE_PERMANENT_OWNER = "change_permanent_owner",
  MOVE = "move",
  SWAP = "swap",
  DRAW_CARD = "draw_card",
  REMOVE_FROM_DRAW_PILE = "remove_from_draw_pile",
  DISCARD_CARD = "discard_card",
  DAMAGE_PERMANENT = "damage_permanent",
  DISCARD_PERMANENT = "discard_permanent",
  ADD_COUNTER = "add_counter",
  REMOVE_COUNTER = "remove_counter",
  SET_COUNTER = "set_counter",
  INIT_SHARED_EXTRA_STATE = "init_shared_extra_state",
  MODIFY_SHARED_EXTRA_STATE = "modify_shared_extra_state",
  MODIFY_STATS = "modify_stats",
  SET_STEP_NUMBER = "set_step_number",
  WAIT_ANIMATE = "wait_animate",

  // Set the keyframe.
  SET_KEYFRAME = "set_keyframe",
  // Announce a message to the user console.
  ANNOUNCE = "announce",
  // Updates to announce an event.
  // This does not cause the client to mutate game state.
  // Actual game state mutation may be broken up into multiple
  // simpler updates.
  ANNOUNCE_SUMMON_PERMANENT = "announce_summon_permanent",
  ANNOUNCE_MOVE = "announce_move",
  ANNOUNCE_ATTACK_CARD = "announce_attack_card",

  // Deckbuilding-specific updates
  DECKBUILDING_OVERRIDE_STATE = "deckbuilding_override_state",
}

type UpdateSystemEnableMember = {
  type: UpdateType.SYSTEM_ENABLE_MEMBER;
  roomMember: RoomMember;
};

type UpdateSystemUpdateDeck = {
  type: UpdateType.SYSTEM_UPDATE_DECK;
  deckUpd: DeckUpdate;
};

type UpdateSystemUpdateMasteryTree = {
  type: UpdateType.SYSTEM_UPDATE_MASTERY_TREE;
  masteryTreeUpd: MasteryTreeUpdate;
};

type UpdateSystemRoomComplete = {
  type: UpdateType.SYSTEM_ROOM_COMPLETE;
  solvedTeamIds: string[];
  cardUnlocks: TeamCard[];
};

type UpdateStepAck = {
  type: UpdateType.STEP_ACK;
  err?: WSError;
};

type UpdateKick = {
  type: UpdateType.KICK;
};

type UpdateOverrideState = {
  type: UpdateType.OVERRIDE_STATE;
  roomId: string;
  state: GameState;
};

/** Reset the game state to the initial game state for the puzzle. */
type UpdateReset = {
  type: UpdateType.RESET;
};

type UpdateAdvancePhase = {
  type: UpdateType.ADVANCE_PHASE;
  phase: GamePhase;
};

type UpdateSetGameResult = {
  type: UpdateType.SET_GAME_RESULT;
  winner: Player;
  loseReason: LoseReason;
  solves: { [player in Player]?: boolean };
  cardUnlocks: { [player in Player]?: string[] };
  /** The time when the game ended, to end any running turns. */
  endTime: number;
};

type UpdateStartTurn = {
  type: UpdateType.START_TURN;
  player: Player;
  /** Timestamp for when the turn was started. */
  startTurnTime: number;
};

type UpdateEndTurn = {
  type: UpdateType.END_TURN;
  player: Player;
  /** Timestamp for when the turn was ended. */
  endTurnTime: number;
};

type UpdateAddGems = {
  type: UpdateType.ADD_GEMS;
  player: Player;
  gems: GemColor[];
};

type UpdateRemoveGems = {
  type: UpdateType.REMOVE_GEMS;
  player: Player;
  gems: GemColor[];
};

type UpdateClearGems = {
  type: UpdateType.CLEAR_GEMS;
  player: Player;
};

/**
 * Create a permanent on the board. This just adds the permanent to
 * the board and does not trigger any effects.
 */
export type UpdateCreatePermanent = {
  type: UpdateType.CREATE_PERMANENT;
  permanent: Permanent;
  /**
   * If specified, the card with id handCardId is deleted. This should
   * not be a separate update since we need to show both at the same time
   * to the player.
   */
  handCardId?: string;
};

/**
 * Removes a permanent from the board.
 */
export type UpdateRemovePermanent = {
  type: UpdateType.REMOVE_PERMANENT;
  permanentId: string;
  /** If set, the permanent will be added to the owner's discard pile. */
  discard?: boolean;
};

type UpdateReadyPermanent = {
  type: UpdateType.READY_PERMANENT;
  permanentId: string;
};

type UpdateUnreadyPermanent = {
  type: UpdateType.UNREADY_PERMANENT;
  permanentId: string;
};

type UpdateTransformPermanent = {
  type: UpdateType.TRANSFORM_PERMANENT;
  permanentId: string;
  newCardName: string;
};

type UpdateChangePermanentOwner = {
  type: UpdateType.CHANGE_PERMANENT_OWNER;
  permanentId: string;
  owner: Player;
};

type UpdateMove = {
  type: UpdateType.MOVE;
  permanentId: string;
  slot: Slot;
};

type UpdateSwap = {
  type: UpdateType.SWAP;
  permanent1Id: string;
  permanent2Id: string;
};

export type UpdateDrawCard = {
  type: UpdateType.DRAW_CARD;
  player: Player;
  card: Card;
  handCardId: string;
  shouldPopDrawPile: boolean;
  /**
   * The card to set as the next top card in the draw pile, if
   * the consumer has access to that state.
   * May also be omitted if there is not change to the top card.
   */
  nextTopCard?: Card | null;
};

export type UpdateRemoveFromDrawPile = {
  type: UpdateType.REMOVE_FROM_DRAW_PILE;
  player: Player;
  drawPileIndex: number;
  /**
   * The card to set as the next top card in the draw pile, if
   * the consumer has access to that state.
   * May also be omitted if there is not change to the top card.
   */
  nextTopCard?: Card | null;
};

export type UpdateDiscardCard = {
  type: UpdateType.DISCARD_CARD;
  player: Player;
  handCardId: string;
};

type UpdateDamagePermanent = {
  type: UpdateType.DAMAGE_PERMANENT;
  permanentId: string;
  damage: number;
  attackerId?: string; // if attributed to another permanent
};

type UpdateAddCounter = {
  type: UpdateType.ADD_COUNTER;
  permanentId: string;
  counter: Counter;
};

type UpdateRemoveCounter = {
  type: UpdateType.REMOVE_COUNTER;
  permanentId: string;
  counterIndex: number;
};

type UpdateSetCounter = {
  type: UpdateType.SET_COUNTER;
  permanentId: string;
  counterIndex: number;
  counterVal?: number; // if unset, counter value becomes unset
  explanation?: string;
};

/** A battle-specific update for initializing the shared extra state. */
export type UpdateInitSharedExtraState = {
  type: UpdateType.INIT_SHARED_EXTRA_STATE;
  /** The initial sharedExtraState. */
  sharedExtraState: object;
};

/** A battle-specific update for the shared extra state. */
export type UpdateModifySharedExtraState = {
  type: UpdateType.MODIFY_SHARED_EXTRA_STATE;
  /** An object describing the update that needs to be performed. */
  updInfo: object;
};

export type UpdateModifyStats = {
  type: UpdateType.MODIFY_STATS;
  player: Player;
  diffSummons?: number;
  diffDestroys?: number;
  diffDamageDealt?: number;
  diffFactionSummons?: number;
};

export type UpdateSetStepNumber = {
  type: UpdateType.SET_STEP_NUMBER;
  player: Player;
  stepNumber: number;
};

export type UpdateWaitAnimate = {
  type: UpdateType.WAIT_ANIMATE;
  waitTime: number;
};

type UpdateKeyframe = {
  type: UpdateType.SET_KEYFRAME;
  keyframe: Keyframe | null;
};

type UpdateAnnounce = {
  type: UpdateType.ANNOUNCE;
  message: string;
};

type UpdateAnnounceSummonPermanent = {
  type: UpdateType.ANNOUNCE_SUMMON_PERMANENT;
  player: Player;
  cardName: string;
};

type UpdateAnnounceMove = {
  type: UpdateType.ANNOUNCE_MOVE;
  player: Player;
  cardName: string;
};

type UpdateAnnounceAttackCard = {
  type: UpdateType.ANNOUNCE_ATTACK_CARD;
  cardName: string;
  target: string;
  damage: number;
};

// TODO announce draw card?

type UpdateSetDrawPile = {
  type: UpdateType.SET_DRAW_PILE;
  player: Player;
  drawPile: ReadonlyArray<Card>;
};

type UpdateSetDevKnobs = {
  type: UpdateType.SET_DEV_KNOBS;
  devKnobs: DevKnobs;
};

// Deckbuilding-specific updates

type UpdateDeckbuildingOverrideState = {
  type: UpdateType.DECKBUILDING_OVERRIDE_STATE;
};

export type Update = Readonly<
  | UpdateSystemEnableMember
  | UpdateSystemUpdateDeck
  | UpdateSystemUpdateMasteryTree
  | UpdateSystemRoomComplete
  | UpdateStepAck
  | UpdateKick
  | UpdateOverrideState
  | UpdateReset
  | UpdateAdvancePhase
  | UpdateSetGameResult
  | UpdateStartTurn
  | UpdateEndTurn
  | UpdateSetDrawPile
  | UpdateSetDevKnobs
  | UpdateAddGems
  | UpdateRemoveGems
  | UpdateClearGems
  | UpdateCreatePermanent
  | UpdateRemovePermanent
  | UpdateReadyPermanent
  | UpdateUnreadyPermanent
  | UpdateTransformPermanent
  | UpdateChangePermanentOwner
  | UpdateMove
  | UpdateSwap
  | UpdateDrawCard
  | UpdateRemoveFromDrawPile
  | UpdateDiscardCard
  | UpdateDamagePermanent
  | UpdateAddCounter
  | UpdateRemoveCounter
  | UpdateSetCounter
  | UpdateInitSharedExtraState
  | UpdateModifySharedExtraState
  | UpdateModifyStats
  | UpdateSetStepNumber
  | UpdateWaitAnimate
  | UpdateKeyframe
  | UpdateAnnounce
  | UpdateAnnounceSummonPermanent
  | UpdateAnnounceMove
  | UpdateAnnounceAttackCard
  | UpdateDeckbuildingOverrideState
>;
