// Intermediate UI state for a user executing a turn.

import { create } from "zustand";
import produce from "immer";

import { Permanent, Player } from "engine/types/game-state";
import {
  AbilityType,
  EffectOpt,
  type EffectOptForm,
} from "engine/types/effects";
import {
  useInspector,
  useStepMaker,
  useClientGameStore,
  useIsLocked,
} from "stores/ClientGameStore";
import {
  Check,
  FailedChecks,
  getFailText,
} from "engine/types/action-validation";
import { Inspector } from "engine/Inspector";

export enum SelectedCardType {
  HAND = "hand",
  PERMANENT = "permanent",
}

type SelectedCardHand = {
  type: SelectedCardType.HAND;
  player: Player;
  handCardId?: string;
  /**
   * In all-cards-available mode, the cards are not played from the
   * hand, so they are specified by just the card name.
   */
  name?: string;
};
type SelectedCardPermanent = {
  type: SelectedCardType.PERMANENT;
  permanentId: string;
};

export type SelectedCard = SelectedCardHand | SelectedCardPermanent;

export interface TurnState {
  /** Currently highlighted card; can be in hand or permanent. */
  selectedCard: SelectedCard | null;
  /** Whether the selectedCard was set by the keyframe. */
  isKeyframeSelected: boolean;

  /** Set selected card as card in hand. */
  setSelectedCardHand: (player: Player, handCardId: string) => void;
  /** Set selected card as card in hand in all-cards-available mode. */
  setSelectedCardHandAllCardsAvailable: (player: Player, name: string) => void;
  /** Set selected card as permanent on board. */
  setSelectedCardPermanent: (
    permanentId: string,
    isKeyframeSelected?: boolean
  ) => void;
  /** Remove selected card. */
  resetSelectedCard: () => void;

  /**
   * When we're expecting something to be "targeted" by a card, this is
   * non-null. That means we're looking for a click of pendingEffectOpts[0].
   */
  pendingEffect: AbilityType | null;
  /** Forms for opts that need to be selected. */
  pendingForms: EffectOptForm[];
  /** Opts that have already been selected. */
  effectOpts: EffectOpt[];

  /** Start waiting for the client to select the forms for an effect. */
  startEffect: (
    pendingEffect: AbilityType,
    pendingForms: EffectOptForm[]
  ) => void;
  /** Select the next effect opt, which should match the top pending form. */
  selectEffectOpt: (opt: EffectOpt) => void;
  /** Reset the pending effect. */
  resetEffectOpts: () => void;
  /**
   * Select the next opt. If there are then no more pendingForms, return all
   * the effectOpts and reset the pending effects.
   */
  selectEffectOptAndResetIfDone: (opt: EffectOpt) => null | EffectOpt[];
}

export const useTurnStore = create<TurnState>((set, get) => ({
  selectedCard: null,
  isKeyframeSelected: false,
  resetSelectedCard: () =>
    set({
      selectedCard: null,
      isKeyframeSelected: false,
    }),

  setSelectedCardHand: (player: Player, handCardId: string) =>
    set({
      selectedCard: {
        type: SelectedCardType.HAND,
        player,
        handCardId,
      },
    }),
  setSelectedCardHandAllCardsAvailable: (player: Player, name: string) =>
    set({
      selectedCard: {
        type: SelectedCardType.HAND,
        player,
        name,
      },
    }),
  setSelectedCardPermanent: (
    permanentId: string,
    isKeyframeSelected?: boolean
  ) =>
    set({
      selectedCard: {
        type: SelectedCardType.PERMANENT,
        permanentId: permanentId,
      },
      isKeyframeSelected,
    }),

  pendingEffect: null,
  pendingForms: [],
  effectOpts: [],

  startEffect: (pendingEffect, pendingForms) =>
    set({ pendingEffect, pendingForms }),
  selectEffectOpt: (opt: EffectOpt) => {
    set(
      produce((state: TurnState) => {
        if (state.pendingEffect === null) {
          // Handle race condition when effect opts gets resetted
          // at the same time as a selection.
          return;
        }
        state.effectOpts.push(opt);
        state.pendingForms.shift();
      })
    );
  },
  resetEffectOpts: () =>
    set({
      pendingEffect: null,
      pendingForms: [],
      effectOpts: [],
    }),
  selectEffectOptAndResetIfDone: (opt: EffectOpt) => {
    const state = get();
    if (state.pendingForms.length > 1) {
      state.selectEffectOpt(opt);
      return null;
    }
    // otherwise, we are done, return and reset
    const effectOpts = [...state.effectOpts, opt];
    state.resetEffectOpts();
    return effectOpts;
  },
}));

/** Get the selected permanent, if it exists. */
export const useSelectedPermanent = () => {
  const selectedCard = useTurnStore((state) => state.selectedCard);
  const inspector = useInspector();
  return selectedCard !== null &&
    selectedCard.type === SelectedCardType.PERMANENT
    ? // Don't assert. When the server removes the permanent from play,
      // the selected card state is not immediately updated.
      inspector.getPermanentIfExists(selectedCard.permanentId)
    : null;
};

/** Get selected card data. */
export const useSelectedCardData = () => {
  const selectedCard = useTurnStore((state) => state.selectedCard);
  const inspector = useInspector();
  if (selectedCard === null) {
    return null;
  }
  switch (selectedCard.type) {
    case SelectedCardType.HAND: {
      if (selectedCard.name !== undefined) {
        return inspector.cardsDB[selectedCard.name];
      }
      if (selectedCard.handCardId !== undefined) {
        const handCard = inspector.getCardInHandIfExists(
          selectedCard.player,
          selectedCard.handCardId
        );
        return handCard === null ? null : inspector.getCardData(handCard);
      }
      throw new Error("either name or handCardId must be specified");
    }
    case SelectedCardType.PERMANENT: {
      const selectedPermanent = inspector.getPermanentIfExists(
        selectedCard.permanentId
      );
      return selectedPermanent === null
        ? null
        : inspector.getCardData(selectedPermanent);
    }
  }
};

/** The currently pendingForm, or null if none. */
export const usePendingForm = () => {
  const pendingForms = useTurnStore((state) => state.pendingForms);
  return pendingForms.length > 0 ? pendingForms[0] : null;
};

export function getEffectOptFailedChecks(
  effectOpt: EffectOpt,
  effectOpts: EffectOpt[],
  pendingForm: EffectOptForm | null,
  selectedPermanent: Permanent | null,
  inspector: Inspector
): FailedChecks {
  const failedChecks: FailedChecks = new Set();
  if (!pendingForm || !selectedPermanent) {
    return failedChecks;
  }
  const effectOptResolved = inspector.resolveEffectOptIfExists(effectOpt);
  const effectOptsResolved = inspector.resolveEffectOptsIfExist(effectOpts);
  if (effectOptResolved === null || effectOptsResolved === null) {
    failedChecks.add(Check.RESOLVE);
  } else {
    inspector.validateEffectOpt(failedChecks, effectOptResolved, pendingForm, {
      permanent: selectedPermanent,
      effectOpts: effectOptsResolved,
      inspector,
    });
  }
  return failedChecks;
}

export function isEffectOptValid(
  effectOpt: EffectOpt,
  effectOpts: EffectOpt[],
  pendingForm: EffectOptForm | null,
  selectedPermanent: Permanent | null,
  inspector: Inspector
): boolean {
  const failedChecks = getEffectOptFailedChecks(
    effectOpt,
    effectOpts,
    pendingForm,
    selectedPermanent,
    inspector
  );
  return failedChecks.size === 0;
}

/** High-level access to the effect opt state. */
export const useEffectOpts = () => {
  const inspector = useInspector();
  const stepMaker = useStepMaker();
  const selectedPermanent = useSelectedPermanent();
  const effectOpts = useTurnStore((state) => state.effectOpts);
  const pendingForm = usePendingForm();
  const pendingEffect = useTurnStore((state) => state.pendingEffect);

  const sendStep = useClientGameStore((state) => state.sendStep);
  const selectEffectOptAndResetIfDone = useTurnStore(
    (state) => state.selectEffectOptAndResetIfDone
  );
  const isLocked = useIsLocked();

  const getFailedChecks = (effectOpt: EffectOpt) => {
    const failedChecks = getEffectOptFailedChecks(
      effectOpt,
      effectOpts,
      pendingForm,
      selectedPermanent,
      inspector
    );
    if (isLocked) failedChecks.add(Check.UI_LOCKED);
    return failedChecks;
  };

  const isValidOpt = (effectOpt: EffectOpt): string | null => {
    const failedChecks = getFailedChecks(effectOpt);
    return getFailText(failedChecks);
  };

  const selectOptAndSend = (effectOpt: EffectOpt): void => {
    // If the server is down, don't allow the user to select more opts.
    if (!sendStep) return;
    if (!pendingEffect) {
      throw new Error("can't select if no effect is pending");
    }
    const effectOpts = selectEffectOptAndResetIfDone(effectOpt);
    if (effectOpts) {
      if (selectedPermanent === null) {
        throw new Error("expected selected permanent");
      }
      sendStep(
        stepMaker.activateAbility(selectedPermanent, pendingEffect, effectOpts)
      );
    }
  };

  return {
    /** Get the failed checks for an effect opt. */
    getFailedChecks,
    /** Check if this is a valid opt. */
    isValidOpt,
    /** The currently pending form, or null if none. */
    pendingForm,
    /** Select this as the next op, sending the step if done. */
    selectOptAndSend,
  };
};
