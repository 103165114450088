import { SharedGameSpec } from "engine/types/shared-game-specs";
import { Check, FailedChecks } from "engine/types/action-validation";
import {
  ALL_PUSHERS,
  LEVEL_INDICES,
  ZAP_PUSHER_NAME,
  getNumCols,
  getNumRows,
  WALL_NAME,
  ARMORED_CAMP_NAME,
} from "./puzzle-22-data";
import { Permanent, Player } from "engine/types/game-state";
import { ColorSymbol } from "engine/types/card-data";
import {
  EffectOptType,
  EffectOptValidationContext,
} from "engine/types/effects";

const BASE_SHARED_GAME_SPEC = {
  allowCheckpoints: true,
  aiDisableGemAccounting: true,
  hasAI: true,
  adjustMoveChecks(failedChecks, permanent, slot) {
    if (ALL_PUSHERS.has(permanent.card.name || "")) {
      const slot2 = permanent.slot;
      const manhattan =
        Math.abs(slot.column - slot2.column) + Math.abs(slot.row - slot2.row);
      if (manhattan === 1) {
        failedChecks.delete(Check.OCCUPIED);
      }
    }
  },
  adjustAttackChecks(failedChecks, attacker, defender) {
    if (defender.card.name === WALL_NAME) {
      failedChecks.add(Check.GENERIC);
    }
  },
  basesOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  cardEffectsShared: {
    [ARMORED_CAMP_NAME]: {
      isBase: true,
      isStructure: true,
    },
    [ZAP_PUSHER_NAME]: {
      flexCost: [ColorSymbol.ROCK],
      flexForms: [
        {
          userPrompt: "Choose an adjacent Unit to zap.",
          type: EffectOptType.PERMANENT,
          validate: (
            failedChecks: FailedChecks,
            target: Permanent,
            ctx: EffectOptValidationContext
          ) => {
            const { permanent, inspector } = ctx;
            const slot1 = permanent.slot;
            const slot2 = target.slot;
            const manhattan =
              Math.abs(slot1.row - slot2.row) +
              Math.abs(slot1.column - slot2.column);
            if (manhattan != 1) {
              failedChecks.add(Check.ADJACENT);
            }
            if (target.card.name === WALL_NAME) {
              failedChecks.add(Check.GENERIC);
            }
          },
        },
      ],
    },
  },
} as SharedGameSpec;

export const SharedPuzzle22Specs: SharedGameSpec[] = LEVEL_INDICES.map((i) => {
  const val = {
    ...BASE_SHARED_GAME_SPEC,
    numRows: getNumRows(i),
    numColumns: getNumCols(i),
  };
  // Special cases.
  // Level 9
  if (i === 8) {
    val.cardEffectsShared = Object.assign({}, val.cardEffectsShared ?? {});
    val.cardEffectsShared["camp"] = {
      computeText: () =>
        "This camp is super energetic! On start of turn, gain {??}.",
    };
  }
  return val;
});
