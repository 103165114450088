import { memo } from "react";

import "./SlotDisplay.css";

import { useOffsetRef } from "stores/AnimationStore";
import { cssIdFor } from "engine/types/keyframes";

interface SlotDisplayProps {
  row: number;
  column: number;
  smaller?: boolean;
  background?: string;
  keyboardFocused?: boolean;
}

function SlotDisplay(props: SlotDisplayProps) {
  const {
    row,
    column,
    smaller = false,
    background = "transparent",
    keyboardFocused = false,
  } = props;

  const slot = { row, column };
  const ref = useOffsetRef(slot);

  return (
    <div
      id={cssIdFor.slot(slot)}
      className={`game-slot ${smaller ? "smaller" : ""} ${
        keyboardFocused ? "kbfocus" : ""
      }`}
      ref={ref}
      style={{ backgroundColor: background }}
    />
  );
}

export default memo(SlotDisplay);
