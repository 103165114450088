import { z } from "zod";

import { Deck, DeckUpdate } from "engine/types/decks";
import { Player, DevKnobs } from "engine/types/game-state";
import { Step } from "engine/types/steps";
import { Update } from "engine/types/updates";
import { Faction } from "engine/types/factions";
import { EnterRoomParams } from "game-server/Room";
import { GameServerConfig } from "game-server/GameServer";
import { MasteryTree, MasteryTreeStep } from "game-server/masteries";
import { GlobalUpdatesScope, GlobalUpdate } from "game-server/global-updates";
import { CheckAnswerBackendResp } from "game-server/backend-interface/BackendInterface";
import { HuntNotification } from "game-server/notifications";
import { CursorResp, CursorEvent } from "engine/types/presence";

export enum WSErrorCode {
  /** Something went wrong with the connection to the interactive server. */
  CONNECTION_ERROR = "connection_error",
  /** An unexpected error occurred with the interactive server. */
  SERVER_ERROR = "server_error",
  /** Something went wrong with a backend call. */
  BACKEND_ERROR = "backend_error",
  /** Authentication failed. */
  AUTH_ERROR = "auth_error",
  /** Something went wrong on the client. */
  CLIENT_ERROR = "client_error",
  /** The client does not have the permissions for a request. */
  PERMISSION_DENIED = "permission_denied",
  /** The team is inactive. */
  TEAM_INACTIVE = "team_inactive",
  /** The team is temporarily blocked for maintenance */
  TEAM_TEMP_BLOCKED = "down_for_maintenance",
  /** A request requires the client to be in a room, but it isn't. */
  NO_ROOM = "no_room",
  /** A deck is needed but not provided. */
  NEED_DECK = "need_deck",
  /** The provided deck is invalid. */
  INVALID_DECK = "invalid_deck",
  /** There is no checkpoint to restore from. */
  NO_CHECKPOINT = "no_checkpoint",
  /** The provided deck slot is out of bounds. */
  DECK_SLOT_OUT_OF_BOUNDS = "deck_slot_out_of_bounds",
  /** The request was canceled by the client. */
  CANCELED_BY_CLIENT = "canceled_by_client",
  /** The step is invalid. */
  INVALID_STEP = "invalid_step",
  /** The checkpoint slot is invalid. */
  INVALID_CHECKPOINT_SLOT = "invalid_checkpoint_slot",
  /** The connection is not ready for steps. */
  NOT_READY = "not_ready",
  /** The resource is locked by an active battle. */
  LOCKED_BY_BATTLE = "locked_by_battle",
  /** The requested team is invalid. */
  INVALID_TEAM = "invalid_team",
  /** A puzzle selection needs to be supplied. */
  NEED_PUZZLE = "need_puzzle",
  /** The requested puzName is invalid, or not unlocked by the team. */
  INVALID_PUZZLE = "invalid_puzzle",
  /** The request is blocked by the requesting team. */
  BLOCKED_BY_REQUESTER = "blocked_by_requester",
  /** The request is blocked by the target team. */
  BLOCKED_BY_TARGET = "blocked_by_target",
  /** The team does not have the mastery needed to perform the request. */
  NO_MASTERY = "no_mastery",
  /** The target does not have the mastery needed for the request. */
  TARGET_NO_MASTERY = "no_mastery",
  /** The request was already made before. */
  DUPLICATE_REQUEST = "duplicate_request",
  /** The request refers to a PvP request that doesn't exist. */
  NO_PVP_REQUEST = "no_pvp_request",
  /** The request cannot be made while the room is active. */
  ROOM_BUSY = "room_busy",
  /** The WS request is invalid. */
  BAD_REQUEST = "bad_request",
  /** The client tried to spectate with an invalid key. */
  SPECTATE_INVALID = "spectate_invalid",
  /** The client tried to take actions while spectating. */
  SPECTATE_ACTION = "spectate_action",
}

export type WSError = {
  errCode: WSErrorCode;
};

export enum WSReqType {
  AUTH = "auth",
  SET_FACTION = "set_faction",

  ENTER_ROOM = "enter_room",
  STEP = "step",
  DECKBUILDING_STEP = "deckbuilding_step",
  MASTERY_TREE_STEP = "mastery_tree_step",

  NEXT_BATTLE = "next_battle",

  SELECT_PUZZLE = "select_puzzle",
  SELECT_DECK_FOR_BATTLE = "select_deck_for_battle",
  START_AI_BATTLE = "start_ai_battle",
  RESTORE_CHECKPOINT = "restore_checkpoint",
  RESIGN = "resign",

  PVP_CLAIM_TIME_VICTORY = "pvp_claim_time_victory",
  PVP_UNLOCK_DECK = "pvp_unlock_deck",
  PVP_MAKE_REQUEST = "pvp_make_request",
  PVP_CANCEL_REQUEST = "pvp_cancel_request",
  PVP_ACCEPT_REQUEST = "pvp_accept_request",
  PVP_SET_IS_BLOCKED = "pvp_set_is_blocked",
  PVP_ENTER_RING = "pvp_enter_ring",
  PVP_LEAVE_RING = "pvp_leave_ring",

  VIEW_PUZZLE = "view_puzzle",
  SUBMIT_ANSWER = "submit_answer",
  MYOSB_REQUEST = "myosb_request",

  SET_CURSOR_GROUP = "set_cursor_group",
  CURSOR_EVENT = "cursor_event",

  SUBSCRIBE_TO_UPDATES = "subscribe_to_updates",
  PING = "ping",

  // Admin requests
  ADMIN_MODIFY = "admin_modify",
}

const WSReqAuthZod = z.object({
  type: z.literal(WSReqType.AUTH),
  jwt: z.string(),
});
export type WSReqAuth = z.infer<typeof WSReqAuthZod>;

type WSReqSetFaction = {
  type: WSReqType.SET_FACTION;
  faction: Faction | null;
};

type WSReqEnterRoom = {
  type: WSReqType.ENTER_ROOM;
  /** Request ID, used to correlate acks to requests. */
  reqId: number;
  /** If null, leave any room the connection is currently joined to. */
  params: EnterRoomParams | null;
};

type WSReqStep = {
  type: WSReqType.STEP;
  step: Step;
};

type WSReqDeckbuildingStep = {
  type: WSReqType.DECKBUILDING_STEP;
  slot: number;
  deckUpd: DeckUpdate;
};

type WSReqMasteryTreeStep = {
  type: WSReqType.MASTERY_TREE_STEP;
  step: MasteryTreeStep;
};

const WSReqNextBattleZod = z.object({
  type: z.literal(WSReqType.NEXT_BATTLE),
});
type WSReqNextBattle = z.infer<typeof WSReqNextBattleZod>;

const WSReqSelectPuzzleZod = z.object({
  type: z.literal(WSReqType.SELECT_PUZZLE),
  hostPuzName: z.string(),
  selectedPuzName: z.string(),
});
type WSReqSelectPuzzle = z.infer<typeof WSReqSelectPuzzleZod>;

const WSReqSelectDeckForBattleZod = z.object({
  type: z.literal(WSReqType.SELECT_DECK_FOR_BATTLE),
  puzName: z.string(),
  slot: z.number(),
});
type WSReqSelectDeckForBattle = z.infer<typeof WSReqSelectDeckForBattleZod>;

type WSReqStartAIBattle = {
  type: WSReqType.START_AI_BATTLE;
  puzName: string;
  /** If set, select this deck for battle before starting. */
  slot?: number;
  /**
   * If set, and a battle is running, stop it.
   * Otherwise, acts as a no-op if a battle is running.
   */
  restart?: boolean;
  /** If set, reuses the same deck, if any. */
  reuseDeck?: boolean;
  devKnobs?: DevKnobs;
};

const WSReqRestoreCheckpointZod = z.object({
  type: z.literal(WSReqType.RESTORE_CHECKPOINT),
  puzName: z.string(),
  slot: z.number(),
});
type WSReqRestoreCheckpoint = z.infer<typeof WSReqRestoreCheckpointZod>;

type WSReqResign = {
  type: WSReqType.RESIGN;
  player: Player;
  puzName: string;
};

const WsReqPvPClaimTimeVictoryZod = z.object({
  type: z.literal(WSReqType.PVP_CLAIM_TIME_VICTORY),
});
type WSReqPvPClaimTimeVictory = z.infer<typeof WsReqPvPClaimTimeVictoryZod>;

const WSReqPvPUnlockDeckZod = z.object({
  type: z.literal(WSReqType.PVP_UNLOCK_DECK),
});
type WSReqPvPUnlockDeck = z.infer<typeof WSReqPvPUnlockDeckZod>;

const WSReqPvPMakeRequestZod = z.object({
  type: z.literal(WSReqType.PVP_MAKE_REQUEST),
  targetTeamId: z.string(),
  /**
   * Dev flag to test PvP locally. Auto-accepts the PvP request
   * from the other side.
   */
  autoAccept: z.optional(z.boolean()),
});
type WSReqPvPMakeRequest = z.infer<typeof WSReqPvPMakeRequestZod>;

const WSReqPvPCancelRequestZod = z.object({
  type: z.literal(WSReqType.PVP_CANCEL_REQUEST),
  targetTeamId: z.string(),
});
type WSReqPvPCancelRequest = z.infer<typeof WSReqPvPCancelRequestZod>;

type WSReqPvPAcceptRequest = {
  type: WSReqType.PVP_ACCEPT_REQUEST;
  targetTeamId: string;
  devKnobs?: DevKnobs;
};

const WSReqPvPSetIsBlockedZod = z.object({
  type: z.literal(WSReqType.PVP_SET_IS_BLOCKED),
  targetTeamId: z.string(),
  isBlocked: z.boolean(),
});
type WSReqPvPSetIsBlocked = z.infer<typeof WSReqPvPSetIsBlockedZod>;

const WSReqPvPEnterRingZod = z.object({
  type: z.literal(WSReqType.PVP_ENTER_RING),
});
type WSReqPvPEnterRing = z.infer<typeof WSReqPvPEnterRingZod>;

const WSReqPvPLeaveRingZod = z.object({
  type: z.literal(WSReqType.PVP_LEAVE_RING),
});
type WSReqPvPLeaveRing = z.infer<typeof WSReqPvPLeaveRingZod>;

const WSReqViewPuzzleZod = z.object({
  type: z.literal(WSReqType.VIEW_PUZZLE),
  puzName: z.string(),
});
type WSReqViewPuzzle = z.infer<typeof WSReqViewPuzzleZod>;

const WSReqSubmitAnswerZod = z.object({
  type: z.literal(WSReqType.SUBMIT_ANSWER),
  teamId: z.string(),
  puzName: z.string(),
  answer: z.string(),
});
type WSReqSubmitAnswer = z.infer<typeof WSReqSubmitAnswerZod>;

const WSReqMyosbRequestZod = z.object({
  type: z.literal(WSReqType.MYOSB_REQUEST),
  teamId: z.string(),
  components: z.array(z.array(z.number())),
});
type WSReqMyosbRequest = z.infer<typeof WSReqMyosbRequestZod>;

export type AdminModifyTeamParams = {
  isInactive?: boolean;
  isHidden?: boolean;
  isTempBlocked?: boolean;
  displayName?: string;
  overrideSolved?: { [puzName: string]: boolean };
  overrideUnlocked?: { [puzName: string]: boolean };
  overrideCardUnlocked?: { [cardName: string]: boolean };
  overrideMasteriesList?: string[];
  overrideDecks?: { [slot: number]: Deck };
  overrideMasteryTree?: MasteryTree;
};

export type WSReqAdminModify = {
  type: WSReqType.ADMIN_MODIFY;
  teams?: {
    [teamId: string]: AdminModifyTeamParams;
  };
  config?: GameServerConfig;
};

const WSReqSetCursorGroupZod = z.object({
  type: z.literal(WSReqType.SET_CURSOR_GROUP),
  reqId: z.number(),
  cursorGroupName: z.nullable(z.string()),
});
type WSReqSetCursorGroup = z.infer<typeof WSReqSetCursorGroupZod>;

type WSReqCursorEvent = {
  type: WSReqType.CURSOR_EVENT;
  event: CursorEvent;
};

type WSReqSubscribeToUpdates = {
  type: WSReqType.SUBSCRIBE_TO_UPDATES;
  scope: GlobalUpdatesScope;
};

const WSReqPingZod = z.object({
  type: z.literal(WSReqType.PING),
});
type WSReqPing = z.infer<typeof WSReqPingZod>;

export type WSReq = Readonly<
  | WSReqAuth
  | WSReqSetFaction
  | WSReqEnterRoom
  | WSReqStep
  | WSReqDeckbuildingStep
  | WSReqMasteryTreeStep
  | WSReqNextBattle
  | WSReqSelectPuzzle
  | WSReqSelectDeckForBattle
  | WSReqStartAIBattle
  | WSReqRestoreCheckpoint
  | WSReqResign
  | WSReqPvPClaimTimeVictory
  | WSReqPvPUnlockDeck
  | WSReqPvPMakeRequest
  | WSReqPvPCancelRequest
  | WSReqPvPAcceptRequest
  | WSReqPvPSetIsBlocked
  | WSReqPvPEnterRing
  | WSReqPvPLeaveRing
  | WSReqViewPuzzle
  | WSReqSubmitAnswer
  | WSReqMyosbRequest
  | WSReqAdminModify
  | WSReqSetCursorGroup
  | WSReqCursorEvent
  | WSReqSubscribeToUpdates
  | WSReqPing
>;

export enum WSRespType {
  ERROR = "error",
  AUTH_SUCCESS = "auth_success",

  ENTER_ROOM_ERROR = "enter_room_error",
  ENTERED_ROOM = "entered_room",

  UPDATES = "updates",
  GLOBAL_UPDATE = "global_update",

  NEXT_BATTLE = "next_battle",
  NOTIFICATION = "notification",

  SET_CURSOR_GROUP_ACK = "set_cursor_group_ack",
  CURSOR_EVENT = "cursor_event",

  SUBMIT_ANSWER_ACK = "submit_answer_ack",
  MYOSB_RESPONSE = "myosb_response",
  PONG = "pong",
}

export type WSRespError = {
  type: WSRespType.ERROR;
  err: WSError;
};

type WSRespAuthSuccess = {
  type: WSRespType.AUTH_SUCCESS;
};

type WsRespEnterRoomError = {
  type: WSRespType.ENTER_ROOM_ERROR;
  reqId: number;
  err: WSError;
};

/**
 * Signal that we have entered the room described by params.
 */
type WsRespEnteredRoom = {
  type: WSRespType.ENTERED_ROOM;
  reqId: number;
  /**
   * Params that uniquely identify the room that the client entered.
   * A client should be able to submit these params to re-enter
   * the same room later on.
   * This may be different, or contain less information thatn the
   * params passed in during ENTER_ROOM.
   */
  params: EnterRoomParams;
};

type WSRespUpdates = {
  type: WSRespType.UPDATES;
  updates: Update[];
};

type WSRespGlobalUpdate = {
  type: WSRespType.GLOBAL_UPDATE;
  upd: GlobalUpdate;
};

type WSRespNextBattle = {
  type: WSRespType.NEXT_BATTLE;
  roomId: string;
};

type WSRespNotification = {
  type: WSRespType.NOTIFICATION;
  notif: HuntNotification;
};

export type WSRespSetCursorGroupAck = {
  type: WSRespType.SET_CURSOR_GROUP_ACK;
  reqId: number;
};

export type WSRespCursorEvent = {
  type: WSRespType.CURSOR_EVENT;
  events: CursorResp[];
};

type WSRespPong = {
  type: WSRespType.PONG;
};

export type WSRespSubmitAnswerAck = {
  type: WSRespType.SUBMIT_ANSWER_ACK;
  resp: CheckAnswerBackendResp;
};

export type WSRespMyosbResponse = {
  type: WSRespType.MYOSB_RESPONSE;
  resp: string;
};

export type WSResp = Readonly<
  | WSRespError
  | WSRespAuthSuccess
  | WsRespEnterRoomError
  | WsRespEnteredRoom
  | WSRespUpdates
  | WSRespGlobalUpdate
  | WSRespNextBattle
  | WSRespNotification
  | WSRespSetCursorGroupAck
  | WSRespCursorEvent
  | WSRespSubmitAnswerAck
  | WSRespMyosbResponse
  | WSRespPong
>;
