import settings from "settings";

const LOCAL_STORAGE_JWT_LOCATION = "__game_jwt";
const SCHEMA_VERSION = 1;

type LocalStorageJwtCache = {
  version: number;
  teamId: string;
  isAdmin: boolean;
  jwt: string;
};

export const getJwt = async (
  teamId: string,
  isAdmin: boolean,
  forceRegen: boolean
): Promise<string | null> => {
  if (!forceRegen) {
    const cacheEntryStr = localStorage.getItem(LOCAL_STORAGE_JWT_LOCATION);
    if (cacheEntryStr !== null && typeof cacheEntryStr === "object") {
      const {
        version,
        teamId: cachedTeamId,
        isAdmin: cachedIsAdmin,
        jwt,
      } = JSON.parse(cacheEntryStr) as LocalStorageJwtCache;
      if (
        version < SCHEMA_VERSION &&
        cachedTeamId === teamId &&
        isAdmin === cachedIsAdmin
      )
        return jwt;
    }
  }

  try {
    const url = new URL(
      isAdmin
        ? `generate-name-token-admin/${teamId}`
        : `generate-name-token/${teamId}`,
      settings.djangoBaseUrl
    ).href;
    const resp = await fetch(url, { credentials: "include" });
    if (resp.status === 200) {
      const jwt = await resp.text();
      const cacheEntry: LocalStorageJwtCache = {
        version: SCHEMA_VERSION,
        teamId,
        isAdmin,
        jwt,
      };
      localStorage.setItem(
        LOCAL_STORAGE_JWT_LOCATION,
        JSON.stringify(cacheEntry)
      );
      return jwt;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};
