import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedTutorial4Spec: SharedGameSpec = {
  allowCheckpoints: true,
  aiDisableGemAccounting: true,
  hasAI: true,
  deckOverrides: {
    [Player.P1]: ["beeowulf", "chocolate-calf"],
    [Player.P2]: [],
  },
  basesOverrides: {
    [Player.P1]: ["camp", "camp", "camp"],
    [Player.P2]: ["camp"],
  },
};
