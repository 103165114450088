import { z } from "zod";

import { Slot, SlotZod, Permanent } from "engine/types/game-state";
import { Inspector } from "engine/Inspector";
import { FailedChecks } from "engine/types/action-validation";

/**
 * A card can have at most two activated abilities, as opposed to abilities
 * that are passive. Passive abilities are specified with counters.
 */
export enum AbilityType {
  FLEX = "flex",
  SPECIAL = "special",
}
export const AbilityTypeZod = z.nativeEnum(AbilityType);

/** Parameters an activated ability might need to ask the user for. */
export enum EffectOptType {
  PERMANENT = "Unit",
  SLOT = "Space",
  ONE_CHOICE = "one_choice",
}

const PermanentEffectOptZod = z.object({
  type: z.literal(EffectOptType.PERMANENT),
  permanentId: z.string(),
});
type PermanentEffectOpt = z.infer<typeof PermanentEffectOptZod>;

export type PermanentEffectOptResolved = PermanentEffectOpt & {
  permanent: Permanent;
};

const SlotEffectOptZod = z.object({
  type: z.literal(EffectOptType.SLOT),
  slot: SlotZod,
});
export type SlotEffectOpt = z.infer<typeof SlotEffectOptZod>;

/** An effect which makes the user choose one out of a fixed list of options. */
const OneChoiceEffectOptZod = z.object({
  type: z.literal(EffectOptType.ONE_CHOICE),
  choice: z.string(),
});
export type OneChoiceEffectOpt = z.infer<typeof OneChoiceEffectOptZod>;

/** A parameter specified by an activated ability. */
export const EffectOptZod = z.union([
  PermanentEffectOptZod,
  SlotEffectOptZod,
  OneChoiceEffectOptZod,
]);
export type EffectOpt = z.infer<typeof EffectOptZod>;

export type EffectOptResolved =
  | PermanentEffectOptResolved
  | SlotEffectOpt
  | OneChoiceEffectOpt;

// Provides access to parts of the turn state relevant to
// effect opts validation. This should not include anything
// client-specific, since validation needs to be performed
// on the server too.
export type EffectOptValidationContext = {
  // The permanent performing the ability.
  permanent: Permanent;
  effectOpts: ReadonlyArray<EffectOptResolved>;
  inspector: Inspector;
};

type EffectOptFormBase = {
  userPrompt?: string;
};

type PermanentEffectOptForm = EffectOptFormBase & {
  type: EffectOptType.PERMANENT;
  validate?: (
    failedChecks: FailedChecks,
    target: Permanent,
    ctx: EffectOptValidationContext
  ) => void;
};

type SlotEffectOptForm = EffectOptFormBase & {
  type: EffectOptType.SLOT;
  validate?: (
    failedChecks: FailedChecks,
    slot: Slot,
    ctx: EffectOptValidationContext
  ) => void;
};

/** A form which makes the user choose one out of a fixed list of options. */
export type OneChoiceEffectOptForm = EffectOptFormBase & {
  choices: string[]; // The choices available to choose from.
  type: EffectOptType.ONE_CHOICE;
  validate?: (
    failedChecks: FailedChecks,
    choice: string,
    ctx: EffectOptValidationContext
  ) => void;
};

/**
 * validate performs client-side validation.
 * If left blank, any selection is accepted and sent in the step.
 * Ensure that any validation here is also done on the server-side.
 * card-effects-shared.ts can be used to hold common validators
 * and other utilities shared between client and server.
 */
export type EffectOptForm =
  | PermanentEffectOptForm
  | SlotEffectOptForm
  | OneChoiceEffectOptForm;
