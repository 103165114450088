import { parse } from "csv-parse/sync";

/** The puzName for PvP. */
export const PUZ_NAME_PVP = "pvp";
/** The puzName for the intro cutscene. */
export const PUZ_NAME_INTRO = "treasure_chest";
/** The puzName for the first tutorial stage. */
export const PUZ_NAME_TUTORIAL_1 = "tutorial1";
/** The puzName for the final battle. */
export const PUZ_NAME_FINAL_BATTLE = "kero";
/** The puzName for the mastery tree. */
export const PUZ_NAME_MASTERY_TREE = "mastery_tree";
/** The puzName for the dummy mastery tree prompt tracker. */
export const PUZ_NAME_MASTERY_TREE_PROMPT = "mastery_tree_prompt";
/** The puzName for Make Your Own Star Battle. */
export const PUZ_NAME_MYOSB = "make_your_own_star_battle";
/** The puzName for the cutscene after getting the first answer. */
export const PUZ_NAME_FIRST_ANSWER = "first_answer";
/** The puzName for the cutscene after getting the first legendary. */
export const PUZ_NAME_FIRST_LEGENDARY = "first_legendary";
/** The puzName for the cutscene before the final battle. */
export const PUZ_NAME_PRE_FINAL_BATTLE = "entering_the_water";
/** The puzName for the cutscene after the final battle. */
export const PUZ_NAME_POST_FINAL_BATTLE = "the_end";
/** The puzName for the puzzle whose unlock also unlocks Kero's Notes. */
export const PUZ_NAME_KEROS_NOTES_UNLOCK = "tutorial6";
/** The puzName for the tutorial deckbuilding stage. */
export const PUZ_NAME_TUTORIAL_DECKBUILDING = "tutorial6";
export const BATTLE_GROUP_NAME_MASTERY_TREE = "mastery_tree";
export const BATTLE_GROUP_NAME_INTRA_PVP = "intra_pvp";
/** The battleGroupName for the instancer. */
export const BATTLE_GROUP_NAME_INSTANCER = "instancer";
/** The battleGroupName for the tutorial. */
export const BATTLE_GROUP_NAME_TUTORIAL = "tutorial";
/** The battleGroupName for the Moonick group. */
export const BATTLE_GROUP_NAME_MOONICK = "moonick";
/** puzNames for Moonick battles that count for global speedrun time. */
export const PUZ_NAMES_MOONICK_FOR_SPEEDRUN = ["moonick_final"];
export const WEIRD_BATTLE_GROUPS = [
  BATTLE_GROUP_NAME_INSTANCER,
  BATTLE_GROUP_NAME_MASTERY_TREE,
  BATTLE_GROUP_NAME_INTRA_PVP,
  PUZ_NAME_PVP,
];

export const MOONICK_UNLOCK_GROUPING = [12, 9, 3, 1];
export const MOONICK_UNLOCK_THRESHOLDS = [9, 7, 2, 1];

/**
 * PvP solvers can claim victory if their opponent takes longer than
 * this time to end their turn.
 */
export const PVP_TURN_TIME_LIMIT = 3 * 60 * 1000;

export const NUM_LEGENDARIES = 12;

// Handle compound puzNames.
// TODO: Return the whole puzName in production.
export const getPrimaryPuzName = (puzName: string) => {
  return puzName.split(",")[0];
};

export type PuzzleData = {
  /** puzName is the same as "slug" in Django. */
  puzName: string;
  battleGroupName: string;
  order: number;
  /**
   * Round that the puzzle belongs to. Only used by Django for minor
   * functionality like sorting puzzles on the bigboard and other
   * stats displays.
   */
  round: string;
  /**
   * The number of "unlock points" in the src unlock group needed to
   * unlock the puzzle.
   */
  unlockReq: number;
  /**
   * The number of "unlock points" that solving this puzzle gives in
   * its dst unlock group.
   */
  unlockMult: number;
  /**
   * Unlock group that determines when this puzzle is unlocked.
   * This puzzle is unlocked if `unlockReq` puzzles in `srcUnlockGroupId`
   * are solved.
   */
  srcUnlockGroupId: string;
  /**
   * Unlock group that solving this puzzle contributes to.
   * May be different from `srcUnlockGroupId`.
   */
  dstUnlockGroupId: string;
  /**
   * Set of cards that are unlocked when this puzzle is solved.
   */
  cardUnlockGroupId: string | null;
  /**
   * The puzzle's answer. If no answer is specified, then an answer
   * cannot be called in and the solve page is inaccessible until
   * the puzzle is solved (so that solvers can leave feedback and
   * see card unlocks).
   */
  answer: string | null;
  /** If set, solving this battle will solve the battle group dummy. */
  triggersBattleGroupSolve: boolean;
  /**
   * Whether the battle should be treated as a cutscene. This enables
   * a number of default behaviors:
   * - The URL used for the battle is "cutscene/:puzName" rather than
   *   "battle/:puzName", etc.
   * - Battle control panels do not support joining the room if the
   *   room is not ACTIVE, and say "cutscene" instead of "battle".
   * - Clicking on the map clickable starts the cutscene directly.
   * and possibly others.
   */
  isCutscene: boolean;
  /**
   * Whether the puzzle represents a "full" and "real" puzzle from the
   * perspective of Django. When enabled:
   * - Feedback and hints pages will be enabled for the puzzle.
   * - The puzzle will show up on bigboard.
   */
  isFullPuzzle: boolean;
};

export type MapPosition = {
  /** X position, as % of vw. */
  x: number;
  /** Y position, as % of vw. */
  y: number;
};

export type BattleGroupData = {
  battleGroupName: string;
  displayName: string;
  /**
   * Emoji used to represent this puzzle in internal Discord alerts.
   * May be empty if not defined. Currently only used by Django.
   */
  emoji: string;
  /**
   * Position of the clickable on the map page. If omitted, then
   * no clickable is shown.
   */
  mapPos?: MapPosition;
  isLegendary: boolean;
  /** Dialogue to show on the prep page. */
  preBattleDialogue?: string;
  /** Dialogue (by Kero Dos) to show on the solved modal. */
  postBattleDialogue?: string;
  /** Number of puzzles in the battle group. */
  numPuzzles: number;
  /**
   * For battle groups with multiple sub-battles, this is the number of
   * sub-battles with "triggers battle group solve" enabled that need
   * to be solved before the whole group is considered solved.
   * Ignored if zero.
   */
  solveReq: number;
};

export const isInstanceable = (
  hasAnswer: boolean,
  puzName: string,
  battleGroupName: string,
  groupNumPuzzles: number | undefined
) => {
  if (hasAnswer) return false;
  if (WEIRD_BATTLE_GROUPS.includes(battleGroupName)) return false;
  if (puzName === battleGroupName && (groupNumPuzzles ?? 1) > 1) return false;
  return true;
};

export const getPuzzleMapIcon = (
  hasAnswer: boolean,
  battleGroupName: string,
  isCutscene: boolean,
  isLegendary: boolean
) => {
  const isMasteryTree = battleGroupName === PUZ_NAME_MASTERY_TREE;
  const isPvP = battleGroupName === PUZ_NAME_PVP;
  const isBattle = !isMasteryTree && !hasAnswer;
  const isLinkExternal = !isMasteryTree && hasAnswer;

  if (isMasteryTree) return "🌲";
  if (isPvP) return "🤼";
  if (battleGroupName === PUZ_NAME_FINAL_BATTLE) return "❓";
  if (battleGroupName === BATTLE_GROUP_NAME_INSTANCER) return "🕸️";
  if (isCutscene) return "💬";
  if (isLegendary) return "👑";
  if (isBattle) return "️⚔️";
  if (isLinkExternal) return "🧩";
};

export const isUsedForSolveCount = (
  puzName: string,
  battleGroupName: string,
  isCutscene: boolean
): boolean => {
  // Only battle groups that are not cutscenes count towards the
  // team's public solve count.
  return puzName === battleGroupName && !isCutscene;
};

export const isUsedForGlobalSpeedrunTime = (
  puzName: string,
  battleGroupName: string,
  isCutscene: boolean
): boolean => {
  if (isCutscene) return false;
  if (
    [BATTLE_GROUP_NAME_TUTORIAL, ...WEIRD_BATTLE_GROUPS].includes(
      battleGroupName
    )
  )
    return false;
  if (
    battleGroupName === BATTLE_GROUP_NAME_MOONICK &&
    !PUZ_NAMES_MOONICK_FOR_SPEEDRUN.includes(puzName)
  )
    return false;
  // No need to worry about battles that don't produce speedrun times,
  // since they won't have a speedrun time to add anyway.
  return true;
};

const getColStartingWith = (
  row: { [colName: string]: string },
  colName: string
): string => {
  const matchedCols = Object.keys(row).filter((fullColName) =>
    fullColName.startsWith(colName)
  );
  if (matchedCols.length !== 1) {
    throw new Error("expected exactly one matching col");
  }
  return row[matchedCols[0]];
};

function parsePuzzle(
  row: { [colName: string]: string },
  order: number
): {
  puzData: PuzzleData;
  battleGroupData?: BattleGroupData;
} {
  const round = getColStartingWith(row, "round");
  const answerOpt = getColStartingWith(row, "answer");
  const answer = answerOpt === "" ? null : answerOpt;
  const puzName = getColStartingWith(row, "slug");
  const battleGroupNameOpt = getColStartingWith(row, "battle group");
  const battleGroupName =
    battleGroupNameOpt === "" ? puzName : battleGroupNameOpt;
  const srcUnlockGroupId = getColStartingWith(row, "src unlock group");
  const dstUnlockGroupId = getColStartingWith(row, "dst unlock group");
  const cardUnlockGroupId = getColStartingWith(row, "card unlock group");
  const unlockMult = getColStartingWith(row, "unlock multiplier");

  const res: {
    puzData: PuzzleData;
    battleGroupData?: BattleGroupData;
  } = {
    puzData: {
      puzName,
      battleGroupName,
      order,
      round,
      unlockReq: Number(getColStartingWith(row, "unlock requirement")),
      unlockMult: unlockMult === "" ? 1 : Number(unlockMult),
      srcUnlockGroupId,
      dstUnlockGroupId,
      cardUnlockGroupId: cardUnlockGroupId === "" ? null : cardUnlockGroupId,
      answer,
      triggersBattleGroupSolve:
        getColStartingWith(row, "triggers battle group solve") === "TRUE",
      isCutscene: round === "cutscenes",
      isFullPuzzle: getColStartingWith(row, "is full puzzle") === "TRUE",
    },
  };
  if (battleGroupName === puzName) {
    const mapX = getColStartingWith(row, "map x");
    const mapY = getColStartingWith(row, "map y");
    if ((mapX === "") !== (mapY === ""))
      throw new Error("expect mapX and mapY to be defined together");
    const mapPos =
      mapX === "" || mapY === ""
        ? undefined
        : {
            x: Number(mapX),
            y: Number(mapY),
          };
    const preBattleDialogue = getColStartingWith(row, "prep dialogue");
    const postBattleDialogue = getColStartingWith(row, "end dialogue");
    res.battleGroupData = {
      battleGroupName,
      displayName: getColStartingWith(row, "name"),
      emoji: getColStartingWith(row, "emoji"),
      mapPos,
      isLegendary: getColStartingWith(row, "is legendary") === "TRUE",
      preBattleDialogue:
        preBattleDialogue === "" ? undefined : preBattleDialogue,
      postBattleDialogue:
        postBattleDialogue === "" ? undefined : postBattleDialogue,
      // These will be set for all battle groups at the end.
      numPuzzles: 0,
      solveReq: 0,
    };
  }
  return res;
}

export type AllPuzzles = {
  puzzles: PuzzleData[];
  battleGroups: BattleGroupData[];
};

export const parsePuzzles = (allPuzzlesCsv: string): AllPuzzles => {
  const rows = parse(allPuzzlesCsv, { columns: true });
  const puzzles: PuzzleData[] = [];
  const battleGroups: BattleGroupData[] = [];
  const numSolveTriggersPerBattleGroup: Map<string, number> = new Map();
  const numSubpuzzlesPerBattleGroup: Map<string, number> = new Map();
  for (const row of rows) {
    const round = getColStartingWith(row, "round");
    if (round === "") {
      // Skip empty rows.
      continue;
    }
    const order = puzzles.length;

    const { puzData, battleGroupData } = parsePuzzle(row, order);
    if (battleGroupData !== undefined) battleGroups.push(battleGroupData);
    puzzles.push(puzData);

    const { puzName, triggersBattleGroupSolve, battleGroupName } = puzData;
    // Ignore dummy puzzles.
    // If the battle group does not have a dummy puzzle,
    // we'll handle the case later.
    if (puzName !== battleGroupName) {
      numSubpuzzlesPerBattleGroup.set(
        battleGroupName,
        (numSubpuzzlesPerBattleGroup.get(battleGroupName) ?? 0) + 1
      );
    }
    if (triggersBattleGroupSolve) {
      numSolveTriggersPerBattleGroup.set(
        battleGroupName,
        (numSolveTriggersPerBattleGroup.get(battleGroupName) ?? 0) + 1
      );
    }
  }
  for (const battleGroup of battleGroups) {
    const { battleGroupName } = battleGroup;
    // If there is only one puzzle in the battle group, then there
    // won't be any dummy puzzles and the count would be zero.
    const numPuzzles = Math.max(
      numSubpuzzlesPerBattleGroup.get(battleGroupName) ?? 0,
      1
    );
    if (numPuzzles === undefined)
      throw new Error("expect numPuzzles to be set for every battle group");
    battleGroup.numPuzzles = numPuzzles;
    battleGroup.solveReq =
      numSolveTriggersPerBattleGroup.get(battleGroupName) ?? 0;
  }
  return {
    puzzles,
    battleGroups,
  };
};

export class PuzzlesDB {
  puzzles: Map<string, PuzzleData>;
  battleGroups: Map<string, BattleGroupData>;

  constructor(allPuzzles: AllPuzzles) {
    const { puzzles, battleGroups } = allPuzzles;
    this.puzzles = new Map(
      Object.values(puzzles).map((puzData) => [puzData.puzName, puzData])
    );
    this.battleGroups = new Map(
      Object.values(battleGroups).map((battleGroup) => [
        battleGroup.battleGroupName,
        battleGroup,
      ])
    );
  }

  getPrimaryPuzName(puzName: string): string {
    // Handle compound puzNames.
    // TODO: Disable this in production.
    return puzName.split(",")[0];
  }

  tryGetPuzzle(puzName: string): PuzzleData | null {
    return this.puzzles.get(puzName) ?? null;
  }

  getPuzzle(puzName: string): PuzzleData {
    const puzData = this.tryGetPuzzle(puzName);
    if (puzData === null)
      throw new Error(`could not resolve puzzle ${puzName}`);
    return puzData;
  }

  /** Get the canonical answer for a fish puzzle. */
  getFishPuzzleCanonicalAnswer(puzName: string): string {
    const puzSpec = this.puzzles.get(puzName);
    if (puzSpec === undefined)
      throw new Error(`${puzName} is not a fish puzzle`);
    const { answer } = puzSpec;
    if (answer === null) throw new Error(`${puzName} does not have an answer`);
    return answer;
  }

  /** Get the battle group that a puzzle belongs to. */
  tryGetBattleGroup(puzName: string): BattleGroupData | null {
    const puzData = this.tryGetPuzzle(puzName);
    if (puzData === null) return null;
    return this.battleGroups.get(puzData.battleGroupName) ?? null;
  }

  /** Get the battle group that a puzzle belongs to. */
  getBattleGroup(puzName: string): BattleGroupData {
    const battleGroup = this.tryGetBattleGroup(puzName);
    if (battleGroup === null)
      throw new Error(`cannot find battle group for ${puzName}`);
    return battleGroup;
  }

  getFirstSubpuzzleInBattleGroup(puzName: string): PuzzleData {
    const { battleGroupName, numPuzzles } = this.getBattleGroup(puzName);
    return [...this.puzzles.values()]
      .filter(
        (puzData) =>
          puzData.battleGroupName === battleGroupName &&
          (numPuzzles === 1 || puzData.puzName !== battleGroupName)
      )
      .sort((puz1, puz2) => puz1.order - puz2.order)[0];
  }
}

export const MYOSB_DATA = {
  ansString: "ANSWERISLOTTERY",
  // prettier-ignore
  possible: [
    [ [0, 3], [1, 0], [2, 2], [3, 4], [4, 1] ],
    [ [0, 3], [1, 1], [2, 4], [3, 2], [4, 0] ],
    [ [0, 3], [1, 1], [2, 4], [3, 0], [4, 2] ],
    [ [0, 1], [1, 3], [2, 0], [3, 2], [4, 4] ],
    [ [0, 1], [1, 3], [2, 0], [3, 4], [4, 2] ],
    [ [0, 0], [1, 3], [2, 1], [3, 4], [4, 2] ],
    [ [0, 2], [1, 0], [2, 3], [3, 1], [4, 4] ],
    [ [0, 4], [1, 1], [2, 3], [3, 0], [4, 2] ],
    [ [0, 2], [1, 4], [2, 1], [3, 3], [4, 0] ],
    [ [0, 2], [1, 4], [2, 0], [3, 3], [4, 1] ],
    [ [0, 4], [1, 2], [2, 0], [3, 3], [4, 1] ],
    [ [0, 2], [1, 0], [2, 4], [3, 1], [4, 3] ],
    [ [0, 0], [1, 2], [2, 4], [3, 1], [4, 3] ],
    [ [0, 1], [1, 4], [2, 2], [3, 0], [4, 3] ],
  ],
};
