import { ColorSymbol, GemColor } from "engine/types/card-data";
import { AbilityType, type EffectOptForm } from "engine/types/effects";
import {
  Step,
  StepResolved,
  StepType,
  stepToPayment,
  stepToGain,
  stepToName,
} from "engine/types/steps";
import { Inspector } from "engine/Inspector";

function capitalize(str: string) {
  return str.replace(/^[a-z]/, (l) => l.toUpperCase());
}

function describeEffect(type: AbilityType, text: string) {
  // super hax
  for (const line of text.split("\n")) {
    const [pre, post] = line.split(": ");
    if (pre.includes(capitalize(type))) return post;
  }
}

export function describeForm(
  inspector: Inspector,
  pendingForm: EffectOptForm,
  pendingEffect: AbilityType | null,
  cardName?: string
): string {
  const lines: string[] = [];
  if (pendingForm.userPrompt)
    lines.push(capitalize(pendingForm.userPrompt) + ".");
  else lines.push(`Select a ${pendingForm.type}.`);
  if (pendingEffect && cardName) {
    lines.push("");
    const name = inspector.getCardDisplayName(cardName);
    const text = inspector.getCardText(cardName);
    lines.push(`#${name}’s ${capitalize(pendingEffect)}`);
    const effect = describeEffect(pendingEffect, text);
    if (effect) lines.push("#" + effect);
  }
  return lines.join("\n");
}

function describeCost(gems: GemColor[]) {
  return gems.map((gem) => (gem == ColorSymbol.RAINBOW ? "?" : gem)).join("");
}

export function describeStep(
  inspector: Inspector,
  step: Step,
  resolved: StepResolved | null
): string {
  const parts: string[] = [];
  let extra = "";
  if (resolved !== null) {
    const gain = stepToGain(resolved);
    const payment = stepToPayment(resolved);
    if (gain.length) parts.push(`gain {${describeCost(gain)}}`, "and");
    if (payment.length) parts.push(`pay {-${describeCost(payment)}}`, "to");
  }
  switch (step.type) {
    case StepType.SUMMON: {
      const { player, handCardId, cardName } = step;
      const summonName = (() => {
        if (handCardId !== undefined) {
          const handCard = inspector.getCardInHandIfExists(player, handCardId);
          if (handCard === null) return "this Creature";
          return inspector.getCardDisplayName(handCard.card);
        }
        if (cardName !== undefined) {
          return inspector.getCardDisplayName(cardName);
        }
        return "this Creature";
      })();
      parts.push(`Summon ${summonName} to this Space`);
      break;
    }
    case StepType.ATTACK: {
      const { attackerId, defenderId } = step;
      const attackerName = (() => {
        const attacker = inspector.getPermanentIfExists(attackerId);
        return attacker === null
          ? "this Creature"
          : inspector.getCardDisplayName(attacker);
      })();
      const defenderName = (() => {
        const defender = inspector.getPermanentIfExists(defenderId);
        return defender === null
          ? "this Unit"
          : inspector.getCardDisplayName(defender);
      })();
      parts.push(`Attack ${defenderName} with ${attackerName}`);
      break;
    }
    case StepType.MOVE: {
      const { permanentId } = step;
      const moveName = (() => {
        const permanent = inspector.getPermanentIfExists(permanentId);
        return permanent === null
          ? "this Creature"
          : inspector.getCardDisplayName(permanent);
      })();
      parts.push(`Move ${moveName} to this Space`);
      break;
    }
    case StepType.CREATE_GEMS:
      parts.pop();
      break;
    case StepType.DRAW:
      parts.push("draw a card");
      break;
    case StepType.END_TURN:
      parts.push("end your turn");
      break;
    // @ts-expect-error Fallthrough intended
    case StepType.ACTIVATE_ABILITY: {
      const cardName = inspector.getPermanent(step.permanentId).card.name;
      if (cardName) {
        const name = inspector.getCardDisplayName(cardName);
        const text = inspector.getCardText(cardName);
        parts.push(`use ${name}’s ${capitalize(step.abilityType)}`);
        const effect = describeEffect(step.abilityType, text);
        if (effect) extra = "\n\n#" + effect;
        break;
      }
    }
    // falls through
    default:
      parts.push(stepToName(step) || step.type);
      break;
  }
  return capitalize(parts.join(" ")) + "." + extra;
}
