import { z } from "zod";

import { DeckSelection } from "engine/types/decks";
import { GemColor, CardData } from "engine/types/card-data";
import { Counter } from "engine/types/counters";
import { SharedGameSpec } from "engine/types/shared-game-specs";
import { Keyframe } from "engine/types/keyframes";
import { LoseReason } from "game-server/backend-interface/BackendInterface";
import { Faction } from "engine/types/factions";

// Game state, apart from the engine-only state, is readonly
// everywhere except in the Reducer. This utility type allows
// us to declare the game state types as mutable only in the
// Reducer.
export type Mutable<T> = {
  -readonly [k in keyof T]: T[k];
};

export enum Player {
  P1 = "p1",
  P2 = "p2",
}
export const PlayerZod = z.nativeEnum(Player);

export const opponentOf = (player: Player) => {
  return player === Player.P1 ? Player.P2 : Player.P1;
};

export const PLAYER_SYMBOL = {
  [Player.P1]: {
    dark: "▲",
    light: "△",
    "sneaky-dark": "▴",
    "sneaky-light": "▵",
  },
  [Player.P2]: {
    dark: "▼",
    light: "▽",
    "sneaky-dark": "▾",
    "sneaky-light": "▿",
  },
} as const;

/** Parameters that a player selects in the prep for battle screen. */
export type Loadout = {
  deckCardList?: string[];
  masteriesList?: string[];
};

export type LoadoutResolved = Loadout & {
  deckCardList: string[];
  masteriesList: string[];
};

/**
 * Instance of a card. The card may not be known to the user
 * (e.g. if the card is face down, or held by the opponent).
 * In that case, `name` should be undefined.
 * Conversely, if `name` is undefined, the card is assumed to
 * be face down.
 * This may also be used to hold per-card modifications that only
 * apply within the scope of a single game. If a game does so, make
 * sure that such modifications are only defined for clients that
 * have access to that information. Modifications that persist
 * across games should instead go in CardData.
 */
export type Card = {
  name?: string;
};

/** A Slot is a place in the field. */
export const SlotZod = z
  .object({
    row: z.number(),
    column: z.number(),
  })
  .readonly();
export type Slot = z.infer<typeof SlotZod>;

/** Are these slots equal? */
export const areSlotsEqual = (slot1: Slot, slot2: Slot): boolean => {
  return slot1.row == slot2.row && slot1.column == slot2.column;
};

/** Are these slots adjacent? */
export const areSlotsAdjacent = (slot1: Slot, slot2: Slot): boolean => {
  return (
    Math.abs(slot1.column - slot2.column) + Math.abs(slot1.row - slot2.row) ===
    1
  );
};

/** What is the euclidean distance (not Manhattan distance) between slots? */
export const getSlotsEuclideanDistance = (slot1: Slot, slot2: Slot): number => {
  return Math.sqrt(
    (slot1.row - slot2.row) * (slot1.row - slot2.row) +
      (slot1.column - slot2.column) * (slot1.column - slot2.column)
  );
};

/** Return all slots. */
export const getAllSlots = (rows: number, columns: number): Slot[] => {
  const slots = [];
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {
      slots.push({ row: i, column: j });
    }
  }
  return slots;
};

/** A card in play. */
export type Permanent = Readonly<{
  /**
   * A unique ID for the permanent.
   * Note that this is different from its card name.
   */
  id: string;
  card: Card;
  damage: number;
  counters: ReadonlyArray<Counter>;
  ready: boolean; // can it be used this turn?
  owner: Player;
  slot: Slot;
}>;

export type MutablePermanent = Mutable<Omit<Permanent, "counters">> & {
  counters: Mutable<Counter>[];
};

/** A card in hand. */
export type HandCard = Readonly<{
  /**
   * A unique ID for the card in hand.
   * Note that this is different from its card name.
   */
  id: string;
  card: Card;
}>;

export type MutableHandCard = Mutable<HandCard>;

/**
 * A string, Card, CardData, HandCard, or Permanent, for functions that
 * query card properties. If a string is used, it is interpreted as a
 * card name (not a permanent ID).
 */
export type CardLike =
  | string
  | (Card & {
      card?: undefined;
      displayName?: undefined;
      counters?: undefined;
    })
  | (CardData & { card?: undefined; counters?: undefined })
  | (Permanent & { displayName?: undefined })
  | (HandCard & { displayName?: undefined; counters?: undefined });

/** Team data that doesn't change throughout the game. */
export type PlayerStateTeamData = {
  teamId: string;
  displayName: string;
  faction: Faction | null;
  /**
   * Snapshot of the team's faction score contribution when they
   * started the game, to display faction rank.
   */
  factionScoreContribution: number;
  enabledMasteries: { [masteryId: string]: boolean };
  /** Whether the player has solved this battle before. */
  isReplay?: boolean;
  /** The player's best prior speedrun time, if any. */
  prevBestSpeedrunTime?: number;
};

export type PlayerStateStats = {
  /** Number of cards summoned. */
  summons: number;
  /** Number of units destroyed by friendly units. */
  destroys: number;
  /** Total amount of damage dealt by friendly units. */
  damageDealt: number;
  /** Number of cards summoned belonging to the player's faction. */
  factionSummons: number;
};

export type PlayerState = Readonly<{
  /** The team data for the player, if controlled by a team. */
  teamData?: PlayerStateTeamData;
  /** The number of valid steps applied from player. */
  stepNumber: number;
  /** gems a player can spend */
  gems: ReadonlyArray<GemColor>;
  /** left -> right */
  hand: ReadonlyArray<HandCard>;
  /** bottom -> top */
  drawPile?: ReadonlyArray<Card>;
  /** bottom -> top */
  discardPile?: ReadonlyArray<Card>;

  /** Time spent on previous turns. */
  prevTurnsTime: number;
  /**
   * Timestamp of the start of the current turn, or null if the
   * player's turn is not currently active.
   */
  startTurnTime: number | null;

  /**
   * Stats that may be used to calculate faction points or generate
   * leaderboards.
   */
  stats: PlayerStateStats;

  /**
   * Whether this player has solved the puzzle, if there is a
   * solve state.
   */
  isSolved?: boolean;
  /** Cards unlocked by this player from this battle, if any. */
  cardUnlocks?: string[];

  // State used by players, not engine.

  /** Size of draw pile. */
  drawPileSize?: number;
  /** Top card, if viewable. */
  topCard?: Card | null;
}>;

export type FullPlayerState = PlayerState & {
  drawPile: ReadonlyArray<Card>;
  discardPile: ReadonlyArray<Card>;
};

export type MutablePlayerState = Mutable<
  Omit<FullPlayerState, "gems" | "hand" | "drawPile" | "discardPile"> & {
    gems: GemColor[];
    hand: HandCard[];
    drawPile: Card[];
    discardPile: Card[];
  }
>;

export const DevKnobsZod = z
  .object({
    /** Override the number of gems each player gets per turn. */
    extraGemsPerTurn: z.optional(z.number()),
    /** If true, make it free to summon a card. */
    freeSummon: z.optional(z.boolean()),
    /** If true, make all cards available to play each turn. */
    allCardsAvailable: z.optional(z.boolean()),
    /** If true, cards are always summoned ready. */
    alwaysReady: z.optional(z.boolean()),
    /**
     * If true, allow actions to be taken out of turn.
     * To allow non-free actions to be taken out of turn, gems are
     * also refreshed for both players each turn.
     */
    noTurnCheck: z.optional(z.boolean()),
    /** If true, don't shuffle either deck before the game starts. */
    noShuffle: z.optional(z.boolean()),
    /** If true, allow starting the game without a valid deck. */
    allowInvalidDecks: z.optional(z.boolean()),
    /**
     * If true, checkpoint after every step, except the last one.
     * Used to test checkpointing.
     */
    checkpointAfterEveryStep: z.optional(z.boolean()),
    /** If true, the game gets solved once P1 makes their first step. */
    instasolve: z.optional(z.boolean()),
  })
  .readonly();
export type DevKnobs = z.infer<typeof DevKnobsZod>;

export type EngineOnlyGameState = Readonly<{
  /** Deck selections by each player, if provided. */
  deckSelections: { [player in Player]?: DeckSelection };
  /** Whether the game has started. */
  hasGameStarted: boolean;
  /** The next permanent ID to assign when creating a new permanent. */
  nextPermanentId: number;
  /** The next ID to assign when creating a new hand card. */
  nextHandCardId: number;
  /** The next ID to assign when creating a dynamic keyframe. */
  nextKeyframeId: number;
  /**
   * The overall score gained from the battle (single-player only). Ensures you
   * can't endlessly farm score by reloading checkpoints.
   */
  gainedScore: number;
  /**
   * Extra battle-specific state that may differ between game specs.
   * Any battle-specific, engine-only state that needs to persist
   * across steps should go in here.
   * Warning: All state should be JSON-serializable, so don't store
   * things like classes or functions.
   */
  extraState?: object;
}>;

export type MutableEngineOnlyGameState = Mutable<EngineOnlyGameState>;

export enum GamePhase {
  ACTIVE = "active",
  ENDING = "ending",
  ENDED = "ended",
}

export const GAME_PHASE_ORDER: { [phase in GamePhase]: number } = {
  [GamePhase.ACTIVE]: 0,
  [GamePhase.ENDING]: 1,
  [GamePhase.ENDED]: 2,
};

export type GameState = Readonly<{
  /**
   * The puzName of the active battle. Only differs from the page puzName
   * in instancer rooms.
   */
  puzName: string;

  /** Global "is the game done". */
  phase: GamePhase;

  /** All permanents in play. */
  permanents: Readonly<{ [id: string]: Permanent }>;

  [Player.P1]: PlayerState;
  [Player.P2]: PlayerState;
  /** The current turn. Only valid if the game is in progress. */
  currentTurnPlayer: Player;
  /**
   * The turn number. This counts turns from both players.
   * It starts from 0 and increments every time a turn is ended.
   */
  turnNumber: number;
  /** If set, show the corresponding keyframe. */
  keyframe?: Keyframe;
  /** The winner, if the game has ended. */
  winner?: Player;
  /** How the game was lost. */
  loseReason?: LoseReason;

  /** Dev-only behavior override knobs. */
  devKnobs?: DevKnobs;

  /**
   * State that is only visible to the Engine (and god role).
   * The state is stored here instead of directly in Engine so
   * that it can be easily saved and loaded along with the
   * rest of the game state.
   */
  engineOnly?: EngineOnlyGameState;

  /**
   * Extra battle-specific state that may differ between game specs.
   * Any battle-specific state that needs to persist across steps,
   * that also needs to be shared between server and client, should
   * go in here.
   * All modifications to this state should go through the
   * modifySharedExtraState engine hook, with the actual modifications
   * applied in the modifySharedExtraState shared game spec hook.
   * Warning: All state should be JSON-serializable, so don't store
   * things like classes or functions.
   */
  sharedExtraState?: object;
}>;

export type FullGameState = GameState & {
  [Player.P1]: FullPlayerState;
  [Player.P2]: FullPlayerState;
  engineOnly: EngineOnlyGameState;
};

export type EngineGameState = Omit<FullGameState, "engineOnly"> & {
  engineOnly: MutableEngineOnlyGameState;
};

export type MutableGameState = Mutable<
  Omit<
    GameState,
    Player.P1 | Player.P2 | "permanents" | "devKnobs" | "engineOnly"
  >
> & {
  permanents: { [id: string]: MutablePermanent };
  [Player.P1]: MutablePlayerState;
  [Player.P2]: MutablePlayerState;
  devKnobs?: Mutable<DevKnobs>;
  engineOnly: MutableEngineOnlyGameState;
};

// This exclusion is necessary to prevent typescript from complaining
// about comparing 2 !== 1.
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const MAX_MANUAL_SAVES: number = 2;

export const isCheckpointSlotValid = (slot: number) => {
  return Number.isInteger(slot) && slot >= 0 && slot < 1 + MAX_MANUAL_SAVES;
};

export type CheckpointState = Readonly<{
  selectedPuzName: string | null;
  gameState: EngineGameState;
}>;

export type SerializedCheckpointState = Readonly<{
  turnNumber: number;
  roomId: string;
  timestamp: number;
  gainedScore: number;
  serializedState: string;
}>;

const makeInitPlayerState = (gameSpec: SharedGameSpec, player: Player) => {
  const playerState: MutablePlayerState = {
    hand: [],
    drawPile: [],
    discardPile: [],
    stepNumber: 0,
    gems: [],
    prevTurnsTime: 0,
    startTurnTime: null,
    stats: {
      summons: 0,
      destroys: 0,
      damageDealt: 0,
      factionSummons: 0,
    },
  };
  if (gameSpec.initPlayerState) {
    gameSpec.initPlayerState(playerState);
  }
  return playerState;
};

export const makeInitGameState = (
  puzName: string,
  gameSpec: SharedGameSpec
) => {
  const gameState: MutableGameState = {
    puzName,
    phase: GamePhase.ACTIVE,
    permanents: {},
    [Player.P1]: makeInitPlayerState(gameSpec, Player.P1),
    [Player.P2]: makeInitPlayerState(gameSpec, Player.P2),
    currentTurnPlayer: Player.P2,
    turnNumber: 0,
    engineOnly: {
      deckSelections: {},
      hasGameStarted: false,
      nextPermanentId: 0,
      nextHandCardId: 0,
      nextKeyframeId: 0,
      gainedScore: 0,
    },
  };
  if (gameSpec.initGameState) {
    gameSpec.initGameState(gameState);
  }
  return gameState;
};
