import {
  AfterDisconnectEventData,
  ServerInterface,
  ServerInterfaceType,
} from "game-server/server-interface/ServerInterface";
import {
  WSReq,
  WSReqType,
  WSResp,
  WSRespType,
  WSRespSubmitAnswerAck,
  WSError,
  WSErrorCode,
  WSRespCursorEvent,
} from "game-server/ws";
import {
  GlobalUpdatesScope,
  ClientGlobalState,
  GlobalUpdate,
  GlobalStateBattleRoomStatus,
} from "game-server/global-updates";
import {
  HuntNotification,
  HuntNotificationType,
} from "game-server/notifications";
import { Deck } from "engine/types/decks";
import { Player, DevKnobs } from "engine/types/game-state";
import { Step } from "engine/types/steps";
import { Role, UpdateType, Update } from "engine/types/updates";
import { EnterRoomParams } from "game-server/Room";

class EventController<T> {
  listeners: Map<string, (evData: T) => void>;
  nextListenerId: number;

  constructor() {
    this.listeners = new Map();
    this.nextListenerId = 0;
  }

  private genListenerId(): string {
    const listenerId = `listener_${this.nextListenerId}`;
    this.nextListenerId++;
    return listenerId;
  }

  /** Adds a listener too the event. Returns a cleanup function. */
  addListener(func: (evData: T) => void) {
    const listenerId = this.genListenerId();
    this.listeners.set(listenerId, func);
    return () => {
      this.listeners.delete(listenerId);
    };
  }

  fire(evData: T) {
    for (const func of this.listeners.values()) {
      func(evData);
    }
  }
}

class ServerInterfaceConnectionController {
  type: ServerInterfaceType;
  getJwtFunc: (forceRegenJwt: boolean) => Promise<string | null>;
  onResp: (resp: WSResp) => void;
  dumpWsMessages: boolean;

  serverInterface?: ServerInterface;
  isAuthed: boolean;
  pendingAuthReq?: (result: boolean) => void;
  /** Requests waiting for the connection to be established. */
  pendingReqs: WSReq[];

  /**
   * Event that fires every time we successfully authenticate
   * to the server, i.e. the server is ready to receive our requests.
   * Note that we cannot send steps until we have joined a room.
   */
  afterAuthEvent: EventController<void>;
  /**
   * Event that fires every time we get disconnected and plan to
   * reconnect.
   */
  afterDisconnectEvent: EventController<AfterDisconnectEventData>;

  /** Timer handle for the ping task. */
  pingTimer?: ReturnType<typeof setInterval>;

  constructor(
    type: ServerInterfaceType,
    dumpWsMessages: boolean,
    getJwtFunc: (forceRegenJwt: boolean) => Promise<string | null>,
    onResp: (resp: WSResp) => void
  ) {
    this.type = type;
    this.dumpWsMessages = dumpWsMessages;
    this.getJwtFunc = getJwtFunc;
    this.onResp = onResp;

    this.isAuthed = false;
    this.pendingReqs = [];

    this.afterAuthEvent = new EventController();
    this.afterDisconnectEvent = new EventController();
  }

  async init(
    makeServerInterfaceFunc: () => Promise<ServerInterface>,
    afterAuth: (success: boolean) => void,
    afterDisconnect: () => void
  ) {
    let connectCounter = 0;
    this.serverInterface = await makeServerInterfaceFunc();
    this.serverInterface.init({
      onOpen: () => {
        (async () => {
          const success = await (async (): Promise<boolean> => {
            const currConnectCounter = connectCounter;
            if (await this.authAsync(false)) return true;
            if (currConnectCounter !== connectCounter) return false;
            // If we failed auth, there might be something wrong with
            // our token. Regenerate one and try again.
            return await this.authAsync(true);
          })();

          this.processPendingReqs();

          // This must be separate from afterAuthEvent since we
          // need to guarantee that this runs before any client-added
          // after-auth events.
          afterAuth(success);
          if (success) this.afterAuthEvent.fire();
        })();
      },
      onResp: (msg) => {
        const resp = JSON.parse(msg) as WSResp;
        if (this.dumpWsMessages) {
          console.log(`[${this.type}] > ${JSON.stringify(resp, null, 2)}`);
        }
        this.handleResp(resp);
      },
      afterDisconnect: (evData) => {
        connectCounter++;
        const { pendingAuthReq } = this;
        delete this.pendingAuthReq;
        pendingAuthReq?.(false);
        this.isAuthed = false;

        this.afterDisconnectEvent.fire(evData);
        // This must be separate from afterDisconnectEvent since we
        // need to guarantee that this runs after any client-added
        // after-disconnect events.
        afterDisconnect();
      },
    });

    // Send a heartbeat to the server every so often to prevent the connection
    // from being closed.
    this.pingTimer = setInterval(() => {
      this.sendReq({ type: WSReqType.PING });
    }, 20000);
  }

  reconnect(): void {
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.reconnect();
  }

  teardown(): void {
    if (this.pingTimer !== undefined) clearInterval(this.pingTimer);
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.close();
  }

  resetServer(): void {
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.resetServer?.();
  }

  private sendReqRaw(req: WSReq) {
    if (this.dumpWsMessages) {
      console.log(`[${this.type}] < ${JSON.stringify(req, null, 2)}`);
    }
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.send(JSON.stringify(req));
  }

  sendReq(req: WSReq) {
    // Drop cursor events if not authed.
    if (
      !this.isAuthed &&
      [WSReqType.PING, WSReqType.CURSOR_EVENT].includes(req.type)
    )
      return;
    this.pendingReqs.push(req);
    this.processPendingReqs();
  }

  processPendingReqs() {
    while (this.isAuthed) {
      const req = this.pendingReqs.shift();
      if (req === undefined) break;
      this.sendReqRaw(req);
    }
  }

  /**
   * Authenticate to the server. Returns whether the authentication
   * was successful.
   */
  async authAsync(forceRegenJwt: boolean): Promise<boolean> {
    const jwt = await this.getJwtFunc(forceRegenJwt);
    if (jwt === null) return false;
    this.sendReqRaw({ type: WSReqType.AUTH, jwt });
    return await new Promise((resolve, reject) => {
      if (this.pendingAuthReq !== undefined)
        throw new Error("only expect one auth request at a time");
      this.pendingAuthReq = resolve;
    });
  }

  handleResp(resp: WSResp): void {
    // Preliminary handling for auth-related responses.
    switch (resp.type) {
      case WSRespType.ERROR: {
        const { err } = resp;
        switch (err.errCode) {
          case WSErrorCode.AUTH_ERROR: {
            const { pendingAuthReq } = this;
            delete this.pendingAuthReq;
            pendingAuthReq?.(false);
            break;
          }
        }
        break;
      }
      case WSRespType.AUTH_SUCCESS: {
        this.isAuthed = true;
        const { pendingAuthReq } = this;
        delete this.pendingAuthReq;
        pendingAuthReq?.(true);
        break;
      }
    }
    this.onResp(resp);
  }

  /**
   * Utility function to set up effects that should be active
   * during any authenticated session.
   * `func` is a function that sets up the effect, and optionally
   * returns a function to cleanup the effect.
   * The effect is set up at the start of each authenticated session,
   * and cleaned up after. If the session has already started, the
   * effect is set up immediately.
   * Returns a cleanup function, which cleans up and unregisters
   * the effect.
   */
  addAuthEffect(func: () => (() => void) | void): () => void {
    let cleanupFunc: (() => void) | void = undefined;

    if (this.isAuthed) cleanupFunc = func();
    const cleanupAfterAuth = this.afterAuthEvent.addListener(() => {
      if (cleanupFunc !== undefined)
        throw new Error("func was not cleaned up for previous auth");
      cleanupFunc = func();
    });
    const cleanupAfterDisconnect = this.afterDisconnectEvent.addListener(() => {
      cleanupFunc?.();
      cleanupFunc = undefined;
    });

    return () => {
      cleanupFunc?.();
      cleanupAfterAuth();
      cleanupAfterDisconnect();
    };
  }
}

type ServerInterfaceControllerOpts = {
  dumpWsMessages: boolean;
  teamId: string;
  initIsAdmin: boolean;
  initIsSpectating: boolean;
  getJwtFunc: (
    teamId: string,
    isAdmin: boolean,
    forceRegen: boolean
  ) => Promise<string | null>;
  /** Async function to create the server interface. */
  makeServerInterfaceFunc: (
    type: ServerInterfaceType
  ) => Promise<ServerInterface>;
  /** Initial isFlushToggled. */
  isFlushToggled?: boolean;
  /** Artificial delay in ms for simulating high-latency connections */
  delay?: number;
};

/** Dev-only opts to automatically prep before a battle starts. */
export type AutoPrepOpts = {
  /** If set, automatically sets ready for battle for the given puzName. */
  readyForBattlePuzName?: string;
  /** If set, overrides the role when entering the room. */
  role?: Role;
  devKnobs?: DevKnobs;
  /** Decks to override in prep. */
  decks?: { [player in Player]?: Deck };
  /** Mastery tree IDs of mastery trees to select in prep. */
  masteryTrees?: { [player in Player]?: string };
};

enum SwitchRoomState {
  INIT = "init",
  CONNECTING = "connecting",
  IDLE = "idle",
  ENTER_ROOM = "enter_room",
  READY = "ready",
}

type PendingSwitchRoomState = {
  reqId: number;
  onStepAck?: (err: WSError | null) => void;
  onAck?: (err: WSError | null) => void;
  onUpdAsync: (upd: Update) => Promise<void>;
  onUpdsFlush?: (upds: Update[]) => Promise<void>;
};

export class ServerInterfaceController {
  opts: ServerInterfaceControllerOpts;

  connectionControllers: {
    [type in ServerInterfaceType]: ServerInterfaceConnectionController;
  };
  /** Whether we are authenticated as an admin. */
  isAdmin: boolean;
  /** Whether we are spectating. */
  isSpectating: boolean;
  /**
   * Whether we are stuck, and the user should be told to refresh.
   * This could happen if the Node or Django server becomes unresponsive.
   */
  isStuckErr: WSError | null;
  /** The role assigned to us by the server, if one exists. */
  role?: Role;

  /** Requests waiting for the connection to be established. */
  pendingReqs: WSReq[];

  // We track room changes in three steps:
  //
  // 1. Switching the activeEnterRoomReq sets switchState to CONNECTING.
  // 2. When the connection has initialized, switchState is set
  //    to ENTER_ROOM.
  // 3. When ENTER_ROOM succeeds, switchState is set to ACTIVE.
  activeEnterRoomReq: PendingSwitchRoomState | null;
  switchState: SwitchRoomState;
  nextEnterRoomReqId: number;

  /** Whether the client is currently applying an update. */
  isClientApplyingUpdate: boolean;
  /** Whether the client requested to flush all updates next time. */
  isFlushRequested: boolean;
  /** Whether the client requests to permanently flush updates. */
  isFlushToggled: boolean;
  /**
   * A queue that holds updates received from the server.
   * Updates can only be removed from the queue in two scenarios:
   * - If a client receives an update an applies it to its state.
   * - If we enter a different room and drop all pending updates.
   * In particular, a client should never drop an update after
   * receiving it. This guarantees that an update is always valid
   * to be applied on receipt.
   */
  pendingUpdates: Update[];

  pendingConnectReq?: (result: boolean) => void;
  pendingAuthReqs: {
    [type in ServerInterfaceType]?: (result: boolean) => void;
  };
  pendingStartNewBattle?: {
    puzName: string;
    req: (result: boolean) => void;
  };

  /** Event that fires whenever we get an error from the server. */
  onErrorEvent: EventController<WSError>;
  /**
   * Event that fires if we get stuck and the user should be told
   * to refresh.
   */
  onStuckEvent: EventController<WSError>;
  /**
   * Event that fires every time a message is received from the server
   * and processed.
   */
  afterRespEvent: EventController<WSResp>;
  /**
   * Event that fires every time there is a pending update and the
   * client is ready for updates.
   */
  onUpdateEvent: EventController<Update>;
  /** Event that fires every time a global update is received. */
  onGlobalUpdateEvent: EventController<GlobalUpdate>;
  /**
   * Event that fires when a prompt to go to the next battle is received.
   * The argument provided is the roomId that the request came from.
   * Only clients in the same roomId would be moved to the next battle.
   */
  onNextBattleEvent: EventController<string>;
  onNotificationEvent: EventController<HuntNotification>;
  onSetCursorGroupAckEvent: EventController<number>;
  onCursorEvent: EventController<WSRespCursorEvent>;
  /** Event that fires every time a submit answer ack is received. */
  onSubmitAnswerAckEvent: EventController<WSRespSubmitAnswerAck>;
  /** Event for MYOSB responses. */
  onMYOSBResponseEvent: EventController<string>;

  /** Response queue for artificially delaying responses */
  respQueue: { emitTime: number; resp: WSResp }[];
  /** Delay in milliseconds */
  delay: number;

  constructor(opts: ServerInterfaceControllerOpts) {
    this.opts = opts;
    this.isAdmin = this.opts.initIsAdmin;
    this.isSpectating = this.opts.initIsSpectating;
    this.isStuckErr = null;
    this.pendingReqs = [];
    this.activeEnterRoomReq = null;
    this.switchState = SwitchRoomState.INIT;
    this.nextEnterRoomReqId = 0;
    this.isClientApplyingUpdate = false;
    this.isFlushRequested = false;
    this.isFlushToggled = opts.isFlushToggled ?? false;
    this.pendingUpdates = [];
    this.pendingAuthReqs = {};

    this.onErrorEvent = new EventController();
    this.onStuckEvent = new EventController();
    this.afterRespEvent = new EventController();
    this.onUpdateEvent = new EventController();
    this.onGlobalUpdateEvent = new EventController();
    this.onNextBattleEvent = new EventController();
    this.onNotificationEvent = new EventController();
    this.onSetCursorGroupAckEvent = new EventController();
    this.onCursorEvent = new EventController();
    this.onSubmitAnswerAckEvent = new EventController();
    this.onMYOSBResponseEvent = new EventController();

    this.respQueue = [];
    this.delay = this.opts.delay ?? 0;

    const makeConnectionController = (type: ServerInterfaceType) =>
      new ServerInterfaceConnectionController(
        type,
        this.opts.dumpWsMessages,
        (forceRegenJwt) => {
          return this.opts.getJwtFunc(
            this.getTeamId(),
            this.isAdmin,
            forceRegenJwt
          );
        },
        (resp) => {
          if (this.delay > 0) {
            const emitTime = Date.now() + this.delay * (1 + Math.random());
            this.respQueue.push({ emitTime, resp });
          } else {
            this.handleResp(resp);
          }
        }
      );

    this.connectionControllers = {
      [ServerInterfaceType.MAIN]: makeConnectionController(
        ServerInterfaceType.MAIN
      ),
      [ServerInterfaceType.CURSOR]: makeConnectionController(
        ServerInterfaceType.CURSOR
      ),
    };

    if (this.delay > 0) {
      setTimeout(() => this.handleQueue(), this.delay);
    }
  }

  getTeamId(): string {
    return this.opts.teamId;
  }

  setStuck(err: WSError) {
    this.isStuckErr = err;
    this.onStuckEvent.fire(err);
    // If we're stuck, give up trying to connect.
    for (const controller of Object.values(this.connectionControllers))
      controller.teardown();
  }

  async initServerInterfacesAsync() {
    for (const [typeStr, controller] of Object.entries(
      this.connectionControllers
    )) {
      const type = typeStr as ServerInterfaceType;
      const isMain = type === ServerInterfaceType.MAIN;
      controller.init(
        async () => {
          return await this.opts.makeServerInterfaceFunc(type);
        },
        (success) => {
          if (!isMain) {
            if (!success) console.warn(`[${type}] could not connect`);
            return;
          }

          if (success) {
            this.switchState = SwitchRoomState.IDLE;
          } else {
            this.setStuck({ errCode: WSErrorCode.AUTH_ERROR });
          }

          const { pendingConnectReq } = this;
          delete this.pendingConnectReq;
          pendingConnectReq?.(success);
        },
        () => {
          if (!isMain) {
            console.warn(`[${type}] disconnected`);
            return;
          }

          const { pendingStartNewBattle } = this;
          delete this.pendingStartNewBattle;
          pendingStartNewBattle?.req?.(false);

          if (this.activeEnterRoomReq !== null)
            throw new Error("enter room request not cleaned up on disconnect");

          this.switchState = SwitchRoomState.CONNECTING;
        }
      );
    }
  }

  teardown(): void {
    Object.values(this.connectionControllers).forEach((controller) =>
      controller.teardown()
    );
  }

  getIsAuthed(serverInterfaceType?: ServerInterfaceType): boolean {
    serverInterfaceType ??= ServerInterfaceType.MAIN;

    return this.connectionControllers[serverInterfaceType].isAuthed;
  }

  setIsAdmin(newIsAdmin: boolean) {
    if (newIsAdmin === this.isAdmin) return;
    this.isAdmin = newIsAdmin;
    Object.values(this.connectionControllers).forEach((controller) =>
      controller.reconnect()
    );
  }

  /**
   * Connect to the server and authenticate.
   * Returns whether the connection and authentication was successful.
   */
  async connectAsync(): Promise<boolean> {
    if (this.isConnected())
      throw new Error("only expect to be told to connect once");
    this.switchState = SwitchRoomState.CONNECTING;

    return await new Promise((resolve, reject) => {
      if (this.pendingConnectReq !== undefined)
        throw new Error("only expect to be told to connect once");
      this.pendingConnectReq = resolve;

      this.initServerInterfacesAsync();
    });
  }

  isConnected(): boolean {
    return this.switchState !== SwitchRoomState.INIT;
  }

  isInRoom(): boolean {
    return this.switchState === SwitchRoomState.READY;
  }

  addAuthEffect(
    func: () => (() => void) | void,
    serverInterfaceType?: ServerInterfaceType
  ): () => void {
    serverInterfaceType ??= ServerInterfaceType.MAIN;

    return this.connectionControllers[serverInterfaceType].addAuthEffect(func);
  }

  /**
   * Call func when stuck, or if already stuck.
   * Returns a cleanup function.
   */
  addStuckEffect(func: (err: WSError) => void): () => void {
    if (this.isStuckErr !== null) func(this.isStuckErr);
    return this.onStuckEvent.addListener(func);
  }

  private subscribeToUpdates(scope: GlobalUpdatesScope) {
    this.sendReq({
      type: WSReqType.SUBSCRIBE_TO_UPDATES,
      scope,
    });
  }

  addSubscribeEffect(scope: GlobalUpdatesScope) {
    return this.addAuthEffect(() => {
      this.subscribeToUpdates(scope);
    });
  }

  async startAIBattleAsync(
    puzName: string,
    opts: {
      slot?: number;
      restart?: boolean;
      reuseDeck?: boolean;
      devKnobs?: DevKnobs;
    }
  ) {
    this.sendReq({
      type: WSReqType.START_AI_BATTLE,
      puzName,
      ...opts,
    });
    return await new Promise((resolve, reject) => {
      const { pendingStartNewBattle } = this;
      if (pendingStartNewBattle !== undefined) {
        // If the previous request never succeeded, just drop it.
        // We could get here if the client tries to start a battle
        // multiple times (such as by switching out and back into
        // the page), or makes an invalid request.
        delete this.pendingStartNewBattle;
        pendingStartNewBattle?.req?.(false);
      }
      this.pendingStartNewBattle = {
        puzName,
        req: resolve,
      };
    });
  }

  /**
   * Automatically prepare for or start a battle, just to help with
   * development. No need to take care of async edge cases here.
   */
  async autoPrepAsync(autoPrepOpts: AutoPrepOpts): Promise<boolean> {
    const { readyForBattlePuzName, role, devKnobs } = autoPrepOpts;
    if (readyForBattlePuzName !== undefined) {
      if (!(await this.startAIBattleAsync(readyForBattlePuzName, { devKnobs })))
        return false;
    }
    return true;
  }

  /**
   * The client should call this after every global state update,
   * or optionally more frequently.
   * This is intended for development and should not be depended on
   * in production, as there may be some race conditions with
   * React hooks.
   */
  afterUpdateGlobalState(globalState: ClientGlobalState): void {
    const { subscribedTeam } = globalState;
    if (
      this.pendingStartNewBattle !== undefined &&
      subscribedTeam !== null &&
      subscribedTeam.teamId === this.getTeamId()
    ) {
      const { puzName, req } = this.pendingStartNewBattle;
      const puzState = subscribedTeam.puzzles[puzName];
      if (
        puzState !== undefined &&
        puzState.roomStatus !== undefined &&
        puzState.roomStatus === GlobalStateBattleRoomStatus.ACTIVE
      ) {
        req(true);
      }
    }
  }

  triggerUpdatesQueue(newUpds?: Update[]): void {
    (() => {
      // Ignore stale updates.
      if (!this.isInRoom()) return;
      if (this.activeEnterRoomReq === null) return;
      const { onUpdAsync, onUpdsFlush } = this.activeEnterRoomReq;

      // Push any new updates.
      if (newUpds !== undefined) this.pendingUpdates.push(...newUpds);

      // Don't apply an update if an update is already being applied.
      if (this.isClientApplyingUpdate) return;

      const handleStepAck = (stepErr: WSError | null) => {
        if (this.activeEnterRoomReq === null)
          throw new Error("expect to have ignored stale updates");
        const onStepAck = this.activeEnterRoomReq?.onStepAck ?? null;
        if (onStepAck === null)
          throw new Error("received step ack but no pending step");
        delete this.activeEnterRoomReq.onStepAck;
        onStepAck(stepErr ?? null);
      };

      if (this.isFlushRequested) {
        // apply all updates (SYNCHRONOUSLY!)
        const updsToFlush: Update[] = [];
        const stepAckErrs: (WSError | null)[] = [];
        for (const upd of this.pendingUpdates) {
          if (upd.type === UpdateType.STEP_ACK)
            stepAckErrs.push(upd.err ?? null);
          else updsToFlush.push(upd);
        }
        onUpdsFlush?.(updsToFlush);
        for (const stepAckErr of stepAckErrs) {
          handleStepAck(stepAckErr);
        }
        this.pendingUpdates = [];
        return;
      }

      while (true) {
        const upd = this.pendingUpdates.shift();
        if (upd === undefined) return;

        switch (upd.type) {
          case UpdateType.STEP_ACK: {
            // Handle the step ack and proceed to the next update, if any.
            handleStepAck(upd.err ?? null);
            break;
          }
          default: {
            this.isClientApplyingUpdate = true;
            onUpdAsync(upd);
            return;
          }
        }
      }
    })();
    if (!this.isClientApplyingUpdate) this.isFlushRequested = false;
  }

  flushAllUpdates(): void {
    this.isFlushRequested = true;
    this.triggerUpdatesQueue();
  }

  setClientDoneApplyingUpdate(): void {
    this.isClientApplyingUpdate = false;
    if (this.isFlushToggled) {
      this.isFlushRequested = true;
    }
    this.triggerUpdatesQueue();
  }

  handleQueue(): void {
    const now = Date.now();
    while (this.respQueue.length > 0 && this.respQueue[0].emitTime < now) {
      const { resp } = this.respQueue.shift() ?? {};
      if (resp !== undefined) {
        this.handleResp(resp);
      }
    }

    const wait =
      this.respQueue.length > 0
        ? Math.max(0, this.respQueue[0].emitTime - Date.now())
        : this.delay;
    setTimeout(() => this.handleQueue(), wait);
  }

  handleResp(resp: WSResp): void {
    switch (resp.type) {
      case WSRespType.ERROR: {
        const { err } = resp;
        console.error(err);
        this.onErrorEvent.fire(err);
        switch (err.errCode) {
          case WSErrorCode.TEAM_TEMP_BLOCKED: {
            this.setStuck(err);
            break;
          }
        }
        break;
      }
      case WSRespType.ENTER_ROOM_ERROR: {
        const { reqId, err } = resp;
        console.error(err);
        this.onErrorEvent.fire(err);

        if (this.activeEnterRoomReq === null) break;
        if (reqId < this.activeEnterRoomReq.reqId) break;

        // Resolve pending promises and clear the enter room state.
        if (this.activeEnterRoomReq.onStepAck !== undefined)
          throw new Error(
            "there should not be any pending steps before room entered"
          );
        this.activeEnterRoomReq.onAck?.(err);
        this.activeEnterRoomReq = null;
        this.switchState = SwitchRoomState.IDLE;

        break;
      }
      case WSRespType.ENTERED_ROOM: {
        const { reqId, params } = resp;

        // Drop stale ENTERED_ROOM requests. These could appear if
        // we cancel an active enter room request.
        if (this.activeEnterRoomReq === null) break;
        if (reqId < this.activeEnterRoomReq.reqId) break;

        // Reset the pending updates state.
        this.pendingUpdates = [];
        this.setClientDoneApplyingUpdate();

        // The params from the server might be slightly different from
        // what we requested, and should be treated as the source of truth.
        this.role = params.role;
        this.switchState = SwitchRoomState.READY;
        // Resolve the promise.
        this.activeEnterRoomReq.onAck?.(null);
        delete this.activeEnterRoomReq.onAck;

        break;
      }
      case WSRespType.UPDATES: {
        const { updates } = resp;
        this.triggerUpdatesQueue(updates);
        break;
      }
      case WSRespType.GLOBAL_UPDATE: {
        const { upd } = resp;
        this.onGlobalUpdateEvent.fire(upd);
        break;
      }
      case WSRespType.NEXT_BATTLE: {
        const { roomId } = resp;
        this.onNextBattleEvent.fire(roomId);
        break;
      }
      case WSRespType.SET_CURSOR_GROUP_ACK: {
        this.onSetCursorGroupAckEvent.fire(resp.reqId);
        break;
      }
      case WSRespType.CURSOR_EVENT: {
        this.onCursorEvent.fire(resp);
        break;
      }
      case WSRespType.NOTIFICATION: {
        const { notif } = resp;
        this.onNotificationEvent.fire(notif);
        break;
      }
      case WSRespType.SUBMIT_ANSWER_ACK: {
        this.onSubmitAnswerAckEvent.fire(resp);
        break;
      }
      case WSRespType.MYOSB_RESPONSE: {
        this.onMYOSBResponseEvent.fire(resp.resp);
        break;
      }
    }

    this.afterRespEvent.fire(resp);
  }

  /**
   * Enter a room, and waits until one of the following happens:
   * - We successfully enter the room. Returns null.
   * - We encounter an enter room error. Returns the error.
   * - The client cancels the enter room request. Returns
   *   WSErrorCode.CANCELED_BY_CLIENT.
   * Clean this up with leaveRoom.
   */
  async enterRoomAsync(
    params: EnterRoomParams,
    onUpdAsync: (upd: Update) => Promise<void>,
    onUpdsFlush?: (upds: Update[]) => Promise<void>
  ): Promise<WSError | null> {
    return await new Promise((resolve, reject) => {
      // Enter room requests should be guarded by an auth effect,
      // and should always be cleaned up afterwards, so we should
      // be in IDLE whenever we are told to start an enter room
      // request.
      if (this.switchState !== SwitchRoomState.IDLE)
        throw new Error(
          `only expect enter room requests when idle, but switch state is ${this.switchState}`
        );

      if (this.activeEnterRoomReq !== null)
        throw new Error("don't expect an active enter room request when idle");

      const reqId = this.nextEnterRoomReqId++;
      this.activeEnterRoomReq = {
        reqId,
        onAck: resolve,
        onUpdAsync,
        onUpdsFlush,
      };
      this.switchState = SwitchRoomState.ENTER_ROOM;
      this.sendReq({
        type: WSReqType.ENTER_ROOM,
        reqId,
        params,
      });
    });
  }

  /**
   * Enter room as an auth effect. onUpd takes in an extra parameter
   * that allows it to detect if the effect has been canceled, in
   * case it needs to be used for async tasks.
   */
  addEnterRoomEffect(
    params: EnterRoomParams,
    onUpdAsync: (upd: Update, isStale: () => boolean) => Promise<void>,
    onUpdsFlush?: (upds: Update[], isStale: () => boolean) => Promise<void>
  ): () => void {
    return this.addAuthEffect(() => {
      let isCanceled = false;
      this.enterRoomAsync(
        params,
        (upd) => {
          return onUpdAsync(upd, () => isCanceled);
        },
        onUpdsFlush === undefined
          ? undefined
          : (upds) => {
              return onUpdsFlush(upds, () => isCanceled);
            }
      );
      return () => {
        this.leaveRoom();
        isCanceled = true;
      };
    });
  }

  leaveRoom() {
    delete this.role;

    const reqId = this.nextEnterRoomReqId++;
    this.sendReq({
      type: WSReqType.ENTER_ROOM,
      reqId,
      params: null,
    });
    const canceledError = {
      errCode: WSErrorCode.CANCELED_BY_CLIENT,
    };
    this.activeEnterRoomReq?.onAck?.(canceledError);
    this.activeEnterRoomReq?.onStepAck?.(canceledError);
    this.activeEnterRoomReq = null;
    this.switchState = SwitchRoomState.IDLE;
  }

  sendReq(req: WSReq) {
    const serverInterfaceType = (() => {
      switch (req.type) {
        case WSReqType.SET_CURSOR_GROUP:
        case WSReqType.CURSOR_EVENT: {
          return ServerInterfaceType.CURSOR;
        }
        default: {
          return ServerInterfaceType.MAIN;
        }
      }
    })();
    if (
      this.isSpectating &&
      ![
        WSReqType.ENTER_ROOM,
        WSReqType.SET_CURSOR_GROUP,
        WSReqType.SUBSCRIBE_TO_UPDATES,
      ].includes(req.type)
    ) {
      this.onNotificationEvent.fire({
        type: HuntNotificationType.INTERNAL,
        message: `Tried to ${req.type}, but you’re spectating!`,
      });
      return;
    }
    this.connectionControllers[serverInterfaceType].sendReq(req);
  }

  async sendStepAsync(step: Step): Promise<WSError | null> {
    return await new Promise<WSError | null>((resolve, reject) => {
      if (!this.isInRoom()) {
        resolve({ errCode: WSErrorCode.NOT_READY });
        return;
      }
      if (this.activeEnterRoomReq === null)
        throw new Error("expect an active enter room req when ready");

      if (this.activeEnterRoomReq.onStepAck !== undefined) {
        resolve({ errCode: WSErrorCode.CLIENT_ERROR });
        return;
      }

      this.sendReq({
        type: WSReqType.STEP,
        step,
      });

      this.activeEnterRoomReq.onStepAck = resolve;
    });
  }

  // Only used for mock servers.
  resetServer() {
    for (const controller of Object.values(this.connectionControllers)) {
      controller.resetServer();
    }
  }
}
