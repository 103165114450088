import { Faction, factionToColor, capitalize } from "engine/types/factions";
import ColorSymbolIcon from "components/ColorSymbolIcon";
import Tooltip from "components/Tooltip";
import "./FactionIcon.css";

interface FactionIconProps {
  faction: Faction;
}

function FactionIcon(props: FactionIconProps) {
  const { faction } = props;

  return (
    <div className="faction-icon">
      <Tooltip text={capitalize(faction)}>
        <ColorSymbolIcon symbol={factionToColor(faction)} />
      </Tooltip>
    </div>
  );
}

export default FactionIcon;
