import { z } from "zod";

import { GemColor, GemColorZod } from "engine/types/card-data";
import { SlotZod, Player, PlayerZod, Permanent } from "engine/types/game-state";
import {
  AbilityType,
  AbilityTypeZod,
  EffectOptZod,
  EffectOptResolved,
} from "engine/types/effects";
import { Role } from "engine/types/updates";
import { Keyframe } from "engine/types/keyframes";
import { RoomMember } from "game-server/Room";

export enum StepType {
  // System steps. These can't be generated by clients.
  SYSTEM_JOIN = "system_join",
  SYSTEM_RESIGN = "system_resign",
  SYSTEM_TIME_OUT = "system_time_out",

  // Workshop-specific steps
  REMOVE = "remove_permanent",

  // Game steps
  SUMMON = "summon",
  ATTACK = "attack",
  CREATE_GEMS = "create_gems",
  MOVE = "move",
  DRAW = "draw",
  ACTIVATE_ABILITY = "activate_ability",
  END_TURN = "end_turn",

  // Out-of-game steps
  CREATE_CHECKPOINT = "create_checkpoint",
  ADVANCE_KEYFRAME = "advance_keyframe",
}

/** Client joins the room. */
export type SystemJoinStep = Readonly<{
  type: StepType.SYSTEM_JOIN;
  teamId: string;
  roomMember: RoomMember;
  role: Role;
}>;

export type SystemResignStep = Readonly<{
  type: StepType.SYSTEM_RESIGN;
  player: Player;
}>;

export type SystemTimeOutStep = Readonly<{
  type: StepType.SYSTEM_TIME_OUT;
  player: Player;
}>;

export type SystemStep = SystemJoinStep | SystemResignStep | SystemTimeOutStep;

/** Card is removed (only available in god mode) **/
const RemoveStepZod = z
  .object({
    type: z.literal(StepType.REMOVE),
    permanentId: z.string(),
  })
  .readonly();
export type RemoveStep = z.infer<typeof RemoveStepZod>;

export type RemoveStepResolved = RemoveStep & {
  permanent: Permanent;
};

/** Player summons card to slot. */
const SummonStepZod = z
  .object({
    type: z.literal(StepType.SUMMON),
    player: PlayerZod,
    slot: SlotZod,
    /**
     * If handCardId is specified, the card with ID handCardId will
     * be summoned.
     */
    handCardId: z.optional(z.string()),
    /**
     * If name is specified, a card of that name will be summoned
     * without consuming a card in hand. Only available if the
     * all-cards-available dev knob is set.
     */
    cardName: z.optional(z.string()),
    stepNumber: z.number(),
  })
  .readonly();
export type SummonStep = z.infer<typeof SummonStepZod>;

export type SummonStepResolved = SummonStep & {
  cardName: string;
  /**
   * Flag that indicates that the player cannot pay for this step,
   * and the payment field is invalid.
   * This is used to allow resolving steps that cannot be paid for,
   * and still report any additional fail reasons during validation.
   */
  cannotPay?: boolean;
  /** Gems used to pay for the action. */
  payment: GemColor[];
};

/** Player attacks defender with attacker. */
const AttackStepZod = z
  .object({
    type: z.literal(StepType.ATTACK),
    attackerId: z.string(),
    defenderId: z.string(),
    stepNumber: z.number(),
  })
  .readonly();
export type AttackStep = z.infer<typeof AttackStepZod>;

export type AttackStepResolved = AttackStep & {
  attacker: Permanent;
  defender: Permanent;
  /**
   * Flag that indicates that the player cannot pay for this step,
   * and the payment field is invalid.
   * This is used to allow resolving steps that cannot be paid for,
   * and still report any additional fail reasons during validation.
   */
  cannotPay?: boolean;
  /** Gems used to pay for the action. */
  payment: GemColor[];
};

/** Player creates gem(s), usually one of the permanent's color. */
const CreateGemsStepZod = z
  .object({
    type: z.literal(StepType.CREATE_GEMS),
    permanentId: z.string(),
    /** The gem color(s) to create. (Almost always just one but some cards might
     * create more.) */
    gemColors: GemColorZod.array(),
    stepNumber: z.number(),
  })
  .readonly();
export type CreateGemsStep = z.infer<typeof CreateGemsStepZod>;

export type CreateGemsStepResolved = CreateGemsStep & {
  permanent: Permanent;
};

/** Player moves permanentId to slot. */
const MoveStepZod = z
  .object({
    type: z.literal(StepType.MOVE),
    permanentId: z.string(),
    slot: SlotZod,
    stepNumber: z.number(),
  })
  .readonly();
export type MoveStep = z.infer<typeof MoveStepZod>;

export type MoveStepResolved = MoveStep & {
  permanent: Permanent;
};

/** Player draws card (outside of turn start). */
const DrawStepZod = z
  .object({
    type: z.literal(StepType.DRAW),
    player: PlayerZod,
    stepNumber: z.number(),
  })
  .readonly();
export type DrawStep = z.infer<typeof DrawStepZod>;

export type DrawStepResolved = DrawStep & {
  /**
   * Flag that indicates that the player cannot pay for this step,
   * and the payment field is invalid.
   * This is used to allow resolving steps that cannot be paid for,
   * and still report any additional fail reasons during validation.
   */
  cannotPay?: boolean;
  /** Gems used to pay for the action. */
  payment: GemColor[];
};

/** Activate a permanent's flex or special. Flexes are different
    from specials because they do not cost an action and can be used
    multiple times per turn. */
const ActivateAbilityStepZod = z
  .object({
    type: z.literal(StepType.ACTIVATE_ABILITY),
    permanentId: z.string(), // activating permanent
    abilityType: AbilityTypeZod,

    // Optional arguments needed to fully specify the use of an
    // flex or special (targets, etc.)
    effectOpts: EffectOptZod.array().readonly(),

    stepNumber: z.number(),
  })
  .readonly();
export type ActivateAbilityStep = z.infer<typeof ActivateAbilityStepZod>;

export type ActivateAbilityStepResolved = ActivateAbilityStep & {
  permanent: Permanent;
  /**
   * Flag that indicates that the player cannot pay for this step,
   * and the payment field is invalid.
   * This is used to allow resolving steps that cannot be paid for,
   * and still report any additional fail reasons during validation.
   */
  cannotPay?: boolean;
  /** Gems used to pay for the action. */
  payment: GemColor[];
  effectOptsResolved: EffectOptResolved[];
};

/** Player ends their turn. */
const EndTurnStepZod = z
  .object({
    type: z.literal(StepType.END_TURN),
    player: PlayerZod,
    stepNumber: z.number(),
  })
  .readonly();
export type EndTurnStep = z.infer<typeof EndTurnStepZod>;

/** Player creates checkpoint. */
const CreateCheckpointStepZod = z
  .object({
    type: z.literal(StepType.CREATE_CHECKPOINT),
    slot: z.number(),
  })
  .readonly();
export type CreateCheckpointStep = z.infer<typeof CreateCheckpointStepZod>;

/** Player advances to next keyframe. */
const AdvanceKeyframeStepZod = z
  .object({
    type: z.literal(StepType.ADVANCE_KEYFRAME),
    /**
     * The keyframe ID of the keyframe to advance,
     * to detect race conditions.
     */
    keyframeId: z.string(),
    /** Which dialog option the player selected. */
    dialogOptionIndex: z.optional(z.number()),
  })
  .readonly();
export type AdvanceKeyframeStep = z.infer<typeof AdvanceKeyframeStepZod>;

export type AdvanceKeyframeStepResolved = AdvanceKeyframeStep &
  Readonly<{
    /** The keyframe that we're advancing past. */
    keyframe: Keyframe;
  }>;

export type Step =
  | RemoveStep
  | SummonStep
  | AttackStep
  | CreateGemsStep
  | MoveStep
  | DrawStep
  | ActivateAbilityStep
  | EndTurnStep
  | CreateCheckpointStep
  | AdvanceKeyframeStep;
export const StepZod = z.union([
  RemoveStepZod,
  SummonStepZod,
  AttackStepZod,
  CreateGemsStepZod,
  MoveStepZod,
  DrawStepZod,
  ActivateAbilityStepZod,
  EndTurnStepZod,
  CreateCheckpointStepZod,
  AdvanceKeyframeStepZod,
]);

export type UnifiedStep = SystemStep | Step;

// A step after validating and resolving identifiers. Used by the engine.
export type StepResolved =
  | RemoveStepResolved
  | SummonStepResolved
  | AttackStepResolved
  | CreateGemsStepResolved
  | MoveStepResolved
  | DrawStepResolved
  | ActivateAbilityStepResolved
  | EndTurnStep
  | CreateCheckpointStep
  | AdvanceKeyframeStepResolved;

/** How many gems do we pay for this step? */
export const stepToPayment = (step: StepResolved | null): GemColor[] => {
  if (!step) return [];
  switch (step.type) {
    case StepType.DRAW:
    case StepType.SUMMON:
    case StepType.ATTACK:
    case StepType.ACTIVATE_ABILITY: {
      const { cannotPay, payment } = step;
      return cannotPay ? [] : payment;
    }
    default: {
      return [];
    }
  }
};

/** How many gems do we gain from this step? */
export const stepToGain = (step: StepResolved | null): GemColor[] => {
  if (!step) return [];
  switch (step.type) {
    case StepType.CREATE_GEMS: {
      return step.gemColors;
    }
    default: {
      return [];
    }
  }
};

export const stepToName = (step: Step): string | undefined => {
  switch (step.type) {
    case StepType.SUMMON:
      return "Summon";
    case StepType.ATTACK:
      return "Attack";
    case StepType.CREATE_GEMS:
      return "Create";
    case StepType.MOVE:
      return "Move";
    case StepType.DRAW:
      return "Draw";
    case StepType.ACTIVATE_ABILITY:
      return step.abilityType === AbilityType.FLEX ? "Flex" : "Special";
    case StepType.END_TURN:
      return "End turn";
    case StepType.CREATE_CHECKPOINT:
      return "Save checkpoint";
    case StepType.REMOVE:
      return "Destroy (god only)";
  }
};

/** Get the player responsible for making the step, if attributable. */
export const stepToPlayer = (step: StepResolved): Player | null => {
  switch (step.type) {
    case StepType.SUMMON:
    case StepType.DRAW:
    case StepType.END_TURN:
      return step.player;
    case StepType.ATTACK:
      return step.attacker.owner;
    case StepType.CREATE_GEMS:
    case StepType.MOVE:
    case StepType.ACTIVATE_ABILITY:
      return step.permanent.owner;
    default:
      return null;
  }
};
