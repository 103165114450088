import { memo, useMemo } from "react";

import "./StepOverlays.css";

import { describeStep } from "components/BattleHelpText";
import HoverNotifier from "components/HoverNotifier";
import Icon, { IconType } from "components/Icon";
import {
  AttackStep,
  AttackStepResolved,
  MoveStep,
  MoveStepResolved,
  stepToGain,
  stepToPayment,
  StepType,
  SummonStep,
  SummonStepResolved,
} from "engine/types/steps";
import {
  Check,
  FailedChecks,
  getFailText,
  isPassthrough,
} from "engine/types/action-validation";
import { Player, getAllSlots, Slot } from "engine/types/game-state";
import {
  useClientGameStore,
  useInspector,
  useStepMaker,
  useRole,
  useIsLocked,
} from "stores/ClientGameStore";
import {
  useTurnStore,
  useSelectedPermanent,
  usePendingForm,
  SelectedCardType,
} from "stores/TurnStore";
import TranslateToSlot from "./TranslateToSlot";

type OverlayStep = SummonStep | AttackStep | MoveStep;
type OverlayStepResolved =
  | SummonStepResolved
  | AttackStepResolved
  | MoveStepResolved;

const stepToKey = (step: OverlayStep): string => {
  switch (step.type) {
    case StepType.SUMMON:
      return `${step.type}-${step.slot.row}-${step.slot.column}`;
    case StepType.ATTACK:
      return `${step.type}-${step.defenderId}`;
    case StepType.MOVE:
      return `${step.type}-${step.slot.row}-${step.slot.column}`;
  }
};

const stepToIcon = (step: OverlayStep): IconType => {
  switch (step.type) {
    case StepType.SUMMON:
      return IconType.SUMMON;
    case StepType.ATTACK:
      return IconType.ATTACK;
    case StepType.MOVE:
      return IconType.MOVE;
  }
};

const stepToSlot = (step: OverlayStepResolved): Slot => {
  switch (step.type) {
    case StepType.SUMMON:
      return step.slot;
    case StepType.ATTACK:
      return step.defender.slot;
    case StepType.MOVE:
      return step.slot;
  }
};

interface StepOverlayProps {
  step: OverlayStep;
  resolved: OverlayStepResolved;
  failedChecks: FailedChecks;
  isValid: boolean;
  enablePointerEvents: boolean;
  helpText: string;
}

function StepOverlayNoMemo(props: StepOverlayProps) {
  const {
    step,
    resolved,
    failedChecks,
    isValid,
    enablePointerEvents,
    helpText,
  } = props;

  const sendStep = useClientGameStore((state) => state.sendStep);

  const payment = stepToPayment(resolved);
  const gain = stepToGain(resolved);
  const icon = stepToIcon(step);
  const slot = stepToSlot(resolved);
  const failText = getFailText(failedChecks);
  const text = failText ?? helpText;

  return (
    <TranslateToSlot row={slot.row} column={slot.column}>
      <HoverNotifier payment={payment} gain={gain} help={text}>
        <div
          style={{
            pointerEvents: enablePointerEvents ? "auto" : undefined,
          }}
          className={`step-overlay ${isValid ? "step-valid" : "step-preview"}`}
          onClick={(e) => {
            if (isValid) {
              e.stopPropagation();
              sendStep?.(step);
            }
          }}
        >
          <Icon className="step-icon" icon={icon} />
        </div>
      </HoverNotifier>
    </TranslateToSlot>
  );
}

/**
 * A button that covers a slot, that when clicked, sends that step. Assumes
 * it's *not* passthrough; it's the caller's responsibility to check that.
 */
const StepOverlay = memo(StepOverlayNoMemo);

/** Renders all the StepOverlays. */
function StepOverlays() {
  const inspector = useInspector();
  const stepMaker = useStepMaker();
  const keyframe = useClientGameStore((state) => state.gameState.keyframe);
  const { id: keyframeId } = keyframe ?? {};
  const role = useRole();

  const pendingForm = usePendingForm();
  const selectedCard = useTurnStore((state) => state.selectedCard);
  const selectedPermanent = useSelectedPermanent();
  const isLocked = useIsLocked();

  const rows = inspector.getNumRows();
  const columns = inspector.getNumColumns();
  const slots = useMemo(() => getAllSlots(rows, columns), [columns, rows]);

  const stepToDom = (step: OverlayStep) => {
    const { resolved, failedChecks, isValid } = inspector.resolveAndValidate(
      step,
      role
    );
    if (isLocked) failedChecks.add(Check.UI_LOCKED);

    const enablePointerEvents = (() => {
      if (keyframeId === undefined) return false;
      switch (keyframeId) {
        case "tutorial2-attack-2": {
          if (step.type !== StepType.ATTACK) return false;
          const targetPermanent = inspector.getPermanentIfExists(
            step.defenderId
          );
          if (targetPermanent === null) return false;
          if (targetPermanent.owner !== Player.P2) return false;
          if (targetPermanent.card.name !== "camp") return false;
          return true;
        }
      }
      return false;
    })();

    const failedChecksForPassthrough: FailedChecks = new Set(failedChecks);
    if (enablePointerEvents) {
      failedChecksForPassthrough.delete(Check.KEYFRAME);
      failedChecksForPassthrough.delete(Check.UI_LOCKED);
    }

    if (
      !resolved ||
      pendingForm ||
      isPassthrough(failedChecksForPassthrough, step.type)
    )
      return null;

    return (
      <StepOverlay
        key={stepToKey(step)}
        step={step}
        resolved={resolved as OverlayStepResolved}
        failedChecks={failedChecks}
        isValid={isValid}
        enablePointerEvents={enablePointerEvents}
        helpText={describeStep(inspector, step, resolved)}
      />
    );
  };

  const summonSteps =
    selectedCard?.type !== SelectedCardType.HAND
      ? []
      : slots.map<SummonStep>((slot) =>
          stepMaker.summonOrSpawn({
            player: selectedCard.player,
            slot,
            handCardId: selectedCard.handCardId,
            cardName: selectedCard.name,
          })
        );

  const attackSteps = !selectedPermanent
    ? []
    : inspector
        .getAllPermanents()
        .map<AttackStep>((permanent) =>
          stepMaker.attack(selectedPermanent, permanent)
        );

  const moveSteps = !selectedPermanent
    ? []
    : slots.map<MoveStep>((slot) => stepMaker.move(selectedPermanent, slot));

  return (
    <>
      {summonSteps.map(stepToDom)}
      {attackSteps.map(stepToDom)}
      {moveSteps.map(stepToDom)}
    </>
  );
}

export default StepOverlays;
