import { Check } from "engine/types/action-validation";
import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedPuzzle28Spec: SharedGameSpec = {
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: ["barn"],
  },
  cardEffectsShared: {
    barn: {
      isStructure: true,
      isBase: true,
    },
  },
  adjustSummonChecks(failedChecks, player, cardName, slot, ctx) {
    // can only summon on turn 0,1,2,3 (first two turns for each player)
    const { inspector } = ctx;
    const turnNumber = inspector.getTurnNumber();
    if (turnNumber > 3) {
      failedChecks.add(Check.GENERIC);
    }
  },
};
