import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

// Common base shared spec for cutscenes.
export const SharedCutsceneSpec: SharedGameSpec = {
  hasAI: true,
  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
};
