import "./Tooltip.css";
import { HTMLProps, PropsWithChildren } from "react";

import Tooltip from "components/Tooltip";
import { GemColor } from "engine/types/card-data";
import { useHoverActionStore } from "stores/HoverActionStore";
import { useUserPreferencesStore } from "stores/UserPreferencesStore";

interface HoverNotifierProps {
  enablePointerEvents?: boolean;
  /**
   * Anything here will be shown as subtracted from the current gems while it's
   * hovered.
   */
  payment?: GemColor[];
  /**
   * Anything here will be shown as added to the current gems while it's
   * hovered.
   */
  gain?: GemColor[];
  /** Help text shown as a tooltip on hover. */
  help: string;
}

/**
 * Anything that can be clicked to change turn state should be wrapped in a
 * HoverNotifier, so we can make changes to the UI on hover.
 *
 * Right now the only thing that changes is the payment, notifying the GemDisplay,
 * but maybe other things can change too. (For example, other things can hide
 * themselves when something's hovered.)
 *
 * TODO: maybe zoomed card should be moved to hover state
 * TODO: if we can't find more uses for this this should just not be its own
 * component lol
 */
function HoverNotifier(
  props: PropsWithChildren<HoverNotifierProps> & HTMLProps<HTMLDivElement>
) {
  const { enablePointerEvents = false, payment, gain, help, children } = props;
  const { setPayment, setGain, resetHoverState } = useHoverActionStore();
  const recurseActionTooltips = useUserPreferencesStore(
    (state) => state.recurseActionTooltips
  );

  return (
    <Tooltip
      text={help}
      format={true}
      disableRecursion={!recurseActionTooltips}
    >
      <div
        style={{
          pointerEvents: enablePointerEvents ? "auto" : undefined,
        }}
        onMouseEnter={() => {
          if (payment) {
            setPayment(payment);
          }
          if (gain) {
            setGain(gain);
          }
        }}
        onMouseLeave={() => {
          resetHoverState();
        }}
        {...props}
      >
        {children}
      </div>
    </Tooltip>
  );
}

export default HoverNotifier;
