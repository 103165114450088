import { CounterType } from "engine/types/counters";
import { EngineContext } from "engine/types/game-specs";
import { Permanent, Player } from "engine/types/game-state";

export const PUSHER_NAME = "worker-bot";
export const ZAP_PUSHER_NAME = "zap-worker-bot";
export const WALL_NAME = "pothole";
export const BOX_NAME = "box";
export const EXPLOSIVES_NAME = "explosives";
export const FIGHTER_ALLY_NAME = "friendly-battle-bot";
export const FIGHTER_ENEMY_NAME = "enemy-battle-bot";
export const ARMORED_FIGHTER_ENEMY_NAME = "armored-enemy-battle-bot";
export const ARMORED_CAMP_NAME = "armored-camp";
const BEAVER_NAME = "eager-beever";

export const ALL_PUSHERS = new Set([PUSHER_NAME, ZAP_PUSHER_NAME]);
export const ALL_AUTO_BATTLERS = new Set([
  FIGHTER_ALLY_NAME,
  FIGHTER_ENEMY_NAME,
  ARMORED_FIGHTER_ENEMY_NAME,
]);

function nameForLetter(letter: string) {
  switch (letter) {
    case "c":
    case "C":
      return "camp";
    case "D":
      return ARMORED_CAMP_NAME;
    case "e":
    case "E":
      return EXPLOSIVES_NAME;
    case "w":
      return PUSHER_NAME;
    case "z":
      return ZAP_PUSHER_NAME;
    case "X":
      return WALL_NAME;
    case "b":
    case "B":
      return BOX_NAME;
    case "F":
      return FIGHTER_ENEMY_NAME;
    case "f":
      return FIGHTER_ALLY_NAME;
    case "A":
      return ARMORED_FIGHTER_ENEMY_NAME;
    case "v":
      return BEAVER_NAME;
    default:
      return "";
  }
}

function playerForLetter(letter: string) {
  switch (letter) {
    case "w":
    case "z":
    case "c":
    case "f":
    case "e":
    case "b":
    case "v":
      return Player.P1;
    default:
      return Player.P2;
  }
}

function setPower(permanent: Permanent, power: number, ctx: EngineContext) {
  const delta = power - ctx.inspector.getBasePower(permanent);
  if (delta !== 0) {
    ctx.engine.adjustPermanentStats(permanent, delta, 0);
  }
}

function setHealth(permanent: Permanent, health: number, ctx: EngineContext) {
  const delta = health - ctx.inspector.getBaseHealth(permanent);
  if (delta !== 0) {
    ctx.engine.adjustPermanentStats(permanent, 0, delta);
  }
}

function setStats(
  permanent: Permanent,
  power: number,
  health: number,
  ctx: EngineContext
) {
  setPower(permanent, power, ctx);
  setHealth(permanent, health, ctx);
}

const LEVEL_1_LAYOUT = `
..C..
..F..
.....
.....
.fw..
..c..
`;

const LEVEL_2_LAYOUT = `
..C..
...F.
...F.
..w..
fffff
..c..
`;

function LEVEL_2_EXTRA_INIT(ctx: EngineContext) {
  const perm = ctx.inspector.getPermanentAtIfExists({ row: 4, column: 2 });
  if (!perm) {
    return;
  }
  // skipping adjustPermanentStats b/c custom explanation
  ctx.engine.addCounter(perm, {
    type: CounterType.POWER_ADJUSTMENT,
    val: -1,
    explanation: "This bot seems defective for some reason.",
  });
}

const LEVEL_3_LAYOUT = `
.BC..
.....
BBBBB
..wB.
...f.
..c..
`;

const LEVEL_4_LAYOUT = `
..C..
FFFFF
.FFF.
.....
.fw..
..c..
`;

function LEVEL_4_EXTRA_INIT(ctx: EngineContext) {
  const { inspector } = ctx;
  const backFighter = inspector.getPermanents({ row: 1 });
  for (const fighter of backFighter) {
    setHealth(fighter, 3, ctx);
  }
  const myFighter = inspector.getPermanentsAt({ row: 4, column: 1 })[0];
  setHealth(myFighter, 2, ctx);
}

const LEVEL_5_LAYOUT = `
....C
....F
XX..F
..X..
w?X.f
....c
`;

function LEVEL_5_EXTRA_INIT(ctx: EngineContext) {
  const { engine, inspector } = ctx;
  engine.spawn("chicken", { row: 4, column: 1 }, Player.P1);
  const enemyBots = inspector.getPermanents({
    owner: Player.P2,
    name: FIGHTER_ENEMY_NAME,
  });
  for (const bot of enemyBots) {
    setHealth(bot, 2, ctx);
  }
  const allCamps = inspector.getPermanents({ name: "camp" });
  for (const camp of allCamps) {
    setHealth(camp, 1, ctx);
  }
}

const LEVEL_12_LAYOUT = LEVEL_5_LAYOUT;

function LEVEL_12_EXTRA_INIT(ctx: EngineContext) {
  const { engine, inspector } = ctx;
  engine.spawn("chicken", { row: 4, column: 1 }, Player.P1);
  const enemyBots = inspector.getPermanents({
    owner: Player.P2,
    name: FIGHTER_ENEMY_NAME,
  });
  for (const bot of enemyBots) {
    setStats(bot, 100, 100, ctx);
  }
  const allCamps = inspector.getPermanents({ name: "camp" });
  for (const camp of allCamps) {
    setHealth(camp, 1, ctx);
  }
}

const LEVEL_6_LAYOUT = `
FFCFF
FFFFF
.FFF.
.FFF.
.f...
z.c..
`;

const LEVEL_7_LAYOUT = `
FFFFC
FFFFF
FFFFF
.....
ffzfX
ffffc
`;

const LEVEL_8_LAYOUT = `
..C..
XXXXX
FFXFF
.???.
.w...
eecee
`;

function LEVEL_8_EXTRA_INIT(ctx: EngineContext) {
  const { inspector, engine } = ctx;
  const enemyBase = inspector.getPermanentAtIfExists({ row: 0, column: 2 });
  if (enemyBase) {
    setHealth(enemyBase, 9, ctx);
  }
  const bot1 = inspector.getPermanentAtIfExists({ row: 2, column: 0 });
  const bot2 = inspector.getPermanentAtIfExists({ row: 2, column: 1 });
  const bot3 = inspector.getPermanentAtIfExists({ row: 2, column: 3 });
  const bot4 = inspector.getPermanentAtIfExists({ row: 2, column: 4 });
  if (bot1) {
    setStats(bot1, 4, 5, ctx);
  }
  if (bot2) {
    setStats(bot2, 3, 3, ctx);
  }
  if (bot3) {
    setStats(bot3, 8, 1, ctx);
  }
  if (bot4) {
    setStats(bot4, 2, 3, ctx);
  }

  const boarName = "boarry-farmer";
  engine.spawn(boarName, { row: 3, column: 1 }, Player.P1);
  engine.spawn(boarName, { row: 3, column: 2 }, Player.P1);
  engine.spawn(boarName, { row: 3, column: 3 }, Player.P1);

  const boar1 = inspector.getPermanentAtIfExists({ row: 3, column: 1 });
  const boar2 = inspector.getPermanentAtIfExists({ row: 3, column: 2 });
  const boar3 = inspector.getPermanentAtIfExists({ row: 3, column: 3 });
  if (boar1) {
    setStats(boar1, 3, 4, ctx);
  }
  if (boar2) {
    setStats(boar2, 2, 5, ctx);
  }
  if (boar3) {
    setStats(boar3, 1, 5, ctx);
  }

  const myCamp = inspector.getPermanentAtIfExists({ row: 5, column: 2 });
  if (myCamp) {
    setHealth(myCamp, 1, ctx);
  }
}

const LEVEL_10_LAYOUT = `
w...C
.E.E.
..E..
.E.E.
..E..
f...c
`;

function LEVEL_10_EXTRA_INIT(ctx: EngineContext) {
  const { inspector } = ctx;
  const bomb1 = inspector.getPermanentAtIfExists({ row: 1, column: 1 });
  const bomb2 = inspector.getPermanentAtIfExists({ row: 2, column: 2 });
  const bomb3 = inspector.getPermanentAtIfExists({ row: 1, column: 3 });
  const bomb4 = inspector.getPermanentAtIfExists({ row: 3, column: 1 });
  const bomb5 = inspector.getPermanentAtIfExists({ row: 4, column: 2 });
  const bomb6 = inspector.getPermanentAtIfExists({ row: 3, column: 3 });

  if (bomb1) {
    setHealth(bomb1, 1, ctx);
  }
  if (bomb2) {
    setHealth(bomb2, 2, ctx);
  }
  if (bomb3) {
    setHealth(bomb3, 3, ctx);
  }
  if (bomb4) {
    setHealth(bomb4, 4, ctx);
  }
  if (bomb5) {
    setHealth(bomb5, 5, ctx);
  }
  if (bomb6) {
    setHealth(bomb6, 6, ctx);
  }
}

const LEVEL_11_LAYOUT = `
C...C
Ff.fF
..f..
.f.f.
..f..
c.w.c
`;

function LEVEL_11_EXTRA_INIT(ctx: EngineContext) {
  const { inspector } = ctx;
  const enemy1 = inspector.getPermanentAtIfExists({ row: 1, column: 0 });
  const enemy2 = inspector.getPermanentAtIfExists({ row: 1, column: 4 });
  const ally1 = inspector.getPermanentAtIfExists({ row: 1, column: 1 });
  const ally2 = inspector.getPermanentAtIfExists({ row: 2, column: 2 });
  const ally3 = inspector.getPermanentAtIfExists({ row: 1, column: 3 });
  const ally4 = inspector.getPermanentAtIfExists({ row: 3, column: 1 });
  const ally5 = inspector.getPermanentAtIfExists({ row: 4, column: 2 });
  const ally6 = inspector.getPermanentAtIfExists({ row: 3, column: 3 });

  if (enemy1) {
    setHealth(enemy1, 11, ctx);
  }
  if (enemy2) {
    setHealth(enemy2, 53, ctx);
  }
  if (ally1) {
    setPower(ally1, 1, ctx);
  }
  if (ally2) {
    setPower(ally2, 2, ctx);
  }
  if (ally3) {
    setPower(ally3, 3, ctx);
  }
  if (ally4) {
    setPower(ally4, 4, ctx);
  }
  if (ally5) {
    setPower(ally5, 5, ctx);
  }
  if (ally6) {
    setPower(ally6, 6, ctx);
  }
}

const LEVEL_9_LAYOUT = `
D...D
A...A
.....
.?w?.
..b..
eeeec
`;

function LEVEL_9_EXTRA_INIT(ctx: EngineContext) {
  const { inspector, engine } = ctx;
  engine.spawn("switch-boar-d", { row: 3, column: 1 }, Player.P1);
  engine.spawn("switch-boar-d", { row: 3, column: 3 }, Player.P1);

  const enemyCamp1 = inspector.getPermanentAtIfExists({ row: 0, column: 0 });
  const enemyCamp2 = inspector.getPermanentAtIfExists({ row: 0, column: 4 });
  const myCamp = inspector.getPermanentAtIfExists({ row: 5, column: 4 });
  const bomb1 = inspector.getPermanentAtIfExists({ row: 5, column: 0 });
  const bomb2 = inspector.getPermanentAtIfExists({ row: 5, column: 1 });
  const bomb3 = inspector.getPermanentAtIfExists({ row: 5, column: 2 });
  const bomb4 = inspector.getPermanentAtIfExists({ row: 5, column: 3 });

  if (enemyCamp1) {
    setHealth(enemyCamp1, 1, ctx);
  }
  if (enemyCamp2) {
    setHealth(enemyCamp2, 1, ctx);
  }
  if (myCamp) {
    setHealth(myCamp, 2, ctx);
    // Extra gem for this battle.
    engine.mergeValueCounter(myCamp, CounterType.BASE_GEMS, 1);
  }
  if (bomb1) {
    setHealth(bomb1, 2, ctx);
  }
  if (bomb2) {
    setHealth(bomb2, 2, ctx);
  }
  if (bomb3) {
    setHealth(bomb3, 2, ctx);
  }
  if (bomb4) {
    setHealth(bomb4, 2, ctx);
  }
}

const LEVEL_13_LAYOUT = `
.XF.C
..F.X
..z..
.v.e.
X.X.X
XfcXX
`;

function LEVEL_13_EXTRA_INIT(ctx: EngineContext) {
  const { inspector, engine } = ctx;

  const enemyFighter1 = inspector.getPermanentAtIfExists({ row: 0, column: 2 });
  const enemyFighter2 = inspector.getPermanentAtIfExists({ row: 1, column: 2 });

  const myBomb = inspector.getPermanentAtIfExists({ row: 3, column: 3 });
  const zapBot = inspector.getPermanentAtIfExists({ row: 2, column: 2 });
  const myCamp = inspector.getPermanentAtIfExists({ row: 5, column: 2 });

  if (enemyFighter1) {
    setHealth(enemyFighter1, 3, ctx);
  }
  if (enemyFighter2) {
    setHealth(enemyFighter2, 3, ctx);
  }
  if (myBomb) {
    setHealth(myBomb, 1, ctx);
  }
  if (zapBot) {
    setHealth(zapBot, 2, ctx);
  }
  if (myCamp) {
    setHealth(myCamp, 1, ctx);
  }
}

const ALL_LAYOUT_DATA = [
  [LEVEL_1_LAYOUT],
  [LEVEL_2_LAYOUT, LEVEL_2_EXTRA_INIT],
  [LEVEL_3_LAYOUT],
  [LEVEL_4_LAYOUT, LEVEL_4_EXTRA_INIT],
  [LEVEL_5_LAYOUT, LEVEL_5_EXTRA_INIT],
  [LEVEL_6_LAYOUT],
  [LEVEL_7_LAYOUT],
  [LEVEL_8_LAYOUT, LEVEL_8_EXTRA_INIT],
  [LEVEL_9_LAYOUT, LEVEL_9_EXTRA_INIT],
  [LEVEL_10_LAYOUT, LEVEL_10_EXTRA_INIT],
  [LEVEL_11_LAYOUT, LEVEL_11_EXTRA_INIT],
  [LEVEL_12_LAYOUT, LEVEL_12_EXTRA_INIT],
  [LEVEL_13_LAYOUT, LEVEL_13_EXTRA_INIT],
] as ReadonlyArray<LayoutData>;

const ALL_PARSED_LAYOUTS: ReadonlyArray<ParsedLayout> =
  ALL_LAYOUT_DATA.map(parseLayout);

export function getNumRows(idx: number) {
  return ALL_PARSED_LAYOUTS[idx].rows;
}

export function getNumCols(idx: number) {
  return ALL_PARSED_LAYOUTS[idx].cols;
}

type ExtraInit = (ctx: EngineContext) => void;
type LayoutData = [string, ExtraInit?];
type LayoutItem = {
  name: string;
  row: number;
  column: number;
  player: Player;
};
type ParsedLayout = {
  allItems: LayoutItem[];
  rows: number;
  cols: number;
  extraInit?: ExtraInit;
};

function parseLayout(layoutData: LayoutData): ParsedLayout {
  const layout = layoutData[0];
  const lines = layout.split("\n").filter((s) => !!s);
  const rows = lines.length;
  const cols = lines[0].length;
  const allItems = [] as LayoutItem[];
  for (let row = 0; row < rows; row++) {
    const line = lines[row];
    if (line.length != cols) {
      throw new Error("bad sokoban layout :(\n" + layout);
    }
    for (let col = 0; col < cols; col++) {
      const c = line[col];
      const name = nameForLetter(c);
      if (name) {
        allItems.push({
          name,
          player: playerForLetter(c),
          row,
          column: col,
        });
      }
    }
  }

  return {
    allItems,
    rows,
    cols,
    extraInit: layoutData[1],
  };
}

function initFromParsed(ctx: EngineContext, parsed: ParsedLayout) {
  const { engine } = ctx;
  for (const item of parsed.allItems) {
    engine.spawn(
      item.name,
      { row: item.row, column: item.column },
      item.player
    );
  }
  if (parsed.extraInit) {
    parsed.extraInit(ctx);
  }
}

export const LEVEL_INDICES: ReadonlyArray<number> = ALL_LAYOUT_DATA.map(
  (_, i) => i
);
export const LEVEL_INITERS: ReadonlyArray<(ctx: EngineContext) => void> =
  LEVEL_INDICES.map((i) => {
    return (ctx: EngineContext) => initFromParsed(ctx, ALL_PARSED_LAYOUTS[i]);
  });
