import { setup } from "./HuntLib";

import {
  PUZ_NAME_PVP,
  PUZ_NAME_MASTERY_TREE,
  PUZ_NAME_MASTERY_TREE_PROMPT,
  PUZ_NAME_FINAL_BATTLE,
  PUZ_NAME_POST_FINAL_BATTLE,
  BATTLE_GROUP_NAME_INSTANCER,
  BATTLE_GROUP_NAME_TUTORIAL,
} from "engine/puzzles/puzzle-data";
import { WSErrorCode, WSReqType } from "game-server/ws";
import {
  getCompletionVerb,
  GlobalUpdatesScopeType,
  GlobalUpdateType,
} from "game-server/global-updates";
import {
  HuntNotificationType,
  HuntNotification,
} from "game-server/notifications";
import { ServerInterfaceController } from "game-server/server-interface/ServerInterfaceController";
import { BrowserRawWSInterface } from "game-server/server-interface/BrowserRawWSInterface";
import { InteractiveServerInterface } from "game-server/server-interface/InteractiveServerInterface";
import {
  ServerInterfaceType,
  NullServerInterface,
} from "game-server/server-interface/ServerInterface";
import settings, { mockServerOpts } from "settings";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import { useClientGlobalStateStore } from "stores/ClientGlobalStateStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import {
  useIsAdminEnabledStore,
  useAdminPreferencesStore,
  useUserPreferencesStore,
} from "stores/UserPreferencesStore";
import { getJwt } from "getJwt";
import Globals, { showNotify, toastr } from "Globals";
import { SharedWorkerInterface } from "game-server/server-interface/SharedWorkerInterface";

import SharedWorkerServer from "./sharedWorkerServer?sharedworker";

class SubscriptionCleanupController {
  cleanupFunc?: () => void;

  trigger(func: () => (() => void) | void) {
    if (this.cleanupFunc !== undefined) {
      this.cleanupFunc();
      delete this.cleanupFunc;
    }
    this.cleanupFunc = func() ?? undefined;
  }
}

const setupServerInterfaceControllerAsync = async () => {
  const initIsAdmin =
    useAdminPreferencesStore.getState().isAdminEnabledPref &&
    settings.hasAdminAccess;
  const initIsSpectating =
    useAdminPreferencesStore.getState().isSpectatingPref &&
    settings.hasAdminAccess;
  const { speed } = useUserPreferencesStore.getState();

  useIsAdminEnabledStore.getState().setIsAdminEnabled(initIsAdmin);
  useIsAdminEnabledStore.getState().setIsSpectating(initIsSpectating);

  const serverInterfaceController = new ServerInterfaceController({
    dumpWsMessages: settings.dumpWsMessages,
    teamId: settings.teamId,
    // In posthunt mode, connect to the server as admin,
    // but make it seem like we're not admin in the UI.
    initIsAdmin: initIsAdmin || settings.isPosthunt,
    initIsSpectating,
    delay: settings.clientDelay,
    getJwtFunc: async (teamId, isAdmin, forceRegen) => {
      if (!settings.useJwt)
        return JSON.stringify({
          teamId: settings.teamId,
          isAdmin: isAdmin ? true : undefined,
        });
      return getJwt(teamId, isAdmin, forceRegen);
    },
    makeServerInterfaceFunc: async (type) => {
      if (
        settings.isPosthunt ||
        (import.meta.env.MODE === "development" && settings.localMode)
      ) {
        if (window.SharedWorker === undefined) {
          // If shared workers aren't supported, fallback to
          // the raw mock server interface.
          const makeClientMockServerInterface = (
            await import("makeClientMockServerInterface")
          ).default;
          return await makeClientMockServerInterface(type);
        }
        const worker = new SharedWorkerServer();
        return new SharedWorkerInterface(worker, mockServerOpts);
      }

      const endpoint =
        type === ServerInterfaceType.MAIN
          ? settings.wsEndpoint
          : settings.cursorsWsEndpoint;

      // Fallback in case we don't have a cursor WS.
      if (!endpoint) {
        console.warn(`[${type}] endpoint not found`);
        return new NullServerInterface();
      }

      return new InteractiveServerInterface({
        rawWsInterface: new BrowserRawWSInterface(endpoint),
      });
    },
    isFlushToggled: speed === 0,
  });
  Globals.serverInterfaceController = serverInterfaceController;

  // Notifications that might be waiting for global state to be updated
  // so that we can resolve and show them.
  const notifsQueue: HuntNotification[] = [];
  const tryGetPuzzleDisplayName = (puzName: string): string | null => {
    const { subscribedTeam } = useClientGlobalStateStore.getState().globalState;
    if (subscribedTeam === null) return null;
    const { puzzles, battleGroups } = subscribedTeam;
    const battleGroupName = puzzles[puzName]?.battleGroupName ?? null;
    if (battleGroupName === null) return null;
    return battleGroups[battleGroupName]?.displayName ?? null;
  };
  const tryGetIsSubpuzzle = (puzName: string): boolean | null => {
    const { subscribedTeam } = useClientGlobalStateStore.getState().globalState;
    if (subscribedTeam === null) return null;
    const { puzzles } = subscribedTeam;
    if (puzzles[puzName] === undefined) return null;
    return puzzles[puzName].battleGroupName !== puzName;
  };

  /**
   * Pop up notifications from the notifs queue as long as we have the
   * global state synced for them.
   */
  const processNotifsQueue = (): void => {
    while (true) {
      const notif = notifsQueue[0];
      if (notif === undefined) break;
      const notify: typeof showNotify = (data) => {
        showNotify(data);
        useClientGlobalStateStore.getState().playAudioNotif?.(notif);
      };
      const isResolved = ((): boolean => {
        switch (notif.type) {
          case HuntNotificationType.INTERNAL: {
            const { message } = notif;
            toastr.warning(message);
            return true;
          }
          case HuntNotificationType.VICTORY: {
            notify({
              title: "Congratulations!",
              text: "You’ve finished Galactic Puzzle Hunt 2023!",
              link: `${settings.djangoBaseUrl}victory`,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.HINT_ANSWERED: {
            const { puzName } = notif;
            const displayName = tryGetPuzzleDisplayName(puzName);
            if (displayName === null) return false;
            notify({
              title: displayName,
              text: "Hint answered!",
              link: `${settings.djangoBaseUrl}hints/${puzName}`,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.ERRATUM: {
            notify({
              title: "New erratum",
              text: "A new erratum has been published.",
              link: `${settings.djangoBaseUrl}errata`,
            });
            return true;
          }
          case HuntNotificationType.PVP_REQUEST_RECEIVED: {
            notify({
              title: "PvP",
              text: "You’ve been challenged to a PvP battle!",
              link: `${settings.djangoBaseUrl}game/prep/pvp`,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.PVP_REQUEST_ACCEPTED: {
            notify({
              title: "PvP",
              text: "Your PvP challenge request has been accepted!",
              link: `${settings.djangoBaseUrl}game/prep/pvp`,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.SOLVE: {
            const { puzName } = notif;

            const { subscribedTeam } =
              useClientGlobalStateStore.getState().globalState;
            if (subscribedTeam === null) return false;
            const { puzzles, battleGroups } = subscribedTeam;
            if (puzzles[puzName] === undefined) return false;
            const { battleGroupName, hasAnswer = false } = puzzles[puzName];
            const { displayName, isCutscene = false } =
              battleGroups[battleGroupName];
            const completionVerb = getCompletionVerb(
              battleGroups[battleGroupName]
            );

            const isSubpuzzle = battleGroupName !== puzName;
            // Don't show notifications for subpuzzle solves.
            if (isSubpuzzle) return true;
            // Don't show notification for pvp/instancer solves.
            if (puzName === PUZ_NAME_PVP) return true;
            if (puzName === BATTLE_GROUP_NAME_INSTANCER) return true;
            // Don't show the notification for completing the kero
            // battle, to ensure a smooth transition.
            if (puzName === PUZ_NAME_FINAL_BATTLE) return true;
            // Don't show notification for cutscene solves.
            if (isCutscene) return true;

            notify({
              title: displayName,
              text: `${hasAnswer ? "Solved" : completionVerb}!`,
              link: `${settings.djangoBaseUrl}solve/${puzName}`,
            });
            return true;
          }
          case HuntNotificationType.UNLOCK: {
            const { puzName } = notif;

            const { subscribedTeam } =
              useClientGlobalStateStore.getState().globalState;
            if (subscribedTeam === null) return false;
            const { puzzles, battleGroups } = subscribedTeam;
            if (puzzles[puzName] === undefined) return false;
            const { battleGroupName, hasAnswer = false } = puzzles[puzName];
            const { displayName, isCutscene = false } =
              battleGroups[battleGroupName];

            // Don't notify for mastery tree unlock since we're
            // jumping into it straight after a cutscene.
            if (puzName === PUZ_NAME_MASTERY_TREE) return true;

            if (puzName === PUZ_NAME_MASTERY_TREE_PROMPT) {
              // This means that the fish meta has unlocked.
              // The fish meta may unlock after the mastery tree,
              // but is still accessed from the mastery tree page.
              notify({
                title: displayName,
                text: `You unlocked a new puzzle!`,
                link: `${settings.djangoBaseUrl}game/mastery`,
              });
              return true;
            }

            if (hasAnswer) {
              notify({
                title: displayName,
                text: `You unlocked a new puzzle!`,
                link: `${settings.djangoBaseUrl}puzzle/${puzName}`,
              });
              return true;
            }

            const isSubpuzzle = battleGroupName !== puzName;
            // Don't show notifications for subpuzzle unlocks.
            if (isSubpuzzle) return true;
            // Don't show notification for pvp/instancer/tutorial unlocks.
            if (puzName === PUZ_NAME_PVP) return true;
            if (puzName === BATTLE_GROUP_NAME_INSTANCER) return true;
            if (puzName === BATTLE_GROUP_NAME_TUTORIAL) return true;
            // Don't show the notification for unlocking the final
            // cutscene, to ensure a smooth transition.
            if (puzName === PUZ_NAME_POST_FINAL_BATTLE) return true;

            if (isCutscene) {
              notify({
                title: displayName,
                text: `A new cutscene is available!`,
                link: `${settings.djangoBaseUrl}game/cutscene/${puzName}/start`,
                isImportant: true,
              });
              return true;
            }

            notify({
              title: displayName,
              text: `You unlocked a new battle!`,
              link: `${settings.djangoBaseUrl}game/prep/${puzName}`,
            });
            return true;
          }
          default: {
            throw new Error("not implemented yet");
          }
        }
      })();
      if (!isResolved) return;
      notifsQueue.shift();
    }
  };

  serverInterfaceController.onGlobalUpdateEvent.addListener((upd) => {
    useClientGlobalStateStore.getState().applyGlobalUpdate(upd);
    switch (upd.type) {
      case GlobalUpdateType.TEAM_UPDATE_STATE: {
        const { unlocks, cardUnlocks, solveTimes } = upd;

        for (const [puzName, solveTime] of Object.entries(solveTimes ?? {})) {
          if (solveTime === null) continue;
          serverInterfaceController.onNotificationEvent.fire({
            type: HuntNotificationType.SOLVE,
            puzName,
          });
        }

        // TODO: Fix unlock notifications for battle groups and dummies.
        for (const [puzName, unlock] of Object.entries(unlocks ?? {})) {
          if (unlock === null) continue;
          serverInterfaceController.onNotificationEvent.fire({
            type: HuntNotificationType.UNLOCK,
            puzName,
          });
        }

        // TODO: notify card unlocks, meta solves, hunt complete, etc.
        break;
      }
    }
  });

  serverInterfaceController.onNotificationEvent.addListener((data) => {
    notifsQueue.push(data);
    processNotifsQueue();
  });

  const teamSubscriptionController = new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.subscribedTeamId,
    (subscribedTeamId) => {
      teamSubscriptionController.trigger(() => {
        if (subscribedTeamId === null) return;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.TEAM,
          teamId: subscribedTeamId,
        });
      });
    }
  );
  if (settings.teamId !== null)
    useGlobalSubscriptionStore.getState().setSubscribedTeamId(settings.teamId);

  const teamDecksSubscriptionController = new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.subscribedTeamDecksTeamId,
    (subscribedTeamDecksTeamId) => {
      teamDecksSubscriptionController.trigger(() => {
        if (subscribedTeamDecksTeamId === null) return;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.TEAM_DECKS,
          teamId: subscribedTeamDecksTeamId,
        });
      });
    }
  );

  const adminSubscriptionController = new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.needAdmin,
    (needAdmin) => {
      adminSubscriptionController.trigger(() => {
        if (!needAdmin) return;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.SERVER,
        });
      });
    }
  );

  const teamListSubscriptionController = new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.needTeamList,
    (needTeamList) => {
      teamListSubscriptionController.trigger(() => {
        if (!needTeamList) return;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.TEAM_LIST,
        });
      });
    }
  );

  const teamBattleSubscriptionController = new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.subscribedTeamBattle,
    (subscribedTeamBattle) => {
      teamBattleSubscriptionController.trigger(() => {
        if (subscribedTeamBattle === null) return;
        const { teamId, puzName } = subscribedTeamBattle;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.TEAM_BATTLE,
          teamId,
          puzName,
        });
      });
    }
  );

  // Subscribe to updates for the fish puzzle solve page.
  const teamFishPuzzleSubscriptionController =
    new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.subscribedTeamFishPuzzle,
    (subscribedTeamFishPuzzle) => {
      teamFishPuzzleSubscriptionController.trigger(() => {
        if (subscribedTeamFishPuzzle === null) return;
        const { teamId, puzName } = subscribedTeamFishPuzzle;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.TEAM_FISH_PUZZLE,
          teamId,
          puzName,
        });
      });
    }
  );

  const factionHistorySubscriptionController =
    new SubscriptionCleanupController();
  useGlobalSubscriptionStore.subscribe(
    (state) => state.needFactionHistory,
    (needFactionHistory) => {
      factionHistorySubscriptionController.trigger(() => {
        if (!needFactionHistory) return;
        return serverInterfaceController.addSubscribeEffect({
          type: GlobalUpdatesScopeType.FACTION_HISTORY,
        });
      });
    }
  );

  serverInterfaceController.onErrorEvent.addListener((err) => {
    const { errCode } = err;
    toastr.error(`Received error "${errCode}".`);
    switch (errCode) {
      case WSErrorCode.TEAM_INACTIVE: {
        window.location.href = `${settings.djangoBaseUrl}`;
        break;
      }
    }
  });

  useClientGlobalStateStore.subscribe(({ globalState }) => {
    serverInterfaceController.afterUpdateGlobalState(globalState);
    Globals.globalState = globalState;
  });

  const { puzName } = Globals;
  if (puzName !== undefined) {
    serverInterfaceController.addAuthEffect(() => {
      serverInterfaceController.sendReq({
        type: WSReqType.VIEW_PUZZLE,
        puzName,
      });
    });
  }

  serverInterfaceController.addAuthEffect(() => {
    const setIsAdmin = useServerInteractionStore.getState().setIsAdmin;
    setIsAdmin(settings.hasAdminAccess && serverInterfaceController.isAdmin);
    return () => {
      setIsAdmin(null);
    };
  });

  serverInterfaceController.connectAsync();
  useServerInteractionStore
    .getState()
    .setServerInterfaceController(serverInterfaceController);
};
if (!settings.isPublicAccess) setupServerInterfaceControllerAsync();

setup();
