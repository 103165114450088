import { Spinner } from "components/Loading";
import {
  CheckAnswerBackendResp,
  CheckAnswerBackendRespType,
} from "game-server/backend-interface/BackendInterface";
import { WSReqType } from "game-server/ws";
import Globals, { toastr } from "Globals";
import { useEffect, useRef, useState } from "react";
import {
  useSubscribedTeam,
  useSubscribedTeamFishPuzzle,
} from "stores/ClientGlobalStateStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import { useNavStore } from "stores/NavStore";
import SolvePageCardUnlocks from "./SolvePageCardUnlocks";
import settings from "settings";

const SubmitAnswerForm = () => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  const teamId = serverInterfaceController?.getTeamId() ?? null;
  const { puzName } = Globals;
  if (puzName === undefined) throw new Error("expect puzName to be defined");
  const [submitAnswerLock, setSubmitAnswerLock] = useState<boolean>(false);
  const [submitAnswerResp, setSubmitAnswerResp] =
    useState<CheckAnswerBackendResp | null>(null);
  const subscribedTeamFishPuzzle = useSubscribedTeamFishPuzzle(teamId, puzName);
  const { setSubscribedTeamFishPuzzle } = useGlobalSubscriptionStore();
  const subscribedTeam = useSubscribedTeam();
  const battleGroups = subscribedTeam?.battleGroups ?? null;
  const setNavPuzName = useNavStore((state) => state.setNavPuzName);

  const puzzles = subscribedTeam?.puzzles ?? null;
  const puzData = puzzles?.[puzName] ?? null;
  const hasAnswer = puzData === null ? null : puzData.hasAnswer ?? false;

  const isSolved = puzData === null ? null : puzData.solveTime !== undefined;
  const answer = puzData?.answer ?? null;
  const answerInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNavPuzName(puzName);
  }, [puzName, setNavPuzName]);

  useEffect(() => {
    if (teamId === null) return;
    if (hasAnswer === null) return;
    if (!hasAnswer) return;

    setSubscribedTeamFishPuzzle({ teamId, puzName });
    return () => {
      setSubscribedTeamFishPuzzle(null);
    };
  }, [teamId, puzName, hasAnswer, setSubscribedTeamFishPuzzle]);

  useEffect(() => {
    if (hasAnswer === null) return;
    if (!hasAnswer) return;
    if (isSolved === null) return;
    if (isSolved) return;

    if (!submitAnswerLock && answerInputRef.current !== null) {
      answerInputRef.current.value = "";
      answerInputRef.current.focus();
    }
  }, [hasAnswer, isSolved, submitAnswerLock, answerInputRef]);

  useEffect(() => {
    if (serverInterfaceController === null) return;

    return serverInterfaceController.onSubmitAnswerAckEvent.addListener(
      (resp) => {
        const backendResp = resp.resp;
        setSubmitAnswerResp(backendResp);
        setSubmitAnswerLock(false);
        switch (backendResp.type) {
          case CheckAnswerBackendRespType.ALREADY_SOLVED: {
            toastr.error("You’ve already solved this puzzle!");
            break;
          }
          case CheckAnswerBackendRespType.NO_GUESSES: {
            toastr.error("You have no more guesses for this puzzle!");
            break;
          }
          case CheckAnswerBackendRespType.SUCCESS: {
            if (backendResp.isCorrect) {
              toastr.success(`${backendResp.submittedAnswer} is correct!`);
            } else {
              toastr.error(`${backendResp.submittedAnswer} is incorrect.`);
            }
            break;
          }
        }
      }
    );
  }, [serverInterfaceController, setSubmitAnswerLock]);

  useEffect(() => {
    if (serverInterfaceController === null) return;

    return serverInterfaceController.onErrorEvent.addListener((upd) => {
      setSubmitAnswerLock(false);
    });
  }, [serverInterfaceController, setSubmitAnswerLock]);

  useEffect(() => {
    if (isSolved === null) return;
    if (!isSolved) return;

    // If the puzzle is solved, show the survey.
    const surveyElem = document.getElementById("survey");
    const solvedTitleMarkerElem = document.getElementById(
      "solved-title-marker"
    );
    const solvedTitleAnswerElem = document.getElementById(
      "solved-title-answer"
    );

    if (surveyElem === null) throw new Error("expect survey element to exist");
    if (solvedTitleMarkerElem === null)
      throw new Error("expect solved title marker element to exist");
    if (solvedTitleAnswerElem === null)
      throw new Error("expect solved title answer element to exist");

    surveyElem.style.display = "";
    solvedTitleMarkerElem.style.display = "";
    if (answer !== null) solvedTitleAnswerElem.innerText = answer;
  }, [isSolved, answer]);

  if (isSolved === null || hasAnswer === null || subscribedTeam === null)
    return <Spinner inline={true} />;

  if (isSolved) {
    // If we've already solved the puzzle, show the card unlocks instead
    // of the solve box.
    return <SolvePageCardUnlocks />;
  }

  // If this is a battle, then there's nothing else to render.
  if (!hasAnswer) return null;

  if (subscribedTeamFishPuzzle === null) return <Spinner inline={true} />;

  const { numGuessesRemaining } = subscribedTeamFishPuzzle;

  if (!settings.isPosthunt && numGuessesRemaining === 0)
    return <p>You have no more guesses remaining for this puzzle!</p>;

  const formErrMsgs = ((): ReadonlyArray<string> => {
    if (submitAnswerResp === null) return [];
    switch (submitAnswerResp.type) {
      case CheckAnswerBackendRespType.PUZZLE_MESSAGES: {
        return submitAnswerResp.messages;
      }
      case CheckAnswerBackendRespType.NO_ANSWER: {
        return [
          "All puzzle answers will have at least one letter A through Z (case does not matter).",
        ];
      }
      case CheckAnswerBackendRespType.TRIED_BEFORE: {
        return [
          `You’ve already tried calling in the answer “${submitAnswerResp.submittedAnswer}” for this puzzle.`,
        ];
      }
      default:
        return [];
    }
  })();

  const submit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (submitAnswerLock) return;
    if (answerInputRef.current === null) return;
    if (serverInterfaceController === null) return;
    if (teamId === null) return;

    serverInterfaceController.sendReq({
      type: WSReqType.SUBMIT_ANSWER,
      teamId,
      puzName,
      answer: answerInputRef.current.value,
    });
    setSubmitAnswerLock(true);
  };

  return (
    <form onSubmit={submit}>
      {formErrMsgs.map((msg, i) => {
        return (
          <ul key={i} className="errorlist nonfield">
            <li>{msg}</li>
          </ul>
        );
      })}
      <div className="form-row">
        <div className="form-desc">Enter your guess:</div>
        <input
          type="text"
          name="answer"
          autoFocus
          maxLength={500}
          required
          disabled={submitAnswerLock}
          ref={answerInputRef}
        />
        <div className="form-desc">
          {submitAnswerLock ? (
            "Submitting..."
          ) : settings.isPosthunt ? null : (
            <>
              You have {numGuessesRemaining}{" "}
              {numGuessesRemaining === 1 ? "guess" : "guesses"} remaining for
              this puzzle.
            </>
          )}
        </div>
        <a className="delete-row" href="#" onClick={submit}>
          &#x2794;
        </a>
      </div>
    </form>
  );
};

export default SubmitAnswerForm;
