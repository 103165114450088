import { Player, HandCard, Permanent, Slot } from "engine/types/game-state";

/**
 * Buttons here should be unique in the UI, and should be given the ID from
 * idFrom below.
 */
export enum UIElement {
  AI_BATTLER = "ai_battler",
  CREATE_GEMS = "create_gems",
  DRAW = "draw",
  END_TURN = "end_turn",
  FLEX = "flex",
  SPECIAL = "special",
  POWER = "power",
  HEALTH = "health",
  GEMS = "gems",
  // etc: food, card cost, whatever
}

class CSSIdFactory {
  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  obj = (type: string) => (id?: string) => `${this.prefix}${type}-${id}`;

  permanentId = this.obj("permanent");
  handCardId = this.obj("handCard");

  permanent = (permanent?: Permanent | null, element?: UIElement) =>
    // Need to handle element === undefined here since permanentId is
    // sometimes used directly.
    this.permanentId(
      `${element === undefined ? "" : `${element}-`}${permanent?.id}`
    );
  handCard = (handCard?: HandCard | null) => this.handCardId(handCard?.id);
  slot = (slot: Slot) => this.obj("slot")(`${slot.row}-${slot.column}`);
  gems = (player: Player) => this.obj("gems")(player);
  element = (element: UIElement) => this.obj("element")(element.toString());
}

/**
 * The client should use these to make CSS IDs, so that game specs can target
 * them with keyframes.
 *
 * (Game specs should probably use selectorFor, not cssIdFor.)
 */
export const cssIdFor = new CSSIdFactory("");

/**
 * Game specs should use this to make selectors for keyframes.
 *
 * (This returns IDs with a leading #, cssIdFor doesn't.)
 */
export const selectorFor = new CSSIdFactory("#");

export enum Speaker {
  NARRATOR = "narrator",
  ENEMY = "enemy",
  DINO = "dino",
  CAPTAIN_PI = "captain_pi",
  KERO = "kero",
  ANGRY_KERO = "angry_kero",
  KERO_DOS = "kero_dos",
}

/**
 * A game can have a series of keyframes. Most common case is for showing
 * dialog from the enemy battler, but we also use it to run synced story
 * cutscenes.
 */
export type Keyframe = Readonly<{
  /** IDs for keyframes in a game spec. */
  id: string;
  /**
   * By default, keyframes advance to the next keyframe after the user
   * acknowledges them. If endCutscene is true, this doesn't happen.
   */
  endCutscene?: boolean;
  /** The message. */
  dialogue?: string;
  /** Speaker enum, determines the style of the modal. */
  speaker?: Speaker;
  /** Where the message will appear, should be a UIElement. */
  selector?: UIElement;
  /** The card name of a permanent to use as selector. */
  selectorPermanent?: string;
  /** The slot to use as selector. */
  selectorSlot?: Slot;
  /**
   * Where the message will appear relative to the selected element.
   * See https://floating-ui.com/ for valid Placement strings.
   */
  placement?: string;
  /** Dialog response options to show, if not the default one. */
  dialogOptions?: string[];
  /** Shows dialog options. */
  showNext?: boolean;
  /** Element that, when clicked, moves to the next keyframe ID. */
  nextSelector?: UIElement;
  /** The card name of a permanent to use as nextSelector. */
  nextSelectorPermanent?: string;
  /** Hide the normal game UI. */
  hideGame?: boolean;
  /** Hide the AI battler image. */
  hideBattler?: boolean;
  /**
   * Show a mask that puts everything under a black overlay except the
   * selector element, and the unmaskSelectors elements.
   */
  showMask?: boolean;
  /** Block user input outside the modal and selector elements. */
  blocking?: boolean;
  /** Don't mask these elements. */
  unmaskSelectors?: string[];
  /**
   * Display the alternate cutscene UI. It would go on top of the mask,
   * if present.
   */
  useCutsceneUI?: boolean;
  /**
   * Name of image in the cutscene-art directory to show over the full
   * screen. Needs useCutsceneUI to be enabled to take effect.
   */
  image?: string;
  /**
   * If set, going past this keyframe will end the game with P1 marked
   * as having solved the "battle".
   */
  endGame?: boolean;
  /**
   * If set, going past this keyframe will end the game with P2 marked
   * as having won the "battle", without offering a solve.
   */
  endGameUnsolved?: boolean;
  /**
   * The keyframe to jump to, depending on which dialog option is
   * selected. If only one ID is provided, the jump is unconditional.
   */
  nextIds?: string[];
}>;
