import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { TeamPuz } from "game-server/backend-interface/BackendInterface";

export interface GlobalSubscriptionState {
  subscribedTeamId: string | null;
  setSubscribedTeamId: (subscribedTeamId: string | null) => void;

  subscribedTeamDecksTeamId: string | null;
  setSubscribedTeamDecksTeamId: (
    subscribedTeamDecksTeamId: string | null
  ) => void;

  subscribedTeamBattle: TeamPuz | null;
  setSubscribedTeamBattle: (subscribedTeamBattle: TeamPuz | null) => void;

  subscribedTeamFishPuzzle: TeamPuz | null;
  setSubscribedTeamFishPuzzle: (
    subscribedTeamFishPuzzle: TeamPuz | null
  ) => void;

  needAdmin: boolean;
  setNeedAdmin: (needAdmin: boolean) => void;
  needTeamList: boolean;
  setNeedTeamList: (needTeamList: boolean) => void;
  needFactionHistory: boolean;
  setNeedFactionHistory: (needFactionHistory: boolean) => void;
}

export const useGlobalSubscriptionStore = create<GlobalSubscriptionState>()(
  subscribeWithSelector((set) => ({
    subscribedTeamId: null,
    setSubscribedTeamId: (subscribedTeamId) => set({ subscribedTeamId }),

    subscribedTeamDecksTeamId: null,
    setSubscribedTeamDecksTeamId: (subscribedTeamDecksTeamId) =>
      set({ subscribedTeamDecksTeamId }),

    subscribedTeamBattle: null,
    setSubscribedTeamBattle: (subscribedTeamBattle) =>
      set({ subscribedTeamBattle }),

    subscribedTeamFishPuzzle: null,
    setSubscribedTeamFishPuzzle: (subscribedTeamFishPuzzle) =>
      set({ subscribedTeamFishPuzzle }),

    needAdmin: false,
    setNeedAdmin: (needAdmin) => set({ needAdmin }),
    needTeamList: false,
    setNeedTeamList: (needTeamList) => set({ needTeamList }),
    needFactionHistory: false,
    setNeedFactionHistory: (needFactionHistory) => set({ needFactionHistory }),
  }))
);
