import { useEffect } from "react";
import { PUZ_NAME_MASTERY_TREE_PROMPT } from "engine/puzzles/puzzle-data";
import Modal from "components/Modal";
import { WSReqType } from "game-server/ws";
import { useSubscribedTeam } from "stores/ClientGlobalStateStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import { useNavStore } from "stores/NavStore";
import { useServerInterfaceController } from "stores/ServerInteractionStore";

export default function MasteryUnlockModal() {
  const { subscribedTeamId } = useGlobalSubscriptionStore();
  const serverInterfaceController = useServerInterfaceController();
  const subscribedTeam = useSubscribedTeam(subscribedTeamId);

  const isMasteryUnlockModalOpen = useNavStore(
    (state) => state.isMasteryUnlockModalOpen
  );
  const setIsMasteryUnlockModalOpen = useNavStore(
    (state) => state.setIsMasteryUnlockModalOpen
  );
  const navIsMasteryTreePage = useNavStore(
    (state) => state.navIsMasteryTreePage
  );

  // If we navigate out of the page, reset the modal.
  useEffect(() => {
    if (!navIsMasteryTreePage) {
      setIsMasteryUnlockModalOpen(false);
    }
  }, [navIsMasteryTreePage, setIsMasteryUnlockModalOpen]);

  if (!subscribedTeam) return null;

  const { teamId } = subscribedTeam;
  const masteryIds = Object.keys(subscribedTeam.masteries);

  const noMasteries = () =>
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideMasteriesList: [],
          overrideSolved: Object.fromEntries(masteryIds.map((m) => [m, false])),
          overrideUnlocked: {
            [PUZ_NAME_MASTERY_TREE_PROMPT]: false,
          },
        },
      },
    });

  const allMasteries = () =>
    serverInterfaceController.sendReq({
      type: WSReqType.ADMIN_MODIFY,
      teams: {
        [teamId]: {
          overrideMasteriesList: masteryIds,
        },
      },
    });

  return (
    <Modal isOpen={isMasteryUnlockModalOpen} title="Mastery Tree Unlocks">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          marginTop: "0.5em",
          maxWidth: "40em",
        }}
      >
        <div>
          <b>Note to postsolvers:</b> This panel did not exist during the hunt.
          It is only included for your convenience to support postsolving
          individual battles.
        </div>
        <div>
          Force-activating masteries will not spoil other puzzles. Making any
          modifications to the mastery tree automatically clears forced
          activations.
        </div>
        <button
          onClick={allMasteries}
          disabled={Object.values(subscribedTeam.masteries).every(
            (m) => m.isEnabled
          )}
        >
          Force-activate all masteries
        </button>
        <button onClick={noMasteries}>Clear forced activations</button>
        <button
          onClick={() => {
            setIsMasteryUnlockModalOpen(false);
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
