import {
  Card,
  HandCard,
  Player,
  GAME_PHASE_ORDER,
  MutablePermanent,
  MutableGameState,
} from "engine/types/game-state";
import { UpdateType, Update } from "engine/types/updates";
import { SharedGameSpec, ReducerContext } from "engine/types/shared-game-specs";
import { Inspector } from "engine/Inspector";

// Contains only core game state control utilities and update
// functions. Used by both the server and client. The client uses
// this to apply updates received by the server.
//
// The client accesses Reducer functionality from immer
// by creating a new Reducer instance, calling an update
// function, then reading out the game state.
//
// Only solver-visible content should go in here. Specifically, only
// the functionality that is required to apply updates coming from
// UPDATES messages.
//
// Because Updates should be small and granular, there shouldn't
// be any game logic inside the Reducer! If an update does
// more than one thing, it probably should be split.

export class Reducer {
  gameSpec: SharedGameSpec;
  allSpecs: ReadonlyArray<SharedGameSpec>;
  gameState: MutableGameState;
  inspector: Inspector;

  constructor(
    gameSpec: SharedGameSpec,
    allSpecs: ReadonlyArray<SharedGameSpec>,
    gameState: MutableGameState
  ) {
    this.gameSpec = gameSpec;
    this.allSpecs = allSpecs;
    this.gameState = gameState;
    // The reducer doesn't have access to card data or effects.
    // This restriction ensures that updates are sufficiently
    // granular, and leaves open the possibility of the client
    // loading card data asynchronously.
    this.inspector = new Inspector(
      this.gameSpec,
      this.allSpecs,
      {},
      {},
      this.gameState
    );
  }

  /** Returns what a GameSpec can get from the Reducer. */
  makeContext(): ReducerContext {
    return {
      inspector: this.inspector,
      reducer: this,
    };
  }

  /**
   * Get the permanent with a given id, asserting its existence.
   * Unlike the corresponding Inspector method, the permanent
   * returned by this method can be mutated and should only be
   * used within the Reducer.
   */
  getPermanent(id: string): MutablePermanent {
    const permanent = this.gameState.permanents[id];
    if (permanent === undefined) {
      throw new Error(`permanent ${id} does not exist`);
    }
    return permanent;
  }

  /** Remove a card from a player's hand; throws if doesn't exist. */
  takeCardFromHand(player: Player, handCardId: string): HandCard {
    const hand = this.gameState[player].hand;
    const handIndex = hand.findIndex((handCard) => handCard.id === handCardId);
    if (handIndex === -1)
      throw new Error(`${player} has no hand card with id ${handCardId}`);
    const [card] = hand.splice(handIndex, 1);
    return card;
  }

  /**
   * Remove a card from the player's draw pile and return it.
   * If this returns null, then either the draw pile is not visible to
   * the owner of this GameState, or the draw pile is empty.
   * If spliceIndex is not provided, then pop from the top of the
   * draw pile instead.
   */
  removeFromDrawPile(player: Player, spliceIndex?: number): Card | null {
    const playerState = this.gameState[player];
    const { drawPile } = playerState;

    // Update the draw pile size, if we're keeping track of it.
    if (playerState.drawPileSize !== undefined) playerState.drawPileSize--;

    // If the draw pile is undefined, then it is not visible to the user.
    if (!drawPile) return null;
    const card = (() => {
      if (spliceIndex === undefined) return drawPile.pop();
      return drawPile.splice(spliceIndex, 1)[0];
    })();
    return card ?? null;
  }

  /** Add a card to player's discard pile. */
  addCardToDiscard(player: Player, card: Card) {
    // Only track the discard pile if we have access to it.
    const discardPile = this.gameState[player].discardPile;
    if (discardPile !== undefined) {
      discardPile.push(card);
    }
  }

  applyUpdate(upd: Update): void {
    switch (upd.type) {
      case UpdateType.OVERRIDE_STATE: {
        const { state } = upd;
        Object.keys(this.gameState).forEach((key) => {
          // @ts-expect-error: Here, we delete all the keys in the
          // current state, then copy over everything from the new
          // state. In the middle, this.gameState will not conform
          // to the GameState type, but this is okay.
          delete this.gameState[key];
        });
        Object.assign(this.gameState, state);
        break;
      }
      case UpdateType.ADVANCE_PHASE: {
        const { phase } = upd;

        if (GAME_PHASE_ORDER[phase] <= GAME_PHASE_ORDER[this.gameState.phase])
          throw new Error("cannot go backwards in phase");

        this.gameState.phase = phase;
        break;
      }
      case UpdateType.SET_GAME_RESULT: {
        const { winner, loseReason, solves, cardUnlocks, endTime } = upd;
        this.gameState.winner = winner;
        this.gameState.loseReason = loseReason;
        for (const player of [Player.P1, Player.P2]) {
          const playerState = this.gameState[player];

          // Stop any running clocks.
          const { startTurnTime } = playerState;
          if (startTurnTime !== null) {
            playerState.prevTurnsTime += endTime - startTurnTime;
            playerState.startTurnTime = null;
          }

          if (solves[player] ?? false) playerState.isSolved = true;
          if (cardUnlocks[player] !== undefined)
            playerState.cardUnlocks = cardUnlocks[player];
        }
        break;
      }
      case UpdateType.START_TURN: {
        const { player, startTurnTime } = upd;
        const playerState = this.gameState[player];
        playerState.startTurnTime = startTurnTime;
        break;
      }
      case UpdateType.END_TURN: {
        const { player, endTurnTime } = upd;
        this.gameState.currentTurnPlayer = this.inspector.getOpponentOf(player);
        this.gameState.turnNumber++;

        const playerState = this.gameState[player];
        const { startTurnTime } = playerState;
        if (startTurnTime === null)
          throw new Error("no start turn timestamp found");
        playerState.prevTurnsTime += endTurnTime - startTurnTime;
        playerState.startTurnTime = null;
        break;
      }
      case UpdateType.SET_KEYFRAME: {
        const { keyframe } = upd;
        if (keyframe === null) {
          delete this.gameState.keyframe;
        } else {
          this.gameState.keyframe = keyframe;
        }
        break;
      }
      case UpdateType.ADD_GEMS: {
        const { player, gems } = upd;

        if (this.gameSpec.aiDisableGemAccounting && player === Player.P2) break;

        this.gameState[player].gems.push(...gems);
        break;
      }
      case UpdateType.REMOVE_GEMS: {
        const { player, gems } = upd;

        if (this.gameSpec.aiDisableGemAccounting && player === Player.P2) break;

        const newGems = this.inspector.subtractGems(
          this.inspector.getPlayerGems(player),
          gems
        );
        if (newGems === null) {
          throw new Error("can't pay");
        }
        this.gameState[player].gems = newGems;
        break;
      }
      case UpdateType.CLEAR_GEMS: {
        const { player } = upd;
        this.gameState[player].gems = [];
        break;
      }
      case UpdateType.CREATE_PERMANENT: {
        const { handCardId, permanent } = upd;
        if (handCardId != undefined) {
          this.takeCardFromHand(permanent.owner, handCardId);
        }
        // Do a deep copy to enforce type safety.
        this.gameState.permanents[permanent.id] = JSON.parse(
          JSON.stringify(permanent)
        );
        break;
      }
      case UpdateType.READY_PERMANENT: {
        const { permanentId } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.ready = true;
        break;
      }
      case UpdateType.UNREADY_PERMANENT: {
        const { permanentId } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.ready = false;
        break;
      }
      case UpdateType.CHANGE_PERMANENT_OWNER: {
        const { permanentId, owner } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.owner = owner;
        break;
      }
      case UpdateType.MOVE: {
        const { permanentId, slot } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.slot = slot;
        break;
      }
      case UpdateType.SWAP: {
        const { permanent1Id, permanent2Id } = upd;
        const permanent1 = this.getPermanent(permanent1Id);
        const permanent2 = this.getPermanent(permanent2Id);
        const slot1 = permanent1.slot;
        const slot2 = permanent2.slot;
        permanent1.slot = slot2;
        permanent2.slot = slot1;
        break;
      }
      case UpdateType.DRAW_CARD: {
        const { player, card, handCardId, nextTopCard, shouldPopDrawPile } =
          upd;
        if (shouldPopDrawPile) {
          this.removeFromDrawPile(player);
        }
        const playerState = this.gameState[player];
        // Only update the top card if the player is allowed to see it.
        if (playerState.topCard !== undefined) {
          // Handle the case where the draw pile goes empty as a special
          // case, as we don't send down a topCard then.
          if (
            playerState.drawPileSize !== undefined &&
            playerState.drawPileSize === 0
          )
            playerState.topCard = null;
          else if (nextTopCard !== undefined) playerState.topCard = nextTopCard;
        }
        playerState.hand.push({ card, id: handCardId });
        break;
      }
      case UpdateType.REMOVE_FROM_DRAW_PILE: {
        const { player, drawPileIndex, nextTopCard } = upd;
        const playerState = this.gameState[player];
        this.removeFromDrawPile(player, drawPileIndex);
        // Only update the top card if the player is allowed to see it.
        if (playerState.topCard !== undefined && nextTopCard !== undefined)
          playerState.topCard = nextTopCard;
        break;
      }
      case UpdateType.DISCARD_CARD: {
        const { player, handCardId } = upd;
        this.takeCardFromHand(player, handCardId);
        break;
      }
      case UpdateType.DAMAGE_PERMANENT: {
        const { permanentId, damage } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.damage = Math.max(permanent.damage + damage, 0);
        break;
      }
      case UpdateType.REMOVE_PERMANENT: {
        const { permanentId, discard } = upd;
        const permanent = this.getPermanent(permanentId);
        delete this.gameState.permanents[permanentId];
        if (discard) {
          this.addCardToDiscard(permanent.owner, permanent.card);
        }
        break;
      }
      case UpdateType.TRANSFORM_PERMANENT: {
        const { permanentId, newCardName } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.card.name = newCardName;
        // TODO: Lower damage taken so that unit always survives?
        // this.inspector.getCounterValSum(permanent, CounterType.DAMAGE_TAKEN)
        break;
      }
      case UpdateType.ADD_COUNTER: {
        const { permanentId, counter } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.counters.push(counter);
        break;
      }
      case UpdateType.REMOVE_COUNTER: {
        const { permanentId, counterIndex } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.counters.splice(counterIndex, 1);
        break;
      }
      case UpdateType.SET_COUNTER: {
        const { permanentId, counterIndex, counterVal } = upd;
        const permanent = this.getPermanent(permanentId);
        permanent.counters[counterIndex].val = counterVal;
        if (upd.explanation) {
          permanent.counters[counterIndex].explanation = upd.explanation;
        }
        break;
      }
      case UpdateType.SET_DRAW_PILE: {
        const { player, drawPile } = upd;
        const playerState = this.gameState[player];
        if (playerState.drawPile === undefined) {
          throw new Error("player should not have access to drawPile");
        }
        playerState.drawPile = [...drawPile];
        break;
      }
      case UpdateType.SET_DEV_KNOBS: {
        this.gameState.devKnobs = upd.devKnobs;
        break;
      }
      case UpdateType.INIT_SHARED_EXTRA_STATE: {
        this.gameState.sharedExtraState = upd.sharedExtraState;
        break;
      }
      case UpdateType.MODIFY_SHARED_EXTRA_STATE: {
        const { updInfo } = upd;
        const modifySharedExtraState = this.gameSpec.modifySharedExtraState;
        if (modifySharedExtraState === undefined)
          throw new Error(
            "game spec does not support modifying shared extra state"
          );
        if (this.gameState.sharedExtraState === undefined)
          throw new Error("shared extra state not initialized");
        modifySharedExtraState(updInfo, this.gameState.sharedExtraState);
        break;
      }
      case UpdateType.MODIFY_STATS: {
        const {
          player,
          diffSummons,
          diffDestroys,
          diffDamageDealt,
          diffFactionSummons,
        } = upd;
        const { stats } = this.gameState[player];
        stats.summons += diffSummons ?? 0;
        stats.destroys += diffDestroys ?? 0;
        stats.damageDealt += diffDamageDealt ?? 0;
        stats.factionSummons += diffFactionSummons ?? 0;
        break;
      }
      case UpdateType.SET_STEP_NUMBER: {
        const { player, stepNumber } = upd;
        this.gameState[player].stepNumber = stepNumber;
        break;
      }
      default: {
        // There might be updates that don't affect game state, so
        // don't throw here.
      }
    }
  }
}

// Apply an update. Only used by the client.
export const applyUpdate = (
  gameSpec: SharedGameSpec,
  allSpecs: ReadonlyArray<SharedGameSpec>,
  gameState: MutableGameState,
  upd: Update
): void => {
  // Single-use Reducer, just used to hold
  // the context needed to apply the update.
  const reducer = new Reducer(gameSpec, allSpecs, gameState);
  reducer.applyUpdate(upd);
};
