import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedPuzzle8Spec: SharedGameSpec = {
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: ["mint", "mint", "mint", "mint", "mint"],
  },
  cardEffectsShared: {
    mint: {
      isStructure: true,
      isBase: true,
    },
    "mint--overdrive-": {
      isStructure: true,
      isBase: true,
    },
  },
};
