import { CounterType } from "engine/types/counters";
import { Check } from "engine/types/action-validation";
import { SharedGameSpec } from "engine/types/shared-game-specs";
import { COMMON_CARD_EFFECTS_SHARED } from "engine/cards/card-effects-shared";

/**
 * Common "game spec" used in all puzzles. Note that only some subset
 * of the game spec hooks are supported for the common spec.
 */
export const SharedCommonSpec: SharedGameSpec = {
  cardEffectsShared: COMMON_CARD_EFFECTS_SHARED,
  numRows: 6,
  numColumns: 5,
  extraProtectedChecks: (defender, attacker, ctx) => {
    const { inspector } = ctx;

    // PROTECTS_ADJACENT_COLUMNS counter.
    // This applies to both creatures and structures.
    if (
      inspector.getPermanents({
        owner: defender.owner,
        columnAdjacentTo: defender.slot.column,
        hasCounter: CounterType.PROTECTS_ADJACENT_COLUMNS,
      }).length > 0
    )
      return true;

    return false;
  },
  adjustMoveChecks: (failedChecks, permanent, slot, ctx) => {
    const { inspector } = ctx;

    // ALLOWS_MOVE_TO_ADJACENT counter takes priority over adjacency
    // or allied territory check
    const isSlotEnabledByAMTA =
      inspector.getPermanents({
        owner: permanent.owner,
        adjacentTo: slot,
        hasCounter: CounterType.ALLOWS_MOVE_TO_ADJACENT,
      }).length > 0;

    if (isSlotEnabledByAMTA) {
      // AMTA allows moving even to non-adjacent slots.
      failedChecks.delete(Check.ADJACENT);
    }
  },
};

export const PUZ_NAME_COMMON = "common";
