import Dropdown from "components/Dropdown";
import { PUZ_NAME_FINAL_BATTLE } from "engine/puzzles/puzzle-data";
import { useCallback, useEffect, useRef } from "react";
import { useRoomBattleGroupNameFromGameState } from "stores/ClientGlobalStateStore";
import {
  AudioLayer,
  audioLayerLabel,
  useAudioPreferencesStore,
} from "stores/UserPreferencesStore";
import "./VolumeDropdown.css";

function Slider({
  label,
  volume,
  setVolume,
  isMuted,
  setIsMuted,
}: {
  label: string;
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
}) {
  const sliderRef = useRef<HTMLDivElement>(null);

  // scroll wheel to adjust volume by 5
  const scrollListener = useCallback(
    (evt: WheelEvent) => {
      evt.preventDefault();
      const delta = evt.deltaY > 0 ? -5 : 5;

      setVolume(Math.min(100, Math.max(0, volume + delta)));
    },
    [setVolume, volume]
  );

  useEffect(() => {
    if (sliderRef.current === null) return;
    const el = sliderRef.current;
    el.addEventListener("wheel", scrollListener, {
      passive: false, // prevent scrolling
    });
    return () => {
      el.removeEventListener("wheel", scrollListener);
    };
  }, [sliderRef, scrollListener]);

  return (
    <div className="volume-slider" ref={sliderRef}>
      <label
        onClick={(e) => {
          e.stopPropagation();
          setIsMuted(!isMuted);
        }}
      >
        {label} Volume
      </label>
      <input
        type="range"
        value={volume}
        style={{ filter: isMuted ? "saturate(0)" : undefined }}
        onChange={(e) => {
          e.stopPropagation();
          setVolume(+e.target.value);
          // unmute if volume is set to non-zero
          if (isMuted && +e.target.value > 0) {
            setIsMuted(false);
          }
        }}
      />
    </div>
  );
}

function VolumeDropdown() {
  const volume = useAudioPreferencesStore((state) => state.volume);
  const setVolume = useAudioPreferencesStore((state) => state.setVolume);
  const isMuted = useAudioPreferencesStore((state) => state.isMuted);
  const setMuted = useAudioPreferencesStore((state) => state.setMuted);
  const layers = useAudioPreferencesStore((state) => state.layers);
  const setLayer = useAudioPreferencesStore((state) => state.setLayer);

  const roomBattleGroupName = useRoomBattleGroupNameFromGameState();
  const showBGMSlider = roomBattleGroupName === PUZ_NAME_FINAL_BATTLE;

  // TODO: hide BGM slider unless final puzzle
  return (
    <Dropdown
      className="volume-dropdown"
      closeOnClick={false}
      trigger={
        <button className="tabs-tab" onClick={() => setMuted(!isMuted)}>
          {isMuted ? "🔇" : volume === 0 ? "🔈" : volume < 50 ? "🔉" : "🔊"}
        </button>
      }
    >
      <p className="volume-help">Click on a label to mute.</p>
      <Slider
        label="Master"
        volume={volume}
        setVolume={setVolume}
        isMuted={isMuted}
        setIsMuted={setMuted}
      />
      {Object.values(AudioLayer).map((key) => {
        if (key === AudioLayer.BGM && !showBGMSlider) {
          return null;
        }
        return (
          <Slider
            key={key}
            label={audioLayerLabel(key)}
            volume={layers[key].volume}
            setVolume={(volume) => setLayer(key, "volume", volume)}
            isMuted={layers[key].isMuted}
            setIsMuted={(isMuted) => setLayer(key, "isMuted", isMuted)}
          />
        );
      })}
    </Dropdown>
  );
}

export default VolumeDropdown;
