import { SharedGameSpec } from "engine/types/shared-game-specs";
import { Player } from "engine/types/game-state";

export const SharedPuzzle101Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: [],
  },
};
