import { ColorSymbol } from "engine/types/card-data";
import { GemProp, GemType } from "components/GemDisplay";

export type FormatOpts = {
  ignoreKeywords?: boolean;
  /** Base form of a keyword to ignore. */
  ignoredKeyword?: string;
  lineGap?: string;
  isFinalBattle?: boolean;
};

type HelpTextRaw = string | { forms: string[]; text: string };

/**
 * Define the word, which could appear in any of the forms given. Make sure to
 * state what you're defining in the help text.
 *
 * TODO: gem?
 * TODO: more definitions, review definitions
 */
const HELP_TEXT_RAW: { [keyword: string]: HelpTextRaw } = {
  act: {
    forms: ["action", "acted", "actions", "acts"],
    text: "You can Act with a Ready Unit. Creatures can perform these Actions: Move, Create, Attack. Units may also have Flex or Special Actions.",
  },
  adjacent: "Two Spaces are Adjacent if they share an edge.",
  attack: {
    forms: ["attacked", "attacking", "attacks"],
    text: "When a Creature Attacks, it deals Damage to an Enemy Unit equal to its Power. Normally, this requires the Enemy Unit be Unprotected. You can Attack with a Ready Creature by feeding it Food from its faction.",
  },
  base: {
    forms: ["bases"],
    text: "A Base is a Unit that can’t Act. You lose if all your Bases are Destroyed.",
  },
  create: {
    forms: ["created", "creating", "creates"],
    text: "You can Create with a Ready Creature to gain a Food of its faction.",
  },
  creature: {
    forms: ["creatures"],
    text: "A Creature is a Unit that can Act.",
  },
  "current health": "Current Health is Health minus Damage.",
  damage: "When a Unit’s Damage equals or exceeds its Health, it is Destroyed.",
  damaged: "A Unit is Damaged when its Damage increases.",
  destroy: {
    forms: ["destroyed", "destroying", "destroys"],
    text: "A Unit is Destroyed when the total Damage it has taken equals or exceeds its Health. Destroyed Units are removed from their Space.",
  },
  empty: "A Space is Empty if there are no Units on it.",
  food: "Food ({R}{Y}{G}{P}{B}{W}) is used to Summon and Act. Each faction’s Creatures like a certain Food, and they can Create it and use it to Attack.",
  flex: "You can Flex a Unit during your turn by paying the listed Food cost, whether or not it’s Ready. Unlike other Actions, Flex does not Unready the Unit.",
  health: "Health is the Damage a Unit can take before it’s Destroyed.",
  immobile: "An Immobile Unit can’t Move.",
  invulnerable: "An Invulnerable Unit cannot take Damage or be Destroyed.",
  indestructable:
    "An Indestructable Unit ignores Damage that would Destroy it.",
  legendary: {
    forms: ["legendargle", "legendairy"],
    text: "Your deck can have at most one unique Legendary card.",
  },
  mobile: "A Mobile Base can Move.",
  move: "You can Move a Ready Creature to an Empty Adjacent Space.",
  obliterate: {
    forms: ["obliterates"],
    text: "Kero can use UWU to Obliterate Units, removing them from the field without triggering any additional effects.",
  },
  power: "Power is the Damage a Creature deals when it Attacks.",
  player: {
    forms: ["friendly", "enemy"],
    text: "A Friendly Unit is on your team, and Enemy Units aren’t. Each Space is also either Friendly or Enemy, depending on which half it’s on.",
  },
  protect: {
    forms: ["protects", "protected", "unprotected"],
    text: "A Unit on a Friendly Space Protects Friendly Units behind it from Units on Enemy Spaces.",
  },
  ready: {
    forms: ["readies", "unready", "unreadies"],
    text: "A Ready Unit can Act. At start of turn, each Unit becomes Ready.",
  },
  // shell:
  //   "Damage to a Unit is dealt to Shell first before Health. At start of turn, resets to maximum.",
  sneaky:
    "Units in an Enemy Space are Sneaky.\nA Sneaky Unit can Attack Protected Enemy Units, but doesn’t Protect Friendly Units behind it.",
  space: {
    forms: ["spaces"],
    text: "A Space is a location on the field where a Unit can be.",
  },
  special:
    "You can use the Special of a Ready Unit during your turn by paying the listed Food cost.",
  summon: {
    forms: ["summoned", "summons"],
    text: "You can Summon a Creature to a Friendly Space by paying its Food cost. It doesn’t start Ready.",
  },
  swap: "Two Units that are Swapped exchange the Spaces they are on.",
  touching: "Two Spaces are Touching if they share a corner or an edge.",
  unit: {
    forms: ["units", "unit's", "unit’s"],
    text: "Each card on the field is a Unit. Units are either Bases or Creatures.",
  },
  speedy: "Readies more quickly and dashes every turn.",
  poisonous: "Attacks deal additional Damage Over Time.",
};

type HelpText = {
  /** Base form of the word being defined, e.g. "unit" for "units". */
  base: string;
  /** Help text, which may contain keywords of its own. */
  text: string;
};

/** Dictionary from lowercase words to HelpText. */
export const HELP_TEXT = Object.fromEntries<HelpText>(
  Object.entries(HELP_TEXT_RAW).flatMap<[string, HelpText]>(
    ([keyword, helpText]) => {
      if (typeof helpText === "string") {
        return [[keyword, { base: keyword, text: helpText }]];
      }
      const { forms, text } = helpText;
      const value = { base: keyword, text };
      return forms
        .map<[string, HelpText]>((form) => [form, value])
        .concat([[keyword, value]]);
    }
  )
);

export enum TokenType {
  FACE,
  FLAVOR,
  GEMS,
  KEYWORD,
  RAWS,
}

export type Token =
  | { type: TokenType.FACE; raw: string }
  | { type: TokenType.FLAVOR; raw: string }
  | { type: TokenType.GEMS; gems: GemProp[] }
  | ({ type: TokenType.KEYWORD; raw: string } & HelpText)
  | { type: TokenType.RAWS; raws: string[] };

const FACE_REGEX = /(\$[^$]*\$)/g;
const FLAVOR_REGEX = /(\*[^*]*\*)/g;
const GEMS_REGEX = /(\{[^{}]*\})/g;

const parseFace = (text: string, opts: FormatOpts): Token[] => {
  const splits = text.split(FACE_REGEX);
  const result: Token[] = [];
  splits.forEach((content, i) => {
    if (i % 2 === 0) {
      result.push(...parseTokens(content, opts));
    } else {
      result.push({ type: TokenType.FACE, raw: content.slice(1, -1) });
    }
  });
  return result;
};

const parseFlavor = (text: string, opts: FormatOpts): Token[] => {
  const splits = text.split(FLAVOR_REGEX);
  const result: Token[] = [];
  splits.forEach((content, i) => {
    if (i % 2 === 0) {
      result.push(...parseTokens(content, opts));
    } else {
      result.push({ type: TokenType.FLAVOR, raw: content.slice(1, -1) });
    }
  });
  return result;
};

const GEM_TEXT_TO_GEM: { [colorSpec: string]: ColorSymbol } = {
  R: ColorSymbol.RED,
  Y: ColorSymbol.YELLOW,
  G: ColorSymbol.GREEN,
  P: ColorSymbol.PURPLE,
  W: ColorSymbol.WHITE,
  B: ColorSymbol.BLACK,
  "*": ColorSymbol.RAINBOW,
  ".": ColorSymbol.ROCK,
};

const COLOR_DECODING_TABLE: { [colorSpec: string]: ColorSymbol } = {
  R: ColorSymbol.RED,
  Y: ColorSymbol.YELLOW,
  G: ColorSymbol.GREEN,
  P: ColorSymbol.PURPLE,
  W: ColorSymbol.WHITE,
  B: ColorSymbol.BLACK,
  "(W/B)": ColorSymbol.WHITEBLACK,
  "?": ColorSymbol.RAINBOW,
  ".": ColorSymbol.ROCK,
};

export const GEM_TEXT_TO_TOOLTIP_TEXT: { [colorSpec: string]: string } = {
  // gem or cost
  R: "Boarry.",
  Y: "Honey.",
  G: "Egg.",
  P: "Flower.",
  W: "Whipped Cream.",
  B: "Butter.",
  // ---
  "W/B": "Milk. Can be paid with {B} or {W}.", // cost only
  ".": "Any food. Can be paid with {R}, {Y}, {G}, {P}, {B}, or {W}.", // cost only
  "*": "Pie. Can pay for {R}, {Y}, {G}, {P}, {B}, or {W}.", // gem only
};

const parseGemsSpec = (spec: string, opts: FormatOpts) => {
  let parseIndex = 0;
  const gems: GemProp[] = [];
  const type = spec.startsWith("-")
    ? ((spec = spec.substr(1)), GemType.CROSSED)
    : GemType.SOLID;
  while (parseIndex < spec.length) {
    let foundToken = false;
    for (const [specToken, color] of Object.entries(COLOR_DECODING_TABLE)) {
      if (spec.slice(parseIndex, parseIndex + specToken.length) == specToken) {
        gems.push({ color, type });
        parseIndex += specToken.length;
        foundToken = true;
      }
    }
    if (!foundToken) {
      throw new Error(`could not parse token from ${spec.slice(parseIndex)}`);
    }
  }
  return gems;
};

const parseGems = (text: string, opts: FormatOpts): Token[] => {
  const splits = text.split(GEMS_REGEX);
  const result: Token[] = [];
  splits.forEach((content, i) => {
    if (i % 2 === 0) {
      result.push(...parseTokens(content, opts));
    } else {
      result.push({
        type: TokenType.GEMS,
        gems: parseGemsSpec(content.slice(1, -1), opts),
      });
    }
  });
  return result;
};

const parseKeywords = (text: string, opts: FormatOpts): Token[] => {
  const splits = text.split(/(\W+)/g);
  const result: Token[] = [];

  for (let i = 0; i < splits.length; i++) {
    const content = splits[i];

    // special case for current health:
    if (
      content.toLowerCase() === "current" &&
      splits[i + 2].toLowerCase() === "health"
    ) {
      result.push({
        type: TokenType.KEYWORD,
        raw: `${content} ${splits[i + 2]}`,
        ...HELP_TEXT["current health"],
      });
      i += 2;
      continue;
    }

    // general case:
    const helpText = HELP_TEXT[content.toLowerCase()];
    if (
      helpText &&
      !(opts.ignoreKeywords ?? false) &&
      opts.ignoredKeyword !== helpText.base
    ) {
      const actualHelpText =
        helpText.base === "legendary" && opts.isFinalBattle
          ? {
              ...helpText,
              text: "Your deck can only have one unique Legendary card... for most battles. In this battle, your deck can have at most twelve unique Legendary cards.",
            }
          : helpText;
      result.push({
        type: TokenType.KEYWORD,
        raw: content,
        ...actualHelpText,
      });
    } else {
      result.push({
        type: TokenType.RAWS,
        raws: [content],
      });
    }
  }

  return result;
};

export const parseTokens = (text: string, opts: FormatOpts): Token[] => {
  if (text.match(FACE_REGEX)) {
    return parseFace(text, opts);
  }
  if (text.match(FLAVOR_REGEX)) {
    return parseFlavor(text, opts);
  }
  if (text.match(GEMS_REGEX)) {
    return parseGems(text, opts);
  }
  const acc: Token[] = [];
  for (const cur of parseKeywords(text, opts)) {
    const last = acc[acc.length - 1];
    if (
      last !== undefined &&
      last.type === TokenType.RAWS &&
      cur.type === TokenType.RAWS
    ) {
      const rest = acc.slice(0, -1);
      acc.pop();
      acc.push({
        type: TokenType.RAWS,
        raws: last.raws.concat(cur.raws),
      });
      continue;
    }
    acc.push(cur);
  }
  return acc;
};
