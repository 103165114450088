import { getKeroArtPath } from "images";
import { forwardRef, HTMLProps } from "react";
import { ImageAttrs } from "vite-plugin-image-presets";
import "./Image.css";

interface ImageProps {
  src: string | ImageAttrs[];
  pictureClassName?: string;
}

const Image = forwardRef(function Image(
  props: ImageProps & Omit<HTMLProps<HTMLImageElement>, "src">,
  ref
) {
  // eslint-disable-next-line react/prop-types
  const { src, pictureClassName, ...rest } = props;

  const allSources = (Array.isArray(src) ? src : [{ srcset: src, src }]).map(
    ({ srcset, ...rest }) => ({
      ...rest,
      srcSet: srcset,
    })
  );
  const sources = allSources.slice(0, -1);
  const lastSource = allSources[allSources.length - 1];

  return (
    <picture className={pictureClassName}>
      {sources.map((attrs, i) => (
        /* @ts-expect-error vite-plugin-image-presets */
        <source key={i} {...attrs} />
      ))}
      {/* @ts-expect-error vite-plugin-image-presets */}
      <img ref={ref} {...rest} {...lastSource} />
    </picture>
  );
});

export default Image;

export function KeroImage({
  keroLayers,
  className,
}: {
  keroLayers: string[];
  className?: string;
}) {
  return (
    <div className={`kero-image ${className}`}>
      {keroLayers.map((layer, i) => (
        <Image key={i} className="kero-layer" src={getKeroArtPath(layer)} />
      ))}
    </div>
  );
}
