import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedPuzzle4Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  aiDisableTurnCheck: true,
  basesOverrides: {
    // place bases in beforeNewGame
    [Player.P2]: [],
  },
};
