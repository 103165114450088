import { ColorSymbol, SYMBOL_INFO } from "engine/types/card-data";
import { Player, Permanent, PLAYER_SYMBOL } from "engine/types/game-state";
import { cssIdFor, UIElement } from "engine/types/keyframes";

import FormattedText from "components/FormattedText";
import { GemsDisplay, GemType } from "components/GemDisplay";
import Image from "components/Image";
import Tooltip from "components/Tooltip";
import { getCardArtPath } from "images";
import { useCardsDBStore } from "stores/CardsDBStore";
import { useInspector } from "stores/ClientGameStore";
import { useSubscribedTeam } from "stores/ClientGlobalStateStore";
import { useZoomCardStore } from "stores/ZoomCardStore";
import CardTitle from "./CardTitle";
import { CardStatDisplay, CountersDisplay } from "./StatDisplay";
import { CardSize } from "./types";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { defaultOverlayScrollbarsOptions } from "HuntLib";
import { Spinner } from "components/Loading";

interface CardTextProps {
  size: CardSize;
  cardName: string;
  permanent?: Permanent;
}

function CardTextDisplay(props: CardTextProps) {
  const { size, cardName, permanent } = props;
  const inspector = useInspector();

  if (size !== CardSize.LARGE) return null;

  return (
    <div className="card-text">
      <FormattedText
        text={
          permanent === undefined
            ? inspector.getCardText(cardName)
            : inspector.getCardText(permanent)
        }
      />
    </div>
  );
}

interface CardFooterProps {
  cardName: string;
  permanent?: Permanent;
  size: CardSize;
  showDelta?: boolean;
}

function CardFooter(props: CardFooterProps) {
  const { cardName, permanent, size, showDelta } = props;

  return (
    <div className="card-footer">
      {size === CardSize.SMALL ? null : (
        <CountersDisplay permanent={permanent} />
      )}
      <div className="card-stats">
        <CardStatDisplay
          eltId={
            permanent === undefined
              ? undefined
              : cssIdFor.permanent(permanent, UIElement.POWER)
          }
          type="power"
          cardName={cardName}
          permanent={permanent}
          showDelta={showDelta}
        />
        <CardStatDisplay
          eltId={
            permanent === undefined
              ? undefined
              : cssIdFor.permanent(permanent, UIElement.HEALTH)
          }
          type="health"
          cardName={cardName}
          permanent={permanent}
          showDelta={showDelta}
        />
      </div>
    </div>
  );
}

// Sounds like we probably won't be using this, so temp disable.
function KeroDosCardImage() {
  const subscribedTeam = useSubscribedTeam();

  if (subscribedTeam === null) return null;

  // const { cardUnlocks } = subscribedTeam;
  // const keroLayers = [
  //   ...getKeroLayers(Object.keys(cardUnlocks))
  //     .reverse()
  //     .map((layer) => getKeroArtPath(layer)),
  //   getCardArtPath("kero-dos"),
  // ];

  return (
    <div
      className="card-image"
      style={
        {
          // background: keroLayers
          //   .map((layer) => `url("${layer}") center / contain no-repeat`)
          //   .join(", "),
        }
      }
    ></div>
  );
}

interface CardArtProps {
  cardName: string;
  permanent?: Permanent;
  size: CardSize;
}

function CardArt(props: CardArtProps) {
  const { cardName, size } = props;
  const { cardsDB } = useCardsDBStore();
  const cardData = cardsDB[cardName];

  if ([CardSize.SMALL, CardSize.SMALL_FIELD].includes(size)) return null;

  // Sounds like we probably won't be using this, so temp disable.
  // if (cardData.name === "kero-dos") return <KeroDosCardImage />;

  // find cardname.png in images/card-art/ if it exists,
  // otherwise use placeholder
  return <Image className="card-image" src={getCardArtPath(cardData.name)} />;
}

interface CardMainContentsProps {
  cardName: string;
  permanent?: Permanent;
  isOwner: boolean;
  isSneaky: boolean;
  size: CardSize;
  disableScroll: boolean;
}

function CardMainContents(props: CardMainContentsProps) {
  const { size, cardName, permanent, isOwner, isSneaky, disableScroll } = props;
  const { cardsDB } = useCardsDBStore();
  const cardData = cardsDB[cardName];
  const contents = (
    <>
      {size === CardSize.SMALL || !permanent ? null : (
        <div className="card-owner">
          <Tooltip
            text={`${isOwner ? "Friendly" : "Enemy"}.${
              permanent.ready ? "\nReady." : ""
            }${isSneaky ? "\nSneaky." : ""}`}
            format={true}
          >
            {
              // HACK: Now that the player perspective is always at
              // the bottom, it is technically wrong to be indexing
              // by Player (rather we should be indexing top vs. bottom).
              // Just keep using Player to avoid making a new enum.
              PLAYER_SYMBOL[isOwner ? Player.P1 : Player.P2][
                isSneaky
                  ? permanent.ready
                    ? "sneaky-dark"
                    : "sneaky-light"
                  : permanent.ready
                  ? "dark"
                  : "light"
              ]
            }
          </Tooltip>
        </div>
      )}
      {size === CardSize.NORMAL || size === CardSize.SMALL_FIELD ? null : (
        <div className="card-cost">
          <GemsDisplay
            gems={cardData.cost.map((c) => ({
              color: c,
              type: GemType.SOLID,
            }))}
          />
        </div>
      )}
      <CardTitle title={cardData.displayName} />
      <CardArt cardName={cardName} size={size} />
      <CardTextDisplay size={size} cardName={cardName} permanent={permanent} />
    </>
  );
  return size === CardSize.LARGE ? (
    <OverlayScrollbarsComponent
      options={{
        ...defaultOverlayScrollbarsOptions,
        overflow: { y: disableScroll ? "visible" : "scroll" },
      }}
      defer
      className="card-main-contents"
      style={{
        overflowY: disableScroll ? "hidden" : undefined,
      }}
    >
      {contents}
    </OverlayScrollbarsComponent>
  ) : (
    <div className="card-main-contents">{contents}</div>
  );
}

interface FaceUpCardProps {
  cardName: string;
  // The permanent associated with this card, if any.
  // (If it doesn't exist, the card can be in hand or in deck.)
  permanent?: Permanent;
  isOwner: boolean;
  isSneaky: boolean;
  size: CardSize;
  highlight: boolean;
  keyboardFocus: boolean;
  canZoom: boolean;
  showCost: boolean;
  /** Whether the card's owner, if any, has turn pending. */
  isOwnerTurnPending: boolean;
  /** Whether the permanent has actions (distinct from being ready). */
  hasActions: boolean;
  /** If set, disables scroll on large cards. */
  disableScroll: boolean;
  /** If set, disables zoom on hover. */
  disableHoverZoom: boolean;
  showDelta: boolean;
}

const defaultProps = {
  isOwner: false,
  isSneaky: false,
  highlight: false,
  keyboardFocus: false,
  canZoom: true,
  showCost: true,
  isOwnerTurnPending: false,
  hasActions: true,
  disableScroll: false,
  disableHoverZoom: false,
  showDelta: false,
};

function FaceUpCardDisplay(props: FaceUpCardProps) {
  const {
    cardName,
    permanent,
    isOwner,
    isSneaky,
    size,
    highlight,
    keyboardFocus,
    canZoom,
    showCost,
    isOwnerTurnPending,
    hasActions,
    disableScroll,
    disableHoverZoom,
    showDelta,
  } = props;
  const setZoomedCard = useZoomCardStore((state) => state.setMouseZoomedCard);
  const resetZoomedCard = useZoomCardStore(
    (state) => state.resetMouseZoomedCard
  );
  const { cardsDB } = useCardsDBStore();

  const cardData = cardsDB[cardName];
  if (!cardData)
    return (
      <div>
        <Spinner inline={true} />
      </div>
    );

  const color = cardData.color ?? ColorSymbol.ROCK;
  const background = SYMBOL_INFO[color].color(700);
  // spread black more since it contrasts less with the background
  const spread = color === ColorSymbol.BLACK ? 2 : 1;
  const borderColor = SYMBOL_INFO[color].color(
    color === ColorSymbol.YELLOW ? 600 : 400
  );

  // outline for cards that are ready to Act
  const boxShadow = highlight
    ? "0 0 0 3px var(--yellow-400)"
    : keyboardFocus
    ? "0 0 0 3px rgba(236, 201, 75, .6)"
    : !permanent || !hasActions
    ? ""
    : isOwnerTurnPending
    ? `0 0 4px ${spread * 2}px ${borderColor}`
    : `0 0 3px ${spread}px ${borderColor}`;

  const filter = !permanent || permanent.ready ? "" : "grayscale(0.8)";

  return (
    <div className="game-card-container">
      {size !== CardSize.NORMAL || !showCost ? null : (
        <div className="card-cost-floating">
          <GemsDisplay
            gems={cardData.cost.map((c) => ({ color: c, type: GemType.SOLID }))}
          />
        </div>
      )}
      <div
        className={`game-card ${size}`}
        style={{ background, borderColor, boxShadow, filter }}
        onMouseEnter={() => {
          if (!disableHoverZoom && canZoom) {
            setZoomedCard(cardName, permanent?.id);
          }
        }}
        onMouseLeave={() => {
          if (!disableHoverZoom && canZoom) {
            resetZoomedCard();
          }
        }}
      >
        <CardMainContents
          cardName={cardName}
          permanent={permanent}
          isOwner={isOwner}
          isSneaky={isSneaky}
          size={size}
          disableScroll={disableScroll}
        />
        <CardFooter
          cardName={cardName}
          permanent={permanent}
          size={size}
          showDelta={showDelta}
        />
      </div>
    </div>
  );
}
FaceUpCardDisplay.defaultProps = defaultProps;

export default FaceUpCardDisplay;
