export type AfterDisconnectEventData = {
  /**
   * Number of times we have tried reconnecting so far, not including
   * the next one.
   */
  numReconnections: number;
  /** Number of seconds before we reconnect. */
  reconnectDelayInSeconds: number;
};

export type ServerInterfaceCallbacks = {
  /**
   * Called whenever a new connection is opened. This includes
   * when a connection is opened due to a reconnection.
   */
  onOpen: () => void;
  onResp: (msg: string) => void;
  /**
   * Called whenever we get disconnected. The event data contains
   * information about when we plan to reconnect.
   */
  afterDisconnect: (evData: AfterDisconnectEventData) => void;
};

export enum ServerInterfaceType {
  MAIN = "main",
  CURSOR = "cursor",
}

// Common interface for the local or remote engine.
export interface ServerInterface {
  init: (callbacks: ServerInterfaceCallbacks) => void;
  send: (msg: string) => void;
  reconnect: () => void;
  close: () => void;
  // Only used for mock servers.
  resetServer?: () => void;
}

export class NullServerInterface {
  init() {
    return;
  }
  send() {
    return;
  }
  reconnect() {
    return;
  }
  close() {
    return;
  }
}
