import { Player, Slot } from "engine/types/game-state";
import {
  InspectorContext,
  SharedGameSpec,
} from "engine/types/shared-game-specs";
import { Check, FailedChecks } from "engine/types/action-validation";
import { BOARD_SIZE } from "./puzzle-93";

export const SharedPuzzle93Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  numRows: BOARD_SIZE,
  numColumns: BOARD_SIZE,

  basesOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },

  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },

  cardEffectsShared: {
    "light-piece": {
      adjustSummonChecks: pieceSummonChecker,
    },
    "dark-piece": {
      adjustSummonChecks: pieceSummonChecker,
    },
  },
};

function pieceSummonChecker(
  failedChecks: FailedChecks,
  player: Player,
  cardName: string,
  slot: Slot,
  ctx: InspectorContext
) {
  failedChecks.delete(Check.TERRAIN);
  const { inspector } = ctx;
  for (const otherPerm of inspector.getAllPermanents()) {
    if (isAdjacentSlot(slot, otherPerm.slot)) {
      // Success! Don't add any FailedChecks
      return;
    }
  }
  failedChecks.add(Check.GENERIC);
}

function isAdjacentSlot(slot1: Slot, slot2: Slot) {
  return (
    Math.abs(slot1.column - slot2.column) <= 1 &&
    Math.abs(slot1.row - slot2.row) <= 1 &&
    !isEqualSlot(slot1, slot2)
  );
}

function isEqualSlot(slot1: Slot, slot2: Slot) {
  return slot1.column === slot2.column && slot1.row === slot2.row;
}
