import "./ColorSymbolIcon.css";
import { ColorSymbol, SYMBOL_INFO } from "engine/types/card-data";
import Icon, { IconType } from "./Icon";
import { forwardRef, HTMLProps } from "react";

const SYMBOL_ICONS = {
  [ColorSymbol.RED]: IconType.BOARRY,
  [ColorSymbol.YELLOW]: IconType.HONEY,
  [ColorSymbol.GREEN]: IconType.EGG,
  [ColorSymbol.PURPLE]: IconType.FLOWER,
  [ColorSymbol.WHITE]: IconType.CREAM,
  [ColorSymbol.BLACK]: IconType.BUTTER,
  [ColorSymbol.WHITEBLACK]: IconType.MILK,
  [ColorSymbol.ROCK]: IconType.ROCK,
  [ColorSymbol.RAINBOW]: IconType.PIE,
} as const;

interface ColorSymbolIconProps {
  symbol?: ColorSymbol;
  text?: string;
  crossed?: boolean;
  dashed?: boolean;
}

const ColorSymbolIcon = forwardRef<
  HTMLDivElement,
  ColorSymbolIconProps & HTMLProps<HTMLDivElement>
>(function ColorSymbolIcon(props, ref) {
  const {
    symbol = ColorSymbol.RAINBOW,
    crossed = false,
    dashed = false,
    text: propsText,
    ...domProps
  } = props;
  const {
    lightColor,
    darkColor,
    text: symbolText,
  } = symbol === ColorSymbol.BLACK
    ? {
        ...SYMBOL_INFO[symbol],
        // black's color symbol is reversed
        lightColor: SYMBOL_INFO[symbol].darkColor,
        darkColor: SYMBOL_INFO[symbol].lightColor,
      }
    : SYMBOL_INFO[symbol];
  const icon = SYMBOL_ICONS[symbol];

  const text = propsText ?? symbolText;
  const scale = text.length === 1 ? 0.8 : 1.3 / text.length;
  const style: React.CSSProperties = {
    fontSize: `${scale}em`,
    height: `${1 / scale}em`,
    width: `${1 / scale}em`,
  };

  if (dashed) {
    style.background = "transparent";
    style.color = lightColor;
    style.border = `2px dashed ${lightColor}`;
  } else {
    style.background = darkColor;
    style.color = darkColor;
  }

  return (
    <div
      className={`color-symbol ${crossed ? "crossed" : ""} ${
        dashed ? "dashed" : ""
      }`}
      style={style}
      ref={ref}
      {...domProps}
    >
      <Icon color={lightColor} icon={icon} />
    </div>
  );
});

export default ColorSymbolIcon;
