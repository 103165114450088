import CardDisplay, { CardSize } from "components/CardDisplay";
import ALL_CARDS from "engine/cards/all-cards-client";
import { makeCardsDB } from "engine/cards/CardsDB";
import Globals from "Globals";
import { useEffect } from "react";
import { useCardsDBStore } from "stores/CardsDBStore";
import { useSubscribedTeam } from "stores/ClientGlobalStateStore";
import "./SolvePageCardUnlocks.css";

const SolvePageCardUnlocks = () => {
  const { puzName } = Globals;
  if (puzName === undefined) throw new Error("expect puzName to be defined");
  const subscribedTeam = useSubscribedTeam();
  const cardsDB = useCardsDBStore((state) => state.cardsDB);
  const setCardsDB = useCardsDBStore((state) => state.setCardsDB);

  useEffect(() => {
    setCardsDB(makeCardsDB(ALL_CARDS));
  }, [setCardsDB]);

  if (subscribedTeam === null)
    throw new Error("expect subscribed team to be available");

  const { cardUnlocks, puzzles } = subscribedTeam;
  const cardUnlocksForPuzzle = Object.keys(cardUnlocks).filter((cardName) => {
    const { puzName: unlockPuzName } = cardUnlocks[cardName];
    if (unlockPuzName === null) return false;
    const { battleGroupName: unlockBattleGroupName } = puzzles[unlockPuzName];
    return unlockBattleGroupName === puzName;
  });

  // If there are no card unlocks, don't render anything.
  if (cardUnlocksForPuzzle.length === 0) return null;

  const cardUnlocksDom = cardUnlocksForPuzzle.map((cardName) => {
    return (
      <CardDisplay
        key={cardName}
        cardName={cardName}
        size={CardSize.LARGE}
        canZoom={false}
      />
    );
  });
  return (
    <>
      <h4>Friends found</h4>
      <div className="solve-page-card-unlocks">{cardUnlocksDom}</div>
    </>
  );
};

export default SolvePageCardUnlocks;
