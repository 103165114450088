import { z } from "zod";
import { CardsDB } from "engine/cards/CardsDB";

/**
 * Color symbols, for the purposes of rendering. The allowed gem and cost
 * colors are respectively subsets of these possible values.
 */
export enum ColorSymbol {
  RED = "R",
  YELLOW = "Y",
  GREEN = "G",
  PURPLE = "P",
  WHITE = "W",
  BLACK = "B",
  RAINBOW = "*",
  WHITEBLACK = "W/B",
  ROCK = ".",
}

export const PureColorZod = z.union([
  z.literal(ColorSymbol.RED),
  z.literal(ColorSymbol.YELLOW),
  z.literal(ColorSymbol.GREEN),
  z.literal(ColorSymbol.PURPLE),
  z.literal(ColorSymbol.WHITE),
  z.literal(ColorSymbol.BLACK),
]);
export type PureColor = z.infer<typeof PureColorZod>;

/** Card colors. Gem and cost symbols can always be card colors. */
export const CardColorZod = z.union([
  PureColorZod,
  z.literal(ColorSymbol.WHITEBLACK),
]);
export type CardColor = z.infer<typeof CardColorZod>;

/**
 * A GemColor is a gem available to pay for an action. It is also used
 * to represent a card's color.
 * Larger number = more general symbol.
 */
export const GemColorZod = z.union([
  PureColorZod,
  /** Can act as any color. */
  z.literal(ColorSymbol.RAINBOW),
]);
export type GemColor = z.infer<typeof GemColorZod>;

/**
 * A CostColor is part of a card's cost, where it can be paid for with
 * any matching gem.
 * Larger number = more general symbol.
 */
export const CostColorZod = z.union([
  PureColorZod,
  z.literal(ColorSymbol.WHITEBLACK),
  /** Can be paid for with any gem. */
  z.literal(ColorSymbol.ROCK),
]);
export type CostColor = z.infer<typeof CostColorZod>;

/**
 * Gem colors that you can create for each card color, if not just
 * the card color itself.
 */
export const getDefaultCreateGemsColors = (
  cardColor: CardColor
): GemColor[][] => {
  switch (cardColor) {
    case ColorSymbol.WHITEBLACK:
      return [[ColorSymbol.WHITE], [ColorSymbol.BLACK]];
    default:
      return [[cardColor]];
  }
};

/** Can this gem pay for this cost? */
export const canGemPayFor = (gem: GemColor, cost: CostColor): boolean => {
  if (gem === cost) return true;
  if (gem === ColorSymbol.RAINBOW) return true;
  if (cost === ColorSymbol.ROCK) return true;
  if (
    cost === ColorSymbol.WHITEBLACK &&
    (gem === ColorSymbol.BLACK || gem === ColorSymbol.WHITE)
  )
    return true;
  return false;
};

/** Text, light color, and dark color for each symbol. */
export const SYMBOL_INFO = {
  [ColorSymbol.RED]: {
    text: "R",
    lightColor: "var(--red-200)",
    darkColor: "var(--red-800)",
    color: (shade: number) => `var(--red-${shade})`,
  },
  [ColorSymbol.YELLOW]: {
    text: "Y",
    lightColor: "var(--yellow-200)",
    darkColor: "var(--yellow-800)",
    color: (shade: number) => `var(--yellow-${shade})`,
  },
  [ColorSymbol.GREEN]: {
    text: "G",
    lightColor: "var(--green-200)",
    darkColor: "var(--green-800)",
    color: (shade: number) => `var(--green-${shade})`,
  },
  [ColorSymbol.PURPLE]: {
    text: "P",
    lightColor: "var(--purple-300)",
    darkColor: "var(--purple-800)",
    color: (shade: number) => `var(--purple-${shade})`,
  },
  [ColorSymbol.WHITE]: {
    text: "W",
    lightColor: "var(--gray-200)",
    darkColor: "var(--gray-600)",
    color: (shade: number) => `var(--gray-${Math.max(shade - 200, 100)})`,
  },
  [ColorSymbol.BLACK]: {
    text: "B",
    lightColor: "var(--gray-400)",
    darkColor: "var(--gray-800)",
    color: (shade: number) => `var(--gray-${Math.min(shade + 200, 900)})`,
  },
  [ColorSymbol.WHITEBLACK]: {
    text: "W/B",
    lightColor: "linear-gradient(135deg, #1a202c, #ccc2c0)", // hard-coded into svg, this isn't used
    darkColor: "linear-gradient(95deg, #bba5b6, #644f5d)",
    color: (shade: number) => `var(--gray-${shade})`,
  },
  [ColorSymbol.ROCK]: {
    text: "•",
    lightColor: "var(--cold-gray-400)",
    darkColor: "var(--cold-gray-800)",
    color: (shade: number) => `var(--cold-gray-${shade})`,
  },
  [ColorSymbol.RAINBOW]: {
    text: "★",
    lightColor: "var(--primary-400)",
    darkColor: "var(--primary-800)",
    color: (shade: number) => `var(--primary-${shade})`,
  },
} as const;

export enum CardType {
  CREATURE = "creature",
  STRUCTURE = "structure",
}

/** All cards are either creatures or structures. */
/** A card not in the field. Persists across games. */
export type CardData = {
  // Unique card name.
  name: string;
  // Name displayed on the card.
  displayName: string;
  // Text displayed in the body of the card.
  text: string;
  cost: CostColor[];
  power: number;
  shell: number;
  maxHealth: number;
  /**
   * A card's color. This serves as both the gem color (or colors, for
   * W/B) that can be created by the card, and its attack cost.
   */
  color: CardColor | null;
  capturable: boolean;
  implemented: boolean;
  /** The unlock group that this card belongs to. */
  cardUnlockGroupId: string | null;
  // TODO: does the CardData specify a card's special actions?
};

const WEIRD_CARD_NAMES = [
  "master-bramble",
  "kero--floppy",
  "professor-galactic",
  "stalactica",
  "test-card",
];

export const getUnlockableCards = (cardsDB: CardsDB) => {
  return Object.keys(cardsDB)
    .filter((cardName) => cardsDB[cardName].cardUnlockGroupId !== null)
    .filter((cardName) => !WEIRD_CARD_NAMES.includes(cardName));
};
