import { CounterType } from "engine/types/counters";
import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";
import { CardEffectsShared } from "engine/cards/card-effects-shared";
import {
  NUM_LEGENDARIES,
  PUZ_NAME_POST_FINAL_BATTLE,
} from "engine/puzzles/puzzle-data";

export type Puzzle76SharedExtraState = {
  keroPhase: number;
};

const textEffects: CardEffectsShared = {
  computeText: (permanent, ctx) => {
    const { inspector } = ctx;
    const hasDoom = inspector.doesPermanentHaveCounterType(
      permanent,
      CounterType.DOOM
    );
    const doom = hasDoom
      ? inspector.getCounterValSum(permanent, CounterType.DOOM) + 1
      : 0;
    return inspector
      .getCardData(permanent)
      .text.split("\n")
      .map((line) => {
        if (
          line.startsWith("After") &&
          line.endsWith("Destroy all Enemy Units.")
        )
          return `After ${doom} ${
            doom === 1 ? "turn" : "turns"
          }, Destroy all Enemy Units.`;
        return line;
      })
      .join("\n");
  },
};

export const SharedPuzzle76Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: ["kero-dos"],
  },
  maxNumLegendaries: NUM_LEGENDARIES,
  cardEffectsShared: {
    "kero-dos": {
      isBase: true,
      isStructure: true,
    },
    "dinosaur-tail": textEffects,
    "boar-tusks": textEffects,
    "dryad-horns": textEffects,
    "cow-udder": textEffects,
    "bee-wings": textEffects,
    kero: textEffects,
    // TESTING:
    // "dinosaur-tail": {
    //   maxHealth: 3,
    // },
    // "boar-tusks": {
    //   maxHealth: 3,
    // },
  },
  modifySharedExtraState(updInfo, sharedExtraState) {
    Object.assign(
      sharedExtraState as Puzzle76SharedExtraState,
      updInfo as Puzzle76SharedExtraState
    );
  },
  endCutsceneRedirect: `/cutscene/${PUZ_NAME_POST_FINAL_BATTLE}/start`,
};
