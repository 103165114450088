import { Deck } from "engine/types/decks";
import {
  CardData,
  CardType,
  ColorSymbol,
  GemColor,
  CostColor,
  CardColor,
  getDefaultCreateGemsColors,
} from "engine/types/card-data";
import {
  Card,
  HandCard,
  Permanent,
  CardLike,
  Slot,
  Player,
  DevKnobs,
  GamePhase,
  GAME_PHASE_ORDER,
  GameState,
  areSlotsAdjacent,
  getSlotsEuclideanDistance,
  isCheckpointSlotValid,
} from "engine/types/game-state";
import { CounterType, ValueCounterType } from "engine/types/counters";
import {
  AbilityType,
  EffectOptType,
  EffectOpt,
  EffectOptResolved,
  EffectOptForm,
  EffectOptValidationContext,
} from "engine/types/effects";
import { Step, StepResolved, StepType, stepToPlayer } from "engine/types/steps";
import { Role, canRoleControlPlayer } from "engine/types/updates";
import { Check, FailedChecks } from "engine/types/action-validation";
import {
  SharedGameSpec,
  StaticInspectorContext,
  InspectorContext,
} from "engine/types/shared-game-specs";
import { calcFactionScore } from "engine/types/factions";
import {
  CardEffectsShared,
  validateEffectOpt,
  SharedEffectsDB,
} from "engine/cards/card-effects-shared";
import { CardsDB } from "engine/cards/CardsDB";
import { DRAW_CARD_MASTERY_ID } from "engine/puzzles/mastery-data";
import { PermanentQuery, queryPermanents } from "engine/PermanentQuery";
import { StepMaker } from "engine/StepMaker";
import { validateDeck } from "game-server/deckbuilding";
import { getAllSlots } from "engine/types/game-state";

/**
 * Card or game spec related utility functions. Does not mutate.
 * Used to inspect properties that do not depend on game state.
 * For now, anything here should work nicely with multiple game specs.
 * This is because there may not be a "primary" game spec in contexts
 * like deckbuilding.
 */
export class StaticInspector {
  cardsDB: CardsDB;
  effectsDB: SharedEffectsDB;
  allSpecs: ReadonlyArray<SharedGameSpec>;

  constructor(
    allSpecs: ReadonlyArray<SharedGameSpec>,
    cardsDB: CardsDB,
    effectsDB: SharedEffectsDB
  ) {
    this.allSpecs = allSpecs;
    this.cardsDB = cardsDB;
    this.effectsDB = effectsDB;
  }

  /** Returns what a GameSpec can get from the Inspector. */
  makeContext(): StaticInspectorContext {
    return {
      inspector: this,
    };
  }

  /** Whether we have an AI. */
  hasAI(): boolean {
    return this.allSpecs.some((spec) => spec.hasAI ?? false);
  }

  getDefaultRole(): Role {
    if (this.hasAI()) return Role.P1;
    return Role.GOD;
  }

  isNoDeckAllowed(): boolean {
    for (const spec of this.allSpecs.slice().reverse()) {
      if (spec.allowNoDeck ?? false) return true;
    }
    return false;
  }

  areCheckpointsAllowed(): boolean {
    return this.allSpecs.some((spec) => spec.allowCheckpoints ?? false);
  }

  getDeckOverride(player: Player): ReadonlyArray<string> | null {
    for (const spec of this.allSpecs.slice().reverse()) {
      const deckOverride = spec.deckOverrides?.[player] ?? null;
      if (deckOverride !== null) return deckOverride;
    }

    // AIs should always have an override deck. If none is supplied,
    // use an empty deck by default.
    if (this.hasAI() && player === Player.P2) return [];

    return null;
  }

  getMaxNumCards(): number {
    return 30;
  }

  getMaxNumLegendaries(): number {
    for (const { maxNumLegendaries } of this.allSpecs) {
      if (maxNumLegendaries !== undefined) return maxNumLegendaries;
    }
    return 1;
  }

  validateDeck(failedChecks: FailedChecks, player: Player, deck: Deck) {
    const maxNumCards = this.getMaxNumCards();
    const maxNumLegendaries = this.getMaxNumLegendaries();
    if (deck !== undefined)
      validateDeck(failedChecks, deck, maxNumCards, maxNumLegendaries);

    for (const spec of this.allSpecs) {
      if (spec.adjustDeckChecks) {
        spec.adjustDeckChecks(
          failedChecks,
          player,
          deck ?? null,
          this.makeContext()
        );
      }
    }
  }

  getCardName(arg: CardLike): string {
    if (typeof arg === "string") return arg;
    // If `card` exists, then this is a HandCard or Permanent.
    if (arg.card !== undefined) return this.getCardName(arg.card);
    // If `displayName` exists, then this is a CardData.
    if (arg.displayName !== undefined) return arg.name;
    // Otherwise, this is a Card.
    if (arg.name === undefined) {
      throw new Error("card not known");
    }
    return arg.name;
  }

  getCardData(arg: CardLike): CardData {
    const name = typeof arg === "string" ? arg : this.getCardName(arg);
    return this.cardsDB[name];
  }

  getSharedEffects(arg: CardLike): CardEffectsShared {
    const effects = this.effectsDB[this.getCardName(arg)];
    return effects ? effects : {};
  }

  isLegendary(arg: CardLike): boolean {
    return this.getSharedEffects(arg).isLegendary ?? false;
  }
}

/** Utilities to inspect game state. Does not mutate. */
export class Inspector {
  cardsDB: CardsDB;
  effectsDB: SharedEffectsDB;
  gameSpec: SharedGameSpec;
  allSpecs: ReadonlyArray<SharedGameSpec>;
  gameState: GameState;
  staticInspector: StaticInspector;

  constructor(
    gameSpec: SharedGameSpec,
    allSpecs: ReadonlyArray<SharedGameSpec>,
    cardsDB: CardsDB,
    effectsDB: SharedEffectsDB,
    gameState: GameState
  ) {
    this.gameSpec = gameSpec;
    this.allSpecs = allSpecs;

    this.cardsDB = cardsDB;
    this.effectsDB = effectsDB;
    this.gameState = gameState;
    this.staticInspector = new StaticInspector(
      this.allSpecs,
      this.cardsDB,
      this.effectsDB
    );
  }

  /** Returns what a GameSpec can get from the Inspector. */
  makeContext(): InspectorContext {
    return {
      inspector: this,
    };
  }

  /** Check if player has mastery masteryId enabled. */
  doesPlayerHaveMastery(player: Player, masteryId: string): boolean {
    return (
      this.gameState[player].teamData?.enabledMasteries[masteryId] ?? false
    );
  }

  /** Get the number of rows on the board. */
  getNumRows(): number {
    for (const spec of this.allSpecs.slice().reverse()) {
      if (spec.numRows !== undefined) return spec.numRows;
    }
    throw new Error("no definition for num rows");
  }

  /** Get the number of columns on the board. */
  getNumColumns(): number {
    for (const spec of this.allSpecs.slice().reverse()) {
      if (spec.numColumns !== undefined) return spec.numColumns;
    }
    throw new Error("no definition for num columns");
  }

  getSlotBackground(slot: Slot): string {
    if (this.gameSpec.getSlotBackground) {
      return this.gameSpec.getSlotBackground(slot, this.makeContext());
    }
    return "";
  }

  isNoDeckAllowed(): boolean {
    return this.staticInspector.isNoDeckAllowed();
  }

  getDeckOverride(player: Player): ReadonlyArray<string> | null {
    return this.staticInspector.getDeckOverride(player);
  }

  getBasesOverride(player: Player): ReadonlyArray<string> | null {
    return this.gameSpec.basesOverrides?.[player] ?? null;
  }

  getDefaultBases(player: Player): ReadonlyArray<string> {
    return this.getBasesOverride(player) ?? ["camp", "camp", "camp"];
  }

  getBasePositions(player: Player, numBases: number): ReadonlyArray<number> {
    const baseColumnOverride = this.gameSpec.baseColumnOverrides?.[player];
    if (baseColumnOverride !== undefined) {
      if (baseColumnOverride.length !== numBases)
        throw new Error("base column override has wrong number of columns");
      return baseColumnOverride;
    }

    const width = this.getNumColumns();
    if (numBases > width) throw new Error("too many bases");
    if (numBases == 1) return [Math.floor((width - 1) / 2)]; // center position
    // bases are distributed as evenly as possible, with the first base going on
    // the far left and the last base going on the far right
    const baseColumns: number[] = [];
    for (let i = 0; i < numBases; i++) {
      baseColumns.push(Math.round((i * (width - 1)) / (numBases - 1)));
    }
    return baseColumns;
  }

  getEndCutsceneRedirect(): string | null {
    for (const { endCutsceneRedirect } of this.allSpecs) {
      if (endCutsceneRedirect !== undefined) return endCutsceneRedirect;
    }
    return null;
  }

  getEndCutsceneRedirectDjango(): string | null {
    for (const { endCutsceneRedirectDjango } of this.allSpecs) {
      if (endCutsceneRedirectDjango !== undefined)
        return endCutsceneRedirectDjango;
    }
    return null;
  }

  /**
   * Check if the game has started. If the game has not started, the
   * client should still be in the "prep for battle" screen.
   */
  hasGameStarted(): boolean {
    return (
      GAME_PHASE_ORDER[this.gameState.phase] >=
      GAME_PHASE_ORDER[GamePhase.ACTIVE]
    );
  }

  /**
   * Check if the game is active, meaning that it can receive
   * steps for player game actions.
   */
  isGameActive(): boolean {
    return this.gameState.phase === GamePhase.ACTIVE;
  }

  /**
   * Check if the game has ended, meaning that the end modal
   * can be shown.
   */
  hasGameEnded(): boolean {
    return (
      GAME_PHASE_ORDER[this.gameState.phase] >=
      GAME_PHASE_ORDER[GamePhase.ENDED]
    );
  }

  getSpeedrunTime(): number {
    if (!this.hasGameEnded())
      throw new Error(
        "getting speedrun time not supported before the game has ended"
      );
    const p1State = this.gameState[Player.P1];
    const p2State = this.gameState[Player.P2];
    if (p1State.startTurnTime !== null || p2State.startTurnTime !== null)
      throw new Error(
        "expect start turn times to be unset once game has ended"
      );

    // Currently, speedrun times are only defined for single-player games,
    // so we just include the time from both players.
    return p1State.prevTurnsTime + p2State.prevTurnsTime;
  }

  calcFactionScore(player: Player, isPvP: boolean, isSolved: boolean): number {
    if (!isPvP && (!isSolved || player !== Player.P1)) return 0;
    const playerState = this.gameState[player];
    const winner = this.gameState.winner;
    const { stats } = playerState;
    return calcFactionScore(stats, isPvP, winner === player);
  }

  /**
   * Get the current turn number. This counts turns from both players.
   * It starts from 0 and increments every time a turn is ended.
   */
  getTurnNumber(): number {
    return this.gameState.turnNumber;
  }

  /** Get the opponent of a player. */
  getOpponentOf(player: Player): Player {
    return player === Player.P1 ? Player.P2 : Player.P1;
  }

  /**
   * Returns a card's name, throws if not known.
   * A card's name is a unique identifier for the card, and is
   * distinct from its display name.
   */
  getCardName(card: CardData | Card): string;

  /**
   * Returns a permanent's card name, throws if not known.
   * A card's name is a unique identifier for the card, and is
   * distinct from its display name, or the permanent's ID.
   */
  getCardName(permanent: Permanent): string;

  getCardName(arg: CardLike): string;

  getCardName(arg: CardLike): string {
    return this.staticInspector.getCardName(arg);
  }

  /**
   * Returns a card or permanent's card data, throws if not known.
   * If a string is passed in, it is interpreted as a card name.
   */
  getCardData(arg: CardLike): CardData {
    return this.staticInspector.getCardData(arg);
  }

  /** Returns a card's name, throws if not known. */
  getCardDisplayName(card: string | CardData | Card): string;

  /** Returns a permanent's card name, throws if not known. */
  getCardDisplayName(permanent: Permanent): string;

  getCardDisplayName(arg: CardLike): string {
    return this.getCardData(arg).displayName;
  }

  /**
   * Get the color of a card or permanent.
   * If a string is passed in, it is interpreted as a card name.
   */
  getCardColor(arg: CardLike): CardColor | null {
    return this.getCardData(arg).color;
  }

  /**
   * Returns the player's draw pile (current deck in play)
   * Can be empty.
   */
  getDrawPile(player: Player): ReadonlyArray<Card> {
    const drawPile = this.gameState[player].drawPile;
    if (drawPile === undefined) {
      throw new Error("draw pile not visible");
    }
    return drawPile;
  }

  /**
   * Get gem colors that the card or permanent can create.
   */
  getCreateGemsColors(arg: CardLike): GemColor[][] {
    const effects = this.getSharedEffects(arg);
    if (effects.createGemColors) {
      return effects.createGemColors;
    }

    const cardColor = this.getCardColor(arg);
    if (cardColor !== null) {
      return getDefaultCreateGemsColors(cardColor);
    }
    return [];
  }

  /**
   * Returns the top two cards of player's draw pile, if they exit.
   * Throws if draw pile not visible.
   */
  getTopCardsInDrawPile(player: Player): {
    topCard: Card | null;
    nextTopCard: Card | null;
  } {
    const drawPile = this.gameState[player].drawPile;
    if (drawPile === undefined) {
      throw new Error("draw pile not visible");
    }
    const drawPileSize = drawPile.length;
    return {
      topCard: drawPileSize > 0 ? drawPile[drawPileSize - 1] : null,
      nextTopCard: drawPileSize > 1 ? drawPile[drawPileSize - 2] : null,
    };
  }

  /**
   * Get a card in a Player's hand, or null if it doesn't exist.
   */
  getCardInHandIfExists(player: Player, handCardId: string): HandCard | null {
    const handCard = this.gameState[player].hand.find((handCard) => {
      return handCard.id === handCardId;
    });
    if (handCard === undefined) {
      return null;
    }
    return handCard;
  }

  /**
   * Get the card with ID handCardId in a Player's hand; throws if
   * it doesn't exist.
   */
  getCardInHand(player: Player, handCardId: string): HandCard {
    const card = this.getCardInHandIfExists(player, handCardId);
    if (card === null) {
      throw new Error("card does not exist in hand");
    }
    return card;
  }

  /**
   * Get the row'th row nearest to player.
   */
  getRowForPlayer(row: number, player: Player): number {
    if (player === Player.P1) return this.getNumRows() - row - 1;
    else return row;
  }

  /** Get all slots, in an unspecified order. */
  getAllSlots(): Slot[] {
    return getAllSlots(this.getNumRows(), this.getNumColumns());
  }

  /**
   * Get all unoccupied slots for a player's side.
   */
  getUnoccupiedSlots(player: Player): Slot[] {
    return this.getAllSlots().filter(
      (slot) => this.getTerrainOf(slot) === player && !this.isSlotOccupied(slot)
    );
  }

  /**
   * Get all unoccupied slots for a player's side, sorted by distance to a given slot.
   * No guarantee on the order of equidistant slots.
   * example:
   *    .
   *   748
   *  .3019
   *   625
   *    .
   */
  getUnoccupiedSlotsClosestTo(player: Player, slot: Slot): Slot[] {
    return this.getUnoccupiedSlots(player).sort(
      (a, b) =>
        getSlotsEuclideanDistance(a, slot) - getSlotsEuclideanDistance(b, slot)
    );
  }

  /**
   * Returns the slot reflected to the opponent's side.
   */
  getReflectedSlot(slot: Slot): Slot {
    return {
      row: this.getNumRows() - slot.row - 1,
      column: slot.column,
    };
  }

  /** Get adjacent friendly permanents to a permanent. */
  getAdjacentFriendlyPermanents(permanent: Permanent): Permanent[] {
    return this.getPermanents({
      owner: permanent.owner,
      adjacentTo: permanent.slot,
    });
  }

  /** Get friendly permanents behind a permanent from closest to furthest */
  getBehindFriendlyPermanents(permanent: Permanent): Permanent[] {
    const player = permanent.owner;
    return this.getPermanents({
      owner: player,
      column: permanent.slot.column,
      rowBehind: { row: permanent.slot.row, perspective: player },
      sort: [
        // sort by closest to furthest
        { type: "frontToBack", perspective: player },
      ],
    });
  }

  getBases(player: Player): Permanent[] {
    return this.getPermanents({
      owner: player,
      isBase: true,
    });
  }

  /** Is this column in bounds? */
  isColumnInBounds(column: number): boolean {
    if (column < 0 || column >= this.getNumColumns()) return false;
    return true;
  }

  /**
   * Returns the row index from a player's perspective, where the
   * zeroth row is the row nearest to the player.
   */
  getPlayerRowIndex(row: number, player: Player): number {
    switch (player) {
      case Player.P1:
        return this.getNumRows() - row - 1;
      case Player.P2:
        return row;
    }
  }

  /**
   * Returns the row index of the nth nearest row from a player's
   * perspective.
   */
  getNthNearestRow(row: number, player: Player): number {
    switch (player) {
      case Player.P1:
        return this.getNumRows() - row - 1;
      case Player.P2:
        return row;
    }
  }

  /** Returns if row1 is in front of row2 from player's perspective. */
  isRowInFrontOf(row1: number, row2: number, player: Player): boolean {
    return (
      this.getPlayerRowIndex(row1, player) >
      this.getPlayerRowIndex(row2, player)
    );
  }

  /**
   * Returns if permanent is in opponent terrain,
   * meaning it no longer Protects Friendly Units and can attack Protected Enemy Units.
   */
  isSneaky(permanent: Permanent): boolean {
    const terrainOwner = this.getTerrainOf(permanent.slot);
    return permanent.owner !== terrainOwner;
  }

  /**
   * Returns if defender is protected from attacker, taking into account things
   * like passives.
   */
  isProtectedFrom(defender: Permanent, attacker: Permanent): boolean {
    const defenderSlot = defender.slot;
    const attackerSlot = attacker.slot;
    const defenderPlayer = defender.owner;
    const attackerPlayer = attacker.owner;
    const defenderTerrain = this.getTerrainOf(defenderSlot);
    const attackerTerrain = this.getTerrainOf(attackerSlot);

    if (attackerPlayer === defenderTerrain) {
      // If the attacker is in hostile terrain, it treats all
      // hostile units as unprotected.
      return false;
    }

    const defenderPermanents = this.getPermanentsOf(defenderPlayer);

    // A unit in hostile terrain is unprotected.
    if (defenderPlayer === attackerTerrain) return false;

    // A unit is protected if there is at least one allied unit
    // in front of it (up to the midline of the battlefield).
    if (
      defenderPermanents.some(
        (permanent) =>
          this.getTerrainOf(permanent.slot) === defenderTerrain &&
          permanent.slot.column === defenderSlot.column &&
          this.isRowInFrontOf(
            permanent.slot.row,
            defenderSlot.row,
            defenderPlayer
          ) &&
          !this.doesPermanentHaveCounterType(
            permanent,
            CounterType.DOES_NOT_PROTECT
          )
      )
    )
      return true;

    for (const spec of this.allSpecs) {
      if (
        spec.extraProtectedChecks &&
        spec.extraProtectedChecks(defender, attacker, this.makeContext())
      )
        return true;
    }

    return false;
  }

  /** Is this slot in bounds? */
  isSlotInBounds(slot: Slot): boolean {
    const { row, column } = slot;
    if (row < 0 || row >= this.getNumRows()) return false;
    if (!this.isColumnInBounds(column)) return false;
    return true;
  }

  /** Whose terrain is this? */
  getTerrainOf(slot: Slot): Player {
    if (slot.row < this.getNumRows() / 2) return Player.P2;
    return Player.P1;
  }

  /** Get the permanent in slot if one exists, otherwise returns null. */
  getPermanentAtIfExists(slot: Slot): Permanent | null {
    const permanents = this.getPermanentsAt(slot);
    if (permanents.length > 1) {
      throw new Error("should have at most one permanent per slot");
    }
    return permanents[0] ?? null;
  }

  /** Get all permanents in the given slot. */
  getPermanentsAt(slot: Slot): Permanent[] {
    return this.getPermanents({ slot });
  }

  /** Is there a permanent (creature or structure) in this slot? */
  isSlotOccupied(slot: Slot): boolean {
    return this.getPermanentsAt(slot).length > 0;
  }

  /** Get all permanents. */
  getAllPermanents(): Permanent[] {
    return Object.values(this.gameState.permanents);
  }

  /**
   * Get permanents filtered and sorted by given criteria.
   * See {@link PermanentQuery} for the query format.
   */
  getPermanents(query: PermanentQuery): Permanent[] {
    return queryPermanents(this, query);
  }

  /** Get all permanents controlled by a player. */
  getPermanentsOf(player: Player): Permanent[] {
    return this.getPermanents({ owner: player });
  }

  /** Get the permanent with given id, returning null if one doesn't exist. */
  getPermanentIfExists(id: string): Permanent | null {
    return this.gameState.permanents[id] ?? null;
  }

  /** Get the permanent with given id, asserting its existence. */
  getPermanent(id: string): Permanent {
    const permanent = this.getPermanentIfExists(id);
    if (permanent === null) {
      throw new Error(`permanent ${id} does not exist`);
    }
    return permanent;
  }

  /** Check if a permanent still exists in the field. */
  doesPermanentExist(permanent: Permanent): boolean {
    return this.getPermanentIfExists(permanent.id) !== null;
  }

  /** Get a player's gems. */
  getPlayerGems(player: Player): ReadonlyArray<GemColor> {
    return this.gameState[player].gems;
  }

  // Get the cost of drawing a card.
  getDrawCardCost(): CostColor[] {
    return [ColorSymbol.ROCK, ColorSymbol.ROCK];
  }

  /**
   * Resolve what gems are used to pay for a cost, given a set of
   * available gems.
   * Does so greedily, by paying with more specific gems first.
   * Returns null if gems can't pay.
   */
  resolvePayment(
    player: Player,
    costs: ReadonlyArray<CostColor>
  ): GemColor[] | null {
    if (player === Player.P2 && this.gameSpec.aiDisableGemAccounting) return [];
    const available = this.getPlayerGems(player);
    // Get available and costs as frequency maps.
    // availableMap[color] can be undefined or 0 when there is no more of that ColorSymbol available.
    const availableMap = new Map<GemColor, number>();
    for (const color of available) {
      availableMap.set(color, (availableMap.get(color) ?? 0) + 1);
    }
    const costMap = new Map<CostColor, number>();
    for (const color of costs) {
      costMap.set(color, (costMap.get(color) ?? 0) + 1);
    }
    const payment: GemColor[] = [];

    const pay = (gemColor: GemColor, costColor: CostColor, num: number) => {
      if (num === 0) return;
      if (num < 0) throw new Error("negative payment");
      const leftover = (availableMap.get(gemColor) ?? 0) - num;
      const leftoverCost = (costMap.get(costColor) ?? 0) - num;
      if (leftoverCost < 0) throw new Error("overpaid");
      if (leftover < 0) throw new Error("overspent");
      availableMap.set(gemColor, leftover);
      costMap.set(costColor, leftoverCost);
      for (let i = 0; i < num; i++) {
        payment.push(gemColor);
      }
    };

    /** Pay as much of the gem color towards the cost color. */
    const payMax = (gemColor: GemColor, costColor: CostColor) => {
      pay(
        gemColor,
        costColor,
        Math.min(availableMap.get(gemColor) ?? 0, costMap.get(costColor) ?? 0)
      );
    };

    const costColorToGemColor = (color: CostColor): GemColor | null => {
      switch (color) {
        case ColorSymbol.RED:
        case ColorSymbol.YELLOW:
        case ColorSymbol.GREEN:
        case ColorSymbol.PURPLE:
        case ColorSymbol.WHITE:
        case ColorSymbol.BLACK:
          return color;
        default:
          return null;
      }
    };

    // Handle the concrete colors.
    for (const [costColor, cost] of costMap) {
      const gemColor = costColorToGemColor(costColor);
      if (gemColor === null) continue;

      payMax(gemColor, costColor);

      // See if the rainbow gems in our possession can cover the cost.
      payMax(ColorSymbol.RAINBOW, costColor);
    }

    // Now handle the multiple-possibility costs (WHITEBLACK and RAINBOW).
    // We do this by determining "least popular" gems and using those to pay first.

    // For each color, add all non-rock gem costs in hand.
    const numUses = new Map<CostColor, number>();
    const incNumUses = (color: CostColor) => {
      numUses.set(color, (numUses.get(color) ?? 0) + 1);
    };
    if (player) {
      for (const card of this.gameState[player].hand) {
        // If we don't have access to a card, don't include it
        // in the heuristic.
        if (card.card.name === undefined) continue;

        for (const color of this.getCardData(card).cost) {
          if (color !== ColorSymbol.ROCK) {
            incNumUses(color);
          }
        }
      }
      // For each color, add all owned ready permanents in play.
      for (const permanent of this.getPermanents({
        owner: player,
        ready: true,
      })) {
        // If we don't have access to a card, don't include it
        // in the heuristic.
        if (permanent.card.name === undefined) continue;

        const color = this.getCardColor(permanent);
        if (color !== null) incNumUses(color);
      }
    }

    const costTiebreakOrder: { [color in CostColor]: number } = {
      [ColorSymbol.RED]: 0,
      [ColorSymbol.YELLOW]: 1,
      [ColorSymbol.GREEN]: 2,
      [ColorSymbol.PURPLE]: 3,
      [ColorSymbol.WHITE]: 4,
      [ColorSymbol.BLACK]: 5,
      [ColorSymbol.WHITEBLACK]: 6,
      [ColorSymbol.ROCK]: 7,
    };

    // Some colors might not appear in the frequency map, so handle
    // any remaining available colors.
    for (const gemColor of availableMap.keys()) {
      if (gemColor === ColorSymbol.RAINBOW) continue;
      if (numUses.has(gemColor)) continue;
      numUses.set(gemColor, 0);
    }

    // Now sort ascending by frequency, with ColorSymbol enum order as a tiebreaker.
    const colorFreqPairs = Array.from(numUses).sort((a, b) => {
      const difference = a[1] - b[1];
      return difference == 0
        ? costTiebreakOrder[a[0]] - costTiebreakOrder[b[0]]
        : difference;
    });

    // Handle WHITEBLACK.
    const whiteIndex = colorFreqPairs.find(
      ([color, _]) => color === ColorSymbol.WHITE
    );
    const blackIndex = colorFreqPairs.find(
      ([color, _]) => color === ColorSymbol.BLACK
    );
    if (
      (whiteIndex ?? colorFreqPairs.length) <=
      (blackIndex ?? colorFreqPairs.length)
    ) {
      // White is "less popular" than black.
      payMax(ColorSymbol.WHITE, ColorSymbol.WHITEBLACK);
      // Consume all the W, then overflow over to B.
      payMax(ColorSymbol.BLACK, ColorSymbol.WHITEBLACK);
    } else {
      // Black is "less popular" than white.
      payMax(ColorSymbol.BLACK, ColorSymbol.WHITEBLACK);
      // Consume all the B, then overflow over to W.
      payMax(ColorSymbol.WHITE, ColorSymbol.WHITEBLACK);
    }
    // If necessary, overflow onto Rainbow.
    payMax(ColorSymbol.RAINBOW, ColorSymbol.WHITEBLACK);

    // Handle ROCK.
    // Go through all the concrete colors to see if we can use them to pay for the ROCKs.
    for (const [costColor, freq] of colorFreqPairs) {
      const gemColor = costColorToGemColor(costColor);
      if (gemColor === null) continue;
      payMax(gemColor, ColorSymbol.ROCK);
    }
    // Handle RAINBOW last, so that it has lower priority than
    // the concrete colors.
    payMax(ColorSymbol.RAINBOW, ColorSymbol.ROCK);

    // If the payment doesn't cover the costs, then we're not able to pay.
    if (payment.length < costs.length) return null;

    return payment;
  }

  /**
   * Subtract payment from gems. Throws if can't pay.
   * Returns the gems remaining.
   */
  subtractGems(
    gems: ReadonlyArray<GemColor>,
    payment: ReadonlyArray<GemColor>
  ): GemColor[] {
    const remainingGems = gems.slice();
    for (const paymentGem of payment) {
      const index = remainingGems.findIndex((gem) => gem === paymentGem);
      if (index === -1) throw new Error("payment not a subset of gems");
      remainingGems.splice(index, 1);
    }
    return remainingGems;
  }

  /** Can player perform an action at this time? */
  isTurnPending(player: Player): boolean {
    if (this.isNoTurnCheck()) return true;
    if (!this.isGameActive()) return false;
    return this.gameState.currentTurnPlayer === player;
  }

  /**
   * Computes card text. If the card has an effect defined for computeText,
   * returns the result of that function. Otherwise, gets the text from the
   * card data. */
  getCardText(arg: CardLike): string {
    if (!(typeof arg === "object" && arg.counters !== undefined))
      return this.getCardData(arg).text;

    const permanent = arg;
    const computeTextFn = this.getSharedEffects(permanent).computeText;
    return computeTextFn !== undefined
      ? computeTextFn(permanent, this.makeContext())
      : this.getCardData(permanent).text;
  }

  /** Get the sum of all counters for a permanent matching a type. */
  getCounterValSum(permanent: Permanent, type: ValueCounterType): number {
    let totVal = 0;
    for (const counter of permanent.counters) {
      if (counter.type === type) {
        totVal += counter.val;
      }
    }
    return totVal;
  }

  /** Returns whether permanent has counter of type counterType. */
  doesPermanentHaveCounterType(
    permanent: Permanent,
    counterType: CounterType
  ): boolean {
    return permanent.counters.some((counter) => counter.type === counterType);
  }

  /**
   * Get the effects that are shared with client and server.
   * If a string is passed in, it is interpreted as a card name.
   */
  getSharedEffects(arg: CardLike): CardEffectsShared {
    return this.staticInspector.getSharedEffects(arg);
  }

  /** Get whether a card is a legendary. */
  isLegendary(arg: CardLike): boolean {
    return this.staticInspector.isLegendary(arg);
  }

  /**
   * Get the ability cost of a card or permanent.
   * Returns null if there's no such ability.
   * If a string is passed in, it is interpreted as a card name.
   */
  getAbilityCostIfExists(
    arg: CardLike,
    abilityType: AbilityType
  ): CostColor[] | null {
    const effects = this.getSharedEffects(arg);
    switch (abilityType) {
      case AbilityType.FLEX: {
        return effects.flexCost ?? null;
      }
      case AbilityType.SPECIAL: {
        return effects.specialCost ?? null;
      }
    }
  }

  /**
   * Returns a card or permanent's type (whether it is a creature
   * or a structure).
   * If a string is passed in, it is interpreted as a card name.
   */
  getCardType(arg: CardLike): CardType {
    const isStructure = this.getSharedEffects(arg).isStructure ?? false;
    return isStructure ? CardType.STRUCTURE : CardType.CREATURE;
  }

  /**
   * Returns a card or permanent's base power.
   * If a string is passed in, it is interpreted as a card name.
   */
  getBasePower(arg: CardLike): number {
    const powerOverride = this.getSharedEffects(arg).power;
    if (powerOverride !== undefined) return powerOverride;
    return this.getCardData(arg).power;
  }

  /**
   * Returns card's power.
   * If a string is passed in, it is interpreted as a card name.
   */
  getPower(card: string | CardData | Card): number;

  /**
   * Returns permanent's power, including adjustments.
   */
  getPower(permanent: Permanent): number;

  getPower(arg: CardLike): number {
    // If arg is a card, then there is only the base power.
    if (!(typeof arg === "object" && arg.counters !== undefined))
      return this.getBasePower(arg);

    const permanent = arg;
    const adjustment = this.getCounterValSum(
      permanent,
      CounterType.POWER_ADJUSTMENT
    );
    return Math.max(this.getBasePower(permanent) + adjustment, 0);
  }

  /**
   * Returns a card or permanent's base (max) health.
   * If a string is passed in, it is interpreted as a card name.
   */
  getBaseHealth(arg: CardLike): number {
    const maxHealthOverride = this.getSharedEffects(arg).maxHealth;
    if (maxHealthOverride !== undefined) return maxHealthOverride;
    return this.getCardData(arg).maxHealth;
  }

  /** Returns a permanent's max health, including adjustments. */
  getMaxHealth(permanent: Permanent): number {
    const adjustment = this.getCounterValSum(
      permanent,
      CounterType.MAX_HEALTH_ADJUSTMENT
    );
    return Math.max(this.getBaseHealth(permanent) + adjustment, 0);
  }

  /**
   * Returns card's health.
   * If a string is passed in, it is interpreted as a card name.
   */
  getHealth(card: string | CardData | Card): number;

  /**
   * Returns permanent's health, including adjustments and damage.
   */
  getHealth(permanent: Permanent): number;

  getHealth(arg: CardLike): number {
    // If arg is a card, then there is only the base health.
    if (!(typeof arg === "object" && arg.counters !== undefined))
      return this.getBaseHealth(arg);

    const permanent = arg;
    const health = this.getMaxHealth(permanent) - permanent.damage;
    if (health < 0) {
      // The Reducer should guarantee that health never goes below 0.
      throw new Error("health should not be negative");
    }
    return health;
  }

  /**
   * Returns a card or permanent's base shell.
   * If a string is passed in, it is interpreted as a card name.
   */
  getBaseShell(arg: CardLike): number {
    return this.getCardData(arg).shell;
  }

  /**
   * Returns card's shell.
   * If a string is passed in, it is interpreted as a card name.
   */
  getShell(card: string | CardData | Card): number;

  /**
   * Returns permanent's shell, including adjustments.
   */
  getShell(permanent: Permanent): number;

  getShell(arg: CardLike): number {
    // If arg is a card, then there is only the base shell.
    if (!(typeof arg === "object" && arg.counters !== undefined))
      return this.getBaseShell(arg);

    const permanent = arg;
    const adjustment = this.getCounterValSum(
      permanent,
      CounterType.SHELL_ADJUSTMENT
    );
    return Math.max(this.getBaseShell(permanent) + adjustment, 0);
  }

  /**
   * Returns permanent's undamaged shell.
   */
  getUndamagedShell(permanent: Permanent): number {
    const shell = this.getShell(permanent);
    const shellDamage = this.getCounterValSum(
      permanent,
      CounterType.SHELL_DAMAGE_TAKEN
    );
    return Math.max(shell - shellDamage, 0);
  }

  /** Whether we have an AI. */
  hasAI(): boolean {
    return this.staticInspector.hasAI();
  }

  /** Whether player is an AI. */
  isPlayerAI(player: Player): boolean {
    return this.hasAI() && player === Player.P2;
  }

  areCheckpointsAllowed(): boolean {
    return this.staticInspector.areCheckpointsAllowed();
  }

  /** Whether we have disabled gem clearing at the end of the turn. */
  isGemClearingDisabled(): boolean {
    return this.gameSpec.disableClearGems ?? false;
  }

  /**
   * Get the battle-specific extra state shared between client and server.
   */
  getSharedExtraState(): object {
    const sharedExtraState = this.gameState.sharedExtraState;
    if (sharedExtraState === undefined) {
      throw new Error("sharedExtraState not initialized");
    }
    return sharedExtraState;
  }

  //
  // DEV KNOBS
  //

  getDevKnobs(): DevKnobs {
    return this.gameState.devKnobs ?? {};
  }

  /** Check if the dev-only all-cards-available mode is enabled. */
  isAllCardsAvailable(): boolean {
    return this.getDevKnobs().allCardsAvailable ?? false;
  }

  /**
   * Get the hand that would be displayed in the UI.
   * In all-cards-available mode, every card in the cards DB would
   * be displayed in the hand.
   */
  getHandForUI(player: Player): ReadonlyArray<HandCard> {
    if (this.isAllCardsAvailable()) {
      const allCardNames = Object.keys(this.cardsDB);
      allCardNames.sort();
      return allCardNames.map((name, i) => ({
        card: { name },
        id: `${i}`,
      }));
    }
    return this.gameState[player].hand;
  }

  /** Check if the dev-only always-ready mode is enabled. */
  isAlwaysReady(): boolean {
    return this.getDevKnobs().alwaysReady ?? false;
  }

  /** Check if the dev-only no-turn-check mode is enabled. */
  isNoTurnCheck(): boolean {
    return this.getDevKnobs().noTurnCheck ?? false;
  }

  //
  // STEPS: COSTS AND VALIDATORS
  // none of the validators should check turn/cost/ready!
  //

  validateCreateGems(
    failedChecks: FailedChecks,
    permanent: Permanent,
    gemColors: GemColor[]
  ): void {
    const cardType = this.getCardType(permanent);

    // check counter
    if (this.doesPermanentHaveCounterType(permanent, CounterType.CANNOT_CREATE))
      failedChecks.add(Check.CANNOT_CREATE);

    // only creatures can create gems
    if (cardType !== CardType.CREATURE) failedChecks.add(Check.CARD_TYPE);

    // gem color must match permanent's color
    if (
      !this.getCreateGemsColors(permanent).some(
        (colors) =>
          colors.length === gemColors.length &&
          colors.every((c, i) => c === gemColors[i])
      )
    )
      failedChecks.add(Check.COLOR);
  }

  /** Check if a card can be summoned to a slot. */
  validateSummon(
    failedChecks: FailedChecks,
    player: Player,
    cardName: string,
    slot: Slot
  ): void {
    const effects = this.getSharedEffects(cardName);
    const effectsAdjust = this.gameSpec.disableCardEffectsAdjustSummonChecks
      ? undefined
      : effects.adjustSummonChecks;

    const cardData = this.cardsDB[cardName];
    const cardType = this.getCardType(cardData);

    if (!this.isSlotInBounds(slot)) {
      failedChecks.add(Check.BOUNDS);
      // We can't do any further validation if the slot is out of bounds.
      return;
    }

    // cannot move into occupied slot
    if (this.isSlotOccupied(slot)) failedChecks.add(Check.OCCUPIED);
    if (this.getTerrainOf(slot) !== player) failedChecks.add(Check.TERRAIN);

    if (effectsAdjust)
      effectsAdjust(failedChecks, player, cardName, slot, this.makeContext());

    for (const spec of this.allSpecs) {
      if (spec.adjustSummonChecks) {
        spec.adjustSummonChecks(
          failedChecks,
          player,
          cardName,
          slot,
          this.makeContext()
        );
      }
    }
  }

  /**
   * Check if a move is valid, including effects.
   * Does not do input validation (e.g. slot bounds).
   */
  validateMove(
    failedChecks: FailedChecks,
    permanent: Permanent,
    slot: Slot
  ): void {
    const effects = this.getSharedEffects(permanent);
    const effectsAdjust = this.gameSpec.disableCardEffectsAdjustMoveChecks
      ? undefined
      : effects.adjustMoveChecks;

    const cardType = this.getCardType(permanent);

    if (!this.isSlotInBounds(slot)) {
      failedChecks.add(Check.BOUNDS);
      // We can't do any further validation if the slot is out of bounds.
      return;
    }

    // only creatures can move
    if (cardType !== CardType.CREATURE) failedChecks.add(Check.CARD_TYPE);

    // check counter
    if (this.doesPermanentHaveCounterType(permanent, CounterType.CANNOT_MOVE))
      failedChecks.add(Check.CANNOT_MOVE);

    // cannot move into occupied slot
    if (this.isSlotOccupied(slot)) failedChecks.add(Check.OCCUPIED);

    // can only move to adjacent slots
    if (!areSlotsAdjacent(permanent.slot, slot))
      failedChecks.add(Check.ADJACENT);

    if (effectsAdjust)
      effectsAdjust(failedChecks, permanent, slot, this.makeContext());

    for (const spec of this.allSpecs) {
      if (spec.adjustMoveChecks) {
        spec.adjustMoveChecks(
          failedChecks,
          permanent,
          slot,
          this.makeContext()
        );
      }
    }
  }

  /** Check if an attack is valid, including effects. */
  validateAttack(
    failedChecks: FailedChecks,
    attacker: Permanent,
    defender: Permanent
  ): void {
    const effects = this.getSharedEffects(attacker);
    const effectsAdjust = this.gameSpec.disableCardEffectsAdjustAttackChecks
      ? undefined
      : effects.adjustAttackChecks;

    // check can't attack counter
    if (this.doesPermanentHaveCounterType(attacker, CounterType.CANNOT_ATTACK))
      failedChecks.add(Check.CANNOT_ATTACK);

    // only creatures can attack
    if (this.getCardType(attacker) !== CardType.CREATURE)
      failedChecks.add(Check.CARD_TYPE);

    // cannot attack allied unit
    if (attacker.owner === defender.owner) failedChecks.add(Check.ALLIED);

    // cannot attack unit with counter
    if (this.doesPermanentHaveCounterType(defender, CounterType.INVULNERABLE))
      failedChecks.add(Check.INVULNERABLE);

    // cannot attack protected unit
    if (this.isProtectedFrom(defender, attacker))
      failedChecks.add(Check.PROTECTED);

    if (effectsAdjust)
      effectsAdjust(failedChecks, attacker, defender, this.makeContext());

    for (const spec of this.allSpecs) {
      if (spec.adjustAttackChecks) {
        spec.adjustAttackChecks(
          failedChecks,
          attacker,
          defender,
          this.makeContext()
        );
      }
    }
  }

  /** Get the cost to summon a given card. */
  getSummonCost(arg: string | CardData | Card): CostColor[] {
    const isSummonFree = this.getDevKnobs().freeSummon ?? false;
    if (isSummonFree) return [];
    return this.getCardData(arg).cost;
  }

  /** Resolve the effect opt, null if we can't. */
  resolveEffectOptIfExists(effectOpt: EffectOpt): EffectOptResolved | null {
    switch (effectOpt.type) {
      case EffectOptType.PERMANENT: {
        const permanent = this.getPermanentIfExists(effectOpt.permanentId);
        if (permanent === null) return null;
        return { ...effectOpt, permanent };
        break;
      }
      default: {
        return effectOpt;
      }
    }
  }

  /** Resolve the effect opts, null if we can't. */
  resolveEffectOptsIfExist(
    effectOpts: ReadonlyArray<EffectOpt>
  ): EffectOptResolved[] | null {
    const effectOptsResolved = [];
    for (const effectOpt of effectOpts) {
      const effectOptResolved = this.resolveEffectOptIfExists(effectOpt);
      if (effectOptResolved === null) return null;
      effectOptsResolved.push(effectOptResolved);
    }
    return effectOptsResolved;
  }

  validateEffectOpt(
    failedChecks: FailedChecks,
    effectOpt: EffectOptResolved,
    form: EffectOptForm,
    ctx: EffectOptValidationContext
  ): void {
    validateEffectOpt(failedChecks, effectOpt, form, ctx);
    for (const spec of this.allSpecs) {
      if (spec.adjustEffectOptChecks) {
        spec.adjustEffectOptChecks(failedChecks, effectOpt, form, ctx);
      }
    }
  }

  /** Validate activating an ability. */
  validateActivateAbility(
    failedChecks: FailedChecks,
    permanent: Permanent,
    abilityType: AbilityType,
    effectOpts: ReadonlyArray<EffectOptResolved>
  ): void {
    const effects = this.getSharedEffects(permanent);

    // check existence
    const cost =
      abilityType === AbilityType.FLEX ? effects.flexCost : effects.specialCost;

    if (!cost) {
      failedChecks.add(Check.ABILITY_EXISTS);
      return;
    }

    // validate forms
    const forms =
      (abilityType === AbilityType.FLEX
        ? effects.flexForms
        : effects.specialForms) ?? [];

    if (effectOpts.length !== forms.length) {
      failedChecks.add(Check.NUM_OPTS);
    }

    // Validate whatever opts we have, even if there are too few of them.
    // This is necessary for validation in the UI since we want to
    // validate partial effect opts as they are being specified.
    const validationCtx = {
      permanent,
      effectOpts,
      inspector: this,
    };
    for (const [i, form] of forms.entries()) {
      const effectOpt = effectOpts[i];
      if (!effectOpt) break;
      this.validateEffectOpt(failedChecks, effectOpt, form, validationCtx);
    }
  }

  /** Resolve a step, null if we can't. */
  resolveStep(step: Step): StepResolved | null {
    switch (step.type) {
      case StepType.SUMMON: {
        const { player, handCardId, cardName } = step;
        const resolvedCardName = (() => {
          if (handCardId !== undefined) {
            const handCard = this.getCardInHandIfExists(player, handCardId);
            if (!handCard) return null;
            return this.getCardName(handCard);
          }
          if (
            (this.isAllCardsAvailable() || this.isPlayerAI(player)) &&
            cardName !== undefined
          ) {
            return cardName;
          }
          return null;
        })();
        if (!resolvedCardName) return null;

        const payment = this.resolvePayment(
          player,
          this.getSummonCost(resolvedCardName)
        );

        return {
          ...step,
          cardName: resolvedCardName,
          cannotPay: payment === null,
          payment: payment ?? [],
        };
      }
      case StepType.ATTACK: {
        const { attackerId, defenderId } = step;
        const attacker = this.getPermanentIfExists(attackerId);
        const defender = this.getPermanentIfExists(defenderId);
        if (!attacker) return null;
        if (!defender) return null;

        const isAttackFree = this.doesPermanentHaveCounterType(
          attacker,
          CounterType.ATTACKS_FOR_FREE
        );
        const attackColor = isAttackFree ? null : this.getCardColor(attacker);
        const payment =
          attackColor === null
            ? []
            : this.resolvePayment(attacker.owner, [attackColor]);
        return {
          ...step,
          attacker,
          defender,
          cannotPay: payment === null,
          payment: payment ?? [],
        };
      }
      case StepType.CREATE_GEMS:
      case StepType.MOVE:
      case StepType.REMOVE: {
        const { permanentId } = step;
        const permanent = this.getPermanentIfExists(permanentId);
        if (!permanent) return null;
        return { ...step, permanent };
      }
      case StepType.DRAW: {
        const { player } = step;
        const payment = this.resolvePayment(player, this.getDrawCardCost());
        return { ...step, cannotPay: payment === null, payment: payment ?? [] };
      }
      case StepType.ACTIVATE_ABILITY: {
        const { permanentId, abilityType, effectOpts } = step;

        const permanent = this.getPermanentIfExists(permanentId);
        if (!permanent) return null;

        const cost = this.getAbilityCostIfExists(permanent, abilityType);
        if (cost === null) return null;
        const payment = this.resolvePayment(permanent.owner, cost);

        const effectOptsResolved = this.resolveEffectOptsIfExist(effectOpts);
        if (effectOptsResolved === null) return null;

        return {
          ...step,
          permanent,
          effectOptsResolved,
          cannotPay: payment === null,
          payment: payment ?? [],
        };
      }
      case StepType.ADVANCE_KEYFRAME: {
        const { keyframe } = this.gameState;
        if (keyframe === undefined) return null;
        return {
          ...step,
          keyframe,
        };
      }
      default: {
        return step;
      }
    }
  }

  /** Check if a step is valid (in base game). */
  validateResolvedStep(
    failedChecks: FailedChecks,
    step: StepResolved,
    role: Role
  ): void {
    const player = stepToPlayer(step);
    /** Acting permanent that needs to be ready. */
    const readyPermanent = (() => {
      switch (step.type) {
        case StepType.ATTACK:
          return step.attacker;
        case StepType.CREATE_GEMS:
        case StepType.MOVE:
          return step.permanent;
        case StepType.ACTIVATE_ABILITY:
          return step.abilityType === AbilityType.SPECIAL
            ? step.permanent
            : null;
        default:
          return null;
      }
    })();
    /** Whether the step is invalid due to cost. */
    const cannotPay = (() => {
      switch (step.type) {
        case StepType.DRAW:
        case StepType.SUMMON:
        case StepType.ATTACK:
        case StepType.ACTIVATE_ABILITY:
          return step.cannotPay;
        default:
          return false;
      }
    })();
    const stepNumber = ((): number | null => {
      switch (step.type) {
        case StepType.SUMMON:
        case StepType.ATTACK:
        case StepType.CREATE_GEMS:
        case StepType.MOVE:
        case StepType.DRAW:
        case StepType.ACTIVATE_ABILITY:
        case StepType.END_TURN:
          return step.stepNumber;
        default:
          return null;
      }
    })();

    if (!this.isGameActive()) failedChecks.add(Check.GAME_ENDED);

    if (player !== null && !canRoleControlPlayer(role, player))
      failedChecks.add(Check.ROLE);

    if (player !== null && cannotPay) failedChecks.add(Check.COST);

    if (player !== null && !this.isTurnPending(player)) {
      // check for bypass turn check
      if (!(this.isPlayerAI(player) && this.gameSpec.aiDisableTurnCheck))
        failedChecks.add(Check.TURN);
    }

    if (readyPermanent !== null && !readyPermanent.ready)
      failedChecks.add(Check.READY);

    if (
      this.gameState.keyframe !== undefined &&
      (this.gameState.keyframe.blocking ?? false) &&
      ![StepType.ADVANCE_KEYFRAME, StepType.CREATE_CHECKPOINT].includes(
        step.type
      )
    )
      failedChecks.add(Check.KEYFRAME);

    if (stepNumber !== null) {
      if (player === null)
        throw new Error("expect player to check step number for");
      if (stepNumber !== this.gameState[player].stepNumber)
        failedChecks.add(Check.STALE);
    }

    switch (step.type) {
      case StepType.CREATE_GEMS: {
        const { permanent, gemColors } = step;
        this.validateCreateGems(failedChecks, permanent, gemColors);
        break;
      }
      case StepType.SUMMON: {
        const { player, cardName, slot } = step;
        this.validateSummon(failedChecks, player, cardName, slot);
        break;
      }
      case StepType.MOVE: {
        const { permanent, slot } = step;
        this.validateMove(failedChecks, permanent, slot);
        break;
      }
      case StepType.ATTACK: {
        const { attacker, defender } = step;
        this.validateAttack(failedChecks, attacker, defender);
        break;
      }
      case StepType.ACTIVATE_ABILITY: {
        const { permanent, abilityType, effectOptsResolved } = step;
        this.validateActivateAbility(
          failedChecks,
          permanent,
          abilityType,
          effectOptsResolved
        );
        break;
      }
      case StepType.DRAW: {
        const { player } = step;
        const playerState = this.gameState[player];
        if (!this.doesPlayerHaveMastery(player, DRAW_CARD_MASTERY_ID))
          failedChecks.add(Check.MASTERY);
        const drawPileSize = (() => {
          if (playerState.drawPileSize !== undefined)
            return playerState.drawPileSize;
          if (playerState.drawPile !== undefined)
            return playerState.drawPile.length;
          return null;
        })();
        if (drawPileSize === null) {
          // If we don't have access to the draw pile, then we don't
          // have perms to do a draw card step anyway.
          failedChecks.add(Check.ROLE);
          break;
        }
        if (drawPileSize <= 0) failedChecks.add(Check.DECK_EMPTY);
        break;
      }
      case StepType.END_TURN: {
        const { player } = step;
        for (const spec of this.allSpecs) {
          if (spec.adjustEndTurnChecks) {
            spec.adjustEndTurnChecks(failedChecks, player, this.makeContext());
          }
        }
        break;
      }
      case StepType.ADVANCE_KEYFRAME: {
        const { keyframeId, keyframe, dialogOptionIndex } = step;
        const { dialogOptions } = keyframe;
        if (
          dialogOptionIndex !== undefined &&
          dialogOptions !== undefined &&
          (!Number.isInteger(dialogOptionIndex) ||
            dialogOptionIndex < 0 ||
            dialogOptionIndex >= dialogOptions.length)
        )
          failedChecks.add(Check.INVALID_DIALOG_OPTION);
        if (keyframe.id !== keyframeId) failedChecks.add(Check.STALE);
        break;
      }
      case StepType.CREATE_CHECKPOINT: {
        const { slot } = step;
        if (!this.areCheckpointsAllowed()) failedChecks.add(Check.GENERIC);
        if (!isCheckpointSlotValid(slot) || slot === 0)
          failedChecks.add(Check.INVALID_CHECKPOINT_SLOT);
        break;
      }
      case StepType.REMOVE: {
        if (role !== Role.GOD) failedChecks.add(Check.ROLE);
        break;
      }
    }
  }

  /**
   * Check if a step made by role is valid.
   * Returns the step resolved and a set of reasons why the step
   * is invalid, if any.
   */
  resolveAndValidate(
    step: Step,
    role: Role
  ): {
    resolved: StepResolved | null;
    failedChecks: FailedChecks;
    isValid: boolean;
  } {
    const failedChecks: FailedChecks = new Set();
    const resolved = this.resolveStep(step);
    if (resolved) {
      this.validateResolvedStep(failedChecks, resolved, role);
    } else {
      failedChecks.add(Check.RESOLVE);
    }
    return {
      resolved,
      failedChecks,
      isValid: failedChecks.size === 0,
    };
  }

  /**
   * Return whether step made by role is valid.
   */
  isValid(step: Step, role: Role): boolean {
    return this.resolveAndValidate(step, role).isValid;
  }

  /**
   * Returns whether a permanent has actions, distinct from being ready. This
   * overestimates: sometimes it says there are actions when there aren't any.
   */
  hasActions(permanent: Permanent): boolean {
    const permanentId = permanent.id;
    const player = permanent.owner;
    const role = player === Player.P1 ? Role.P1 : Role.P2;
    const effects = this.getSharedEffects(permanent);
    const sm = new StepMaker(this, permanent.owner, role);

    // nothing ever acts out of turn:
    if (!this.isTurnPending(player)) {
      return false;
    }

    // can we flex?
    if (effects.flexCost && this.resolvePayment(player, effects.flexCost)) {
      // no forms, so check:
      if (effects.flexForms?.length === 0) {
        return this.isValid(
          sm.activateAbility(permanent, AbilityType.FLEX, []),
          role
        );
      }
      // don't bother checking forms in this case:
      return true;
    }

    // everything following requires readiness
    if (!permanent.ready) {
      return false;
    }

    // can we create?
    if (
      this.getCreateGemsColors(permanent).some((gemColors) =>
        this.isValid(sm.create(permanent, gemColors), role)
      )
    ) {
      return true;
    }

    // can we move?
    if (
      this.getAllSlots()
        // check adjacent slots first:
        .sort((slot) => (areSlotsAdjacent(slot, permanent.slot) ? 0 : 1))
        .some((slot) => this.isValid(sm.move(permanent, slot), role))
    ) {
      return true;
    }

    // can we attack?
    if (
      this.getPermanents({
        // check enemy permanents first:
        sort: [(defender) => (defender.owner !== player ? 0 : 1)],
      }).some((defender) => this.isValid(sm.attack(permanent, defender), role))
    ) {
      return true;
    }

    // can we special?
    if (
      effects.specialCost &&
      this.resolvePayment(player, effects.specialCost)
    ) {
      if (effects.specialForms?.length === 0) {
        return this.isValid(
          sm.activateAbility(permanent, AbilityType.SPECIAL, []),
          role
        );
      }
      // don't bother checking forms in this case:
      return true;
    }

    return false;
  }

  getStepNumber(player: Player) {
    return this.gameState[player].stepNumber;
  }
}
