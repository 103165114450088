import { create } from "zustand";
import { GemColor } from "engine/types/card-data";

export enum HoverButton {
  CREATE = "create",
  FLEX = "flex",
  SPECIAL = "special",
}

export interface HoverActionState {
  // TODO: is it silly having both payment and gain?
  payment: GemColor[] | null;
  gain: GemColor[] | null;
  setPayment: (payment: GemColor[]) => void;
  setGain: (gain: GemColor[]) => void;
  resetHoverState: () => void;
}

/**
 * Used for UI when we hover over a possible action:
 * - show how much gems will be deducted / added
 * - show arrows or something?
 */
export const useHoverActionStore = create<HoverActionState>((set) => ({
  payment: null,
  gain: null,
  setPayment: (payment: GemColor[]) => set({ payment }),
  setGain: (gain: GemColor[]) => set({ gain }),
  resetHoverState: () =>
    set({
      payment: null,
      gain: null,
    }),
}));
