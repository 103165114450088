import { PropsWithChildren, useMemo } from "react";

import "./TranslateToSlot.css";

import { useGetTranslate } from "stores/AnimationStore";

interface TranslateToSlotProps {
  row: number;
  column: number;
}

/** A slot-sized div that gets translated to the slotted prop. */
function TranslateToSlot(props: PropsWithChildren<TranslateToSlotProps>) {
  const { row, column, children } = props;

  const getTranslate = useGetTranslate();

  const style = useMemo(
    () => getTranslate({ row, column }),
    [column, getTranslate, row]
  );

  return (
    <div className="translated-slot" style={style}>
      {children}
    </div>
  );
}

export default TranslateToSlot;
