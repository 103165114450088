import { create } from "zustand";

import { useSelectedCardData, useSelectedPermanent } from "stores/TurnStore";

export type ZoomedCardDetails = {
  cardName: string;
  permanentId?: string;
};

export enum ZoomedCardPriority {
  MOUSE,
  KEYBOARD,
}

export interface ZoomCardState {
  priority: ZoomedCardPriority;
  mouseZoomedCard: ZoomedCardDetails | null;
  resetMouseZoomedCard: () => void;
  setMouseZoomedCard: (cardName: string, permanentId?: string) => void;
  keyboardZoomedCard: ZoomedCardDetails | null;
  resetKeyboardZoomedCard: () => void;
  setKeyboardZoomedCard: (cardName: string, permanentId?: string) => void;
}

export const useZoomCardStore = create<ZoomCardState>((set, get) => ({
  priority: ZoomedCardPriority.MOUSE,
  mouseZoomedCard: null,
  resetMouseZoomedCard: () => set({ mouseZoomedCard: null }),
  setMouseZoomedCard: (cardName: string, permanentId?: string) =>
    set({
      mouseZoomedCard: { cardName, permanentId },
      priority: ZoomedCardPriority.MOUSE,
    }),
  keyboardZoomedCard: null,
  resetKeyboardZoomedCard: () => set({ keyboardZoomedCard: null }),
  setKeyboardZoomedCard: (cardName: string, permanentId?: string) =>
    set({
      keyboardZoomedCard: { cardName, permanentId },
      priority: ZoomedCardPriority.KEYBOARD,
    }),
}));

export const useZoomedCard = () => {
  // Not using state picker b/c it needs to refresh whenever the zoom card refreshes.
  const priority = useZoomCardStore((state) => state.priority);
  const mouseZoomedCard = useZoomCardStore((state) => state.mouseZoomedCard);
  const keyboardZoomedCard = useZoomCardStore(
    (state) => state.keyboardZoomedCard
  );
  const selectedCardData = useSelectedCardData();
  const selectedPermanent = useSelectedPermanent();

  const zoomedCard = (() => {
    if (priority === ZoomedCardPriority.MOUSE) {
      return mouseZoomedCard ?? keyboardZoomedCard;
    }
    return keyboardZoomedCard ?? mouseZoomedCard;
  })();

  const card =
    zoomedCard ??
    (selectedCardData
      ? {
          cardName: selectedCardData.name,
          permanentId:
            selectedPermanent !== null ? selectedPermanent.id : undefined,
        }
      : null);

  return card;
};
