import {
  InspectorContext,
  SharedGameSpec,
} from "engine/types/shared-game-specs";
import { Player, Permanent } from "engine/types/game-state";
import { CounterType } from "engine/types/counters";
import {
  makeOneChoiceOptForm,
  plainAbilityActivationValidator,
  SharedEffectsDB,
} from "engine/cards/card-effects-shared";
import { ColorSymbol } from "engine/types/card-data";
import { Check } from "engine/types/action-validation";
import { AbilityType, EffectOptType } from "engine/types/effects";

export const PUZZLE_51_CARDS = [
  "Apple Bee",
  "Bumblebee",
  "Carpenter Bee",
  "Drone Bee",
  "Edward Al Bee",
  "Fluffy Bee",
  "Gardening Bee",
  "Honey Bee",
  "Iridescent Bee",
  "Jasmine Bee",
  "Kombucha Bee",
  "Lavender Bee",
  "Mason Bee",
  "Nectar Bee",
  "Orange Bee",
  "Pollinator Bee",
  "Queen Bee",
  "Rosebud Bee",
  "Sunshine Bee",
  "Tangerine Bee",
  "Ultimatefris Bee",
  "Velvet Bee",
  "Worker Bee",
  "Xylophone Bee",
  "Yellow Jacket Bee",
  "Zinger Bee",
];

const cardEffectsShared: SharedEffectsDB = {
  bearhive: {
    isStructure: true,
    isBase: true,
    computeText: (permanent: Permanent, ctx: InspectorContext) => {
      const happyBears = permanent.counters.find(
        (c) => c.type === CounterType.HAPPY_BEARS
      );
      const sadBears = permanent.counters.find(
        (c) => c.type === CounterType.SAD_BEARS
      );
      return `Base.\nContains ${happyBears?.val ?? 0} happy bear${
        happyBears?.val === 1 ? "" : "s"
      } and ${sadBears?.val ?? 0} sad bear${sadBears?.val === 1 ? "" : "s"}.`;
    },
  },
  beehive: {
    isStructure: true,
    isBase: true,
  },
  larva: {
    specialCost: [ColorSymbol.YELLOW],
    specialForms: [
      makeOneChoiceOptForm(
        PUZZLE_51_CARDS,
        (failedChecks, permanent, choice, ctx) => {
          if (!PUZZLE_51_CARDS.includes(choice)) {
            failedChecks.add(Check.OPT_TYPE);
          }
          plainAbilityActivationValidator(
            failedChecks,
            permanent,
            AbilityType.SPECIAL,
            [
              {
                type: EffectOptType.ONE_CHOICE,
                choice: choice,
              },
            ],
            ctx
          );
        },
        "select a bee type"
      ),
    ],
  },
};

for (const cardName of PUZZLE_51_CARDS) {
  cardEffectsShared[cardName.toLocaleLowerCase().replace(/ /g, "-")] = {
    flexCost: [ColorSymbol.YELLOW],
  };
}

const NUM_CARDS = 54;

export const SharedPuzzle51Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  disableClearGems: true,
  deckOverrides: {
    [Player.P1]: new Array(NUM_CARDS).fill("larva"),
    [Player.P2]: [],
  },
  basesOverrides: {
    [Player.P1]: ["beehive"],
    [Player.P2]: ["bearhive"],
  },
  baseColumnOverrides: {
    [Player.P1]: [2],
    [Player.P2]: [2],
  },
  cardEffectsShared: cardEffectsShared,
  numRows: 6,
  numColumns: 8,
};

// Puzzle 51
