import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import ALL_CARDS from "engine/cards/all-cards-client";
import { makeCardsDB } from "engine/cards/CardsDB";
import { ColorSymbol } from "engine/types/card-data";
import { Faction } from "engine/types/factions";
import { useCardsDBStore } from "stores/CardsDBStore";
import CardDisplay, { CardSize } from "components/CardDisplay";
import FactionIcon from "components/FactionIcon";
import { GemsDisplay, GemType } from "components/GemDisplay";
import TeamDisplayNameDisplay from "components/TeamDisplayNameDisplay";
import Tooltip from "components/Tooltip";
import settings from "settings";
import Keepsake from "./Keepsake";
import "./CardEmbed.css";

function canonicalize(name: string): string {
  return name.toLowerCase().replace(/[^a-z]/g, "");
}

function lookup(name: string): string | undefined {
  const want = canonicalize(name);
  const exact: string[] = [];
  const fuzzy: { [displayName: string]: string[] } = {};
  for (const card of ALL_CARDS) {
    const got = canonicalize(card.displayName);
    if (got === want) exact.push(card.name);
    if (got.includes(want)) (fuzzy[card.displayName] ||= []).push(card.name);
  }
  if (exact.length > 0) return exact[0];
  if (Object.keys(fuzzy).length === 1) return Object.values(fuzzy)[0][0];
}

export function setupCardEmbeds() {
  document.body.addEventListener(
    "mouseenter",
    (evt) => {
      const target = evt.target as HTMLElement;
      if (!target.classList.contains("embed")) return;
      if (target.classList.contains("embedded")) return;
      target.classList.add("embedded");
      const content = target.textContent ?? "";
      const type = target.dataset.embedType;
      ReactDOM.createRoot(target).render(
        <CardEmbed content={content} type={type} />
      );
    },
    { capture: true }
  );

  const hintMasteries = document.getElementById("hint-masteries");
  if (hintMasteries?.textContent) {
    const data = JSON.parse(hintMasteries.textContent);
    ReactDOM.createRoot(hintMasteries).render(<Masteries data={data} />);
  }

  const hintCardUnlocks = document.getElementById("hint-card-unlocks");
  if (hintCardUnlocks?.textContent) {
    const data = JSON.parse(hintCardUnlocks.textContent);
    ReactDOM.createRoot(hintCardUnlocks).render(<CardUnlocks data={data} />);
  }

  // hack in keepsake card directly (no hover embed)
  const keepsake = document.body.querySelector("#keepsake-card") as HTMLElement;
  if (keepsake?.dataset.embedType === "raw-keepsake") {
    ReactDOM.createRoot(keepsake).render(<Keepsake />);
  }

  for (const element of document.getElementsByClassName("wrapup-embed")) {
    const embed = element as HTMLElement;
    const team = embed.dataset.embedTeam;
    if (team)
      ReactDOM.createRoot(embed).render(
        <TeamDisplayNameDisplay teamData={settings.teamsSnapshot[team]} />
      );
    const faction = embed.dataset.embedFaction;
    if (faction)
      ReactDOM.createRoot(embed).render(
        <FactionIcon faction={faction as Faction} />
      );
    const color = embed.dataset.embedColor;
    if (color)
      ReactDOM.createRoot(embed).render(
        <GemsDisplay
          gems={[{ color: color as ColorSymbol, type: GemType.SOLID }]}
          inline={true}
        />
      );
    const card = embed.dataset.embedCard;
    if (card) ReactDOM.createRoot(embed).render(<MiniEmbed card={card} />);
  }
}

function MiniEmbed({ card }: { card: string }) {
  const setCardsDB = useCardsDBStore((state) => state.setCardsDB);
  useEffect(() => {
    setCardsDB(makeCardsDB(ALL_CARDS));
  }, [setCardsDB]);
  return (
    <CardDisplay
      cardName={card}
      size={CardSize.NORMAL}
      canZoom={false}
      showCost={false}
    />
  );
}

export function CardEmbed({
  content,
  type,
}: {
  content: string;
  type?: string;
}) {
  const setCardsDB = useCardsDBStore((state) => state.setCardsDB);
  useEffect(() => {
    setCardsDB(makeCardsDB(ALL_CARDS));
  }, [setCardsDB]);
  const card = (() => {
    if (type === "mtg") {
      const cardName = encodeURIComponent(content);
      return (
        <a
          href={`https://scryfall.com/search?q=${cardName}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={`https://api.scryfall.com/cards/named?fuzzy=${cardName}&format=image&version=border_crop`}
            style={{ width: "var(--card-large-width)" }}
          />
        </a>
      );
    }
    if (type === "keepsake") {
      return <Keepsake />;
    }
    const cardName = lookup(content);
    if (cardName)
      return (
        <CardDisplay
          cardName={cardName}
          size={CardSize.LARGE}
          canZoom={false}
        />
      );
    return "Card not found 🙁️";
  })();
  return (
    <Tooltip text={card} into={true}>
      {content}
    </Tooltip>
  );
}

function Masteries({ data }: { data: [string, string][] }) {
  return (
    <>
      {data.map(([name, text]) => (
        <Tooltip
          key={name}
          text={text}
          into={true}
          format={true}
          className="embed embedded"
        >
          {name}
        </Tooltip>
      ))}
    </>
  );
}

function CardUnlocks({ data }: { data: string[] }) {
  const setCardsDB = useCardsDBStore((state) => state.setCardsDB);
  useEffect(() => {
    setCardsDB(makeCardsDB(ALL_CARDS));
  }, [setCardsDB]);
  return (
    <>
      {data.map((cardName) => (
        <Tooltip
          key={cardName}
          text={
            <CardDisplay
              cardName={cardName}
              size={CardSize.LARGE}
              canZoom={false}
            />
          }
          into={true}
          style={{ display: "inline-block" }}
        >
          <CardDisplay
            cardName={cardName}
            size={CardSize.SMALL}
            canZoom={false}
          />
        </Tooltip>
      ))}
    </>
  );
}
