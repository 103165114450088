import teamsSnapshotJson from "teams-snapshot.json?raw";
import speedrunSnapshotJson from "speedrun-leaderboard-summaries.json?raw";
import membersSnapshotJson from "members-snapshot.json?raw";
import ALL_CARDS from "engine/cards/all-cards-client";
import ALL_MASTERIES from "engine/puzzles/all-masteries-client";
import { Role } from "engine/types/updates";
import { ClientSpeedrunTimeRecord } from "game-server/global-updates";
import { TeamSummaryState } from "game-server/monitoring";
import { BOOTSTRAP_TEAM_ID } from "game-server/backend-interface/MockBackendInterface";
import Globals from "Globals";

const urlParams = new URLSearchParams(window.location.search);

const paramToBool = (param: string | null) => {
  if (param == null) {
    return false;
  }
  // ?param or ?param=1
  return param == "" || param == "1";
};

const windowTeamId = Globals.teamId ?? null;
const windowFishPuzzle = Globals.puzName ?? null;
const djangoBaseUrl = Globals.djangoBaseUrl ?? "http://localhost:8000/";
const isDjango = Globals.djangoBaseUrl !== undefined;
const isPosthunt =
  paramToBool(urlParams.get("posthunt")) || (Globals.isPosthunt ?? false);
const hasAdminAccessOpt = urlParams.get("admin");
const hasAdminAccess =
  hasAdminAccessOpt !== null
    ? paramToBool(hasAdminAccessOpt)
    : Globals.hasAdminAccess ??
      (import.meta.env.MODE === "development" && !isPosthunt);
const isImpersonate = Globals.isImpersonate ?? false;

const DOCUMENT_TITLE = "GalactiCosmiCave";
const workshopMode = paramToBool(urlParams.get("workshop"));
const devModeOpt = urlParams.get("dev");
const devMode =
  devModeOpt === null
    ? import.meta.env.MODE === "development"
    : paramToBool(devModeOpt);
const skipBattlePrep = paramToBool(urlParams.get("noprep")) || workshopMode;
const godTeams = paramToBool(urlParams.get("godteams"));
const teamId = urlParams.get("team") ?? windowTeamId ?? BOOTSTRAP_TEAM_ID;
const isPublicAccess =
  paramToBool(urlParams.get("public")) || (Globals.isPublicAccess ?? false);
const wsEndpointOpt = urlParams.get("ws");
const wsEndpoint =
  wsEndpointOpt !== null &&
  wsEndpointOpt !== "" &&
  // Don't allow setting WS endpoints through urlparams outside
  // dev to prevent XSS attacks.
  import.meta.env.MODE === "development"
    ? wsEndpointOpt
    : Globals.wsEndpoint !== undefined
    ? Globals.wsEndpoint
    : (isDjango
        ? import.meta.env.VITE_DJANGO_WS_ENDPOINT
        : import.meta.env.VITE_WS_ENDPOINT) ?? "ws://localhost:8080";
const cursorsWsEndpointOpt = urlParams.get("cursorsws");
const cursorsWsEndpoint =
  cursorsWsEndpointOpt !== null &&
  cursorsWsEndpointOpt !== "" &&
  // Don't allow setting WS endpoints through urlparams outside
  // dev to prevent XSS attacks.
  import.meta.env.MODE === "development"
    ? cursorsWsEndpointOpt
    : Globals.cursorsWsEndpoint !== undefined
    ? Globals.cursorsWsEndpoint
    : wsEndpoint;
const localMode =
  paramToBool(urlParams.get("local")) ||
  workshopMode ||
  (devMode &&
    !(wsEndpointOpt !== null || paramToBool(urlParams.get("online"))));
// If a teamId is specified through window, then we should be in Django.
const useJwtOpt = urlParams.get("jwt");
const useJwt =
  useJwtOpt !== null
    ? paramToBool(useJwtOpt)
    : !localMode && windowTeamId !== null;
const godRole = paramToBool(urlParams.get("god")) || workshopMode;
const getRole = () => {
  if (godRole) return Role.GOD;
  const roleOpt = urlParams.get("role");
  if (roleOpt === null) return null;
  if ((Object.values(Role) as string[]).includes(roleOpt)) {
    return roleOpt as Role;
  }
  const roleOptDict: { [roleOpt: string]: Role } = {
    "0": Role.GOD,
    "1": Role.P1,
    "2": Role.P2,
  };
  if (roleOpt in roleOptDict) {
    return roleOptDict[roleOpt];
  }
  throw new Error("invalid role option");
};
const ALL_CARD_NAMES = ALL_CARDS.map((card) => card.name);
const parseDeck = (deckSpec: string) => {
  const deck = [];
  if (deckSpec.length != ALL_CARD_NAMES.length) {
    throw new Error("requested deck spec has incorrect length");
  }
  for (let i = 0; i < deckSpec.length; i++) {
    // allow base36 (0-9a-z)
    for (let j = 0; j < parseInt(deckSpec[i], 36); j++) {
      deck.push(ALL_CARD_NAMES[i]);
    }
  }
  return deck;
};
const parseMasteries = (masteriesSpec: string) => {
  const masteryIds = ALL_MASTERIES.map(({ masteryId }) => masteryId).sort();
  if (masteriesSpec.length != masteryIds.length) {
    throw new Error("requested masteries spec has incorrect length");
  }
  return masteryIds.filter((masteryId, i) => masteriesSpec[i] === "1");
};
const getDeck = (): ReadonlyArray<string> | null => {
  const deckSpec = urlParams.get("deck");
  if (deckSpec !== null) {
    return parseDeck(deckSpec);
  }
  return null;
};
const getOppDeck = (): ReadonlyArray<string> | null => {
  const deckSpec = urlParams.get("oppdeck");
  if (deckSpec !== null) {
    return parseDeck(deckSpec);
  }
  return null;
};
const getMasteries = (): ReadonlyArray<string> | null => {
  const masteriesSpec = urlParams.get("masteries");
  if (masteriesSpec !== null) {
    return parseMasteries(masteriesSpec);
  }
  return null;
};
const getOppMasteries = (): ReadonlyArray<string> | null => {
  const masteriesSpec = urlParams.get("oppmasteries");
  if (masteriesSpec !== null) {
    return parseMasteries(masteriesSpec);
  }
  return null;
};

/** Parse a boolean param that is enabled by default in workshop mode. */
const parseWorkshopFlag = (paramName: string) => {
  const paramOpt = urlParams.get(paramName);
  if (paramOpt !== null) {
    return paramToBool(paramOpt);
  }
  return workshopMode;
};
const extraGemsPerTurnOpt = urlParams.get("extragemsperturn");
const extraGemsPerTurn =
  extraGemsPerTurnOpt !== null
    ? parseInt(extraGemsPerTurnOpt)
    : workshopMode
    ? 10
    : null;

const clientDelayOpt = urlParams.get("clientdelay");
const clientDelay =
  clientDelayOpt === null
    ? 0
    : clientDelayOpt === ""
    ? 1000
    : parseInt(clientDelayOpt);
const settings = {
  documentTitle: DOCUMENT_TITLE,
  localMode,
  wsEndpoint,
  cursorsWsEndpoint,
  djangoBaseUrl,
  useJwt,
  skipBattlePrep,
  isPublicAccess,
  isPosthunt,
  hasAdminAccess,
  isImpersonate,
  teamId,
  role: getRole() ?? undefined,
  dumpWsMessages: paramToBool(urlParams.get("dumpws")),
  dumpBackendRequests: paramToBool(urlParams.get("dumpbackend")),
  deck: getDeck(),
  clientDelay,
  oppDeck: getOppDeck(),
  masteries: getMasteries(),
  oppMasteries: getOppMasteries(),
  // Special flag which causes the GOD role to register as both teams.
  // This causes PvP games to start with just GOD in online mode.
  // If starting from battle prep, this lets GOD modify both loadouts.
  godTeams,
  workshop: workshopMode,
  pvpAutoAccept: paramToBool(urlParams.get("autoaccept")),
  teamsSnapshot: JSON.parse(teamsSnapshotJson) as {
    [teamId: string]: TeamSummaryState;
  },
  speedrunLeaderboardSummariesSnapshot: JSON.parse(speedrunSnapshotJson) as {
    [puzName: string]: ClientSpeedrunTimeRecord[];
  },
  membersSnapshot: JSON.parse(membersSnapshotJson) as {
    [teamId: string]: string[];
  },

  // dev knobs
  devKnobs: {
    extraGemsPerTurn: extraGemsPerTurn ?? undefined,
    freeSummon: parseWorkshopFlag("freesummon") ? true : undefined,
    allCardsAvailable: parseWorkshopFlag("allcardsavail") ? true : undefined,
    alwaysReady: parseWorkshopFlag("alwaysready") ? true : undefined,
    noTurnCheck: parseWorkshopFlag("noturncheck") ? true : undefined,
    noShuffle: parseWorkshopFlag("noshuffle") ? true : undefined,
    allowInvalidDecks: parseWorkshopFlag("invaliddecks") ? true : undefined,
    checkpointAfterEveryStep: paramToBool(urlParams.get("checkpointtest"))
      ? true
      : undefined,
    instasolve: paramToBool(urlParams.get("instasolve")) ? true : undefined,
  },
};

export default settings;

export const mockServerOpts = {
  dumpBackendRequests: settings.dumpBackendRequests,
};
