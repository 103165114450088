// Validation for game actions. This is unrelated to the security
// consideration of validating client WS messages.

import { StepType } from "./steps";

/**
 * Validation checks. Some of these only apply to certain game actions.
 * Uses:
 * - Hooks may ignore specific validation checks by deleting the
 *   corresponding key from a FailedChecks structure.
 * - The UI uses the failed check type to figure out how to signpost
 *   the invalid reason to the user.
 * Game spec and card effects validators should use the GENERIC check
 * type unless they would like the invalid reason to be signposted in
 * a different way to the user.
 */
export enum Check {
  // battle prep checks
  LOCKED_IN = "locked_in", // loadout already locked in
  NO_DECK = "no_deck", // no deck was provided
  DECK_TOO_SMALL = "deck_too_small",
  DECK_TOO_LARGE = "deck_too_large",
  DECK_TOO_MANY_LEGENDARIES = "deck_too_many_legendaries",

  // game action checks
  CARD_TYPE = "card_type", // only creatures can move/attack
  ALLIED = "allied", // cannot attack allied units
  PROTECTED = "protected", // cannot attack protected units
  TERRAIN = "terrain", // can only summon to allied terrain
  BOUNDS = "bounds", // can only move/summon to slot in bounds
  OCCUPIED = "occupied", // can only move/summon to unoccupied spaces
  ADJACENT = "adjacent", // can only move to adjacent spaces
  ABILITY_EXISTS = "ability_exists", // activated ability must exist on card
  NUM_OPTS = "num_opts", // activate should specify enough opts
  OPT_TYPE = "opt_type", // activate effect opts should match form type
  DECK_EMPTY = "deck_empty", // cannot draw card from empty deck
  MASTERY = "mastery", // action requires a disabled mastery
  COLOR = "color", // create gems must create a matching color
  CANNOT_MOVE = "cannot_move", // cannot move
  CANNOT_ATTACK = "cannot_attack", // cannot attack
  CANNOT_CREATE = "cannot_create", // cannot create
  INVULNERABLE = "invulnerable", // cannot be damaged
  STALE = "stale", // a race condition was detected
  HIDDEN = "hidden", // the step is hidden
  KEYFRAME = "keyframe", // an active keyframe is blocking the step
  UI_LOCKED = "ui_locked", // the ui is locked (client-only)
  INVALID_CHECKPOINT_SLOT = "invalid_checkpoint_slot",
  INVALID_DIALOG_OPTION = "invalid_dialog_option",
  GAME_ENDED = "game_ended", // no steps allowed after game end

  // general checks
  RESOLVE = "resolve", // step must resolve
  ROLE = "role", // role must have permission for step
  TURN = "turn", // player must act in turn
  COST = "cost", // player must be able to afford step
  READY = "ready", // acting permanent must be ready

  // The Swarm-specific checks
  TOO_FAR = "too_far",

  /** Generic check type for use by effects/game specs. */
  GENERIC = "generic",
}

export type FailedChecks = Set<Check>;

/**
 * Which failed text should we show when it's invalid?
 * Order matters: higher takes priority.
 */
export const getFailText = (failedChecks_: FailedChecks): string | null => {
  const failedChecks = new Set(failedChecks_);
  // We haven't specified any opts yet, so don't disable activate
  // ability buttons due to not enough opts.
  failedChecks.delete(Check.NUM_OPTS);
  if (failedChecks.has(Check.LOCKED_IN)) return "Already locked in.";
  if (failedChecks.has(Check.NO_DECK)) return "No deck selected.";
  if (failedChecks.has(Check.DECK_TOO_MANY_LEGENDARIES))
    return "Deck has too many legendaries.";
  if (failedChecks.has(Check.DECK_TOO_LARGE)) return "Deck too large.";
  if (failedChecks.has(Check.DECK_TOO_SMALL)) return "Deck too small.";
  if (failedChecks.has(Check.INVULNERABLE)) return "Invulnerable.";
  if (failedChecks.has(Check.CARD_TYPE)) return "Not a creature.";
  if (failedChecks.has(Check.ALLIED)) return "Unit is allied.";
  if (failedChecks.has(Check.PROTECTED)) return "Unit is protected.";
  if (failedChecks.has(Check.TERRAIN)) return "Space is on the wrong side.";
  if (failedChecks.has(Check.OCCUPIED)) return "Space is occupied.";
  if (failedChecks.has(Check.TOO_FAR)) return "Too far away.";
  if (failedChecks.has(Check.ROLE)) return "You can't do that.";
  if (failedChecks.has(Check.TURN)) return "Not your turn.";
  if (failedChecks.has(Check.READY)) return "Not Ready.";
  if (failedChecks.has(Check.COST)) return "Can't afford.";
  if (failedChecks.size !== 0) return "Invalid.";
  return null;
};

/**
 * Is this set of failedChecks a passthrough, i.e. invalid, and we
 * don't even show it as an option?
 */
export const isPassthrough = (
  failedChecks_: FailedChecks,
  stepType?: StepType
): boolean => {
  // Keep only the failedChecks that *will* trigger a passthrough,
  // i.e. delete all the checks we *do* want to show.
  const failedChecks = new Set(failedChecks_);

  // We might still want to display the step as a preview even if it's
  // invalid, for example if we just can't afford it.
  failedChecks.delete(Check.COST);
  failedChecks.delete(Check.READY);
  failedChecks.delete(Check.PROTECTED);
  failedChecks.delete(Check.INVULNERABLE);
  failedChecks.delete(Check.TOO_FAR);
  failedChecks.delete(Check.GENERIC);

  // Preview move and attack ranges for opponent permanents.
  if (
    stepType &&
    [StepType.MOVE, StepType.ATTACK, StepType.SUMMON].includes(stepType)
  ) {
    failedChecks.delete(Check.TURN);
    failedChecks.delete(Check.ROLE);
  }

  if (stepType !== undefined && [StepType.END_TURN].includes(stepType))
    failedChecks.delete(Check.UI_LOCKED);

  return failedChecks.size > 0;
};
