import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedTutorial5Spec: SharedGameSpec = {
  allowCheckpoints: true,
  aiDisableGemAccounting: true,
  hasAI: true,
  deckOverrides: {
    [Player.P1]: [
      "beeowulf",
      "beeowulf",
      "pbee---j",
      "pbee---j",
      "two-bees",
      "two-bees",
      "chocolate-calf",
      "chocolate-calf",
      "new-boarn",
      "new-boarn",
      "hog",
      "hog",
      "hamlet",
      "hamlet",
      "yoshisaur-munchakoopas",
      "yoshisaur-munchakoopas",
    ],
    [Player.P2]: [],
  },
};
