import "./GemDisplay.css";
import { ColorSymbol, GemColor } from "engine/types/card-data";
import { Player } from "engine/types/game-state";
import { cssIdFor } from "engine/types/keyframes";
import { useInspector } from "stores/ClientGameStore";
import { useHoverActionStore } from "stores/HoverActionStore";
import ColorSymbolIcon from "./ColorSymbolIcon";
import { HTMLProps } from "react";
import Tooltip from "./Tooltip";
import FormattedText from "./FormattedText";
import { GEM_TEXT_TO_TOOLTIP_TEXT } from "./FormattedText.util";

export enum GemType {
  /** Gem should be displayed as-is. */
  SOLID = "solid",
  /** Gem should be displayed crossed out. */
  CROSSED = "crossed",
  /** Gem should be displayed as "new". */
  NEW = "new",
}

type PlayerGemProps = {
  type: GemType;
  color: ColorSymbol;
};

interface PlayerGemsProps {
  player: Player;
}

function gemColorValue(color: GemColor): number {
  switch (color) {
    case ColorSymbol.RAINBOW:
      return 0;
    case ColorSymbol.RED:
      return 1;
    case ColorSymbol.YELLOW:
      return 2;
    case ColorSymbol.GREEN:
      return 3;
    case ColorSymbol.PURPLE:
      return 4;
    case ColorSymbol.WHITE:
      return 5;
    case ColorSymbol.BLACK:
      return 6;
  }
}

function gemTypeValue(t: GemType): number {
  switch (t) {
    case GemType.SOLID:
      return 0;
    case GemType.CROSSED:
      return 1;
    case GemType.NEW:
      return 2;
  }
}

export function PlayerGemsDisplay(
  props: PlayerGemsProps & HTMLProps<HTMLDivElement>
) {
  const { player, ...htmlProps } = props;

  const hover = useHoverActionStore();
  const inspector = useInspector();

  const payment = !inspector.isTurnPending(player) ? [] : hover.payment ?? [];
  const gain = !inspector.isTurnPending(player) ? [] : hover.gain ?? [];
  const playerGems = inspector.getPlayerGems(player);

  const gemsProps = ((): PlayerGemProps[] => {
    const scratchGemsProps = playerGems.map((color) => {
      return {
        type: GemType.SOLID,
        color,
      };
    });
    for (const color of payment) {
      // Find a gem to cross out.
      const index = scratchGemsProps.findIndex((prop) => {
        return prop.type === GemType.SOLID && prop.color === color;
      });
      // Due to race conditions between updating the game state and
      // the hover action state, the payment may transiently be
      // invalid. Just ignore failures when this happens.
      if (index === -1) continue;
      scratchGemsProps[index].type = GemType.CROSSED;
    }
    scratchGemsProps.push(
      ...gain.map((color) => {
        return {
          type: GemType.NEW,
          color,
        };
      })
    );
    scratchGemsProps.sort((a, b) => {
      const aColorVal = gemColorValue(a.color);
      const bColorVal = gemColorValue(b.color);
      if (aColorVal !== bColorVal) return bColorVal - aColorVal;
      const aTypeVal = gemTypeValue(a.type);
      const bTypeVal = gemTypeValue(b.type);
      return bTypeVal - aTypeVal;
    });
    return scratchGemsProps;
  })();

  // Create an array of color frequency counts (in the same order as gemsProps).
  // Assumes that all gems of the same type in gemsProps are adjacent.
  const counts = new Array<[{ type: GemType; color: ColorSymbol }, number]>();
  gemsProps.forEach(({ type, color }) => {
    const lastItem = counts[counts.length - 1];
    if (
      !lastItem ||
      !(lastItem[0].type === type && lastItem[0].color === color)
    ) {
      counts.push([{ type, color }, 1]);
    } else {
      lastItem[1]++;
    }
  });
  const totalCount = gemsProps.length;

  return (
    <div id={cssIdFor.gems(player)} className="gem-gems" {...htmlProps}>
      {counts
        .flatMap(([{ type, color }, count], i) =>
          count > 5 || (count >= 2 && totalCount >= 10)
            ? [GemGroup(type, color, count, i)]
            : Array(count).fill(IndividualGem(type, color, i))
        )
        .map((e, i) => (
          <span key={i}>{e}</span>
        ))}
    </div>
  );
}

function IndividualGem(type: GemType, color: ColorSymbol, i?: number) {
  const renderIcon = (props: Record<string, unknown>) => {
    switch (type) {
      case GemType.SOLID:
        return <ColorSymbolIcon symbol={color} {...props} />;
      case GemType.CROSSED:
        return <ColorSymbolIcon symbol={color} crossed {...props} />;
      case GemType.NEW:
        return <ColorSymbolIcon symbol={color} dashed {...props} />;
    }
  };
  return (
    <Tooltip
      key={i}
      text={<FormattedText text={GEM_TEXT_TO_TOOLTIP_TEXT[color]} />}
      directRef={(props) => renderIcon(props)}
    />
  );
}

function GemGroup(type: GemType, color: ColorSymbol, count: number, i: number) {
  return (
    <div className="compressed-row" key={i}>
      {IndividualGem(type, color)}
      <span className="count">{count}×</span>
    </div>
  );
}

export interface GemProp {
  color: ColorSymbol;
  type: GemType;
}

interface GemsProps {
  gems: GemProp[];
  inline?: boolean;
}

export function GemsDisplay(props: GemsProps & HTMLProps<HTMLDivElement>) {
  const { gems, inline, ...htmlProps } = props;
  return (
    <div className={`gem-gems${inline ? " inline" : ""}`} {...htmlProps}>
      {gems.map((gemProp, i) => IndividualGem(gemProp.type, gemProp.color, i))}
    </div>
  );
}
