import { useEffect } from "react";
import {
  useSubscribedTeam,
  useSubscribedTeamFishPuzzle,
} from "stores/ClientGlobalStateStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import { Spinner } from "components/Loading";
import Globals from "Globals";
import settings from "settings";

const PreviousGuessesDisplay = () => {
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();
  const teamId = serverInterfaceController?.getTeamId() ?? null;
  const { puzName } = Globals;
  if (puzName === undefined) throw new Error("expect puzName to be defined");
  const subscribedTeamFishPuzzle = useSubscribedTeamFishPuzzle(teamId, puzName);
  const { setSubscribedTeamFishPuzzle } = useGlobalSubscriptionStore();
  const subscribedTeam = useSubscribedTeam();
  const battleGroups = subscribedTeam?.battleGroups ?? null;

  const puzzles = subscribedTeam?.puzzles ?? null;
  const puzData = puzzles?.[puzName] ?? null;
  const hasAnswer = puzData === null ? null : puzData.hasAnswer ?? false;

  useEffect(() => {
    if (teamId === null) return;
    if (hasAnswer === null) return;
    if (!hasAnswer) return;

    setSubscribedTeamFishPuzzle({ teamId, puzName });
    return () => {
      setSubscribedTeamFishPuzzle(null);
    };
  }, [teamId, puzName, hasAnswer, setSubscribedTeamFishPuzzle]);

  if (!hasAnswer) return null;
  if (subscribedTeamFishPuzzle === null) return <Spinner inline={true} />;

  const { previousGuesses } = subscribedTeamFishPuzzle;

  // If there are no previous guesses, then hide the interface entirely.
  if (settings.isPosthunt || previousGuesses.length === 0) return null;

  return (
    <>
      <h4>Previous guesses</h4>
      <ul>
        {previousGuesses.map((submission) => (
          <li key={submission.submittedAnswer}>
            <span className="submitted-text">{submission.submittedAnswer}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PreviousGuessesDisplay;
