import { createContext, useMemo } from "react";
import { Player } from "engine/types/game-state";
import { useInspector, usePlayerPerspective } from "stores/ClientGameStore";
import { useKeyboardCursorStore } from "stores/KeyboardCursorStore";
import { useUserPreferencesStore } from "stores/UserPreferencesStore";
import CurrentTurn from "./CurrentTurn";
import EffectOptOverlays from "./EffectOptOverlays";
import "./FieldDisplay.css";
import PermanentsDisplay from "./PermanentsDisplay";
import SlotDisplay from "./SlotDisplay";
import StepOverlays from "./StepOverlays";

type FieldDisplayContext = {
  disableRecursion?: boolean;
  disableTooltips?: boolean;
};

export const FieldDisplayContext = createContext<FieldDisplayContext>({});

function FieldDisplay() {
  const inspector = useInspector();
  const playerUs = usePlayerPerspective();
  const rows = inspector.getNumRows();
  const columns = inspector.getNumColumns();
  const smaller = rows >= 8 || columns >= 10;

  const recurseFieldTooltips = useUserPreferencesStore(
    (state) => state.recurseFieldTooltips
  );
  const tooltipOpts = useMemo(
    () => ({
      disableRecursion: !recurseFieldTooltips,
    }),
    [recurseFieldTooltips]
  );
  const slotWithFocus = useKeyboardCursorStore((state) =>
    state.getFieldSlotWithFocus()
  );

  const slotsDom = [];
  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < columns; j++) {
      const background = inspector
        .getSlotBackground({ row: i, column: j })
        ?.match(/^color:(.*)$/i)?.[1];

      row.push(
        <SlotDisplay
          key={i * columns + j}
          row={i}
          column={j}
          smaller={smaller}
          background={background}
          keyboardFocused={
            !!slotWithFocus &&
            slotWithFocus.row === i &&
            slotWithFocus.column === j
          }
        />
      );
    }
    slotsDom.push(
      <div key={i} className="game-board-row">
        {row}
      </div>
    );
  }

  return (
    <div id="game-board" className={`game-board ${smaller ? "smaller" : ""}`}>
      <CurrentTurn />
      {playerUs === Player.P1 ? slotsDom : slotsDom.reverse()}
      <FieldDisplayContext.Provider value={tooltipOpts}>
        <PermanentsDisplay smaller={smaller} />
      </FieldDisplayContext.Provider>
      <StepOverlays />
      <EffectOptOverlays />
    </div>
  );
}

export default FieldDisplay;
