import {
  PermanentEventType,
  PermanentEventHandlers,
} from "engine/server-hooks";
/**
 * Types of counters used in the game.
 * A counter serves a similar role to real-life counters. They
 * may be used to track all sorts of custom modifications to
 * a permanent's state, such as:
 * - Buffs, debuffs and other status effects.
 * - Statistics required for game mechanics or card effects.
 * - Reminders to carry out an action at a later time, or when a
 *   certain game event is triggered (see PermanentEvent).
 */
export enum CounterType {
  /** Generic counter just used to run effects on expiry. */
  EFFECT = "effect",

  BASE_GEMS = "base_gems",
  POWER_ADJUSTMENT = "power_adjustment",
  MAX_HEALTH_ADJUSTMENT = "max_health_adjustment",
  SHELL_ADJUSTMENT = "shell_adjustment",
  IGNORED_DAMAGE = "ignored_damage",
  DAMAGE_TAKEN = "damage_taken",
  SHELL_DAMAGE_TAKEN = "shell_damage_taken",

  INVULNERABLE = "invulnerable",
  CANNOT_BE_DESTROYED = "cannot_be_destroyed",
  CANNOT_MOVE = "cannot_move",
  CANNOT_ATTACK = "cannot_attack",
  CANNOT_CREATE = "cannot_create",

  DOES_NOT_PROTECT = "does_not_protect",

  /** Allows friendly creatures to move to an adjacent empty space. */
  ALLOWS_MOVE_TO_ADJACENT = "allows_move_to_adjacent",
  /** Protects friendly units in adjacent columns. */
  PROTECTS_ADJACENT_COLUMNS = "protects_adjacent_columns",
  /** When this permanent destroys another, it moves to the destroyed permanent's space if possible. */
  BODY_SLAM = "body_slam",
  /** Will not ready on its next turn. */
  FROZEN = "frozen",
  /** Units with poison take one damage at the start of their turn. Stacks. */
  POISON = "poison",
  /** Units with this counter are destroyed on the end of the turn. */
  DESTROY_ON_END_OF_TURN = "destroy_on_end_of_turn",
  /** Prevents one instance of damage. */
  DIVINE_SHIELD = "divine_shield",
  /** This permanent attacks without paying gems. */
  ATTACKS_FOR_FREE = "attacks_for_free",

  // Counters that indicate actions in response to game events.
  // These may be very specific to carry out particular card
  // functionality.
  CAT_SPECIAL_PENDING = "cat_special_pending",
  DOOM = "doom",
  SUMMONED_THIS_TURN = "summoned_this_turn",
  FLEXED_THIS_TURN = "flexed_this_turn",
  ASLEEP = "asleep",

  /** Number of happy bears in the Bearhive, for Puzzle 51. */
  HAPPY_BEARS = "happy_bears",
  /** Number of sad bears in the Bearhive, for Puzzle 51. */
  SAD_BEARS = "sad_bears",
}

export type CounterData = {
  handlers?: PermanentEventHandlers;
};

type CounterBase = {
  type: CounterType;
  /** An optional number associated with the counter. */
  val?: number;
  /**
   * Value to override the explanation of the counter, shown when
   * hovering over it in the zoomed card display.
   * Explanations that do not need to be generated by the user (i.e.
   * are user-visible, if we're doing code hiding) should go directly in
   * getCounterExplanation in StatDisplay.tsx.
   * If there is no explanation either here or there, the counter will
   * not be displayed to the user.
   */
  explanation?: string;
  /**
   * If expiry is omitted, then the counter expires when the permanent
   * is destroyed.
   */
  expiry?: PermanentEventType;
};

type SimpleCounter = CounterBase & {
  type:
    | CounterType.EFFECT
    | CounterType.INVULNERABLE
    | CounterType.CANNOT_BE_DESTROYED
    | CounterType.CANNOT_MOVE
    | CounterType.CANNOT_ATTACK
    | CounterType.CANNOT_CREATE
    | CounterType.DOES_NOT_PROTECT
    | CounterType.ALLOWS_MOVE_TO_ADJACENT
    | CounterType.PROTECTS_ADJACENT_COLUMNS
    | CounterType.FROZEN
    | CounterType.CAT_SPECIAL_PENDING
    | CounterType.BODY_SLAM
    | CounterType.DESTROY_ON_END_OF_TURN
    | CounterType.DIVINE_SHIELD
    | CounterType.ATTACKS_FOR_FREE
    | CounterType.SUMMONED_THIS_TURN
    | CounterType.FLEXED_THIS_TURN
    | CounterType.ASLEEP;
};

export type ValueCounterType =
  | CounterType.BASE_GEMS
  | CounterType.POWER_ADJUSTMENT
  | CounterType.MAX_HEALTH_ADJUSTMENT
  | CounterType.SHELL_ADJUSTMENT
  | CounterType.IGNORED_DAMAGE
  | CounterType.DAMAGE_TAKEN
  | CounterType.SHELL_DAMAGE_TAKEN
  | CounterType.DOOM
  | CounterType.POISON
  | CounterType.HAPPY_BEARS // Puzzle 51
  | CounterType.SAD_BEARS; // Puzzle 51

type ValueCounter = CounterBase & {
  type: ValueCounterType;
  val: number;
};

/**
 * Counters are markers placed on a card that change it. Counters may be tied
 * to an expiry event, and potentially trigger an action on expiry.
 */
export type Counter = Readonly<SimpleCounter | ValueCounter>;

export const getCounterExplanation = (counter: Counter): string | null => {
  const { explanation } = counter;

  // Explanation defined on the counter always overrides the default
  // explanations.
  if (explanation !== undefined) return explanation;

  switch (counter.type) {
    case CounterType.CANNOT_BE_DESTROYED:
      return "Indestructable.";
    case CounterType.INVULNERABLE:
      return "Invulnerable.";
    default:
      return null;
  }
};
