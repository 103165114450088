/** Maximum length for the deck display name. */
export const DECK_DISPLAY_NAME_MAX_LENGTH = 63;
/** Number of deck slots per team. */
export const NUM_DECK_SLOTS = 12;

export type Deck = {
  displayName: string;
  /** Number of each card in the deck. */
  cards: {
    [cardName: string]: number;
  };
  /**
   * Card name of the card that would be put at the top of the deck,
   * if present in the deck. Only used when the relevant mastery
   * is enabled.
   */
  priorityCard?: string;
};

export const createNewDeck = (slot: number): Deck => {
  const timeNow = new Date().getTime();

  return {
    displayName: `Deck ${slot + 1}`,
    cards: {},
  };
};

export const cloneDeck = (deck: Deck): Deck => {
  return { ...deck, cards: { ...deck.cards } };
};

export const getDeckSize = (deck: Deck): number => {
  let sz = 0;
  for (const [cardName, val] of Object.entries(deck.cards)) {
    sz += val;
  }
  return sz;
};

export const getDeckCardCount = (deck: Deck, cardName: string): number => {
  return deck.cards[cardName] ?? 0;
};

export const deckToCardList = (deck: Deck): string[] => {
  const cardList = [];
  for (const [cardName, cardCount] of Object.entries(deck.cards)) {
    for (let i = 0; i < cardCount; i++) {
      cardList.push(cardName);
    }
  }
  return cardList;
};

export type DeckUpdate = {
  displayName?: string;
  /**
   * If clear is set to true, resets the deck to a clean state without touching
   * displayName. This is done before applying any of the other updates.
   */
  clear?: true;
  cards?: {
    [cardName: string]: number;
  };
  priorityCard?: string;
  deletePriorityCard?: true;
};

export const applyDeckUpdate = (deck: Deck, upd: DeckUpdate): void => {
  const { displayName, cards, priorityCard, deletePriorityCard, clear } = upd;
  if (clear ?? false) {
    deck.cards = {};
    delete deck.priorityCard;
  }
  if (displayName !== undefined)
    deck.displayName = displayName.slice(0, DECK_DISPLAY_NAME_MAX_LENGTH + 1);
  if (cards !== undefined) {
    for (const [cardName, val] of Object.entries(cards)) {
      if (val === 0) {
        delete deck.cards[cardName];
        continue;
      }
      deck.cards[cardName] = val;
    }
  }
  if (priorityCard !== undefined) deck.priorityCard = priorityCard;
  if (deletePriorityCard ?? false) delete deck.priorityCard;
  if (
    deck.priorityCard !== undefined &&
    deck.cards[deck.priorityCard] === undefined
  )
    delete deck.priorityCard;
};

export type DeckSelection = {
  slot: number | null;
  deck: Deck;
};
