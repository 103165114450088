import { CardsDB } from "engine/cards/CardsDB";
import {
  COMMON_CARD_EFFECTS_SHARED,
  CardEffectsShared,
} from "engine/cards/card-effects-shared";
import { ClientGameSpec } from "engine/types/client-game-specs";

/**
 * Information that needs to be acquired from the player to fully specify the
 * activation. These are split up to make the effects spec shorter.
 */
// Currently, we do not have any client-only effect specs.
export type CardEffectsClient = CardEffectsShared;

export type ClientEffectsDB = { [cardName: string]: CardEffectsClient };

const mergeSharedEffects = (
  allCardEffectsClient: ClientEffectsDB
): ClientEffectsDB => {
  for (const [cardName, sharedEffects] of Object.entries(
    COMMON_CARD_EFFECTS_SHARED
  )) {
    allCardEffectsClient[cardName] = {
      ...(allCardEffectsClient[cardName] ?? {}),
      ...sharedEffects,
    };
  }
  return allCardEffectsClient;
};

export const ALL_CARD_EFFECTS_CLIENT: {
  [cardName: string]: CardEffectsClient;
} = mergeSharedEffects({});

/** Merges auxEffectsDB into effectsDB. */
const mergeEffectsDB = (
  effectsDB: ClientEffectsDB,
  auxEffectsDB?: {
    [cardName: string]: CardEffectsShared | CardEffectsClient;
  }
): void => {
  if (auxEffectsDB === undefined) return;
  for (const [cardName, auxEffects] of Object.entries(auxEffectsDB)) {
    effectsDB[cardName] = {
      ...(effectsDB[cardName] ?? {}),
      ...auxEffects,
    };
  }
};

export const getClientEffectsDB = (
  allSpecs: ReadonlyArray<ClientGameSpec>,
  cardsDB: CardsDB
): ClientEffectsDB => {
  const effectsDB: ClientEffectsDB = {};
  for (const spec of allSpecs) {
    mergeEffectsDB(effectsDB, spec.makeCardEffectsShared?.(cardsDB));
    mergeEffectsDB(effectsDB, spec.cardEffectsShared);
  }
  return effectsDB;
};
