import { Inspector } from "engine/Inspector";
import {
  ClientAnswerSubmissionGlobalState,
  ClientGlobalState,
} from "game-server/global-updates";
import { ServerInterfaceController } from "game-server/server-interface/ServerInterfaceController";

const Globals = window as unknown as {
  djangoBaseUrl?: string;
  wsEndpoint?: string;
  cursorsWsEndpoint?: string;
  isPublicAccess?: true;
  isPosthunt?: true;
  hasAdminAccess?: boolean;
  isImpersonate?: boolean;
  teamId?: string;
  puzName?: string;
  previousGuesses?: ClientAnswerSubmissionGlobalState[];
  numGuessesRemaining?: number;
  viewedTeam?: {
    teamId: string;
    members: string[];
  };
  toastr: {
    success: (msg: string) => void;
    warning: (msg: string) => void;
    error: (msg: string) => void;
    info: (
      msg: string,
      title?: string,
      opts?: {
        toastClass?: string;
        timeOut?: number;
        extendedTimeOut?: number;
        closeButton?: boolean;
        onclick?: () => void;
      }
    ) => void;
  };
  showNotify: (data: {
    title: string;
    text: string;
    link: string;
    isImportant?: boolean;
  }) => void;

  // Debug backdoors. Do not use this for anything functional;
  // these just let us view the state in the browser.
  inspector?: Inspector;
  serverInterfaceController?: ServerInterfaceController;
  globalState?: ClientGlobalState;
};

Globals.showNotify = (data) => {
  const { title, text, link, isImportant } = data;
  Globals.toastr.info(text, title, {
    toastClass: isImportant ?? false ? "toast-important" : undefined,
    timeOut: 30000,
    extendedTimeOut: 30000,
    closeButton: true,
    onclick: () => window.open(link, "_blank"),
  });
};

export default Globals;
export const { toastr, showNotify } = Globals;
