import { useCallback } from "react";
import { InspectorInfo, useKeyboardCursorStore } from "./KeyboardCursorStore";
import { useTurnStore } from "./TurnStore";
import { useCardsDBStore } from "stores/CardsDBStore";
import { makeInspector, useClientGameStore } from "stores/ClientGameStore";
import { useHoverActionStore } from "stores/HoverActionStore";
import { Player } from "engine/types/game-state";
import { AbilityType, EffectOpt, EffectOptForm } from "engine/types/effects";
import { StepMaker } from "engine/StepMaker";

export function useResetSelectedCard() {
  const turnResetCard = useTurnStore((state) => state.resetSelectedCard);
  const keyboardResetCard = useKeyboardCursorStore(
    (state) => state.resetSelectedCard
  );

  return useCallback(() => {
    turnResetCard();
    keyboardResetCard();
  }, [turnResetCard, keyboardResetCard]);
}

export function useSelectFieldPermanent() {
  const turnSelectFieldPermanent = useTurnStore(
    (state) => state.setSelectedCardPermanent
  );
  const keyboardSelectFieldPermanent = useKeyboardCursorStore(
    (state) => state.selectFieldPermanent
  );

  return useCallback(
    (permanentId: string, isKeyframeSelected?: boolean) => {
      const inspectorInfo = getCurrentInspectorInfo();
      turnSelectFieldPermanent(permanentId, isKeyframeSelected);
      keyboardSelectFieldPermanent(permanentId, inspectorInfo);
    },
    [turnSelectFieldPermanent, keyboardSelectFieldPermanent]
  );
}

export function useSelectCardHand() {
  const turnSetSelected = useTurnStore((state) => state.setSelectedCardHand);
  const keyboardSetSelected = useKeyboardCursorStore(
    (state) => state.selectHandCard
  );

  return useCallback(
    (player: Player, handCardId: string) => {
      const inspectorInfo = getCurrentInspectorInfo();
      turnSetSelected(player, handCardId);
      keyboardSetSelected(player, handCardId, inspectorInfo);
    },
    [turnSetSelected, keyboardSetSelected]
  );
}

export function useSelectCardHandAllCardsAvailable() {
  const turnSetSelected = useTurnStore(
    (state) => state.setSelectedCardHandAllCardsAvailable
  );
  const keyboardSetSelected = useKeyboardCursorStore(
    (state) => state.selectHandCardAllCardsAvailable
  );

  return useCallback(
    (player: Player, name: string) => {
      const inspectorInfo = getCurrentInspectorInfo();
      turnSetSelected(player, name);
      keyboardSetSelected(player, name, inspectorInfo);
    },
    [turnSetSelected, keyboardSetSelected]
  );
}

export function useResetEffectOpts() {
  const resetEffectOpts = useTurnStore((state) => state.resetEffectOpts);
  const keyboardResetEffectOpts = useKeyboardCursorStore(
    (state) => state.resetEffectOpts
  );

  return useCallback(() => {
    keyboardResetEffectOpts();
    resetEffectOpts();
  }, [keyboardResetEffectOpts, resetEffectOpts]);
}

export function useSelectEffectOptAndResetIfDone() {
  const selectEffectOptAndResetIfDone = useTurnStore(
    (state) => state.selectEffectOptAndResetIfDone
  );
  const keyboardResetEffectOpts = useKeyboardCursorStore(
    (state) => state.resetEffectOpts
  );
  const keyboardSyncToPendingEffectOpt = useKeyboardCursorStore(
    (state) => state.syncToPendingEffectOpt
  );

  return useCallback(
    (opt: EffectOpt): null | EffectOpt[] => {
      const optsOrNull = selectEffectOptAndResetIfDone(opt);
      if (optsOrNull) {
        keyboardResetEffectOpts();
      } else {
        const form = useTurnStore.getState().pendingForms[0];
        if (form) {
          keyboardSyncToPendingEffectOpt(form);
        }
      }
      return optsOrNull;
    },
    [
      selectEffectOptAndResetIfDone,
      keyboardResetEffectOpts,
      keyboardSyncToPendingEffectOpt,
    ]
  );
}

export function useStartEffect() {
  const startEffect = useTurnStore((state) => state.startEffect);
  const keyboardSyncToPendingEffectOpt = useKeyboardCursorStore(
    (state) => state.syncToPendingEffectOpt
  );

  return useCallback(
    (abilityType: AbilityType, effectForms: EffectOptForm[]) => {
      startEffect(abilityType, effectForms);
      if (effectForms.length) {
        keyboardSyncToPendingEffectOpt(effectForms[0]);
      }
    },
    [startEffect, keyboardSyncToPendingEffectOpt]
  );
}
// Warning: only call this within the event handler! If you call it and
// bind it to a variable in a handler, it will be stuck with the old reference.
function getCurrentInspectorInfo(): InspectorInfo {
  const { cardsDB } = useCardsDBStore.getState();
  const { gameState, role } = useClientGameStore.getState();
  const inspector = makeInspector(cardsDB, gameState);
  const stepMaker = new StepMaker(inspector, undefined, role);
  return {
    inspector,
    stepMaker,
    role,
  };
}

export function useResetGameUIActionsState() {
  const resetSelectedCard = useResetSelectedCard();
  const resetEffectOpts = useResetEffectOpts();
  const resetHoverState = useHoverActionStore((state) => state.resetHoverState);
  return useCallback(() => {
    resetSelectedCard();
    resetEffectOpts();
    resetHoverState();
  }, [resetSelectedCard, resetEffectOpts, resetHoverState]);
}
