import { CounterType } from "engine/types/counters";
import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const ASTEROID_1_NAME = "apocalyptic-asteroid";
export const ASTEROID_2_NAME = "asteroid-fragment";
export const ASTEROID_3_NAME = "meaty-ore";

function asteroidAnnounce(val: number) {
  switch (val) {
    case 0:
      return "BOOM!!!!!!!";
    case 1:
      return "This asteroid lands in 1 turn!";
    default:
      return `This asteroid lands in ${val} turns!`;
  }
}

export const SharedPuzzle82Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  basesOverrides: {
    [Player.P2]: [],
  },
  cardEffectsShared: {
    [ASTEROID_1_NAME]: {
      computeText: (permanent, ctx) => {
        const doom = ctx.inspector.getCounterValSum(
          permanent,
          CounterType.DOOM
        );
        return `${asteroidAnnounce(doom)}
        *If only we had known this were coming!*`;
      },
    },
    [ASTEROID_2_NAME]: {
      computeText: (permanent, ctx) => {
        const doom = ctx.inspector.getCounterValSum(
          permanent,
          CounterType.DOOM
        );
        return `${asteroidAnnounce(doom)}
        *I'm not sure what you thought blasting an asteroid would do.*`;
      },
    },
    [ASTEROID_3_NAME]: {
      computeText: (permanent, ctx) => {
        const doom = ctx.inspector.getCounterValSum(
          permanent,
          CounterType.DOOM
        );
        return `${asteroidAnnounce(doom)}
        Cannot Attack.
        When this Creature lands, set its Health to equal its Power. For each Enemy Unit, deal damage to this Creature equal to that Unit's Health, then destroy that Unit.
        *You’ve revealed the meaty ore within the asteroid! It looks like you’ll just have to absorb the impact though...*`;
      },
    },
  },
};
