import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";
import { Check } from "engine/types/action-validation";

export const BASE1 = "pepper-rich-farm";
export const BASE2 = "train-boar-ding-platform";
export const BOAR = "angry-boarry-farmer";

export const SharedPuzzle109Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P2]: [BASE1, BASE2, BASE1],
  },
  cardEffectsShared: {
    [BASE1]: {
      isStructure: true,
      isBase: true,
    },
    [BASE2]: {
      isStructure: true,
      isBase: true,
    },
    [BOAR]: {
      adjustAttackChecks: (failedChecks, attacker, defender, ctx) => {
        if (attacker.slot.column === defender.slot.column) {
          return;
        }
        if (ctx.inspector.getTerrainOf(defender.slot) === attacker.owner) {
          return;
        }
        failedChecks.add(Check.GENERIC);
      },
      adjustMoveChecks: (failedChecks, permanent, slot, ctx) => {
        if (
          ctx.inspector.getPermanents({ owner: permanent.owner, name: BASE2 })
        ) {
          failedChecks.delete(Check.ADJACENT);
        }
      },
    },
  },
};
