import { HELP_TEXT } from "components/FormattedText.util";
import Icon, { IconType } from "components/Icon";
import Tooltip from "components/Tooltip";
import { CardType } from "engine/types/card-data";
import { Counter, getCounterExplanation } from "engine/types/counters";
import { Permanent } from "engine/types/game-state";
import { HTMLProps, memo, PropsWithChildren } from "react";
import { useInspector } from "stores/ClientGameStore";
import Delta from "./Delta";
import "./StatDisplay.css";

interface StatBoxProps {
  icon: IconType;
}

/** A little box for stats. */
function StatBox(
  props: PropsWithChildren<StatBoxProps> & HTMLProps<HTMLDivElement>
) {
  const { children, icon } = props;
  return (
    <div {...props} className={"stat-box " + (props.className ?? "")}>
      <Icon icon={icon} />
      {children}
    </div>
  );
}

interface StatDisplayProps {
  type: "power" | "shell" | "health";
  base: number;
  max: number | null;
  current: number;
  showDelta?: boolean;
  /** An ID to assign to the element, if necessary. */
  eltId?: string;
}

const StatDisplay = memo((props: StatDisplayProps) => {
  const { type, base, max, current, showDelta = false, eltId } = props;

  const icon = {
    power: IconType.POWER,
    shell: IconType.SHELL,
    health: IconType.HEALTH,
  }[type];

  const isModified = base !== (max ?? current);
  const help = HELP_TEXT[type].text;
  const text = isModified
    ? `${help}\nModified: original value is ${base}.`
    : help;

  return (
    <Tooltip text={text} format={true}>
      <StatBox
        id={eltId}
        className={`card-${type} ${isModified ? "stat-modified" : ""}`}
        icon={icon}
      >
        <Delta value={current} showDelta={showDelta} />
        {max && max !== current ? `/${max}` : null}
      </StatBox>
    </Tooltip>
  );
});
StatDisplay.displayName = "StatDisplay";

interface CardStatDisplayProps {
  type: "power" | "shell" | "health";
  cardName?: string;
  permanent?: Permanent;
  showDelta?: boolean;
  /** An ID to assign to the element, if necessary. */
  eltId?: string;
}

/** An IconBox for a card/permanent's power/shell/health. */
function CardStatDisplay(props: CardStatDisplayProps) {
  const { type, permanent, showDelta, eltId } = props;
  const inspector = useInspector();

  const card = props.cardName ?? permanent?.card.name;
  if (!card) return null;

  const { base, current, max } = (() => {
    if (type === "power") {
      const base = inspector.getBasePower(card);
      const current = permanent ? inspector.getPower(permanent) : base;
      return { base, current, max: null };
    }
    if (type === "shell") {
      const base = inspector.getBaseShell(card);
      const max = permanent ? inspector.getShell(permanent) : base;
      const current = permanent ? inspector.getUndamagedShell(permanent) : max;
      return { base, current, max };
    }
    if (type === "health") {
      const base = inspector.getBaseHealth(card);
      const max = permanent ? inspector.getMaxHealth(permanent) : base;
      const current = permanent ? inspector.getHealth(permanent) : max;
      return { base, current, max };
    }
    throw new Error("must be power/shell/health");
  })();

  if (type === "power" && inspector.getCardType(card) === CardType.STRUCTURE) {
    return null;
  }

  if (type === "shell" && max === 0) {
    return null;
  }

  return (
    <StatDisplay
      eltId={eltId}
      type={type}
      base={base}
      max={max}
      current={current}
      showDelta={showDelta}
    />
  );
}

interface CounterDisplayProps {
  counter: Counter;
}

/** An IconBox, possibly with text next to it, for a counter. */
function CounterDisplay(props: CounterDisplayProps) {
  const { counter } = props;
  const explanation = getCounterExplanation(counter);

  // counter isn't UI-visible, so don't show it
  if (explanation === null) return null;

  // TODO: choose different icons for different counter types
  const icon = IconType.SPECIAL;

  return (
    <div className="counter-display">
      <Tooltip text={explanation} format={true}>
        <StatBox icon={icon} />
      </Tooltip>
    </div>
  );
}

interface CountersDisplayProps {
  permanent?: Permanent;
}

/** Counters. */
function CountersDisplay(props: CountersDisplayProps) {
  const { permanent } = props;

  const counters = Array.from(permanent?.counters.entries() ?? []).map(
    ([i, counter]) => <CounterDisplay key={i} counter={counter} />
  );

  return <div className="card-counters">{counters}</div>;
}

export { CardStatDisplay, CountersDisplay };
