import { CardSize } from "components/CardDisplay/types";
import produce from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

// Note: Ideally these stores should be as separated as possible
// so that they don't overwrite each other in localstorage
// (i.e. if user has two tabs open each using different preference
// fields, changing a preference in one tab doesn't reset the
// preference set in the other).

export enum PvPLobbyTab {
  ALL = "all",
  OUTGOING = "outgoing",
  INCOMING = "incoming",
  BLOCKED = "blocked",
  RING = "ring",
}

export enum CollectionSort {
  ALPHABETICAL = "alphabetical",
  COST = "cost",
  COLOR = "color",
}

export interface UserPreferencesStoreState {
  pvpLobbyTab: PvPLobbyTab;
  setPvPLobbyTab: (pvpLobbyTab: PvPLobbyTab) => void;

  hideInvalid: boolean;
  setHideInvalid: (hideInvalid: boolean) => void;

  showDeckbuildingAnimation: boolean;
  setShowDeckbuildingAnimation: (showDeckbuildingAnimation: boolean) => void;

  cardSize: CardSize;
  setCardSize: (cardSize: CardSize) => void;

  collectionSort: CollectionSort;
  setCollectionSort: (collectionSort: CollectionSort) => void;

  /** 1 is normal, 2 is twice as fast, 0 for skipping altogether. */
  speed: number;
  /** Sets the speed. */
  setSpeed: (speed: number) => void;
  animKeroDos: boolean;
  setAnimKeroDos: (animKeroDos: boolean) => void;

  hasClosedInstancerModal: boolean;
  setHasClosedInstancerModal: () => void;

  hasClosedPvPModal: boolean;
  setHasClosedPvPModal: () => void;

  lastInstancerRoom: string | null;
  setLastInstancerRoom: (lastInstancerRoom: string) => void;

  recurseFieldTooltips: boolean;
  setRecurseFieldTooltips: (recurseFieldTooltips: boolean) => void;
  recurseActionTooltips: boolean;
  setRecurseActionTooltips: (recurseActionTooltips: boolean) => void;

  showSubpuzzles: boolean;
  setShowSubpuzzles: (showSubpuzzles: boolean) => void;
}

export const useUserPreferencesStore = create(
  persist<UserPreferencesStoreState>(
    (set) => ({
      pvpLobbyTab: PvPLobbyTab.ALL,
      setPvPLobbyTab: (pvpLobbyTab) => set({ pvpLobbyTab }),

      hideInvalid: false,
      setHideInvalid: (hideInvalid) => set({ hideInvalid }),

      showDeckbuildingAnimation: true,
      setShowDeckbuildingAnimation: (showDeckbuildingAnimation) =>
        set({ showDeckbuildingAnimation }),

      cardSize: CardSize.LARGE,
      setCardSize: (cardSize) => set({ cardSize }),

      collectionSort: CollectionSort.ALPHABETICAL,
      setCollectionSort: (collectionSort) => set({ collectionSort }),

      speed: 1,
      setSpeed: (speed) => set({ speed }),
      animKeroDos: !window.matchMedia?.("(prefers-reduced-motion)").matches,
      setAnimKeroDos: (animKeroDos) => set({ animKeroDos }),

      hasClosedInstancerModal: false,
      setHasClosedInstancerModal: () => set({ hasClosedInstancerModal: true }),

      hasClosedPvPModal: false,
      setHasClosedPvPModal: () => set({ hasClosedPvPModal: true }),

      lastInstancerRoom: null,
      setLastInstancerRoom: (lastInstancerRoom) => set({ lastInstancerRoom }),

      recurseFieldTooltips: false,
      setRecurseFieldTooltips: (recurseFieldTooltips) =>
        set({ recurseFieldTooltips }),

      recurseActionTooltips: false,
      setRecurseActionTooltips: (recurseActionTooltips) =>
        set({ recurseActionTooltips }),

      showSubpuzzles: true,
      setShowSubpuzzles: (showSubpuzzles) => set({ showSubpuzzles }),
    }),
    {
      name: "__game_preferences",
      version: 1,
    }
  )
);

export enum AudioLayer {
  BGM = "bgm",
  SFX = "sfx",
  NOTIF = "notif",
}

export const audioLayerLabel = (layer: AudioLayer): string => {
  switch (layer) {
    case AudioLayer.BGM: {
      return "Music";
    }
    case AudioLayer.SFX: {
      return "Effects";
    }
    case AudioLayer.NOTIF: {
      return "Notifications";
    }
  }
};

type AudioSetting = {
  isMuted: boolean;
  volume: number;
};

type AudioPreferencesStore = {
  // global:
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  setMuted: (isMuted: boolean) => void;
  // individual:
  layers: Record<AudioLayer, AudioSetting>;
  setLayer: <T extends keyof AudioSetting>(
    key: AudioLayer,
    prop: T,
    value: AudioSetting[T]
  ) => void;
};

export const useAudioPreferencesStore = create(
  persist<AudioPreferencesStore>(
    (set) => ({
      volume: 50,
      setVolume: (volume) => set({ volume }),
      isMuted: false,
      setMuted: (isMuted) => set({ isMuted }),
      layers: {
        [AudioLayer.BGM]: { volume: 50, isMuted: false },
        [AudioLayer.SFX]: { volume: 100, isMuted: false },
        [AudioLayer.NOTIF]: { volume: 100, isMuted: false },
      },
      setLayer: (key, prop, value) =>
        set(
          produce<AudioPreferencesStore>((state) => {
            state.layers[key][prop] = value;
          })
        ),
    }),
    {
      name: "__game_audio_preferences",
      version: 0,
    }
  )
);

export const useAdminPreferencesStore = create(
  persist<{
    isAdminEnabledPref: boolean;
    setIsAdminEnabledPref: (isAdminEnabledPref: boolean) => void;
    isSpectatingPref: boolean;
    setIsSpectatingPref: (isSpectatingPref: boolean) => void;
  }>(
    (set) => ({
      isAdminEnabledPref: true,
      setIsAdminEnabledPref: (isAdminEnabledPref) =>
        set({ isAdminEnabledPref }),
      isSpectatingPref: true,
      setIsSpectatingPref: (isSpectatingPref) => set({ isSpectatingPref }),
    }),
    {
      name: "__game_admin_preferences",
      version: 0,
    }
  )
);

export const useIsAdminEnabledStore = create<{
  isAdminEnabled: boolean;
  setIsAdminEnabled: (isAdminEnabled: boolean) => void;
  isSpectating: boolean;
  setIsSpectating: (isSpectating: boolean) => void;
}>((set) => ({
  isAdminEnabled: true,
  setIsAdminEnabled: (isAdminEnabled) => set({ isAdminEnabled }),
  isSpectating: true,
  setIsSpectating: (isSpectating) => set({ isSpectating }),
}));

export interface HotkeyPreferencesStoreState {
  isEnabled: boolean;
  setIsEnabled: (isEnabled: boolean) => void;
}
export const useHotkeyPreferencesStore = create(
  persist<HotkeyPreferencesStoreState>(
    (set) => ({
      isEnabled: true,
      setIsEnabled: (isEnabled) => set({ isEnabled }),
    }),
    {
      name: "__game_hotkey_preferences",
      version: 0,
    }
  )
);

export enum BigBoardSortOrder {
  LEADERBOARD = "leaderboard",
  HEALTH = "health",
  ACTIVITY = "activity",
}

export interface BigBoardPreferencesStoreState {
  collapseSubbattles: boolean;
  setCollapseSubbattles: (collapseSubbattles: boolean) => void;
  useEmojiHeaders: boolean;
  setUseEmojiHeaders: (useEmojiHeaders: boolean) => void;
  sortOrder: BigBoardSortOrder;
  setSortOrder: (sortOrder: BigBoardSortOrder) => void;
  showHidden: boolean;
  setShowHidden: (showHidden: boolean) => void;
  showCardUnlocks: boolean;
  setShowCardUnlocks: (showCardUnlocks: boolean) => void;
  numTeamsPerPage: number;
  setNumTeamsPerPage: (numTeamsPerPage: number) => void;
  pageNum: number;
  setPageNum: (pageNum: number) => void;
}
export const useBigBoardPreferencesStore = create(
  persist<BigBoardPreferencesStoreState>(
    (set) => ({
      collapseSubbattles: true,
      setCollapseSubbattles: (collapseSubbattles) =>
        set({ collapseSubbattles }),
      useEmojiHeaders: false,
      setUseEmojiHeaders: (useEmojiHeaders) => set({ useEmojiHeaders }),
      sortOrder: BigBoardSortOrder.LEADERBOARD,
      setSortOrder: (sortOrder) => set({ sortOrder }),
      showHidden: false,
      setShowHidden: (showHidden) => set({ showHidden }),
      showCardUnlocks: false,
      setShowCardUnlocks: (showCardUnlocks) => set({ showCardUnlocks }),
      numTeamsPerPage: 30,
      setNumTeamsPerPage: (numTeamsPerPage) => set({ numTeamsPerPage }),
      pageNum: 0,
      setPageNum: (pageNum) => set({ pageNum }),
    }),
    {
      name: "__game_bigboard_preferences",
      version: 1,
    }
  )
);
