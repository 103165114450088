import { SharedGameSpec } from "engine/types/shared-game-specs";
import {
  PUZ_NAME_TUTORIAL_1,
  PUZ_NAME_FINAL_BATTLE,
} from "engine/puzzles/puzzle-data";
import {
  PUZ_NAME_COMMON,
  SharedCommonSpec,
} from "engine/game-specs/common-shared";
import { SharedRobotSpec } from "engine/game-specs/robot-shared";
import { SharedTutorial1Spec } from "engine/game-specs/tutorial1-shared";
import { SharedTutorial2Spec } from "engine/game-specs/tutorial2-shared";
import { SharedTutorial3Spec } from "engine/game-specs/tutorial3-shared";
import { SharedTutorial4Spec } from "engine/game-specs/tutorial4-shared";
import { SharedTutorial5Spec } from "engine/game-specs/tutorial5-shared";
import { SharedTutorial6Spec } from "engine/game-specs/tutorial6-shared";
import { SharedPuzzle4Spec } from "./game-specs/puzzle-4-shared";
import { SharedPuzzle8Spec } from "./game-specs/puzzle-8-shared";
import { SharedPuzzle9Spec } from "./game-specs/puzzle-9-shared";
import { SharedPuzzle22Specs } from "./game-specs/puzzle-22-shared";
import { SharedPuzzle28Spec } from "./game-specs/puzzle-28-shared";
import { SharedPuzzle31Spec } from "./game-specs/puzzle-31-shared";
import { SharedPuzzle44Spec } from "./game-specs/puzzle-44-shared";
import { SharedPuzzle46Spec } from "./game-specs/puzzle-46-shared";
import { SharedPuzzle50Spec } from "./game-specs/puzzle-50-shared";
import { SharedPuzzle51Spec } from "./game-specs/puzzle-51-shared";
import { SharedPuzzle53Spec } from "./game-specs/puzzle-53-shared";
import { SharedPuzzle76Spec } from "./game-specs/puzzle-76-shared";
import { SharedPuzzle80Spec } from "./game-specs/puzzle-80-shared";
import { SharedPuzzle82Spec } from "./game-specs/puzzle-82-shared";
import { SharedPuzzle90Spec } from "./game-specs/puzzle-90-shared";
import { SharedPuzzle93Spec } from "./game-specs/puzzle-93-shared";
import { SharedPuzzle98Spec } from "./game-specs/puzzle-98-shared";
import { SharedPuzzle101Spec } from "./game-specs/puzzle-101-shared";
import { SharedPuzzle109Spec } from "./game-specs/puzzle-109-shared";
import { SharedCutsceneSpec } from "./game-specs/cutscene-shared";

const SharedPvPSpec: SharedGameSpec = {
  maxNumLegendaries: 0,
};

const makeSharedSimpleCutsceneSpec = (
  endCutsceneRedirect?: string
): SharedGameSpec => ({
  ...SharedCutsceneSpec,
  endCutsceneRedirect,
});

const makeSharedSimpleCutsceneRedirectDjangoSpec = (
  endCutsceneRedirectDjango?: string
): SharedGameSpec => ({
  ...SharedCutsceneSpec,
  endCutsceneRedirectDjango,
});

type SharedGameSpecs = {
  [puzName: string]: SharedGameSpec;
};

export const ALL_SHARED_GAME_SPECS: SharedGameSpecs = {
  [PUZ_NAME_COMMON]: SharedCommonSpec,
  pvp: SharedPvPSpec,
  robot: SharedRobotSpec,
  tutorial1: SharedTutorial1Spec,
  tutorial2: SharedTutorial2Spec,
  tutorial3: SharedTutorial3Spec,
  tutorial4: SharedTutorial4Spec,
  tutorial5: SharedTutorial5Spec,
  tutorial6: SharedTutorial6Spec,
  gnutmeg_tree: SharedPuzzle4Spec,
  mister_penny: SharedPuzzle8Spec,
  blancmange: SharedPuzzle9Spec,
  beebay_fulfillment_center_1: SharedPuzzle22Specs[0],
  beebay_fulfillment_center_2: SharedPuzzle22Specs[1],
  beebay_fulfillment_center_3: SharedPuzzle22Specs[2],
  beebay_fulfillment_center_4: SharedPuzzle22Specs[3],
  beebay_fulfillment_center_5: SharedPuzzle22Specs[4],
  beebay_fulfillment_center_6: SharedPuzzle22Specs[5],
  beebay_fulfillment_center_7: SharedPuzzle22Specs[6],
  beebay_fulfillment_center_8: SharedPuzzle22Specs[7],
  beebay_fulfillment_center_9: SharedPuzzle22Specs[8],
  beebay_fulfillment_center_10: SharedPuzzle22Specs[9],
  beebay_fulfillment_center_11: SharedPuzzle22Specs[10],
  beebay_fulfillment_center_12: SharedPuzzle22Specs[11],
  beebay_fulfillment_center_13: SharedPuzzle22Specs[12],
  miss_yu: SharedPuzzle28Spec,
  bb_b: SharedPuzzle31Spec,
  slime: SharedPuzzle44Spec,
  dargle_1: SharedPuzzle46Spec,
  dargle_2: SharedPuzzle46Spec,
  dargle_3: SharedPuzzle46Spec,
  dargle_4: SharedPuzzle46Spec,
  dargle_5: SharedPuzzle46Spec,
  moonick_1: SharedPuzzle50Spec,
  moonick_2: SharedPuzzle50Spec,
  moonick_3: SharedPuzzle50Spec,
  moonick_4: SharedPuzzle50Spec,
  moonick_5: SharedPuzzle50Spec,
  moonick_6: SharedPuzzle50Spec,
  moonick_7: SharedPuzzle50Spec,
  moonick_8: SharedPuzzle50Spec,
  moonick_9: SharedPuzzle50Spec,
  moonick_10: SharedPuzzle50Spec,
  moonick_11: SharedPuzzle50Spec,
  moonick_12: SharedPuzzle50Spec,
  moonick_13: SharedPuzzle50Spec,
  moonick_14: SharedPuzzle50Spec,
  moonick_15: SharedPuzzle50Spec,
  moonick_16: SharedPuzzle50Spec,
  moonick_17: SharedPuzzle50Spec,
  moonick_18: SharedPuzzle50Spec,
  moonick_19: SharedPuzzle50Spec,
  moonick_20: SharedPuzzle50Spec,
  moonick_21: SharedPuzzle50Spec,
  moonick_22: SharedPuzzle50Spec,
  moonick_23: SharedPuzzle50Spec,
  moonick_24: SharedPuzzle50Spec,
  moonick_final: SharedPuzzle50Spec,
  spelling_bee: SharedPuzzle51Spec,
  the_swarm: SharedPuzzle53Spec,
  kero: SharedPuzzle76Spec,
  jabberwock_1: SharedPuzzle80Spec,
  jabberwock_2: SharedPuzzle80Spec,
  jabberwock_3: SharedPuzzle80Spec,
  jabberwock_4: SharedPuzzle80Spec,
  jabberwock_5: SharedPuzzle80Spec,
  jabberwock_6: SharedPuzzle80Spec,
  jabberwock_7: SharedPuzzle80Spec,
  asteroid_1: SharedPuzzle82Spec,
  asteroid_2: SharedPuzzle82Spec,
  asteroid_3: SharedPuzzle82Spec,
  coloring: SharedPuzzle90Spec,
  othello: SharedPuzzle93Spec,
  spirit_of_the_vines_1: SharedPuzzle98Spec,
  spirit_of_the_vines_2: SharedPuzzle98Spec,
  spirit_of_the_vines_3: SharedPuzzle98Spec,
  spirit_of_the_vines_4: SharedPuzzle98Spec,
  spirit_of_the_vines_5: SharedPuzzle98Spec,
  infinitely_many_hogs_and_logs_1: SharedPuzzle101Spec,
  infinitely_many_hogs_and_logs_2: SharedPuzzle101Spec,
  infinitely_many_hogs_and_logs_3: SharedPuzzle101Spec,
  infinitely_many_hogs_and_logs_4: SharedPuzzle101Spec,
  angry_boarry_farmer: SharedPuzzle109Spec,
  test_battle: SharedCutsceneSpec,
  treasure_chest: makeSharedSimpleCutsceneSpec(
    `/battle/${PUZ_NAME_TUTORIAL_1}/start`
  ),
  first_answer: makeSharedSimpleCutsceneSpec("/prep/mastery_tree/start"),
  first_legendary: makeSharedSimpleCutsceneSpec("/"),
  entering_the_water: makeSharedSimpleCutsceneSpec(
    `/prep/${PUZ_NAME_FINAL_BATTLE}/start`
  ),
  the_end: makeSharedSimpleCutsceneSpec("/victory/start"),
};
