import {
  ClientTeamGlobalState,
  TeamNameDisplayData,
} from "game-server/global-updates";
import { Faction, getFactionRank } from "engine/types/factions";
import { REPUTATION_BOOST_MASTERY_ID } from "engine/puzzles/mastery-data";

import FactionIcon from "components/FactionIcon";
import { RoutedLink } from "components/Link";
import "./TeamDisplayNameDisplay.css";
import Twemoji from "react-twemoji";

export const clientTeamGlobalStateToTeamNameDisplayData = ({
  teamId,
  displayName,
  faction,
  factionScoreContributions,
  masteries,
}: ClientTeamGlobalState): TeamNameDisplayData => {
  return {
    teamId,
    displayName,
    faction,
    factionScoreContribution:
      faction === null ? 0 : factionScoreContributions[faction],
    hasReputationBoost: masteries[REPUTATION_BOOST_MASTERY_ID].isEnabled
      ? true
      : undefined,
  };
};

interface TeamDisplayNameDisplayProps {
  teamData: TeamNameDisplayData;
  invalid?: boolean;
  noLink?: boolean;
}

export function FactionRankDisplay({
  faction,
  factionRank,
}: {
  faction: Faction;
  factionRank: number;
}) {
  const factionIcons = [];
  for (let i = 0; i < factionRank; i++) {
    factionIcons.push(<FactionIcon key={i} faction={faction} />);
  }
  return <>{factionIcons}</>;
}

function TeamDisplayNameDisplay(props: TeamDisplayNameDisplayProps) {
  const { teamData, invalid: invalidOpt, noLink } = props;
  const { displayName, faction, factionScoreContribution, hasReputationBoost } =
    teamData;
  const invalid = invalidOpt ?? false;
  const factionRank = getFactionRank(factionScoreContribution);

  const twemojiDisplayName = (
    <Twemoji className="team-display-name" tag="span">
      {displayName}
    </Twemoji>
  );
  const modifiedDisplayName = invalid ? (
    <s>{twemojiDisplayName}</s>
  ) : (
    twemojiDisplayName
  );
  const className = `team-name-link${
    hasReputationBoost ?? false ? " team-name-boost" : ""
  }`;
  return (
    <span className="team-name-container">
      {faction === null ? null : (
        <FactionRankDisplay faction={faction} factionRank={factionRank} />
      )}{" "}
      <span className={invalid ? "team-name-invalid" : ""}>
        {noLink ? (
          <span className={className}>{modifiedDisplayName}</span>
        ) : (
          <RoutedLink
            className={className}
            path={`/team/${encodeURIComponent(
              encodeURIComponent(displayName)
            )}`}
          >
            {modifiedDisplayName}
          </RoutedLink>
        )}
      </span>
    </span>
  );
}

export default TeamDisplayNameDisplay;
