import { CardEffectsShared } from "engine/cards/card-effects-shared";
import { Check } from "engine/types/action-validation";
import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

const BASE_EFFECT: CardEffectsShared = {
  isBase: true,
  isStructure: true,
  adjustMoveChecks: (fail) => fail.delete(Check.CARD_TYPE),
};

export const SharedPuzzle90Spec: SharedGameSpec = {
  allowCheckpoints: true,
  aiDisableGemAccounting: true,
  cardEffectsShared: {
    arvada: BASE_EFFECT,
    burlington: BASE_EFFECT,
    centennial: BASE_EFFECT,
  },
  hasAI: true,
  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  basesOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  numRows: 4,
  numColumns: 7,
};
