import { CardType } from "engine/types/card-data";
import {
  Permanent,
  Slot,
  Player,
  areSlotsEqual,
  areSlotsAdjacent,
  getSlotsEuclideanDistance,
} from "engine/types/game-state";
import { CounterType } from "engine/types/counters";
import { Inspector } from "engine/Inspector";

/**
 * A sort criterion is a function that takes a permanent to a number
 * (its "sort value"). Permanents are sorted in increasing order of
 * their sort value.
 * Boolean sort criteria (e.g. "creaturesFirst") correspond to a
 * difference of one in sort value.
 * Sort criteria may be combined using the "weighted" sort criterion,
 * which assigns each permanent a sort value equal to a weighted sum
 * over the subcriteria.
 */
export type SortCriterion =
  | "health"
  | "-health"
  | "power"
  | "-power"
  | "shell"
  | "-shell"
  | "creaturesFirst"
  | "structuresFirst"
  | { type: "frontToBack"; perspective: Player }
  | {
      type: "prioritize" | "deprioritize";
      cardNames: string[];
    }
  | {
      type: "euclideanDistance" | "-euclideanDistance";
      slot: Slot;
    }
  | {
      type: "weighted";
      weights: {
        criterion: SortCriterion;
        weight: number;
      }[];
    }
  | ((permanent: Permanent) => number);

const evalSortCriterion = (
  permanent: Permanent,
  sortCriterion: SortCriterion,
  inspector: Inspector
): number => {
  if (typeof sortCriterion === "function") {
    return sortCriterion(permanent);
  }
  if (typeof sortCriterion === "string") {
    switch (sortCriterion) {
      case "health":
        return inspector.getHealth(permanent);
      case "-health":
        return -inspector.getHealth(permanent);
      case "power":
        return inspector.getPower(permanent);
      case "-power":
        return -inspector.getPower(permanent);
      case "shell":
        return inspector.getShell(permanent);
      case "-shell":
        return -inspector.getShell(permanent);
      case "creaturesFirst":
        return inspector.getCardType(permanent) === CardType.CREATURE ? -1 : 0;
      case "structuresFirst":
        return inspector.getCardType(permanent) === CardType.STRUCTURE ? -1 : 0;
    }
  }
  switch (sortCriterion.type) {
    case "frontToBack":
      return -inspector.getNthNearestRow(
        permanent.slot.row,
        sortCriterion.perspective
      );
    case "prioritize":
      return sortCriterion.cardNames.includes(inspector.getCardName(permanent))
        ? -1
        : 0;
    case "deprioritize":
      return sortCriterion.cardNames.includes(inspector.getCardName(permanent))
        ? 1
        : 0;
    case "euclideanDistance":
      return getSlotsEuclideanDistance(permanent.slot, sortCriterion.slot);
    case "-euclideanDistance":
      return -getSlotsEuclideanDistance(permanent.slot, sortCriterion.slot);
    case "weighted": {
      const { weights } = sortCriterion;
      let val = 0;
      for (const weightSpec of weights) {
        const { criterion, weight } = weightSpec;
        val += weight * evalSortCriterion(permanent, criterion, inspector);
      }
      return val;
    }
  }
  // unhandled cases will result in a return type error
};

/**
 * A specification for a permanent query.
 * Each property (besides "sort") corresponds to a filter criterion.
 * "sort" specifies a sort order for the returned permanents,
 * formatted as a list of sort criteria. The returned permanents are
 * sorted first by the first sort criterion, then by the second, etc.
 * See {@link SortCriterion} for the specification for sort criteria.
 */
export type PermanentQuery = {
  name?: string;
  ready?: boolean;
  terrain?: Player;
  owner?: Player;
  ownedByOpponentOf?: Player;
  type?: CardType;
  isBase?: boolean;
  row?: number;
  column?: number;
  slot?: Slot;
  rowInFrontOf?: { row: number; perspective: Player };
  rowBehind?: { row: number; perspective: Player };
  adjacentTo?: Slot;
  touching?: Slot;
  columnAdjacentTo?: number;
  protectedFrom?: Permanent;
  notProtectedFrom?: Permanent;
  except?: Permanent; // exclude one permanent (often "self")
  hasCounter?: CounterType;
  custom?: (permanent: Permanent) => boolean;
  sort?: SortCriterion[];
};

/**
 * Filters and sorts permanents in the field.
 * See {@link PermanentQuery} for the query format.
 */
export const queryPermanents = (
  inspector: Inspector,
  query: PermanentQuery
): Permanent[] => {
  const permanents = inspector.getAllPermanents().filter((permanent) => {
    for (const [k, v] of Object.entries(query)) {
      const matches = ((): boolean => {
        switch (k) {
          case "name":
            return inspector.getCardName(permanent) === query.name;
          case "ready":
            return permanent.ready === query.ready;
          case "terrain":
            return inspector.getTerrainOf(permanent.slot) === query.terrain;
          case "owner":
            return permanent.owner === query.owner;
          case "ownedByOpponentOf":
            return permanent.owner !== query.ownedByOpponentOf;
          case "type":
            return inspector.getCardType(permanent) === query.type;
          case "isBase":
            return (
              (inspector.getSharedEffects(permanent).isBase ?? false) ===
              query.isBase
            );
          case "row":
            return permanent.slot.row === query.row;
          case "column":
            return permanent.slot.column === query.column;
          case "slot":
            if (query.slot === undefined) throw new Error();
            return areSlotsEqual(permanent.slot, query.slot);
          case "rowInFrontOf":
            if (query.rowInFrontOf === undefined) throw new Error();
            return inspector.isRowInFrontOf(
              permanent.slot.row,
              query.rowInFrontOf.row,
              query.rowInFrontOf.perspective
            );
          case "rowBehind":
            if (query.rowBehind === undefined) throw new Error();
            return inspector.isRowInFrontOf(
              query.rowBehind.row,
              permanent.slot.row,
              query.rowBehind.perspective
            );
          case "adjacentTo": // excludes SELF
            if (query.adjacentTo === undefined) throw new Error();
            return areSlotsAdjacent(permanent.slot, query.adjacentTo);
          case "touching": // includes SELF and diagonals
            if (query.touching === undefined) throw new Error();
            return (
              getSlotsEuclideanDistance(permanent.slot, query.touching) < 2
            );
          case "columnAdjacentTo":
            if (query.columnAdjacentTo === undefined) throw new Error();
            return (
              Math.abs(permanent.slot.column - query.columnAdjacentTo) === 1
            );
          case "protectedFrom":
            if (query.protectedFrom === undefined) throw new Error();
            return inspector.isProtectedFrom(permanent, query.protectedFrom);
          case "notProtectedFrom":
            if (query.notProtectedFrom === undefined) throw new Error();
            return !inspector.isProtectedFrom(
              permanent,
              query.notProtectedFrom
            );
          case "except":
            if (query.except === undefined) throw new Error();
            return permanent.id !== query.except.id;
          case "custom":
            if (query.custom === undefined) throw new Error();
            return query.custom(permanent);
          case "hasCounter":
            if (query.hasCounter === undefined) throw new Error();
            return inspector.doesPermanentHaveCounterType(
              permanent,
              query.hasCounter
            );
          case "sort":
            // not actually a filtering key
            return true;
        }
        throw new Error(`unrecognized permanent filter key ${k}`);
      })();
      if (!matches) return false;
    }
    return true;
  });
  if (query.sort) {
    permanents.sort((p1, p2) => {
      if (query.sort === undefined) throw new Error();
      for (const sortCriterion of query.sort) {
        const sortResult =
          evalSortCriterion(p1, sortCriterion, inspector) -
          evalSortCriterion(p2, sortCriterion, inspector);
        if (sortResult !== 0) {
          return sortResult;
        }
      }
      return 0;
    });
  }
  return permanents;
};
