import { Check, FailedChecks } from "engine/types/action-validation";
import { Deck, DeckUpdate, getDeckSize } from "engine/types/decks";
import { COMMON_CARD_EFFECTS_SHARED } from "engine/cards/card-effects-shared";
import { CardsDB } from "engine/cards/CardsDB";

export const isCardValidForDeckbuilding = (
  cardName: string,
  cardUnlocks: Set<string>,
  cardsDB: CardsDB
): boolean => {
  const cardData = cardsDB[cardName];
  if (cardData === undefined) return false;
  if (!cardData.capturable) return false;
  if (!cardData.implemented) return false;
  if (cardData.cardUnlockGroupId !== null && !cardUnlocks.has(cardName))
    return false;
  if (COMMON_CARD_EFFECTS_SHARED[cardName]?.isBase ?? false) return false;
  return true;
};

export const validateDeck = (
  failedChecks: FailedChecks,
  deck: Deck,
  maxNumCards: number,
  maxNumLegendaries: number
): void => {
  const deckSize = getDeckSize(deck);
  if (deckSize < 1) failedChecks.add(Check.DECK_TOO_SMALL);
  if (deckSize > maxNumCards) failedChecks.add(Check.DECK_TOO_LARGE);
  // unless final battle, deck can only have 1 unique legendary
  if (getDeckUniqueLegendaries(deck) > maxNumLegendaries)
    failedChecks.add(Check.DECK_TOO_MANY_LEGENDARIES);
};

// return number of unique legendaries in (deck)
export const getDeckUniqueLegendaries = (deck: Deck): number => {
  return Object.entries(deck.cards).filter(
    ([cardName, count]) =>
      count > 0 && (COMMON_CARD_EFFECTS_SHARED[cardName]?.isLegendary ?? false)
  ).length;
};

export const isCardLegendary = (cardName: string): boolean =>
  COMMON_CARD_EFFECTS_SHARED[cardName]?.isLegendary ?? false;

export const isDeckUpdateValid = (
  deckUpd: DeckUpdate,
  deck: Deck,
  cardUnlocks: Set<string>,
  cardsDB: CardsDB
): boolean => {
  const { cards, priorityCard } = deckUpd;
  if (cards !== undefined) {
    for (const [cardName, val] of Object.entries(cards)) {
      if (!isCardValidForDeckbuilding(cardName, cardUnlocks, cardsDB))
        return false;
      const effectsShared = COMMON_CARD_EFFECTS_SHARED[cardName] ?? {};
      const deckbuildingLimit = effectsShared.deckbuildingLimit ?? 2;
      const disableDeckbuildingLimit =
        effectsShared.disableDeckbuildingLimit ?? false;
      if (val < 0 || !Number.isInteger(val)) return false;
      if (!disableDeckbuildingLimit && val > deckbuildingLimit) return false;
    }
  }
  if (priorityCard !== undefined) {
    if (!isCardValidForDeckbuilding(priorityCard, cardUnlocks, cardsDB))
      return false;
  }
  return true;
};
