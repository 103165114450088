import { create } from "zustand";
import produce from "immer";
import { HandCard, Permanent, Player, Slot } from "engine/types/game-state";
import { Inspector } from "engine/Inspector";
import { StepMaker } from "engine/StepMaker";
import { Role } from "engine/types/updates";
import { getPlayerPerspectiveForRole } from "stores/ClientGameStore";
import { ZoomedCardDetails } from "./ZoomCardStore";
import { EffectOptForm, EffectOptType } from "engine/types/effects";

export interface InspectorInfo {
  inspector: Inspector;
  stepMaker: StepMaker;
  // player: Player,
  role: Role;
}

export enum KeyboardCursorMode {
  /** Shortcuts are enabled but cursor is not being shown. */
  NOT_VISIBLE = "not-visible",
  /** Cursor is on the field. */
  FIELD = "field",
  /** Cursor has selected a unit on the field. Using subcursor. */
  FIELD_SELECTED = "field-selected",
  /** Cursor is in the hand. */
  HAND = "hand",
  /** Cursor has selected a card in hand. Using subcursor. */
  HAND_SELECTED = "hand-selected",
  /** Currently selecting a target slot for effect opt, using subcursor. */
  EFFECT_OPT_SLOT = "effect-opt-slot",
  /** Currently selecting a target perm for effect opt, using subcursor. */
  EFFECT_OPT_PERMANENT = "effect-opt-permanent",
  /** ???? */
  EFFECT_OPT_ONE_CHOICE = "effect-opt-one-choice",
  /** ???? */
  UNKNOWN = "unknown",
}

export enum DirectionKey {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}

export interface KeyboardCursorState {
  /** Used when GamePage initialized. */
  resetState: () => void;
  /** If false, cursors should not be displayed and keys should not activate. */
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  /** If true, keypresses are temporarily locked (due to animations) */
  locked: boolean;
  setLocked: (locked: boolean) => void;
  isResponsive: () => boolean;
  /** Location of field cursor (even if it's not currently in FIELD mode)*/
  fieldCursorLocation: Slot;
  /** Index of hand cursor (even if it's not currently in HAND mode) */
  handCursorIndex: number;
  /** Which player's hand the hand cursor is on. */
  handPlayer: Player | null;
  /** Subcursor location on field for either an effectOpt choice,
   *  or possibly to move/attack. Only active in FIELD_SELECTED, HAND_SELECTED,
   *  or EFFECT_OPT modes. */
  subcursorLocation: Slot | null;
  /** The current mode.  */
  cursorMode: KeyboardCursorMode;
  // Getter helpers
  getFieldSlotWithFocus: () => Slot | null;
  getHandCardWithFocus: () => { player: Player; index: number } | null;
  /** Apply mutations */
  applyMutations: (mutations: Partial<KeyboardCursorState>) => void;
  // helper handlers to sync with mouse interactions
  // these do not apply any ExternalMutations
  resetForSuccessfulStep: () => void;
  resetSelectedCard: () => void;
  selectFieldPermanent: (
    permanentId: string,
    inspectorInfo: InspectorInfo
  ) => void;
  selectHandCard: (
    player: Player,
    handCardId: string,
    inspectorInfo: InspectorInfo
  ) => void;
  selectHandCardAllCardsAvailable: (
    player: Player,
    name: string,
    inspectorInfo: InspectorInfo
  ) => void;
  resetEffectOpts: () => void;
  syncToPendingEffectOpt: (form: EffectOptForm) => void;
}

export const useKeyboardCursorStore = create<KeyboardCursorState>(
  (set, get) => ({
    resetState: () =>
      set(
        produce((state: KeyboardCursorState) => {
          state.fieldCursorLocation = { row: 0, column: 0 };
          state.handCursorIndex = 0;
          state.handPlayer = null;
          state.subcursorLocation = null;
          state.cursorMode = KeyboardCursorMode.NOT_VISIBLE;
        })
      ),
    enabled: false,
    setEnabled: (enabled: boolean) =>
      set(
        produce((state: KeyboardCursorState) => {
          if (enabled && !state.enabled) {
            state.fieldCursorLocation = { row: 0, column: 0 };
            state.handCursorIndex = 0;
            state.handPlayer = null;
            state.subcursorLocation = null;
            state.cursorMode = KeyboardCursorMode.NOT_VISIBLE;
          }
          state.enabled = enabled;
        })
      ),
    locked: false,
    setLocked: (locked: boolean) =>
      set(
        produce((state: KeyboardCursorState) => {
          state.locked = locked;
        })
      ),
    isResponsive: () => get().enabled && !get().locked,
    fieldCursorLocation: { row: 0, column: 0 },
    handCursorIndex: 0,
    handPlayer: null,
    subcursorLocation: null,
    cursorMode: KeyboardCursorMode.NOT_VISIBLE,
    getFieldSlotWithFocus: () => {
      const state = get();
      if (!state.enabled) {
        return null;
      }
      switch (state.cursorMode) {
        case KeyboardCursorMode.FIELD:
          return {
            row: state.fieldCursorLocation.row,
            column: state.fieldCursorLocation.column,
          };
        case KeyboardCursorMode.FIELD_SELECTED:
        case KeyboardCursorMode.HAND_SELECTED:
        case KeyboardCursorMode.EFFECT_OPT_SLOT:
        case KeyboardCursorMode.EFFECT_OPT_PERMANENT:
          if (!state.subcursorLocation) return null;
          return {
            row: state.subcursorLocation.row,
            column: state.subcursorLocation.column,
          };
        default:
          return null;
      }
    },
    getHandCardWithFocus: () => {
      const state = get();
      if (!state.enabled || state.handPlayer === null) {
        return null;
      }
      if (state.cursorMode !== KeyboardCursorMode.HAND) {
        return null;
      }
      return {
        player: state.handPlayer,
        index: state.handCursorIndex,
      };
    },
    applyMutations: (mutations) =>
      set(
        produce((state: KeyboardCursorState) => {
          Object.assign(state, mutations);
        })
      ),
    resetForSuccessfulStep: () =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          switch (state.cursorMode) {
            case KeyboardCursorMode.EFFECT_OPT_SLOT:
            case KeyboardCursorMode.EFFECT_OPT_PERMANENT:
            case KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE:
            case KeyboardCursorMode.FIELD_SELECTED:
            case KeyboardCursorMode.HAND_SELECTED: {
              state.cursorMode = KeyboardCursorMode.NOT_VISIBLE;
              state.subcursorLocation = null;
              break;
            }
          }
        })
      ),
    resetSelectedCard: () =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          switch (state.cursorMode) {
            case KeyboardCursorMode.EFFECT_OPT_SLOT:
            case KeyboardCursorMode.EFFECT_OPT_PERMANENT:
            case KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE:
            case KeyboardCursorMode.FIELD_SELECTED:
            case KeyboardCursorMode.HAND_SELECTED: {
              state.cursorMode = KeyboardCursorMode.NOT_VISIBLE;
              state.subcursorLocation = null;
              break;
            }
          }
        })
      ),
    selectFieldPermanent: (permanentId, inspectorInfo) =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          const perm =
            inspectorInfo.inspector.getPermanentIfExists(permanentId);
          if (perm == null) return;
          mutateSelectFieldPermanent(state, perm);
        })
      ),
    selectHandCard: (player, handCardId, inspectorInfo) =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          const { role, inspector } = inspectorInfo;
          if (role === Role.P1 && player === Player.P2) {
            return;
          }
          if (role === Role.P2 && player === Player.P1) {
            return;
          }

          state.cursorMode = KeyboardCursorMode.HAND_SELECTED;
          state.handPlayer = player;
          state.handCursorIndex = inspector
            .getHandForUI(player)
            .findIndex((handCard) => handCard.id === handCardId);
          state.subcursorLocation = null;
        })
      ),
    selectHandCardAllCardsAvailable: (player, name, inspectorInfo) =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          const { role, inspector } = inspectorInfo;
          if (role === Role.P1 && player === Player.P2) {
            return;
          }
          if (role === Role.P2 && player === Player.P1) {
            return;
          }

          state.cursorMode = KeyboardCursorMode.HAND_SELECTED;
          state.handPlayer = player;
          state.handCursorIndex = inspector
            .getHandForUI(player)
            .findIndex((handCard) => handCard.card.name === name);
          state.subcursorLocation = null;
        })
      ),
    resetEffectOpts: () =>
      set(
        produce((state: KeyboardCursorState) => {
          if (!state.enabled) return;
          switch (state.cursorMode) {
            case KeyboardCursorMode.EFFECT_OPT_SLOT:
            case KeyboardCursorMode.EFFECT_OPT_PERMANENT:
            case KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE:
              state.cursorMode = KeyboardCursorMode.FIELD_SELECTED;
          }
        })
      ),
    syncToPendingEffectOpt: (form) =>
      set(
        produce((state: KeyboardCursorState) => {
          switch (form.type) {
            case EffectOptType.PERMANENT:
              state.cursorMode = KeyboardCursorMode.EFFECT_OPT_PERMANENT;
              break;
            case EffectOptType.SLOT:
              state.cursorMode = KeyboardCursorMode.EFFECT_OPT_SLOT;
              break;
            case EffectOptType.ONE_CHOICE:
              state.cursorMode = KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE;
              break;
          }
        })
      ),
  })
);

// Reducer Computation
// For every possible button press, calculate the mutations that must be made.
// This includes mutations of other stores (e.g. TurnStore, ZoomStore)
// Note that these methods should NOT mutate state directly, but instead
// return a MutationResult that describe what mutations should be made.

export interface MutationResult {
  // undefined means same as {}
  keyboardCursorMutations?: KeyboardMutations;
  // undefined means same as []
  externalMutations?: ExternalMutation[];
}
type KeyboardMutations = Partial<KeyboardCursorState>;
export enum ExternalMutationType {
  SET_SELECTED_CARD_HAND = "set-selected-card-hand",
  SET_SELECTED_CARD_HAND_ALL_CARDS_AVAIALBLE = "set-selected-card-hand-all",
  SET_SELECTED_CARD_PERMANENT = "set-selected-card-permanent",
  RESET_SELECTED_CARD = "reset-selected-card",
  PICK_EFFECT_OPT_SLOT = "pick-effect-opt-slot",
  PICK_EFFECT_OPT_PERMANENT = "pick-effect-opt-permanent",
  PICK_EFFECT_OPT_ONE_CHOICE = "pick-effect-opt-one-choice",
  RESET_EFFECT_OPTS = "reset-effect-opts",
  SUMMON = "summon",
  MOVE = "move",
  ATTACK = "attack",
  SPECIAL = "special",
  FLEX = "flex",
  CREATE1 = "create1",
  CREATE2 = "create2",
  END_TURN = "end-turn",
  DRAW = "draw",
  HELP = "help",
}
type SetSelectedCardHandExternalMutation = {
  type: ExternalMutationType.SET_SELECTED_CARD_HAND;
  player: Player;
  handCardId: string;
};
type SetSelectedCardHandAllCardsAvailableExternalMutation = {
  type: ExternalMutationType.SET_SELECTED_CARD_HAND_ALL_CARDS_AVAIALBLE;
  player: Player;
  name: string;
};
type SetSelectedCardPermanentExternalMutation = {
  type: ExternalMutationType.SET_SELECTED_CARD_PERMANENT;
  permanentId: string;
};
type ResetSelectedCardExternalMutation = {
  type: ExternalMutationType.RESET_SELECTED_CARD;
};
type PickEffectOptSlotExternalMutation = {
  type: ExternalMutationType.PICK_EFFECT_OPT_SLOT;
  slot: Slot;
};
type PickEffectOptPermanentExternalMutation = {
  type: ExternalMutationType.PICK_EFFECT_OPT_PERMANENT;
  permanentId: string;
};
type PickEffectOptOneChoiceExternalMutation = {
  type: ExternalMutationType.PICK_EFFECT_OPT_ONE_CHOICE;
  choice: string;
};
type ResetEffectOptsExternalMutation = {
  type: ExternalMutationType.RESET_EFFECT_OPTS;
};
type SummonMutation = {
  type: ExternalMutationType.SUMMON;
  slot: Slot;
};
type MoveMutation = {
  type: ExternalMutationType.MOVE;
  slot: Slot;
};
type AttackMutation = {
  type: ExternalMutationType.ATTACK;
  defenderId: string;
};
type SpecialMutation = {
  type: ExternalMutationType.SPECIAL;
};
type FlexMutation = {
  type: ExternalMutationType.FLEX;
};
type Create1Mutation = {
  type: ExternalMutationType.CREATE1;
};
type Create2Mutation = {
  type: ExternalMutationType.CREATE2;
};
type EndTurnMutation = {
  type: ExternalMutationType.END_TURN;
  player: Player;
};
type DrawMutation = {
  type: ExternalMutationType.DRAW;
  player: Player;
};
type HelpMutation = {
  type: ExternalMutationType.HELP;
};

type ExternalMutation =
  | SetSelectedCardHandExternalMutation
  | SetSelectedCardHandAllCardsAvailableExternalMutation
  | SetSelectedCardPermanentExternalMutation
  | ResetSelectedCardExternalMutation
  | PickEffectOptSlotExternalMutation
  | PickEffectOptPermanentExternalMutation
  | PickEffectOptOneChoiceExternalMutation
  | ResetEffectOptsExternalMutation
  | SummonMutation
  | MoveMutation
  | AttackMutation
  | SpecialMutation
  | FlexMutation
  | Create1Mutation
  | Create2Mutation
  | EndTurnMutation
  | DrawMutation
  | HelpMutation;

export function computeResultsDirectionKey(
  state: KeyboardCursorState,
  direction: DirectionKey,
  inspectorInfo: InspectorInfo
): MutationResult {
  if (!state.enabled) return {};
  switch (state.cursorMode) {
    case KeyboardCursorMode.NOT_VISIBLE: {
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD,
        },
      };
    }
    case KeyboardCursorMode.FIELD: {
      return field_directionKey(state, direction, inspectorInfo);
    }
    case KeyboardCursorMode.HAND: {
      return hand_directionKey(state, direction, inspectorInfo);
    }
    case KeyboardCursorMode.FIELD_SELECTED:
    case KeyboardCursorMode.HAND_SELECTED:
    case KeyboardCursorMode.EFFECT_OPT_SLOT:
    case KeyboardCursorMode.EFFECT_OPT_PERMANENT: {
      return subcursor_directionKey(state, direction, inspectorInfo);
    }
    default:
      return {};
  }
}

export function computeResultsCancelKey(
  state: KeyboardCursorState
): MutationResult {
  if (!state.enabled) return {};
  switch (state.cursorMode) {
    case KeyboardCursorMode.FIELD:
    case KeyboardCursorMode.HAND:
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.NOT_VISIBLE,
        },
      };
    case KeyboardCursorMode.FIELD_SELECTED:
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD,
        },
        externalMutations: [makeResetSelectedCardMutation()],
      };
    case KeyboardCursorMode.HAND_SELECTED:
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.HAND,
        },
        externalMutations: [makeResetSelectedCardMutation()],
      };
    case KeyboardCursorMode.EFFECT_OPT_SLOT:
    case KeyboardCursorMode.EFFECT_OPT_PERMANENT:
    case KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE:
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD_SELECTED,
        },
        externalMutations: [makeResetEffectOptsMutation()],
      };
  }
  return {};
}

export function computeResultsSelectKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  if (!state.enabled) return {};
  const { inspector, role } = inspectorInfo;
  switch (state.cursorMode) {
    case KeyboardCursorMode.NOT_VISIBLE: {
      const card = computeFieldCard(state.fieldCursorLocation, inspector);
      if (!card) {
        return {
          keyboardCursorMutations: {
            cursorMode: KeyboardCursorMode.FIELD,
          },
        };
      }
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD_SELECTED,
        },
        externalMutations: [
          makeSelectPermanentMutation(card.permanentId || ""),
        ],
      };
      break;
    }
    case KeyboardCursorMode.FIELD: {
      const card = computeFieldCard(state.fieldCursorLocation, inspector);
      if (!card) {
        return {};
      }
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD_SELECTED,
        },
        externalMutations: [
          makeSelectPermanentMutation(card.permanentId || ""),
        ],
      };
    }
    case KeyboardCursorMode.HAND: {
      const card = computeHandCard(
        state.handPlayer,
        state.handCursorIndex,
        inspector
      );
      if (!card || !state.handPlayer) {
        return {};
      }
      if (inspector.isAllCardsAvailable()) {
        return {
          keyboardCursorMutations: {
            cursorMode: KeyboardCursorMode.HAND_SELECTED,
          },
          externalMutations: [
            makeSelectHandCardAllCardsAvailableMutation(
              state.handPlayer,
              card.cardName
            ),
          ],
        };
      } else {
        return {
          keyboardCursorMutations: {
            cursorMode: KeyboardCursorMode.HAND_SELECTED,
          },
          externalMutations: [
            makeSelectHandCardMutation(state.handPlayer, card.card.id),
          ],
        };
      }
    }
    case KeyboardCursorMode.FIELD_SELECTED: {
      if (!state.subcursorLocation) return {};
      const selectedPermanent = computeFieldPermanent(
        state.fieldCursorLocation,
        inspector
      );
      if (!selectedPermanent) return {};

      // is move valid?
      // Have to do validation here because it's kinda ambiguous (due to beeBay puzz)
      const moveStep = inspectorInfo.stepMaker.move(
        selectedPermanent,
        state.subcursorLocation
      );
      const { isValid } = inspector.resolveAndValidate(moveStep, role);

      if (isValid) {
        return {
          keyboardCursorMutations: {
            fieldCursorLocation: state.subcursorLocation,
          },
          externalMutations: [makeMoveMutation(state.subcursorLocation)],
        };
      }

      const perm = inspector.getPermanentAtIfExists(state.subcursorLocation);
      if (perm) {
        return {
          externalMutations: [makeAttackMutation(perm.id)],
        };
      }
      return {};
    }
    case KeyboardCursorMode.HAND_SELECTED: {
      if (!state.subcursorLocation) return {};
      return {
        keyboardCursorMutations: {
          fieldCursorLocation: state.subcursorLocation,
        },
        externalMutations: [makeSummonMutation(state.subcursorLocation)],
      };
    }
    case KeyboardCursorMode.EFFECT_OPT_SLOT: {
      if (!state.subcursorLocation) return {};
      return {
        externalMutations: [
          makePickEffectOptSlotMutation(state.subcursorLocation),
        ],
      };
    }
    case KeyboardCursorMode.EFFECT_OPT_PERMANENT: {
      if (!state.subcursorLocation) return {};
      const perm = computeFieldPermanent(state.subcursorLocation, inspector);
      if (!perm) return {};
      return {
        externalMutations: [makePickEffectOptPermanentMutation(perm.id)],
      };
    }
    case KeyboardCursorMode.EFFECT_OPT_ONE_CHOICE:
    case KeyboardCursorMode.UNKNOWN:
      break;
  }
  return {};
}

export function computeResultsEndTurnKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  if (!state.enabled) return {};

  const { inspector } = inspectorInfo;
  const turnP1 = inspector.isTurnPending(Player.P1);
  // This will attempt to trigger the end turn.
  // The handlers in GameHotkeys will perform validation.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.END_TURN,
        player: turnP1 ? Player.P1 : Player.P2,
      },
    ],
  };
}

export function computeResultsDrawKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  if (!state.enabled) return {};

  const { inspector } = inspectorInfo;
  const turnP1 = inspector.isTurnPending(Player.P1);
  // This will attempt to trigger the draw.
  // The handlers in GameHotkeys will perform validation.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.DRAW,
        player: turnP1 ? Player.P1 : Player.P2,
      },
    ],
  };
}

export function computeResultsSpecialKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  // This will attempt to trigger the special.
  // The handlers in GameHotkeys will perform validation and more logic,
  // including potentially updating for effect opts.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.SPECIAL,
      },
    ],
  };
}

export function computeResultsFlexKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  // This will attempt to trigger the flex.
  // The handlers in GameHotkeys will perform validation and more logic,
  // including potentially updating for effect opts.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.FLEX,
      },
    ],
  };
}

export function computeResultsCreate1Key(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  // This will attempt to trigger the create.
  // The handlers in GameHotkeys will perform validation.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.CREATE1,
      },
    ],
  };
}

export function computeResultsCreate2Key(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  // This will attempt to trigger the create.
  // The handlers in GameHotkeys will perform validation.
  return {
    externalMutations: [
      {
        type: ExternalMutationType.CREATE2,
      },
    ],
  };
}

export function computeResultsFocusFieldKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  switch (state.cursorMode) {
    case KeyboardCursorMode.NOT_VISIBLE:
    case KeyboardCursorMode.HAND:
      return {
        keyboardCursorMutations: {
          cursorMode: KeyboardCursorMode.FIELD,
        },
      };
  }
  return {};
}

export function computeResultsFocusHandKey(
  state: KeyboardCursorState,
  inspectorInfo: InspectorInfo
): MutationResult {
  const mutations = {} as KeyboardMutations;
  if (state.handPlayer === null) {
    mutations.handPlayer = getPlayerPerspectiveForRole(inspectorInfo.role);
  }
  switch (state.cursorMode) {
    case KeyboardCursorMode.NOT_VISIBLE:
    case KeyboardCursorMode.FIELD:
      mutations.cursorMode = KeyboardCursorMode.HAND;
      break;
  }
  return {
    keyboardCursorMutations: mutations,
  };
}

export function computeResultsSelectHandCardKey(
  state: KeyboardCursorState,
  index: number,
  inspectorInfo: InspectorInfo
): MutationResult {
  const mutations = {} as KeyboardMutations;
  let handPlayer = state.handPlayer;
  if (handPlayer === null) {
    mutations.handPlayer = getPlayerPerspectiveForRole(inspectorInfo.role);
    handPlayer = mutations.handPlayer;
  }
  switch (state.cursorMode) {
    case KeyboardCursorMode.NOT_VISIBLE:
    case KeyboardCursorMode.FIELD:
    case KeyboardCursorMode.FIELD_SELECTED:
    case KeyboardCursorMode.HAND:
    case KeyboardCursorMode.HAND_SELECTED:
      mutations.cursorMode = KeyboardCursorMode.HAND_SELECTED;
      mutations.handCursorIndex = index;
      mutations.subcursorLocation = null;
      break;
    default:
      return {};
  }
  const card = computeHandCard(handPlayer, index, inspectorInfo.inspector);
  if (card === null) {
    return {};
  }
  return {
    keyboardCursorMutations: mutations,
    externalMutations: [makeSelectHandCardMutation(handPlayer, card.card.id)],
  };
}

export function computeResultsHelpKey(): MutationResult {
  return {
    externalMutations: [
      {
        type: ExternalMutationType.HELP,
      },
    ],
  };
}

function hand_directionKey(
  state: KeyboardCursorState,
  direction: DirectionKey,
  inspectorInfo: InspectorInfo
): MutationResult {
  const { inspector, role } = inspectorInfo;
  const mutations = {} as KeyboardMutations;
  let handPlayer: Player;
  if (state.handPlayer === null) {
    mutations.handPlayer = getPlayerPerspectiveForRole(role);
    handPlayer = mutations.handPlayer;
  } else {
    handPlayer = state.handPlayer;
  }
  let { handCursorIndex } = state;
  switch (direction) {
    case DirectionKey.LEFT: {
      handCursorIndex -= 1;
      const handLen = inspector.getHandForUI(handPlayer).length;
      if (handCursorIndex < 0) {
        handCursorIndex = handLen - 1;
      } else {
        handCursorIndex = Math.min(handCursorIndex, handLen - 1);
      }
      break;
    }
    case DirectionKey.RIGHT: {
      handCursorIndex += 1;
      const handLen = inspector.getHandForUI(handPlayer).length;
      if (handCursorIndex > handLen - 1) {
        handCursorIndex = 0;
      }
      break;
    }
    case DirectionKey.UP: {
      if (role === Role.P1 && handPlayer === Player.P1) {
        mutations.cursorMode = KeyboardCursorMode.FIELD;
      }
      if (role === Role.P2 && handPlayer === Player.P2) {
        mutations.cursorMode = KeyboardCursorMode.FIELD;
      }
      break;
    }
    case DirectionKey.DOWN: {
      if (role === Role.P1 && handPlayer === Player.P2) {
        mutations.cursorMode = KeyboardCursorMode.FIELD;
      }
      if (role === Role.P2 && handPlayer === Player.P1) {
        mutations.cursorMode = KeyboardCursorMode.FIELD;
      }
      break;
    }
  }

  if (mutations.cursorMode === KeyboardCursorMode.FIELD) {
    return {
      keyboardCursorMutations: mutations,
    };
  }

  mutations.handCursorIndex = handCursorIndex;
  mutations.handPlayer = handPlayer;
  return {
    keyboardCursorMutations: mutations,
  };
}

function field_directionKey(
  state: KeyboardCursorState,
  direction: DirectionKey,
  inspectorInfo: InspectorInfo
): MutationResult {
  const newSlot = getSlotInDirection(
    state.fieldCursorLocation,
    direction,
    inspectorInfo
  );
  const { inspector, role } = inspectorInfo;
  const mutations = {} as KeyboardMutations;
  if (newSlot != null) {
    mutations.fieldCursorLocation = newSlot;
    return {
      keyboardCursorMutations: mutations,
    };
  }

  switch (role) {
    case Role.P1: {
      if (
        direction === DirectionKey.DOWN &&
        inspector.getHandForUI(Player.P1).length
      ) {
        mutations.cursorMode = KeyboardCursorMode.HAND;
      }
      break;
    }
    case Role.P2: {
      if (
        direction === DirectionKey.DOWN &&
        inspector.getHandForUI(Player.P2).length
      ) {
        mutations.cursorMode = KeyboardCursorMode.HAND;
      }
      break;
    }
    case Role.GOD: {
      if (
        direction === DirectionKey.UP &&
        inspector.getHandForUI(Player.P2).length
      ) {
        mutations.cursorMode = KeyboardCursorMode.HAND;
        if (state.handPlayer !== Player.P2) {
          mutations.handPlayer = Player.P2;
          mutations.handCursorIndex = 0;
        }
      } else if (
        direction === DirectionKey.DOWN &&
        inspector.getHandForUI(Player.P1).length
      ) {
        mutations.cursorMode = KeyboardCursorMode.HAND;
        if (state.handPlayer !== Player.P1) {
          mutations.handPlayer = Player.P1;
          mutations.handCursorIndex = 0;
        }
      }
      break;
    }
  }
  return {
    keyboardCursorMutations: mutations,
  };
}

function subcursor_directionKey(
  state: KeyboardCursorState,
  direction: DirectionKey,
  inspectorInfo: InspectorInfo
): MutationResult {
  const mutations = {} as KeyboardMutations;
  let cursorLocation = state.fieldCursorLocation;
  if (state.subcursorLocation === null) {
    // initialize this mode
    mutations.subcursorLocation = state.fieldCursorLocation;
  } else {
    cursorLocation = state.subcursorLocation;
  }
  const slot = getSlotInDirection(cursorLocation, direction, inspectorInfo);
  if (slot !== null) {
    mutations.subcursorLocation = slot;
  }

  return {
    keyboardCursorMutations: mutations,
  };
}

function mutateSelectFieldPermanent(
  state: KeyboardCursorState,
  perm: Permanent
) {
  state.cursorMode = KeyboardCursorMode.FIELD_SELECTED;
  state.fieldCursorLocation = perm.slot;
}

function getSlotInDirection(
  slot: Slot,
  direction: DirectionKey,
  inspectorInfo: InspectorInfo
): Slot | null {
  const { inspector, role } = inspectorInfo;
  const player = getPlayerPerspectiveForRole(role);
  let dr = 0,
    dc = 0;
  switch (direction) {
    case DirectionKey.UP:
      dr = player === Player.P1 ? -1 : 1;
      break;
    case DirectionKey.DOWN:
      dr = player === Player.P1 ? 1 : -1;
      break;
    case DirectionKey.LEFT:
      dc = -1;
      break;
    case DirectionKey.RIGHT:
      dc = 1;
      break;
  }
  const newSlot = { row: slot.row + dr, column: slot.column + dc };
  return inspector.isSlotInBounds(newSlot) ? newSlot : null;
}

function computeFieldPermanent(
  slot: Slot,
  inspector: Inspector
): Permanent | null {
  if (!inspector.isSlotInBounds(slot)) return null;
  return inspector.getPermanentAtIfExists(slot);
}

export function computeFieldCard(
  slot: Slot,
  inspector: Inspector
): ZoomedCardDetails | null {
  const perm = computeFieldPermanent(slot, inspector);
  if (!perm) return null;

  return {
    cardName: perm.card.name || "",
    permanentId: perm.id,
  };
}

export function computeHandCard(
  player: Player | null,
  index: number,
  inspector: Inspector
): (ZoomedCardDetails & { card: HandCard }) | null {
  const card = player ? inspector.getHandForUI(player)[index] : null;
  return card
    ? {
        cardName: card.card.name || "",
        card,
      }
    : null;
}

function makeResetSelectedCardMutation(): ResetSelectedCardExternalMutation {
  return {
    type: ExternalMutationType.RESET_SELECTED_CARD,
  };
}

function makeResetEffectOptsMutation(): ResetEffectOptsExternalMutation {
  return {
    type: ExternalMutationType.RESET_EFFECT_OPTS,
  };
}

function makeSelectPermanentMutation(
  permanentId: string
): SetSelectedCardPermanentExternalMutation {
  return {
    type: ExternalMutationType.SET_SELECTED_CARD_PERMANENT,
    permanentId,
  };
}

function makeSelectHandCardMutation(
  player: Player,
  handCardId: string
): SetSelectedCardHandExternalMutation {
  return {
    type: ExternalMutationType.SET_SELECTED_CARD_HAND,
    player,
    handCardId,
  };
}

function makeSelectHandCardAllCardsAvailableMutation(
  player: Player,
  name: string
): SetSelectedCardHandAllCardsAvailableExternalMutation {
  return {
    type: ExternalMutationType.SET_SELECTED_CARD_HAND_ALL_CARDS_AVAIALBLE,
    player,
    name,
  };
}

function makePickEffectOptSlotMutation(
  slot: Slot
): PickEffectOptSlotExternalMutation {
  return {
    type: ExternalMutationType.PICK_EFFECT_OPT_SLOT,
    slot,
  };
}

function makePickEffectOptPermanentMutation(
  permanentId: string
): PickEffectOptPermanentExternalMutation {
  return {
    type: ExternalMutationType.PICK_EFFECT_OPT_PERMANENT,
    permanentId,
  };
}

function makePickEffectOptOneChoiceMutation(
  choice: string
): PickEffectOptOneChoiceExternalMutation {
  return {
    type: ExternalMutationType.PICK_EFFECT_OPT_ONE_CHOICE,
    choice,
  };
}

function makeSummonMutation(slot: Slot): SummonMutation {
  return {
    type: ExternalMutationType.SUMMON,
    slot,
  };
}

function makeMoveMutation(slot: Slot): MoveMutation {
  return {
    type: ExternalMutationType.MOVE,
    slot,
  };
}

function makeAttackMutation(defenderId: string): AttackMutation {
  return {
    type: ExternalMutationType.ATTACK,
    defenderId,
  };
}
