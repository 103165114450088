import { Player } from "engine/types/game-state";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedPuzzle31Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    // one camp to be "easy" to win against
    [Player.P2]: ["camp"],
  },
};
