import { Player } from "engine/types/game-state";
import { CounterType, getCounterExplanation } from "engine/types/counters";
import { SharedGameSpec } from "engine/types/shared-game-specs";
import { Check } from "engine/types/action-validation";

export const SharedPuzzle50Spec: SharedGameSpec = {
  cardEffectsShared: {
    "b-w-copier": {
      isStructure: true,
      isBase: true,
      specialCost: [],
    },
    "pasture-planner": {
      isStructure: true,
      isBase: true,
      specialCost: [],
    },
  },
  makeCardEffectsShared: (cardsDB) => {
    return Object.fromEntries(
      Object.values(cardsDB)
        .filter(
          ({ name: cardName, text }) =>
            cardName.startsWith("puzzle-50") && text === ""
        )
        .map(({ name: cardName }) => [
          cardName,
          {
            computeText: (permanent, ctx) => {
              const { inspector } = ctx;
              const specialCounters = permanent.counters.filter(
                ({ type: counterType }) =>
                  [
                    CounterType.CANNOT_BE_DESTROYED,
                    CounterType.INVULNERABLE,
                    CounterType.CANNOT_MOVE,
                  ].includes(counterType)
              );
              if (specialCounters.length === 0) return "";
              return `${specialCounters
                .map((counter) => {
                  const explanation =
                    counter.type === CounterType.CANNOT_MOVE
                      ? "Immobile."
                      : getCounterExplanation(counter);
                  if (explanation === null)
                    throw new Error(
                      "expect the keyworded counters to have explanations"
                    );
                  return explanation.replace(/[^a-zA-Z]/g, "");
                })
                .join(", ")}.`;
            },
          },
        ])
    );
  },
  hasAI: true,
  aiDisableGemAccounting: true,
  numRows: 7,
  numColumns: 5,
  basesOverrides: {
    [Player.P1]: ["b-w-copier"],
    [Player.P2]: ["pasture-planner"],
  },
  adjustSummonChecks: (failedChecks, _player, _cardName, slot, _ctx) => {
    if (slot.row > 0) {
      failedChecks.delete(Check.TERRAIN);
    }
  },
  adjustAttackChecks: (failedChecks, attacker) => {
    if (attacker.owner === Player.P2) {
      failedChecks.delete(Check.PROTECTED);
    }
  },
};
