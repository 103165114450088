// TODO: In production, we might want to link to the static copy for
// some (or all) of these images rather than importing them directly.

import { ImageAttrs } from "vite-plugin-image-presets";

const IMAGES =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      });

const CARD_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/card-art/*", {
        eager: true,
        as: "preset=card",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/card-art/*", {
        eager: true,
        as: "preset=card",
        import: "default",
      });

const BATTLER_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/battler-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/battler-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      });

const BATTLER_SLICE_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/battler-art/slice/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/battler-art/slice/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      });

const KERO_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/kero-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/kero-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      });

const MAP_BG_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("../static/images/bg/client/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("../static/images/bg/client/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      });

const MAP_BG_SLICE_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("../static/images/bg/slice/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("../static/images/bg/slice/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      });

const CUTSCENE_ART =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("/src/images/cutscene-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("/src/images/cutscene-art/*", {
        eager: true,
        as: "preset=battler",
        import: "default",
      });

const WRAPUP_GALLERY =
  import.meta.env.MODE === "development"
    ? import.meta.glob<ImageAttrs[]>("../static/images/wrapup-gallery/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      })
    : import.meta.glob<ImageAttrs[]>("../static/images/wrapup-gallery/*.png", {
        eager: true,
        as: "preset=background",
        import: "default",
      });

export const getImagePath = (filename: string): ImageAttrs[] => {
  return IMAGES[`/src/images/${filename}`];
};

// returns path to the card art or placeholder if not found
export const getCardArtPath = (cardName: string): ImageAttrs[] => {
  const img = CARD_ART[`/src/images/card-art/${cardName}.png`]; // only supports png
  if (img) {
    return img;
  }
  return CARD_ART["/src/images/card-art/placeholder.png"];
};

// returns path to the battler art or smobster if not found
export const getBattlerArtPath = (battleGroupName: string): ImageAttrs[] => {
  const img = BATTLER_ART[`/src/images/battler-art/${battleGroupName}.png`]; // only supports png
  if (img) {
    return img;
  }
  return BATTLER_ART["/src/images/battler-art/smobster.png"];
};

export const getBattlerSliceArtPaths = (
  battleGroupName: string
): ImageAttrs[][] => {
  return Object.entries(BATTLER_SLICE_ART)
    .filter(([imageName]) =>
      imageName.startsWith(`/src/images/battler-art/slice/${battleGroupName}`)
    )
    .sort()
    .map(([, img]) => img);
};

export const getKeroArtPath = (imageName: string): ImageAttrs[] => {
  const img = KERO_ART[`/src/images/kero-art/${imageName}`];
  if (img === undefined)
    throw new Error(`could not find kero image ${imageName}`);
  return img;
};

export const getMapBgArtPath = (imageName: string): ImageAttrs[] => {
  const img = MAP_BG_ART[`../static/images/bg/client/${imageName}`];
  if (img !== undefined) return img;
  throw new Error(`could not find map bg image ${imageName}`);
};

export const getMapBgSliceArtPaths = (imagePrefix: string): ImageAttrs[][] => {
  return Object.entries(MAP_BG_SLICE_ART)
    .filter(([imageName]) =>
      imageName.startsWith(`../static/images/bg/slice/${imagePrefix}`)
    )
    .sort()
    .map(([, img]) => img);
};

export const getCutsceneArtPath = (imageName: string): ImageAttrs[] => {
  const img = CUTSCENE_ART[`/src/images/cutscene-art/${imageName}`];
  if (img === undefined)
    throw new Error(`could not find cutscene image ${imageName}`);
  return img;
};

export const getWrapupGalleryPath = (imageName: string): ImageAttrs[] => {
  const img = WRAPUP_GALLERY[`../static/images/wrapup-gallery/${imageName}`];
  if (img === undefined)
    throw new Error(`could not find wrapup image ${imageName}`);
  return img;
};
