import { Deck } from "engine/types/decks";
import { Player, SerializedCheckpointState } from "engine/types/game-state";
import { Faction } from "engine/types/factions";
import { MasteryTree } from "game-server/masteries";
import { SavedGameRoom } from "game-server/Room";
import { BackendSyncReqPacket } from "game-server/backend-interface/backend-sync";

export enum LoseReason {
  /** A normal loss, usually by having all bases destroyed. */
  NORMAL = "normal",
  /** Ran out of time. */
  TIME = "time",
  /** Resigned voluntarily. */
  RESIGN = "resign",
}

export type BackendRoom = {
  roomId: string;
  puzName: string;
  /**
   * The host puzzle. In the case of instancer rooms, hostPuzName
   * refers to the instancer puzzle itself, while puzName refers to
   * the puzzle being hosted.
   */
  hostPuzName: string;
  /**
   * Timestamp of when the room was created. Only used to ensure an
   * ordering when requesting the latest room from Django.
   */
  createTime: number;

  p1TeamId?: string;
  p2TeamId?: string;
  p1Faction?: Faction;
  p2Faction?: Faction;
  savedRoom?: SavedGameRoom;

  /**
   * Information about the game end. Null signifies that the game
   * has not ended.
   */
  gameEndInfo?: {
    /**
     * The total time spent active in milliseconds, used for
     * speedrun calculations.
     */
    totalTime: number;
    /** Whether the puzzle was solved. */
    isSolved: boolean;
    /** Who won. */
    winner: Player;
    /** How the loss happened. */
    loseReason: LoseReason;

    p1FactionScoreContribution?: number;
    p2FactionScoreContribution?: number;
  };
};

export type BackendDeck = {
  teamId: string;
  slot: number;
  deck: Deck;
};

export type BackendAnswerSubmission = {
  teamId: string;
  puzName: string;
  timestamp: number;
  answer: string;
  isCorrect: boolean;
  usedFreeAnswer: boolean;
};

export const makeBattleSolveAnswerSubmission = (
  teamId: string,
  puzName: string,
  timestamp: number
): BackendAnswerSubmission => {
  return {
    teamId,
    puzName,
    timestamp,
    answer: "battle-solve",
    isCorrect: true,
    usedFreeAnswer: false,
  };
};

export type BackendUnlock = {
  teamId: string;
  puzName: string;
  /** Time when the puzzle was unlocked. */
  timestamp: number;
};

export type BackendViewTime = {
  teamId: string;
  puzName: string;
  /** Time when the puzzle was first accessed. */
  viewTime: number;
};

export type BackendCardUnlock = {
  teamId: string;
  puzName: string | null;
  cardName: string;
  timestamp: number;
};

export type BackendErratum = {
  puzzle: string | null;
  text: string;
  timestamp: number;
  published: boolean;
};

export enum BackendNotificationDataType {
  HINT_ANSWERED = "hint_answered",
  TEAM_MEMBERS = "team_members",
  ERRATUM = "erratum",
}

// Solve/unlock/victory notifications are handled by HuntLib.
export type BackendNotificationHintAnsweredData = {
  type: BackendNotificationDataType.HINT_ANSWERED;
  teamId: string;
  puzName: string;
};

export type BackendNotificationTeamMembersData = {
  type: BackendNotificationDataType.TEAM_MEMBERS;
  teamId: string;
  members: string[];
};

export type BackendNotificationErratumData = {
  type: BackendNotificationDataType.ERRATUM;
  erratumId: string;
} & BackendErratum;

export type BackendNotification =
  | BackendNotificationHintAnsweredData
  | BackendNotificationTeamMembersData
  | BackendNotificationErratumData;

export type BackendOwnedTeamState = {
  hintsTotal: number;
  /** puzNames of puzzles that hints are used on. */
  hintsUsed: string[];
  extraGuesses: {
    [puzName: string]: number;
  };
};

export type TeamPuz = {
  teamId: string;
  puzName: string;
};

export type TeamCard = {
  teamId: string;
  cardName: string;
};

export type BackendPreferredDeck = TeamPuz & {
  slot: number;
};

export type BackendBlockedTeam = {
  teamId: string;
  targetTeamId: string;
};

export type BackendCutsceneChoice = {
  keyframeId: string;
  dialogOptionIndex: number;
};

export type GetInitDataBackendResp = {
  /**
   * Unique identifier for the game server instance.
   * Should be different every time getInitData is called, even
   * if called again from the same machine.
   */
  gameServerId: string;
  /** Initial team data for all teams. */
  teams: { [teamId: string]: GetInitTeamDataBackendResp };
  factionHistory: { [key: string]: number };

  // Static hunt config to keep in sync with Django if we change them.
  maxGuessesPerPuzzle: number;
  huntStartTime: number;
  huntEndTime: number;

  /**
   * The next index for notifications that the server should start
   * pulling from.
   */
  notifsNextIndex: number;

  errata: { [erratumId: string]: BackendErratum };
  unclaimedHints: number;
};

export type GetInitTeamDataBackendResp = {
  userId: string;
  displayName: string;
  isInactive: boolean;
  isHidden: boolean;
  members: string[];
  faction: Faction | null;
  factionScoreContributions: { [faction in Faction]: number };
  /**
   * The most recent room for each puzzle. May be inactive.
   * Only relevant as part of the init full sync in GetInitData.
   * If a team joins midway, we know they would not be joined to
   * any rooms.
   */
  rooms: { [puzName: string]: BackendRoom };
  decks: BackendDeck[];
  masteryTree: MasteryTree | null;
  /** puzNames of puzzles viewed by the team. */
  views: ReadonlyArray<string>;
  /**
   * Cards unlocked by the team, mapped to the puzName of the puzzles
   * that unlocked them, if any.
   */
  cardUnlocks: { [cardName: string]: string | null };
  /** Time when each puzzle was unlocked. */
  unlockTimes: { [puzName: string]: number };
  /** Time when each puzzle was solved. */
  solveTimes: { [puzName: string]: number };
  /** Fastest completion (with solve) times for each battle. */
  speedrunTimes: { [puzName: string]: number };
  /** Number of wrong guesses, for each puzzle with any wrong guesses. */
  numWrongGuesses: { [puzName: string]: number };
  /** The last used deck slot for each puzzle. */
  preferredDecks: { [puzName: string]: number };
  /** Puzzle selections for instancer rooms. */
  puzzleSelections: { [puzName: string]: string };
  /** Saved checkpoints for each puzzle. */
  checkpoints: {
    [puzName: string]: {
      [slot: number]: SerializedCheckpointState;
    };
  };
  /** Teams blocked in PvP. */
  blockedTeams: { [teamId: string]: boolean };
  /** Whether the player is in the PvP ring. */
  isInRing: boolean;

  /**
   * Team state "owned" by the backend, meaning that updates to this
   * state may be made directly to the backend and so must be
   * periodically synced fromm the backend to the Node server.
   */
  backendOwned: BackendOwnedTeamState;
};

export type SyncDataBackendReq = {
  /** If specified, pulls notifications starting from the given index. */
  notifsStartIndex?: number;

  teamState?: { [teamId: string]: BackendSyncReqPacket };
  /** Rooms to create or set properties of. */
  rooms?: { [roomId: string]: BackendRoom };
  factionHistory?: { [key: string]: number };
};

export type SyncDataBackendResp = {
  notifs?: BackendNotification[];
  notifsNextIndex?: number;
  teams?: { [teamId: string]: BackendOwnedTeamState };
  unclaimedHints?: number;
};

export type CheckAnswerBackendReq = {
  teamId: string;
  puzName: string;
  /**
   * Answer to solve with. This may be omitted if no answer is
   * required (such as for battles), or when overriding solve state.
   */
  answer: string;
};

export enum CheckAnswerBackendRespType {
  ALREADY_SOLVED = "already_solved",
  NO_GUESSES = "no_guesses",
  PUZZLE_MESSAGES = "puzzle_messages",
  NO_ANSWER = "no_answer",
  TRIED_BEFORE = "tried_before",
  SUCCESS = "success",
}

export type CheckAnswerBackendResp =
  | {
      type:
        | CheckAnswerBackendRespType.ALREADY_SOLVED
        | CheckAnswerBackendRespType.NO_GUESSES
        | CheckAnswerBackendRespType.NO_ANSWER;
    }
  | {
      type: CheckAnswerBackendRespType.PUZZLE_MESSAGES;
      messages: ReadonlyArray<string>;
    }
  | {
      type: CheckAnswerBackendRespType.TRIED_BEFORE;
      submittedAnswer: string;
    }
  | {
      type: CheckAnswerBackendRespType.SUCCESS;
      submittedAnswer: string;
      isCorrect: boolean;
    };

/**
 * Interface to wrap all Django interaction functionality.
 * All Django interface calls should be wrapped up in here
 * so that we can swap them out in Django-less mode.
 *
 * These should hook into the URLs provided in cardsdata/urls.py
 */
export interface BackendInterface {
  /**
   * Get initial data to bootstrap the server instance.
   */
  getInitData: () => Promise<GetInitDataBackendResp>;
  /**
   * Get initial persistent data for a team.
   */
  getInitTeamData: (teamId: string) => Promise<GetInitTeamDataBackendResp>;
  /**
   * Sync data to the server.
   */
  syncData: (req: SyncDataBackendReq) => Promise<SyncDataBackendResp>;
  /**
   * Check an answer for a team without updating database state.
   * This needs to be a backend call if we want to support a wider
   * response set, like checking the guess limit, or if the answer
   * was submitted before.
   */
  checkAnswer: (req: CheckAnswerBackendReq) => Promise<CheckAnswerBackendResp>;
}
