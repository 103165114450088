import { BATTLE_GROUP_NAME_MOONICK } from "engine/puzzles/puzzle-data";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { useAnimationSpeed } from "stores/AnimationStore";
import { useInspector, usePlayerPerspective } from "stores/ClientGameStore";
import { useRoomBattleGroupName } from "stores/ClientGlobalStateStore";
import "./CurrentTurn.css";

function CurrentTurn() {
  const { gameState } = useInspector();
  const roomBattleGroupName = useRoomBattleGroupName();
  const isDisabled =
    roomBattleGroupName === null ||
    roomBattleGroupName === BATTLE_GROUP_NAME_MOONICK;
  const playerPerspective = usePlayerPerspective();
  const speed = useAnimationSpeed();
  const atBottom = useMemo(
    () => gameState.currentTurnPlayer === playerPerspective,
    [gameState.currentTurnPlayer, playerPerspective]
  );

  if (isDisabled) return null;

  return (
    <motion.div
      className="current-turn"
      animate={{ y: atBottom ? "50%" : "-50%" }}
      transition={{ duration: speed === 0 ? 0 : 0.3 / speed }}
    />
  );
}

export default CurrentTurn;
