import {
  Player,
  Slot,
  Permanent,
  areSlotsAdjacent,
} from "engine/types/game-state";
import { InspectorContext } from "engine/types/shared-game-specs";
import {
  ColorSymbol,
  CostColor,
  CardType,
  GemColor,
} from "engine/types/card-data";
import { CounterType } from "engine/types/counters";
import {
  EffectOptType,
  EffectOptResolved,
  EffectOptForm,
  EffectOptValidationContext,
  AbilityType,
} from "engine/types/effects";
import { Check, FailedChecks } from "engine/types/action-validation";

/** Card effect information that is shared between client and server. */
export type CardEffectsShared = {
  /** Is this card a structure? Matters for playing creatures on top. */
  isStructure?: boolean;
  /**
   * Is this card a base? Matters for:
   * - Number of gems received at turn start.
   * - Checking the game loss condition.
   */
  isBase?: boolean;
  isLegendary?: boolean;

  /** Override the card's base power. */
  power?: number;
  /** Override the card's base max health. */
  maxHealth?: number;

  /** Override the deckbuilding limit for this card. */
  deckbuildingLimit?: number;
  /** Disable the deckbuilding limit entirely for this card. */
  disableDeckbuildingLimit?: boolean;

  // These are split up to make the effects spec shorter.
  /**
   * Gems that cards can create, if different from the default (one gem of the
   * card's color). Most cards don't need to specify this.
   */
  createGemColors?: GemColor[][];
  /**
   * The cost for the card's activated ability. Should be specified even if
   * free. The existence of the cost is how we know the activated ability
   * exists in the first place.
   */
  flexCost?: CostColor[];
  specialCost?: CostColor[];
  /**
   * Specs for user selections required to fully specify the application
   * of an effect.
   */
  flexForms?: EffectOptForm[];
  specialForms?: EffectOptForm[];

  /** Adjust summon validation. */
  adjustSummonChecks?: (
    failedChecks: FailedChecks,
    player: Player,
    cardName: string,
    slot: Slot,
    ctx: InspectorContext
  ) => void;
  /** Adjust move validation. */
  adjustMoveChecks?: (
    failedChecks: FailedChecks,
    permanent: Permanent,
    slot: Slot,
    ctx: InspectorContext
  ) => void;
  /** Adjust attack validation. */
  adjustAttackChecks?: (
    failedChecks: FailedChecks,
    attacker: Permanent,
    defender: Permanent,
    ctx: InspectorContext
  ) => void;

  /** Compute the card text, if it depends on permanent state. */
  computeText?: (permanent: Permanent, ctx: InspectorContext) => string;
};

export type SharedEffectsDB = { [cardName: string]: CardEffectsShared };

// Utility functions to quickly get opt values selected so far.
const extractOpt = (
  ctx: EffectOptValidationContext,
  index: number
): EffectOptResolved => {
  const { effectOpts } = ctx;
  const effectOpt = effectOpts[index];
  if (effectOpt === undefined) {
    throw new Error("effect opt not selected yet");
  }
  return effectOpt;
};

const extractOptPermanent = (
  ctx: EffectOptValidationContext,
  index: number
): Permanent => {
  const effectOpt = extractOpt(ctx, index);
  if (effectOpt.type !== EffectOptType.PERMANENT) {
    throw new Error("wrong effect opt type");
  }
  return effectOpt.permanent;
};

const extractOptSlot = (
  ctx: EffectOptValidationContext,
  index: number
): Slot => {
  const effectOpt = extractOpt(ctx, index);
  if (effectOpt.type !== EffectOptType.SLOT) {
    throw new Error("wrong effect opt type");
  }
  return effectOpt.slot;
};

export const makePermanentOptForm = (
  validate: (
    failedChecks: FailedChecks,
    permanent: Permanent,
    target: Permanent,
    ctx: EffectOptValidationContext
  ) => void,
  userPrompt?: string
): EffectOptForm => {
  return {
    type: EffectOptType.PERMANENT,
    validate: (failedChecks, target, ctx) => {
      validate(failedChecks, ctx.permanent, target, ctx);
    },
    userPrompt,
  };
};

export const makeSlotOptForm = (
  validate: (
    failedChecks: FailedChecks,
    slot: Slot,
    ctx: EffectOptValidationContext
  ) => void,
  userPrompt?: string
): EffectOptForm => {
  return {
    type: EffectOptType.SLOT,
    validate: (failedChecks, slot, ctx) => {
      validate(failedChecks, slot, ctx);
    },
    userPrompt,
  };
};

/** Create a form which makes the user choose one out of a fixed list of options. */
export const makeOneChoiceOptForm = (
  choices: string[], // The choices available to choose from.
  validate: (
    failedChecks: FailedChecks,
    permanent: Permanent,
    choice: string,
    ctx: InspectorContext
  ) => void,
  userPrompt?: string
): EffectOptForm => {
  return {
    choices: choices,
    type: EffectOptType.ONE_CHOICE,
    validate: (failedChecks, target, ctx) => {
      validate(failedChecks, ctx.permanent, target, ctx);
    },
    userPrompt,
  };
};

export const plainAbilityActivationValidator = (
  failedChecks: FailedChecks,
  permanent: Permanent,
  abilityType: AbilityType,
  effectOpts: EffectOptResolved[],
  ctx: InspectorContext
): void => {
  const { inspector } = ctx;
  inspector.validateActivateAbility(
    failedChecks,
    permanent,
    abilityType,
    effectOpts
  );
};

const plainAttackValidator = (
  failedChecks: FailedChecks,
  permanent: Permanent,
  target: Permanent,
  ctx: InspectorContext
): void => {
  const { inspector } = ctx;
  inspector.validateAttack(failedChecks, permanent, target);
};

export const validateEffectOpt = (
  failedChecks: FailedChecks,
  effectOpt: EffectOptResolved,
  form: EffectOptForm,
  ctx: EffectOptValidationContext
) => {
  const { inspector } = ctx;
  switch (form.type) {
    case EffectOptType.PERMANENT: {
      if (effectOpt.type !== EffectOptType.PERMANENT) {
        failedChecks.add(Check.OPT_TYPE);
        break;
      }
      if (form.validate) {
        form.validate(failedChecks, effectOpt.permanent, ctx);
      }
      break;
    }
    case EffectOptType.SLOT: {
      if (effectOpt.type !== EffectOptType.SLOT) {
        failedChecks.add(Check.OPT_TYPE);
        break;
      }
      if (form.validate) {
        form.validate(failedChecks, effectOpt.slot, ctx);
      }
      break;
    }
  }
};

function boarAdjustAttackChecks(
  failedChecks: FailedChecks,
  attacker: Permanent,
  defender: Permanent
) {
  // can only attack down its column
  if (attacker.slot.column !== defender.slot.column)
    failedChecks.add(Check.GENERIC);
}
/* eslint sort-keys/sort-keys-fix: ["warn", "asc", { minKeys: 15 }] */
export const COMMON_CARD_EFFECTS_SHARED: SharedEffectsDB = {
  "bb-b": {
    deckbuildingLimit: 1,
    isLegendary: true,
  },
  "bee-rider": {
    flexCost: [],
    flexForms: [
      makeSlotOptForm((failedChecks, slot, ctx) => {
        const { inspector, permanent } = ctx;
        // can only flex once per turn
        if (
          inspector.doesPermanentHaveCounterType(
            permanent,
            CounterType.FLEXED_THIS_TURN
          )
        ) {
          failedChecks.add(Check.GENERIC);
        }
        if (!areSlotsAdjacent(permanent.slot, slot)) {
          failedChecks.add(Check.ADJACENT);
        }
        // cannot move into occupied slot
        if (inspector.isSlotOccupied(slot)) failedChecks.add(Check.OCCUPIED);
      }),
    ],
  },
  beeflector: {
    // must be summoned on enemy terrain
    adjustSummonChecks: (failedChecks) => {
      if (failedChecks.has(Check.TERRAIN)) {
        failedChecks.delete(Check.TERRAIN);
      } else {
        failedChecks.add(Check.TERRAIN);
      }
    },
  },
  beeowulf: {
    specialCost: [ColorSymbol.YELLOW],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        inspector.validateAttack(failedChecks, permanent, target);

        // The special can only be used on creatures.
        if (inspector.getCardType(target) !== CardType.CREATURE)
          failedChecks.add(Check.GENERIC);
      }),
      makeSlotOptForm((failedChecks, slot, ctx) => {
        const { inspector } = ctx;
        const defender = extractOptPermanent(ctx, 0);
        // must move to an adjacent space
        if (!areSlotsAdjacent(defender.slot, slot))
          failedChecks.add(Check.ADJACENT);
        // cannot move into occupied slot
        if (inspector.isSlotOccupied(slot)) failedChecks.add(Check.OCCUPIED);
      }, "select a slot to move the target to"),
    ],
  },
  blancmange: {
    deckbuildingLimit: 1,
    isLegendary: true,

    flexCost: [],
    flexForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // can only Flex once on the turn it's summoned and if it hasn't already flexed
        if (
          !inspector.doesPermanentHaveCounterType(
            permanent,
            CounterType.SUMMONED_THIS_TURN
          )
        ) {
          failedChecks.add(Check.GENERIC);
        }
        // not self
        if (target.id === permanent.id) failedChecks.add(Check.GENERIC);
        // friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.ALLIED);
        // Creature
        if (inspector.getCardType(target) !== CardType.CREATURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
        // make sure target is reflectable
        if (inspector.isSlotOccupied(inspector.getReflectedSlot(target.slot)))
          failedChecks.add(Check.OCCUPIED);
      }, "select a friendly Creature"),
    ],
  },
  "boarnana-tree": {
    flexCost: [ColorSymbol.RED],
  },
  "boarry-farmer": {
    adjustAttackChecks: boarAdjustAttackChecks,
  },
  camp: {
    isStructure: true,
    isBase: true,
  },
  "captain-pi": {
    deckbuildingLimit: 1,
    isLegendary: true,
    createGemColors: [[ColorSymbol.RAINBOW]],
  },
  chicken: {
    specialCost: [],
  },
  "chocolate-calf": {
    flexCost: [ColorSymbol.WHITE],
  },
  coloring: {
    deckbuildingLimit: 1,
    isLegendary: true,

    flexCost: [],
    flexForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // can only Flex once on the turn it's summoned and if it hasn't already flexed
        if (
          !inspector.doesPermanentHaveCounterType(
            permanent,
            CounterType.SUMMONED_THIS_TURN
          )
        ) {
          failedChecks.add(Check.GENERIC);
        }
        // not self
        if (target.id === permanent.id) failedChecks.add(Check.GENERIC);
        // friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.ALLIED);
        // Creature
        if (inspector.getCardType(target) !== CardType.CREATURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
        // unready (technically this Check.READY should be the opposite)
        if (target.ready) failedChecks.add(Check.READY);
      }, "select an unready friendly Creature"),
    ],
  },
  dargle: {
    deckbuildingLimit: 1,
    isLegendary: true,
  },
  "flora-the-explora": {
    specialCost: [],
  },
  giraffatitan: {
    specialCost: [],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        inspector.validateAttack(failedChecks, permanent, target);

        // The special can only be used on creatures.
        if (inspector.getCardType(target) !== CardType.CREATURE)
          failedChecks.add(Check.GENERIC);
        // Instead of checking if the target is unprotected,
        // check that it is protected.
        failedChecks.delete(Check.PROTECTED);
        if (!inspector.isProtectedFrom(target, permanent))
          failedChecks.add(Check.GENERIC);

        if (target.slot.column !== permanent.slot.column)
          failedChecks.add(Check.GENERIC);
      }, "select a protected Creature in the same column"),
    ],
  },
  "glass-of-moo": {
    specialCost: [],
  },
  "glass-of-moocha": {
    specialCost: [],
  },
  hog: {
    adjustAttackChecks: boarAdjustAttackChecks,
  },
  "hog-factory": {
    isStructure: true,
    isBase: true,
  },
  "hog-factorytory": {
    isStructure: true,
    isBase: true,
  },
  "hog-factorytorytory": {
    isStructure: true,
    isBase: true,
  },
  "hog-hog": {
    adjustAttackChecks: boarAdjustAttackChecks,
  },
  "hog-on-a-log": {
    adjustAttackChecks: boarAdjustAttackChecks,
  },
  "hog-on-a-log-on-a": {
    adjustAttackChecks: boarAdjustAttackChecks,
  },
  jabberwock: {
    deckbuildingLimit: 1,
    isLegendary: true,

    specialCost: [],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.ALLIED);
        // Creature
        if (inspector.getCardType(target) !== CardType.CREATURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
      }, "select a friendly Creature"),
    ],
  },
  keep: {
    isStructure: true,
    isBase: true,
    adjustMoveChecks: (failedChecks) => {
      // Keep can move even though it is a structure.
      failedChecks.delete(Check.CARD_TYPE);
    },
    adjustAttackChecks: (failedChecks) => {
      // Keep can attack even though it is a structure.
      failedChecks.delete(Check.CARD_TYPE);
    },
  },
  "lady-jane": {
    specialCost: [ColorSymbol.WHITEBLACK],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;

        if (permanent.owner === target.owner) {
          failedChecks.add(Check.ALLIED);
        }
        // Target must have taken damage this turn.
        if (inspector.getCounterValSum(target, CounterType.DAMAGE_TAKEN) <= 0)
          failedChecks.add(Check.GENERIC);
      }),
    ],
  },
  "log-factory": {
    isStructure: true,
    isBase: true,
  },
  "log-factorytory": {
    isStructure: true,
    isBase: true,
  },
  "log-factorytorytory": {
    isStructure: true,
    isBase: true,
  },
  "miss-yu": {
    deckbuildingLimit: 1,
    isLegendary: true,
  },
  "mister-penny": {
    deckbuildingLimit: 1,
    isLegendary: true,

    flexCost: [],
    flexForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.ALLIED);
        // Structure
        if (inspector.getCardType(target) !== CardType.STRUCTURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
      }, "select a friendly Structure"),
    ],
  },
  moonick: {
    deckbuildingLimit: 1,
    isLegendary: true,
  },
  murray: {
    flexCost: [ColorSymbol.WHITEBLACK],
    flexForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // can only flex once per turn
        if (
          inspector.doesPermanentHaveCounterType(
            permanent,
            CounterType.FLEXED_THIS_TURN
          )
        ) {
          failedChecks.add(Check.GENERIC);
        }
      }),
    ],
    specialCost: [
      ColorSymbol.RED,
      ColorSymbol.YELLOW,
      ColorSymbol.PURPLE,
      ColorSymbol.GREEN,
      ColorSymbol.WHITE,
      ColorSymbol.BLACK,
    ],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // cannot attack allied unit
        if (permanent.owner === target.owner) failedChecks.add(Check.ALLIED);
        // target must be a structure
        if (inspector.getCardType(target) !== CardType.STRUCTURE)
          failedChecks.add(Check.GENERIC);
        // target must not be invulnerable
        if (
          inspector.doesPermanentHaveCounterType(
            target,
            CounterType.INVULNERABLE
          )
        )
          failedChecks.add(Check.INVULNERABLE);
      }),
    ],
  },
  "nice-cow": {
    specialCost: [ColorSymbol.ROCK, ColorSymbol.BLACK],
    specialForms: [
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
    ],
  },
  nightshade: {
    specialCost: [],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        if (permanent.owner === target.owner) {
          failedChecks.add(Check.ALLIED);
        }
      }),
    ],
  },
  "oklahoma-tusks": {
    flexCost: [ColorSymbol.RED],
    specialCost: [ColorSymbol.RED],
  },
  othello: {
    deckbuildingLimit: 1,
    isLegendary: true,

    specialCost: [],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.ALLIED);
        // creature
        if (inspector.getCardType(target) !== CardType.CREATURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
        // not self
        if (target.id === permanent.id) failedChecks.add(Check.GENERIC);
      }, "select a friendly Creature"),
      // TODO: slot
      makeSlotOptForm((failedChecks, slot, ctx) => {
        const { inspector, permanent } = ctx;
        // cannot move into occupied slot
        if (inspector.isSlotOccupied(slot)) failedChecks.add(Check.OCCUPIED);
        // friendly
        if (inspector.getTerrainOf(slot) !== permanent.owner) {
          failedChecks.add(Check.ALLIED);
        }
      }, "select a slot to Summon the copy"),
    ],
  },
  "quail-aboard-fiery-steeds": {
    specialCost: [ColorSymbol.RED, ColorSymbol.RED],
  },
  "quantum-butter-churner": {
    adjustAttackChecks: (failedChecks, attacker, defender, ctx) => {
      const { inspector } = ctx;
      // here the attacker must not be protected from the defender (so the
      // attacker is the defender in this isProtectedFrom call)
      if (inspector.isProtectedFrom(attacker, defender))
        failedChecks.add(Check.GENERIC);
    },
  },
  "realistic-rex": {
    specialCost: [ColorSymbol.GREEN],
    specialForms: [
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
    ],
  },
  "reckless-medisaur": {
    specialCost: [],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        // same owner
        if (permanent.owner !== target.owner) {
          failedChecks.add(Check.ALLIED);
        }
        // is base
        if (!inspector.getSharedEffects(target).isBase) {
          failedChecks.add(Check.CARD_TYPE);
        }
      }, "select an allied base"),
      makePermanentOptForm((failedChecks, _, target, ctx) => {
        const { inspector } = ctx;
        // check if creature
        if (inspector.getCardType(target) !== CardType.CREATURE) {
          failedChecks.add(Check.CARD_TYPE);
        }
        // check if target has taken damage
        if (target.damage <= 0) {
          failedChecks.add(Check.GENERIC);
        }
      }, "select a Creature that has taken damage"),
    ],
  },
  robot: {
    specialCost: [],
    // Robot is only used by AI, but provide a form anyway for
    // dev testing.
    specialForms: [{ type: EffectOptType.PERMANENT }],
  },
  "roe-doe-dendron": {
    specialCost: [ColorSymbol.PURPLE],
    specialForms: [
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
    ],
  },
  "rude-cow": {
    specialCost: [ColorSymbol.WHITE],
    specialForms: [
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
    ],
  },
  "sapphire-rose": {
    flexCost: [],
    flexForms: [],
  },
  "slime--slime": {
    disableDeckbuildingLimit: true,
    isLegendary: true,
  },
  "spelling-bee": {
    deckbuildingLimit: 1,
    isLegendary: true,
  },
  "stackstack-tory": {
    isStructure: true,
    isBase: true,
  },
  stacktory: {
    isStructure: true,
    isBase: true,
  },
  "swarm-tactics": {
    specialCost: [ColorSymbol.YELLOW],
  },
  "switch-boar-d": {
    specialCost: [ColorSymbol.RED],
    specialForms: [
      makePermanentOptForm(plainAttackValidator, "select an attack target"),
      makePermanentOptForm((failedChecks, permanent, target) => {
        // target must be friendly
        if (target.owner !== permanent.owner) failedChecks.add(Check.GENERIC);
        // target must be adjacent
        if (!areSlotsAdjacent(target.slot, permanent.slot))
          failedChecks.add(Check.GENERIC);
      }, "select a Creature to swap places with"),
    ],
  },
  "touch-grass": {
    specialCost: [ColorSymbol.PURPLE],
    specialForms: [
      makePermanentOptForm((failedChecks, permanent, target, ctx) => {
        const { inspector } = ctx;
        inspector.validateAttack(failedChecks, permanent, target);

        // The special can only be used on creatures.
        if (inspector.getCardType(target) !== CardType.CREATURE)
          failedChecks.add(Check.GENERIC);
        // Instead of checking if the target is unprotected,
        // check that it is protected.
        failedChecks.delete(Check.PROTECTED);
        if (!inspector.isProtectedFrom(target, permanent))
          failedChecks.add(Check.GENERIC);
      }, "select a protected Creature"),
    ],
  },
  "vanilla-calf": {
    flexCost: [ColorSymbol.BLACK],
  },
};
/* eslint-disable sort-keys/sort-keys-fix */

// --- Programmatic addition of cards for puzzles. ---

const KERO_ENABLE_LAYERS: { [cardName: string]: string[] } = {
  dargle: ["dino-1.png", "!back.png"],
  "miss-yu": ["cow-1.png", "udder.png", "!back-wing.png"],
  coloring: ["dryad-1.png"],
  othello: ["cow-2.png", "udder.png", "!back-wing.png"],
  jabberwock: ["dino-2.png", "!front.png"],
  moonick: ["cow-4.png", "udder.png", "!back-wing.png"],
  "bb-b": ["bee-1.png"],
  "spelling-bee": ["bee-2.png"],
  blancmange: ["cow-3.png", "udder.png", "!back-wing.png"],
  "slime--slime": ["boar-1.png"],
  "mister-penny": ["boar-2.png"],
};

// top layer to bottom layer:
const KERO_LAYERS_ORDER: string[] = [
  "face.png",
  "boar-1.png",
  "boar-2.png",
  "dino-2.png",
  "dryad-1.png",
  "udder.png",
  "front.png",
  "bee-2.png",
  "bee-1.png",
  "dino-1.png",
  "cow-1.png",
  "cow-2.png",
  "back.png",
  "cow-3.png",
  "cow-4.png",
  "back-wing.png",
];

const KERO_PARTS_FINAL_BATTLE = [
  { name: "dinoTail", phase: 1 },
  { name: "dinoHead", phase: 1 },
  { name: "tusk1", phase: 2 },
  { name: "tusk2", phase: 2 },
  { name: "antlers", phase: 3 },
  { name: "blackLeg1", phase: 4 },
  { name: "blackLeg2", phase: 4 },
  { name: "blackLeg3", phase: 4 },
  { name: "blackLeg4", phase: 4 },
  { name: "blackLeg5", phase: 4 },
  { name: "udders", phase: 4 },
  { name: "whiteLeg1", phase: 4 },
  { name: "whiteLeg2", phase: 4 },
  { name: "whiteLeg3", phase: 4 },
  { name: "whiteLeg4", phase: 4 },
  { name: "wing", phase: 5 },
];

// Indicates, for each card's layer effects, which phase after which it should
// be disabled, if any.
const KERO_PHASE_DISABLE: { [cardName: string]: number } = {
  dargle: 1,
  "miss-yu": 4,
  coloring: 3,
  othello: 4,
  jabberwock: 1,
  moonick: 4,
  "bb-b": 5,
  "spelling-bee": 5,
  blancmange: 4,
  "slime--slime": 2,
  "mister-penny": 2,
};

// quick consistency check
for (const [cardName, layers] of Object.entries(KERO_ENABLE_LAYERS)) {
  if (COMMON_CARD_EFFECTS_SHARED[cardName] === undefined)
    throw new Error(
      `unknown card name ${cardName} in kero layers spec: did it get renamed?`
    );
  for (let layer of layers) {
    if (layer.startsWith("!")) {
      layer = layer.slice(1);
    }
    if (!KERO_LAYERS_ORDER.includes(layer))
      throw new Error(`kero layer ${layer} does not have a sort order`);
  }
}

export const getKeroLayers = (
  cardUnlocks: string[],
  keroPhase: number
): string[] => {
  const enabledLayers = new Set([
    "face.png",
    "front.png",
    "back.png",
    "back-wing.png",
  ]);
  for (const cardName of cardUnlocks) {
    if (!(cardName in KERO_ENABLE_LAYERS)) continue;
    for (const layer of KERO_ENABLE_LAYERS[cardName]) {
      if (keroPhase <= KERO_PHASE_DISABLE[cardName]) {
        if (layer.startsWith("!")) {
          enabledLayers.delete(layer.slice(1));
        } else {
          enabledLayers.add(layer);
        }
      }
    }
  }
  return KERO_LAYERS_ORDER.filter((layer) =>
    enabledLayers.has(layer)
  ).reverse();
};

export const getKeroLayersForFinalBattle = (keroPhase: number) => {
  if (keroPhase > 6) {
    return {
      parts: new Set<string>(),
      phase: 7,
    };
  }
  const enabledParts = new Set(["aliveIndicator"]);
  for (const entry of KERO_PARTS_FINAL_BATTLE) {
    const { name, phase } = entry;
    if (keroPhase <= phase) {
      enabledParts.add(name);
    }
  }
  return {
    parts: enabledParts,
    phase: keroPhase,
  };
};
