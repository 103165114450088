// State holding all the cards that the client knows about.
// For now, the client always knows about all cards.

import { create } from "zustand";

import { CardsDB } from "engine/cards/CardsDB";

export interface CardsDBState {
  cardsDB: CardsDB;
  setCardsDB: (cardsDB: CardsDB) => void;
}

export const useCardsDBStore = create<CardsDBState>((set, get) => ({
  cardsDB: {},
  setCardsDB: (cardsDB: CardsDB) =>
    set({
      cardsDB: cardsDB,
    }),
}));
