import { CardData } from "engine/types/card-data";

export type CardsDB = {
  [cardName: string]: CardData;
};

export const makeCardsDB = (allCards: ReadonlyArray<CardData>) => {
  const cardsDB: CardsDB = {};
  for (const cardData of allCards) {
    cardsDB[cardData.name] = cardData;
  }
  return cardsDB;
};
