import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { HTMLProps, PropsWithChildren, ReactNode, useState } from "react";
import Twemoji from "react-twemoji";

interface DropdownProps {
  trigger: ReactNode;
  closeOnClick?: boolean;
  triggerClassName?: string;
}

function Dropdown(
  props: PropsWithChildren<DropdownProps> &
    Omit<HTMLProps<HTMLDivElement>, "style" | "onClick">
) {
  const [open, setOpen] = useState(false);
  const {
    trigger,
    closeOnClick = true,
    triggerClassName = "",
    children,
    className = "",
    ...otherProps
  } = props;

  const { context, refs, floatingStyles } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    hover,
    role,
  ]);

  return (
    <>
      <div
        className={`dropdown-trigger ${triggerClassName}`}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Twemoji>{trigger}</Twemoji>
      </div>
      {open && (
        <FloatingFocusManager context={context} modal={false} initialFocus={-1}>
          <div
            className={`dropdown-content ${className}`}
            ref={refs.setFloating}
            style={floatingStyles}
            onClick={closeOnClick ? () => setOpen(false) : undefined}
            {...getFloatingProps(otherProps)}
          >
            {children}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
}

export default Dropdown;
