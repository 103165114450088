// we inline the CSS here because this file needs to be light
export function Spinner({
  size = 24,
  text = "",
  inline = false,
}: {
  size?: number;
  text?: string;
  inline?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        alignItems: "center",
        justifyContent: "center",
        position: inline ? undefined : "fixed",
        inset: "0",
      }}
    >
      <svg
        width={size}
        height={size}
        stroke="#fff"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
.spinnything { transform-origin: center; animation: outerspinny 2s linear infinite; }

@keyframes outerspinny { 100% { transform: rotate(360deg); } }

.spinnything circle { stroke-linecap: round; animation: innerspinny 1.5s ease-in-out infinite; }

@keyframes innerspinny {
  0% { stroke-dasharray: 0 150; stroke-dashoffset: 0; }
  47.5% { stroke-dasharray: 42 150; stroke-dashoffset: -16; }
  95%, 100% { stroke-dasharray: 42 150; stroke-dashoffset: -59; }
}
          `,
          }}
        />
        <g className="spinnything">
          <circle cx="12" cy="12" r="9.5" fill="none" strokeWidth="3"></circle>
        </g>
      </svg>
      {text}
    </div>
  );
}

export function LargeSpinner({ inline }: { inline?: boolean }) {
  return <Spinner size={128} inline={inline} />;
}
