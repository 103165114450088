import { WSRespType, WSResp } from "game-server/ws";
import {
  speedrunTimeToString,
  TeamNameDisplayDataUpdate,
  GlobalUpdateType,
} from "game-server/global-updates";
import { GameServer } from "game-server/GameServer";

import { Faction } from "engine/types/factions";

import { ClientTeamSpeedrunInfoGlobalState } from "game-server/global-updates";

export const HEALTH_MONITORING_INTERVAL_MS = 6000;
export const CURSOR_EVENTS_MONITORING_INTERVAL_MS = 3000;

export enum DirtyStateType {
  /** The resource is new, so it doesn't need to be synced. */
  NEW = "new",
  /** The resource needs to be synced. */
  DIRTY = "dirty",
  /** The resource is currently being synced. */
  SYNCING = "syncing",
  /** The resource failed to sync. */
  FAILED = "failed",
  /** The resource is synced. */
  SYNCED = "synced",
}

export type DirtyState = {
  type: DirtyStateType;
  lastSyncTime: number;
};

export type ServerDirtyState = {
  teams: {
    [teamId: string]: {
      fastSync?: DirtyState;
      misc?: DirtyState;
      decks?: { [slot: number]: DirtyState };
      masteryTree?: DirtyState;
      rooms?: { [roomId: string]: DirtyState };
      checkpoints?: { [puzName: string]: DirtyState };
      answers?: { [puzName: string]: DirtyState };
    };
  };
};

export type ServerDirtyStateUpdate = {
  teams: {
    [teamId: string]: {
      fastSync?: DirtyState;
      misc?: DirtyState;
      decks?: { [slot: number]: DirtyState };
      masteryTree?: DirtyState;
      rooms?: { [roomId: string]: DirtyState };
      checkpoints?: { [puzName: string]: DirtyState };
      answers?: { [puzName: string]: DirtyState };
    };
  };
};

export const mergeServerDirtyStateUpdate = (
  upd1: ServerDirtyStateUpdate,
  upd2: ServerDirtyStateUpdate
) => {
  for (const [teamId, teamUpds2] of Object.entries(upd2.teams)) {
    const teamUpds1 = upd1.teams[teamId];
    if (teamUpds1 === undefined) {
      upd1.teams[teamId] = teamUpds2;
      continue;
    }
    if (teamUpds2.fastSync !== undefined)
      teamUpds1.fastSync = teamUpds2.fastSync;
    if (teamUpds2.misc !== undefined) teamUpds1.misc = teamUpds2.misc;
    for (const [slot, deckUpd] of Object.entries(teamUpds2.decks ?? {})) {
      teamUpds1.decks ??= {};
      teamUpds1.decks[Number(slot)] = deckUpd;
    }
    if (teamUpds2.masteryTree !== undefined)
      teamUpds1.masteryTree = teamUpds2.masteryTree;
    for (const [roomId, roomUpd] of Object.entries(teamUpds2.rooms ?? {})) {
      teamUpds1.rooms ??= {};
      teamUpds1.rooms[roomId] = roomUpd;
    }
    for (const [puzName, checkpoints] of Object.entries(
      teamUpds2.checkpoints ?? {}
    )) {
      teamUpds1.checkpoints ??= {};
      teamUpds1.checkpoints[puzName] = checkpoints;
    }
    for (const [puzName, answerUpd] of Object.entries(
      teamUpds2.answers ?? {}
    )) {
      teamUpds1.answers ??= {};
      teamUpds1.answers[puzName] = answerUpd;
    }
  }
};

export const updateServerDirtyState = (
  state: ServerDirtyState,
  upd: ServerDirtyStateUpdate
) => {
  // Currently, the state and update have the same type signature,
  // so we can just reuse the merge function.
  mergeServerDirtyStateUpdate(state, upd);
};

export class ServerDirtyStateTracker {
  dirtyState: ServerDirtyState;
  /**
   * Clients only receive dirty state updates in batches every periodic
   * sync. The pending dirty state updates are buffered here, and are
   * only merged into the dirtyState object when they are broadcast
   * to clients.
   */
  pendingDirtyStateUpd: ServerDirtyStateUpdate;

  constructor() {
    this.dirtyState = { teams: {} };
    this.pendingDirtyStateUpd = { teams: {} };
  }

  update(upd: ServerDirtyStateUpdate) {
    mergeServerDirtyStateUpdate(this.pendingDirtyStateUpd, upd);
  }

  commitUpds() {
    updateServerDirtyState(this.dirtyState, this.pendingDirtyStateUpd);
    this.pendingDirtyStateUpd = { teams: {} };
  }
}

export type IntervalMetricsSummary = {
  /** Just pass the interval length in to make reporting simpler. */
  intervalLength: number;
  totNumEvents: number;
  lastEventTime: number;
  numPerInterval: number;
};

export const getIntervalMetricsSummaryString = (
  metrics: IntervalMetricsSummary
): string => {
  const lastEventTimeStr =
    metrics.lastEventTime === 0
      ? "N/A"
      : `${speedrunTimeToString(Date.now() - metrics.lastEventTime, true)} ago`;
  return `${metrics.numPerInterval} / ${metrics.intervalLength / 1000}s (tot: ${
    metrics.totNumEvents
  }) (last: ${lastEventTimeStr})`;
};

export class IntervalMetrics {
  /** Length of the collection interval, in ms. */
  intervalLength: number;

  /** Total number of events recorded. */
  totNumEvents: number;
  /** Timestamp of the last event. */
  lastEventTime: number;
  /** Number of events in the previous interval. */
  prevNumEvents: number;
  /** Number of events in the current interval. */
  currNumEvents: number;

  constructor(intervalLength: number) {
    this.intervalLength = intervalLength;
    this.totNumEvents = 0;
    this.lastEventTime = 0;
    this.prevNumEvents = 0;
    this.currNumEvents = 0;
  }

  record(): void {
    this.update(true);
  }

  update(doRecord?: boolean): void {
    const currTime = new Date().getTime();
    const prevIntervalNum = Math.floor(
      this.lastEventTime / this.intervalLength
    );
    const currIntervalNum = Math.floor(currTime / this.intervalLength);
    if (prevIntervalNum !== currIntervalNum) {
      this.prevNumEvents =
        prevIntervalNum + 1 === currIntervalNum ? this.currNumEvents : 0;
      this.currNumEvents = 0;
    }
    if (doRecord ?? false) {
      this.lastEventTime = currTime;
      this.currNumEvents++;
      this.totNumEvents++;
    }
  }

  getSummary(): IntervalMetricsSummary {
    this.update();
    return {
      intervalLength: this.intervalLength,
      totNumEvents: this.totNumEvents,
      lastEventTime: this.lastEventTime,
      numPerInterval: this.prevNumEvents,
    };
  }

  getSummaryString(): string {
    return getIntervalMetricsSummaryString(this.getSummary());
  }
}

export class TeamMetricsTracker {
  server: GameServer;
  numAuthsSummaries: { [teamId: string]: IntervalMetricsSummary };
  numRequestsSummaries: { [teamId: string]: IntervalMetricsSummary };
  numAuthsUpds: Set<string>;
  numRequestsUpds: Set<string>;

  constructor(server: GameServer) {
    this.server = server;
    this.numAuthsSummaries = {};
    this.numRequestsSummaries = {};
    this.numAuthsUpds = new Set();
    this.numRequestsUpds = new Set();
  }

  recordAuth(teamId: string) {
    this.numAuthsUpds.add(teamId);
  }

  recordRequest(teamId: string) {
    this.numRequestsUpds.add(teamId);
  }

  getAndCommitUpds(): {
    teamNumAuthsMetrics: { [teamId: string]: IntervalMetricsSummary };
    teamNumRequestsMetrics: { [teamId: string]: IntervalMetricsSummary };
  } {
    const upds = {
      teamNumAuthsMetrics: Object.fromEntries(
        Array.from(this.numAuthsUpds, (teamId) => [
          teamId,
          this.server.getTeamController(teamId).numAuthsMetrics.getSummary(),
        ])
      ),
      teamNumRequestsMetrics: Object.fromEntries(
        Array.from(this.numRequestsUpds, (teamId) => [
          teamId,
          this.server.getTeamController(teamId).numRequestsMetrics.getSummary(),
        ])
      ),
    };
    Object.assign(this.numAuthsSummaries, upds.teamNumAuthsMetrics);
    Object.assign(this.numRequestsSummaries, upds.teamNumRequestsMetrics);
    this.numAuthsUpds.clear();
    this.numRequestsUpds.clear();
    return upds;
  }
}

export type BigBoardTeamPuzzleState = {
  solveTime?: number;
  numHintsUsed?: number;
  numWrongGuesses?: number;
  numMembers?: number;
};

export type BigBoardTeamState = {
  numHintsTotal: number;
  unlocks: {
    [puzName: string]: BigBoardTeamPuzzleState;
  };
  cardUnlocks: { [cardName: string]: true };
  selectedPuzzles: { [puzName: string]: string };
  enabledMasteries: { [masteryId: string]: true };
};

export type BigBoardTeamUpdate = {
  numHintsTotal?: number;
  unlocks?: {
    [puzName: string]: {
      solveTime?: number;
      numHintsUsed?: number;
      numWrongGuesses?: number;
      numMembers?: number;
    } | null;
  };
  cardUnlocks?: { [cardName: string]: boolean };
  selectedPuzzles?: { [puzName: string]: string | null };
  enabledMasteries?: { [masteryId: string]: boolean };
};

export const mergeBigBoardTeamUpdate = (
  upd1: BigBoardTeamUpdate,
  upd2: BigBoardTeamUpdate
) => {
  if (upd2.numHintsTotal !== undefined) upd1.numHintsTotal = upd2.numHintsTotal;
  for (const [puzName, puzUpds] of Object.entries(upd2.unlocks ?? {})) {
    upd1.unlocks ??= {};
    if (puzUpds === null) {
      upd1.unlocks[puzName] = null;
      return;
    }
    upd1.unlocks[puzName] = Object.assign(upd1.unlocks[puzName] ?? {}, puzUpds);
  }
  for (const [cardName, isUnlocked] of Object.entries(upd2.cardUnlocks ?? {})) {
    upd1.cardUnlocks ??= {};
    upd1.cardUnlocks[cardName] = isUnlocked;
  }
  for (const [puzName, selectedPuzName] of Object.entries(
    upd2.selectedPuzzles ?? {}
  )) {
    upd1.selectedPuzzles ??= {};
    upd1.selectedPuzzles[puzName] = selectedPuzName;
  }
  for (const [masteryId, isEnabled] of Object.entries(
    upd2.enabledMasteries ?? {}
  )) {
    upd1.enabledMasteries ??= {};
    upd1.enabledMasteries[masteryId] = isEnabled;
  }
};

export const updateBigBoardTeamState = (
  state: BigBoardTeamState,
  upd: BigBoardTeamUpdate
) => {
  const {
    numHintsTotal,
    unlocks,
    cardUnlocks,
    selectedPuzzles,
    enabledMasteries,
  } = upd;
  if (numHintsTotal !== undefined) state.numHintsTotal = numHintsTotal;
  for (const [puzName, puzUpds] of Object.entries(unlocks ?? {})) {
    state.unlocks[puzName] = Object.assign(
      state.unlocks[puzName] ?? {},
      puzUpds
    );
  }
  for (const [cardName, isUnlocked] of Object.entries(cardUnlocks ?? {})) {
    if (isUnlocked) state.cardUnlocks[cardName] = true;
    else delete state.cardUnlocks[cardName];
  }
  for (const [puzName, selectedPuzName] of Object.entries(
    selectedPuzzles ?? {}
  )) {
    if (selectedPuzName === null) delete state.selectedPuzzles[puzName];
    else state.selectedPuzzles[puzName] = selectedPuzName;
  }
  for (const [masteryId, isEnabled] of Object.entries(enabledMasteries ?? {})) {
    if (isEnabled) state.enabledMasteries[masteryId] = true;
    else delete state.enabledMasteries[masteryId];
  }
};

export class BigBoardStateTracker {
  state: { [teamId: string]: BigBoardTeamState };
  /**
   * Clients only receive dirty big board updates in batches every periodic
   * sync. The pending updates are buffered here, and are only merged into
   * the big board state object when they are broadcast to clients.
   */
  pendingUpds: { [teamId: string]: BigBoardTeamUpdate };

  constructor() {
    this.state = {};
    this.pendingUpds = {};
  }

  addTeam(teamId: string, teamState: BigBoardTeamState) {
    this.state[teamId] = teamState;
  }

  updateTeam(teamId: string, upd: BigBoardTeamUpdate) {
    // If we don't know about the team yet, then the team might
    // still be in the process of being initialized, so drop
    // any updates.
    if (this.state[teamId] === undefined) return;

    const existingUpd = this.pendingUpds[teamId];
    if (existingUpd === undefined) {
      this.pendingUpds[teamId] = upd;
      return;
    }
    mergeBigBoardTeamUpdate(existingUpd, upd);

    this.removeRedundantTeamUpdates(teamId);
  }

  private removeRedundantTeamUpdates(teamId: string) {
    const teamUpds = this.pendingUpds[teamId];
    const teamState = this.state[teamId];
    if (teamUpds === undefined) return;
    if (teamState === undefined)
      throw new Error("expect to only receive updates for existing teams");
    const {
      numHintsTotal,
      unlocks,
      cardUnlocks,
      selectedPuzzles,
      enabledMasteries,
    } = teamUpds;

    if (
      numHintsTotal !== undefined &&
      numHintsTotal === teamState.numHintsTotal
    )
      delete teamUpds.numHintsTotal;

    if (unlocks !== undefined) {
      for (const [puzName, puzUpds] of Object.entries(unlocks)) {
        if (puzUpds === null) {
          if (teamState.unlocks[puzName] === undefined) delete unlocks[puzName];
          continue;
        }

        if (teamState.unlocks[puzName] === undefined) continue;
        const puzState = teamState.unlocks[puzName];
        const { solveTime, numHintsUsed, numWrongGuesses, numMembers } =
          puzUpds;
        if (
          solveTime !== undefined &&
          puzState.solveTime !== undefined &&
          solveTime === puzState.solveTime
        )
          delete puzUpds.solveTime;
        if (
          numHintsUsed !== undefined &&
          numHintsUsed === (puzState.numHintsUsed ?? 0)
        )
          delete puzUpds.numHintsUsed;
        if (
          numWrongGuesses !== undefined &&
          numWrongGuesses === (puzState.numWrongGuesses ?? 0)
        )
          delete puzUpds.numWrongGuesses;
        if (
          numMembers !== undefined &&
          numMembers === (puzState.numMembers ?? 0)
        )
          delete puzUpds.numMembers;
        if (Object.keys(puzUpds).length === 0) delete unlocks[puzName];
      }
      if (Object.keys(unlocks).length === 0) delete teamUpds.unlocks;
    }

    if (cardUnlocks !== undefined) {
      for (const [cardName, isUnlocked] of Object.entries(cardUnlocks)) {
        if (isUnlocked === (teamState.cardUnlocks[cardName] ?? false))
          delete cardUnlocks[cardName];
      }
      if (Object.keys(cardUnlocks).length === 0) delete teamUpds.cardUnlocks;
    }

    if (selectedPuzzles !== undefined) {
      for (const [puzName, selectedPuzName] of Object.entries(
        selectedPuzzles
      )) {
        if (selectedPuzName === teamState.selectedPuzzles[puzName])
          delete selectedPuzzles[puzName];
      }
      if (Object.keys(selectedPuzzles).length === 0)
        delete teamUpds.selectedPuzzles;
    }

    if (enabledMasteries !== undefined) {
      for (const [masteryId, isEnabled] of Object.entries(enabledMasteries)) {
        if (isEnabled === (teamState.enabledMasteries[masteryId] ?? false))
          delete enabledMasteries[masteryId];
      }
      if (Object.keys(enabledMasteries).length === 0)
        delete teamUpds.enabledMasteries;
    }

    if (Object.keys(teamUpds).length === 0) delete this.pendingUpds[teamId];
  }

  commitUpds() {
    for (const [teamId, upd] of Object.entries(this.pendingUpds)) {
      const teamState = this.state[teamId];
      if (teamState === undefined)
        throw new Error("expect to only receive updates for existing teams");
      updateBigBoardTeamState(this.state[teamId], upd);
    }
    this.pendingUpds = {};
  }
}

export type TeamSummaryState = {
  teamId: string;
  displayName: string;
  faction: Faction | null;
  factionScoreContribution: number;
  hasReputationBoost?: boolean;
  hasPvP?: boolean;
  isPvPGameActive?: boolean;
  numSolves: number;
  completionTime?: number;
  lastSolveTime?: number;
  speedrunInfo: ClientTeamSpeedrunInfoGlobalState;
  isHidden?: boolean;
};

export type TeamSummaryUpdate = {
  displayName?: string;
  faction?: Faction | null;
  factionScoreContribution?: number;
  hasReputationBoost?: boolean;
  hasPvP?: boolean;
  isPvPGameActive?: boolean;
  numSolves?: number;
  completionTime?: number;
  lastSolveTime?: number;
  speedrunInfo?: ClientTeamSpeedrunInfoGlobalState;
  isHidden?: boolean;
};

export const teamSummaryUpdateToTeamNameDisplayUpdate = (
  upd: TeamSummaryUpdate
): TeamNameDisplayDataUpdate => {
  const { displayName, faction, factionScoreContribution, hasReputationBoost } =
    upd;
  return {
    displayName,
    faction,
    factionScoreContribution,
    hasReputationBoost,
  };
};

export const mergeTeamSummaryUpdate = (
  upd1: TeamSummaryUpdate,
  upd2: TeamSummaryUpdate
) => {
  Object.assign(upd1, upd2);
};

export const updateTeamSummary = (
  state: TeamSummaryState,
  upd: TeamSummaryUpdate
) => {
  Object.assign(state, upd);
};

export class TeamListStateTracker {
  state: { [teamId: string]: TeamSummaryState };
  cachedSerializedOverrideGlobalUpdate?: string;
  /**
   * Clients only receive dirty team list updates in batches every periodic
   * sync. The pending updates are buffered here, and are only merged into
   * the state object when they are broadcast to clients.
   */
  pendingUpds: { [teamId: string]: TeamSummaryUpdate };

  constructor() {
    this.state = {};
    this.pendingUpds = {};
  }

  invalidateCache() {
    delete this.cachedSerializedOverrideGlobalUpdate;
  }

  getSerializedOverrideGlobalUpdate(): string {
    if (this.cachedSerializedOverrideGlobalUpdate !== undefined)
      return this.cachedSerializedOverrideGlobalUpdate;
    // Typecheck the global update.
    const upd: WSResp = {
      type: WSRespType.GLOBAL_UPDATE,
      upd: {
        type: GlobalUpdateType.TEAM_LIST_OVERRIDE_STATE,
        teams: this.state,
      },
    };
    this.cachedSerializedOverrideGlobalUpdate = JSON.stringify(upd);
    return this.cachedSerializedOverrideGlobalUpdate;
  }

  addTeam(teamId: string, teamState: TeamSummaryState) {
    this.state[teamId] = teamState;
    this.invalidateCache();
  }

  removeTeam(teamId: string) {
    delete this.state[teamId];
    delete this.pendingUpds[teamId];
    this.invalidateCache();
  }

  updateTeam(teamId: string, upd: TeamSummaryUpdate) {
    // If we don't know about the team yet, then the team might
    // still be in the process of being initialized, so drop
    // any updates.
    if (this.state[teamId] === undefined) return;

    const existingUpd = this.pendingUpds[teamId];
    if (existingUpd === undefined) {
      this.pendingUpds[teamId] = upd;
      return;
    }
    mergeTeamSummaryUpdate(existingUpd, upd);

    this.removeRedundantTeamUpdates(teamId);
  }

  private removeRedundantTeamUpdates(teamId: string) {
    const teamUpds = this.pendingUpds[teamId];
    const teamState = this.state[teamId];
    if (teamUpds === undefined) return;
    if (teamState === undefined)
      throw new Error("expect to only receive updates for existing teams");

    // This doesn't need to be exhaustive, so no need to worry about
    // speedrunInfo, particularly since it is only updated when there
    // are actual changes.
    const {
      displayName,
      faction,
      factionScoreContribution,
      hasReputationBoost,
      hasPvP,
      isPvPGameActive,
      numSolves,
      completionTime,
      lastSolveTime,
    } = teamUpds;
    if (displayName === teamState.displayName) delete teamUpds.displayName;
    if (faction === teamState.faction) delete teamUpds.faction;
    if (factionScoreContribution === teamState.factionScoreContribution)
      delete teamUpds.factionScoreContribution;
    if (hasReputationBoost === teamState.hasReputationBoost)
      delete teamUpds.hasReputationBoost;
    if (hasPvP === teamState.hasPvP) delete teamUpds.hasPvP;
    if (isPvPGameActive === teamState.isPvPGameActive)
      delete teamUpds.isPvPGameActive;
    if (numSolves === teamState.numSolves) delete teamUpds.numSolves;
    if (completionTime === teamState.completionTime)
      delete teamUpds.completionTime;
    if (lastSolveTime === teamState.lastSolveTime)
      delete teamUpds.lastSolveTime;
    if (Object.keys(teamUpds).length === 0) delete this.pendingUpds[teamId];
  }

  commitUpds() {
    for (const [teamId, upd] of Object.entries(this.pendingUpds)) {
      const teamState = this.state[teamId];
      if (teamState === undefined)
        throw new Error("expect to only receive updates for existing teams");
      updateTeamSummary(this.state[teamId], upd);
    }
    this.pendingUpds = {};
    this.invalidateCache();
  }
}
