import { Player } from "engine/types/game-state";
import { Check } from "engine/types/action-validation";
import { SharedGameSpec } from "engine/types/shared-game-specs";

export const SharedTutorial2Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  numColumns: 1,
  numRows: 6,
  deckOverrides: {
    [Player.P1]: [],
    [Player.P2]: [],
  },
  basesOverrides: {
    [Player.P1]: ["camp"],
    [Player.P2]: ["camp"],
  },
  adjustEndTurnChecks: (failedChecks, player, ctx) => {
    const { inspector } = ctx;
    if (player !== Player.P1) return;
    const { keyframe } = inspector.gameState;
    if (
      inspector.getTurnNumber() <= 3
        ? keyframe === undefined ||
          !["tutorial2-end-turn-1", "tutorial2-end-turn-2"].includes(
            keyframe.id
          )
        : keyframe !== undefined &&
          !["tutorial2-free-play"].includes(keyframe.id)
    )
      failedChecks.add(Check.HIDDEN);
  },
};
