import { z } from "zod";

import { MasteryData, MasteriesDB } from "engine/puzzles/mastery-data";

// Note: All answers here should be capitalized with no spaces.

export enum AnswerOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}
const AnswerOrientationZod = z.nativeEnum(AnswerOrientation);

const AnswerPlacementZod = z.object({
  answer: z.string(),
  x: z.number(),
  y: z.number(),
  orientation: AnswerOrientationZod,
});
export type AnswerPlacement = z.infer<typeof AnswerPlacementZod>;

export type MasteryTree = {
  /**
   * Answers placed in the grid, keyed by their answer.
   */
  placements: { [answer: string]: AnswerPlacement };
  /** Answers that are connected to the start. */
  connectedAnswers: string[];
  /**
   * Dev backdoor to override the resulting masteries list.
   * TODO: Ensure this does not work in prod.
   */
  masteriesListOverride?: string[];
};

export const cloneMasteryTree = (masteryTree: MasteryTree): MasteryTree => {
  return {
    ...masteryTree,
    placements: { ...masteryTree.placements },
    connectedAnswers: [...masteryTree.connectedAnswers],
  };
};

export type MasteryTreeUpdate = {
  /**
   * If true, resets the mastery tree to a clean state. This is done
   * before applying any of the other updates.
   */
  reset?: boolean;
  /** Placements to pop (give the string of the answer(s)). */
  popAnswers?: string[];
  /** Placements to append. This is done after the pops. */
  newPlacements?: AnswerPlacement[];
  /** Override the masteries list override directly. */
  masteriesListOverride?: string[];
};

export enum MasteryTreeStepType {
  PLACE = "place",
  RESET = "reset",
  DELETE = "delete",
}

const PlaceMasteryTreeStepZod = z
  .object({
    type: z.literal(MasteryTreeStepType.PLACE),
    placement: AnswerPlacementZod,
  })
  .readonly();
export type PlaceMasteryTreeStep = z.infer<typeof PlaceMasteryTreeStepZod>;

const ResetMasteryTreeStepZod = z
  .object({
    type: z.literal(MasteryTreeStepType.RESET),
  })
  .readonly();
export type ResetMasteryTreeStep = z.infer<typeof ResetMasteryTreeStepZod>;

const DeleteMasteryTreeStepZod = z
  .object({
    type: z.literal(MasteryTreeStepType.DELETE),
    answer: z.string(),
  })
  .readonly();
export type DeleteMasteryTreeStep = z.infer<typeof DeleteMasteryTreeStepZod>;

export const MasteryTreeStepZod = z.union([
  PlaceMasteryTreeStepZod,
  ResetMasteryTreeStepZod,
  DeleteMasteryTreeStepZod,
]);
export type MasteryTreeStep = z.infer<typeof MasteryTreeStepZod>;

export const normalizeAnswer = (answer: string) => answer.replaceAll(" ", "");

export const applyMasteryTreeUpdate = (
  masteryTree: MasteryTree,
  upd: MasteryTreeUpdate
): void => {
  const { reset, popAnswers, newPlacements, masteriesListOverride } = upd;
  if (reset ?? false) {
    delete masteryTree.masteriesListOverride;
    masteryTree.placements = {};
  }
  if (masteriesListOverride === undefined)
    delete masteryTree.masteriesListOverride;
  else masteryTree.masteriesListOverride = masteriesListOverride;

  for (const answer of popAnswers ?? []) {
    if (masteryTree.placements[answer] !== undefined) {
      delete masteryTree.placements[answer];
    }
  }
  Object.assign(
    masteryTree.placements,
    Object.fromEntries(
      (newPlacements ?? []).map((placement) => [placement.answer, placement])
    )
  );

  masteryTree.connectedAnswers = getConnectedPlacements(masteryTree).map(
    (placement) => placement.answer
  );
};

export const MASTERY_GRID_WIDTH = 24;
export const MASTERY_GRID_HEIGHT = 25;
export const START_X = 16;
export const START_Y = 6;

type MasteryGridRow = (string | null)[];
export type MasteryGrid = MasteryGridRow[];

const makeEmptyMasteryGrid = (): MasteryGrid => {
  const grid: MasteryGrid = [];
  for (let i = 0; i < MASTERY_GRID_HEIGHT; i++) {
    const row: MasteryGridRow = [];
    for (let j = 0; j < MASTERY_GRID_WIDTH; j++) {
      row.push(null);
    }
    grid.push(row);
  }
  return grid;
};

const isPointInGrid = (x: number, y: number): boolean => {
  return x >= 0 && x < MASTERY_GRID_WIDTH && y >= 0 && y < MASTERY_GRID_HEIGHT;
};

const charAtPointOrNull = (
  grid: MasteryGrid,
  x: number,
  y: number
): string | null => {
  if (!isPointInGrid(x, y)) return null;
  return grid[y][x];
};

export const getPlacementCharIndexAt = (
  placement: AnswerPlacement,
  x: number,
  y: number
): number | null => {
  const { orientation, answer } = placement;
  const index = (() => {
    switch (orientation) {
      case AnswerOrientation.HORIZONTAL: {
        if (y !== placement.y) return null;
        return x - placement.x;
      }
      case AnswerOrientation.VERTICAL: {
        if (x !== placement.x) return null;
        return y - placement.y;
      }
    }
  })();
  if (index === null) return null;
  if (index < 0 || index >= answer.length) return null;
  return index;
};

export const getPlacementCharAt = (
  placement: AnswerPlacement,
  x: number,
  y: number
): string | null => {
  const { answer, orientation } = placement;
  const index = getPlacementCharIndexAt(placement, x, y);
  if (index === null) return null;
  return answer[index];
};

/** Check if the addition of a new placement char to grid is valid. */
export const isPlacementCharValid = (
  grid: MasteryGrid,
  placement: AnswerPlacement,
  charIndex: number
): boolean => {
  const { answer, orientation } = placement;

  const x =
    placement.x +
    (orientation === AnswerOrientation.HORIZONTAL ? charIndex : 0);
  const y =
    placement.y + (orientation === AnswerOrientation.VERTICAL ? charIndex : 0);
  const char = answer[charIndex];

  if (!isPointInGrid(x, y)) return false;

  if (grid[y][x] !== null) {
    // If we cross another answer, it must be on the same character.
    if (grid[y][x] !== char) return false;
  } else {
    // If a character doesn't cross another answer, then there cannot be
    // another answer directly beside it.
    for (const offset of [-1, 1]) {
      const ox = x + (orientation === AnswerOrientation.VERTICAL ? offset : 0);
      const oy =
        y + (orientation === AnswerOrientation.HORIZONTAL ? offset : 0);
      if (charAtPointOrNull(grid, ox, oy) !== null) return false;
    }
  }

  // Answer cannot "extend" another answer.
  if (charIndex === 0) {
    const ox = x + (orientation === AnswerOrientation.HORIZONTAL ? -1 : 0);
    const oy = y + (orientation === AnswerOrientation.VERTICAL ? -1 : 0);
    if (charAtPointOrNull(grid, ox, oy) !== null) return false;
  }
  if (charIndex === answer.length - 1) {
    const ox = x + (orientation === AnswerOrientation.HORIZONTAL ? 1 : 0);
    const oy = y + (orientation === AnswerOrientation.VERTICAL ? 1 : 0);
    if (charAtPointOrNull(grid, ox, oy) !== null) return false;
  }

  return true;
};

const tryPlaceAnswerOnMasteryGrid = (
  grid: MasteryGrid,
  placement: AnswerPlacement
): boolean => {
  const { answer, orientation } = placement;
  for (const [i, c] of [...answer].entries()) {
    if (!isPlacementCharValid(grid, placement, i)) return false;

    const x =
      placement.x + (orientation === AnswerOrientation.HORIZONTAL ? i : 0);
    const y =
      placement.y + (orientation === AnswerOrientation.VERTICAL ? i : 0);

    grid[y][x] = c;
  }
  return true;
};

function intersectsStart(placement: AnswerPlacement): boolean {
  const { x, y, answer, orientation } = placement;
  if (orientation === AnswerOrientation.HORIZONTAL) {
    return y === START_Y && x <= START_X && START_X < x + answer.length;
  } else {
    return x === START_X && y <= START_Y && START_Y < y + answer.length;
  }
}

// Assumes the two placements have passed other validation
function placementsIntersect(
  p1: AnswerPlacement,
  p2: AnswerPlacement
): boolean {
  // require one to be horizontal and one to be vertical
  if (p1.orientation === p2.orientation) {
    return false;
  }
  // make p1 horizontal and p2 vertical, swapping if necessary
  if (p1.orientation === AnswerOrientation.VERTICAL) {
    [p1, p2] = [p2, p1];
  }

  const { x: x1, y: y1, answer: answer1 } = p1;
  const { x: x2, y: y2, answer: answer2 } = p2;
  return (
    y2 <= y1 && y1 < y2 + answer2.length && x1 <= x2 && x2 < x1 + answer1.length
  );
}

/** Get the placements connected to the start. */
export function getConnectedPlacements(
  masteryTree: MasteryTree
): AnswerPlacement[] {
  let first = true;
  const placements = masteryTree.placements;
  const used: { [answer: string]: boolean } = {};
  let lastLayer: AnswerPlacement[] = [];
  const connectedPlacements: AnswerPlacement[] = [];

  while (first || lastLayer.length > 0) {
    const nextLayer: AnswerPlacement[] = [];
    if (first) {
      for (const answer of Object.keys(placements)) {
        if (used[answer]) {
          continue;
        }
        if (intersectsStart(placements[answer])) {
          nextLayer.push(placements[answer]);
          used[answer] = true;
        }
      }
      first = false;
    } else {
      for (const answer of Object.keys(placements)) {
        if (used[answer]) {
          continue;
        }
        for (const lastLayerPlacement of lastLayer) {
          if (placementsIntersect(lastLayerPlacement, placements[answer])) {
            nextLayer.push(placements[answer]);
            used[answer] = true;
            break;
          }
        }
      }
    }
    connectedPlacements.push(...nextLayer);
    lastLayer = nextLayer;
  }

  return connectedPlacements;
}
/** Get the masteries enabled by a mastery tree, sorted. */
export const getMasteriesFromMasteryTree = (
  masteriesDB: MasteriesDB,
  masteryTree: MasteryTree
): MasteryData[] => {
  const allConnectedPlacements = getConnectedPlacements(masteryTree);

  return (() => {
    if (masteryTree.masteriesListOverride !== undefined)
      return masteryTree.masteriesListOverride.map(
        (masteryId) => masteriesDB[masteryId]
      );
    return Object.values(masteriesDB).filter((mastery) => {
      return allConnectedPlacements.some(
        (placement) =>
          getPlacementCharAt(placement, mastery.x, mastery.y) !== null
      );
    });
  })().sort((mastery1, mastery2) => mastery1.order - mastery2.order);
};

export const makeMasteryTreeGrid = (masteryTree: MasteryTree) => {
  const grid = makeEmptyMasteryGrid();
  for (const placement of Object.values(masteryTree.placements)) {
    if (!tryPlaceAnswerOnMasteryGrid(grid, placement)) return null;
  }
  return grid;
};

export const isMasteryTreeStepValid = (
  masteryTree: MasteryTree,
  availableAnswers: string[],
  step: MasteryTreeStep
) => {
  switch (step.type) {
    case MasteryTreeStepType.PLACE: {
      const { placement } = step;
      const { answer } = placement;

      if (!availableAnswers.includes(answer)) {
        return false;
      }
      if (masteryTree.placements[answer]) {
        return false;
      }

      const grid = makeMasteryTreeGrid(masteryTree);
      if (grid === null || !tryPlaceAnswerOnMasteryGrid(grid, placement)) {
        return false;
      }
      break;
    }
    case MasteryTreeStepType.RESET: {
      break;
    }
    case MasteryTreeStepType.DELETE: {
      const { answer } = step;
      if (!masteryTree.placements[answer]) {
        return false;
      }
      break;
    }
  }
  return true;
};

export const masteryTreeStepToUpdate = (
  step: MasteryTreeStep
): MasteryTreeUpdate => {
  switch (step.type) {
    case MasteryTreeStepType.PLACE: {
      const { placement } = step;
      return {
        newPlacements: [placement],
      };
    }
    case MasteryTreeStepType.DELETE: {
      const { answer } = step;
      return {
        popAnswers: [answer],
      };
    }
    case MasteryTreeStepType.RESET: {
      return {
        reset: true,
      };
    }
  }

  throw new Error("unknown mastery tree step");
};
