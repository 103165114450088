import {
  ServerInterface,
  ServerInterfaceCallbacks,
} from "game-server/server-interface/ServerInterface";
import { RawWSInterface } from "game-server/server-interface/RawWSInterface";

export type InteractiveServerInterfaceOpts = {
  rawWsInterface: RawWSInterface;
};

class ServerConnection {
  opts: InteractiveServerInterfaceOpts;
  active: boolean;
  rawSend: (msg: string) => void;
  rawIsOpen: () => boolean;
  rawClose: () => void;

  constructor(
    opts: InteractiveServerInterfaceOpts,
    onOpen: () => void,
    onResp: (msg: string) => void,
    onNeedReconnect: () => void
  ) {
    this.opts = opts;
    this.active = true;

    const { send, isOpen, close } = this.opts.rawWsInterface.makeConnection(
      // onOpen
      () => {
        if (!this.active) {
          return;
        }
        onOpen();
      },
      // onClose
      () => {
        if (!this.active) {
          return;
        }
        onNeedReconnect();
      },
      // onRecv
      (msg) => {
        if (!this.active) {
          return;
        }
        onResp(msg);
      },
      // onErr
      (err) => {
        console.error(err);
      }
    );
    this.rawIsOpen = isOpen;
    this.rawSend = send;
    this.rawClose = close;
  }

  send(msg: string) {
    this.rawSend(msg);
  }

  close() {
    this.active = false;
    this.rawClose();
  }
}

/**
 * Delay in seconds to wait before trying again if the first (immediate)
 * reconnect fails.
 */
const RECONNECT_DELAY_BASE = 5;
/**
 * Amount to increment the reconnect delay each time reconnect fails.
 */
const RECONNECT_DELAY_INC = 1;
/**
 * Reconnection will stop after this number of failed attempts.
 */
const RECONNECT_MAX_TRIES = 10;

export class InteractiveServerInterface implements ServerInterface {
  opts: InteractiveServerInterfaceOpts;
  conn?: ServerConnection;
  callbacks?: ServerInterfaceCallbacks;
  reconnectIter: number;

  constructor(opts: InteractiveServerInterfaceOpts) {
    this.opts = opts;
    this.reconnectIter = 0;
  }

  init(callbacks: ServerInterfaceCallbacks) {
    if (this.conn !== undefined) {
      throw new Error("should not init twice");
    }
    this.callbacks = callbacks;
    this.runConnectionLoop();
  }

  private runConnectionLoop() {
    if (this.callbacks === undefined)
      throw new Error("callbacks not initialized");
    const { onOpen, onResp, afterDisconnect } = this.callbacks;
    this.conn = new ServerConnection(
      this.opts,
      () => {
        // Reset reconnect counter.
        this.reconnectIter = 0;
        onOpen();
      },
      onResp,
      () => {
        let delayInSeconds = 0;
        if (this.reconnectIter >= RECONNECT_MAX_TRIES) {
          delayInSeconds = -1;
        } else {
          if (this.reconnectIter >= 1)
            delayInSeconds =
              RECONNECT_DELAY_BASE +
              (this.reconnectIter - 1) * RECONNECT_DELAY_INC;
          setTimeout(() => this.runConnectionLoop(), delayInSeconds * 1000);
        }
        afterDisconnect({
          numReconnections: this.reconnectIter,
          reconnectDelayInSeconds: delayInSeconds,
        });
        this.reconnectIter += 1;
      }
    );
  }

  send(msg: string) {
    if (this.conn === undefined) {
      throw new Error("conn not initialized");
    }
    this.conn.send(msg);
  }

  reconnect(): void {
    if (this.conn === undefined) return;
    this.conn.rawClose();
  }

  close(): void {
    if (this.conn === undefined) return;
    this.conn.close();
  }
}
