import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAnimationSpeed } from "stores/AnimationStore";
import "./Delta.css";

interface DeltaProps {
  value: number;
  showDelta?: boolean;
}

/** Number that animates when it changes. */
function Delta(props: DeltaProps) {
  const { value, showDelta = false } = props;
  const [prevValue, setPrevValue] = useState(value);
  const [delta, setDelta] = useState(0);
  const speed = useAnimationSpeed();

  useEffect(() => {
    if (!showDelta) return;
    if (prevValue === value) return;
    setDelta(value - prevValue);
    setPrevValue(value);
  }, [value, prevValue, showDelta]);

  useEffect(() => {
    if (delta !== 0) {
      setDelta(0);
    }
  }, [delta]);

  return (
    <span style={{ position: "relative" }}>
      {value}
      <AnimatePresence>
        {delta !== 0 ? (
          <motion.span
            className={`delta ${delta > 0 ? "positive" : "negative"}`}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: [null, 1, 0], y: [null, "-1em", "-1.5em"] }}
            transition={{
              duration: speed === 0 ? 0 : 2 / speed,
              times: [0, 0.9, 1],
            }}
          >
            {Math.abs(delta)}
          </motion.span>
        ) : null}
      </AnimatePresence>
    </span>
  );
}

export default Delta;
