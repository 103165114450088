import { ClientGameSpec } from "engine/types/client-game-specs";
import { ALL_SHARED_GAME_SPECS } from "engine/all-shared-game-specs";

type ClientGameSpecs = {
  [puzName: string]: ClientGameSpec;
};

const ALL_CLIENT_GAME_SPECS: ClientGameSpecs = {};

export const getClientGameSpec = (puzName: string): ClientGameSpec => {
  const sharedGameSpec = ALL_SHARED_GAME_SPECS[puzName] ?? {};
  const gameSpec = ALL_CLIENT_GAME_SPECS[puzName] ?? {};

  return {
    ...sharedGameSpec,
    ...gameSpec,
  };
};
