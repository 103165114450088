import { Inspector } from "engine/Inspector";
import {
  PermanentEventHandlers,
  PermanentEventType,
} from "engine/server-hooks";
import { CounterType } from "engine/types/counters";
import { GameSpecServerFragment } from "engine/types/game-specs";
import { Player, Slot } from "engine/types/game-state";

// Implement Othello game state and move logic
const N = 10; // must be bigger than board size
export const BOARD_SIZE = 8;
const MAX_HEALTH = 100;
const AI_MAX_HEALTH = 200;
function rowColToIndex(row: number, col: number) {
  return N * row + col;
}
function slotToIndex(slot: Slot) {
  return rowColToIndex(slot.row, slot.column);
}
function indexToSlot(index: number) {
  return {
    row: Math.floor(index / N),
    column: index % N,
  };
}

class OthelloState {
  constructor(private locations: { [index: number]: Player }) {}

  static makeStateFromInspector(inspector: Inspector) {
    const locations: { [index: number]: Player } = {};
    for (const perm of inspector.getAllPermanents()) {
      locations[slotToIndex(perm.slot)] = perm.owner;
    }
    return new OthelloState(locations);
  }

  // Find the result if the given slot is played on by the given player.
  // Returns object with
  //   state: OthelloState
  //   swaps:
  getResultingState(slot: Slot, player: Player) {
    const newIndex = slotToIndex(slot);
    const newLocations = {
      ...this.locations,
      [newIndex]: player,
    };
    // The possible 8 directions to go, by delta of index.
    const directions = [1, N - 1, N, N + 1, -1, -N + 1, -N, -N - 1];
    const allToFlip: number[] = [];

    for (const delta of directions) {
      let currentIndex = newIndex;
      let toFlip = [];
      while (true) {
        currentIndex += delta;
        // Check that the next adjacent space is occupied.
        if (!this.locations[currentIndex]) {
          toFlip = [];
          break;
        } else if (this.locations[currentIndex] !== player) {
          // If occupied and opposite player, this will be flipped
          toFlip.push(currentIndex);
        } else {
          // If occupied and same player, done finding things to flip
          break;
        }
      }
      allToFlip.push(...toFlip);
    }

    for (const flipIndex of allToFlip) {
      newLocations[flipIndex] = player;
    }

    return {
      state: new OthelloState(newLocations),
      swaps: allToFlip.map(indexToSlot),
    };
  }

  isLegalMove(slot: Slot) {
    if (this.hasUnitAtSlot(slot)) {
      return false;
    }
    const directions = [1, N - 1, N, N + 1, -1, -N + 1, -N, -N - 1];
    const index = slotToIndex(slot);
    for (const delta of directions) {
      const newIndex = index + delta;
      if (this.hasUnitAtIndex(newIndex)) {
        return true;
      }
    }
    return false;
  }

  hasUnitAtSlot(slot: Slot) {
    return this.hasUnitAtIndex(slotToIndex(slot));
  }

  hasUnitAtIndex(idx: number) {
    return this.locations[idx] != undefined;
  }
}

const SQUARE_TO_CHECK = {
  [rowColToIndex(1, 0)]: rowColToIndex(0, 0),
  [rowColToIndex(0, 1)]: rowColToIndex(0, 0),
  [rowColToIndex(1, 1)]: rowColToIndex(0, 0),
  [rowColToIndex(1, BOARD_SIZE - 1)]: rowColToIndex(0, BOARD_SIZE - 1),
  [rowColToIndex(0, BOARD_SIZE - 2)]: rowColToIndex(0, BOARD_SIZE - 1),
  [rowColToIndex(1, BOARD_SIZE - 2)]: rowColToIndex(0, BOARD_SIZE - 1),
  [rowColToIndex(BOARD_SIZE - 1, 1)]: rowColToIndex(BOARD_SIZE - 1, 0),
  [rowColToIndex(BOARD_SIZE - 2, 0)]: rowColToIndex(BOARD_SIZE - 1, 0),
  [rowColToIndex(BOARD_SIZE - 2, 1)]: rowColToIndex(BOARD_SIZE - 1, 0),
  [rowColToIndex(BOARD_SIZE - 1, BOARD_SIZE - 1)]: rowColToIndex(
    BOARD_SIZE - 1,
    BOARD_SIZE - 1
  ),
  [rowColToIndex(BOARD_SIZE - 1, BOARD_SIZE - 2)]: rowColToIndex(
    BOARD_SIZE - 1,
    BOARD_SIZE - 1
  ),
  [rowColToIndex(BOARD_SIZE - 2, BOARD_SIZE - 2)]: rowColToIndex(
    BOARD_SIZE - 1,
    BOARD_SIZE - 1
  ),
};
function getAdjustmentValue(slot: Slot, startState: OthelloState) {
  const { row, column } = slot;
  // Corners are good
  if (
    (row === 0 || row === BOARD_SIZE - 1) &&
    (column === 0 || column === BOARD_SIZE - 1)
  ) {
    return 2;
  }
  const index = slotToIndex(slot);
  if (index in SQUARE_TO_CHECK) {
    if (!startState.hasUnitAtIndex(SQUARE_TO_CHECK[index])) {
      // This would free the corner to be played, so avoid doing that.
      return -2;
    }
  }
  // Slightly prefer playing on edges.
  if (
    row === 0 ||
    row === BOARD_SIZE - 1 ||
    column === 0 ||
    column === BOARD_SIZE - 1
  ) {
    return 0.5;
  }
  return 0;
}

const pieceHandlers: PermanentEventHandlers = {
  [PermanentEventType.CREATED]: (ev) => {
    const { permanent, ctx } = ev;
    const { engine, inspector } = ctx;
    engine.addCounter(permanent, { type: CounterType.CANNOT_ATTACK });
    engine.addCounter(permanent, { type: CounterType.CANNOT_CREATE });
    engine.addCounter(permanent, { type: CounterType.CANNOT_MOVE });
    // const cardName = permanent.card.name;

    // Change the loyalty (and name) of permanents
    const currentState = OthelloState.makeStateFromInspector(inspector);
    const { swaps } = currentState.getResultingState(
      permanent.slot,
      permanent.owner
    );
    const newCardName =
      permanent.owner === Player.P1 ? "light-piece" : "dark-piece";
    for (const swapSlot of swaps) {
      const swapPerm = inspector.getPermanentAtIfExists(swapSlot);
      if (swapPerm) {
        engine.getBackdoorEngine().transformPermanent(swapPerm, newCardName);
        engine.changePermanentOwner(swapPerm);
      }
    }
  },
};

interface Puzzle93ExtraState {
  aiDamage: number; // the amount the AI (P2) has been damaged
  playerDamage: number; // the amount the player (P1) has been damaged
}
export const Puzzle93Spec: GameSpecServerFragment = {
  disableNoBasesLossCondition: true,

  extraLoseChecks: (player, ctx) => {
    if (ctx.inspector.getPermanents({ owner: player }).length === 0) {
      return true;
    }
    const extraState = ctx.engine.getExtraState() as Puzzle93ExtraState;
    if (player === Player.P1) {
      return extraState.playerDamage >= MAX_HEALTH;
    } else {
      return extraState.aiDamage >= AI_MAX_HEALTH;
    }
  },

  cardEffects: {
    "light-piece": {
      handlers: pieceHandlers,
    },
    "dark-piece": {
      handlers: pieceHandlers,
    },
  },

  beforeNewGame: (ctx) => {
    // spawn the 4 pieces
    const { engine } = ctx;
    engine.spawn("light-piece", { row: 3, column: 3 }, Player.P1);
    engine.spawn("light-piece", { row: 4, column: 4 }, Player.P1);
    engine.spawn("dark-piece", { row: 3, column: 4 }, Player.P2);
    engine.spawn("dark-piece", { row: 4, column: 3 }, Player.P2);
  },

  aiTurn: (ctx) => {
    const { ai, inspector } = ctx;
    if (inspector.getTurnNumber() < 2) {
      ai.speak(
        "Let’s play my game! On every turn, you get to place a piece, then all your units do an attack down their column. If they don’t have anyone to hit, they hit the opposing player. I get to go first, so hit End Turn and let’s get started!"
      );
      return;
    }

    const cols = inspector.getNumColumns();
    const rows = inspector.getNumRows();
    const startState = OthelloState.makeStateFromInspector(inspector);

    let bestMoveScore = -1;
    let bestMove: Slot | null = null;

    // detect best square to move on
    for (let row = 0; row < rows; row++) {
      for (let column = 0; column < cols; column++) {
        const slot = { row, column };
        if (!startState.isLegalMove(slot)) {
          continue;
        }
        const thisScore =
          startState.getResultingState(slot, Player.P2).swaps.length +
          getAdjustmentValue(slot, startState);
        if (thisScore > bestMoveScore) {
          bestMoveScore = thisScore;
          bestMove = slot;
        }
      }
    }

    if (bestMove != null) {
      ai.trySpawn("dark-piece", bestMove);
    }
  },

  afterTurnEnd: (player, ctx) => {
    const { ai, inspector, engine } = ctx;
    if (inspector.getTurnNumber() <= 2) {
      return;
    }
    // Deal damage!
    let damage = 0;
    const extraState = engine.getExtraState() as Puzzle93ExtraState;
    for (const playerUnit of inspector.getPermanentsOf(player)) {
      const targetUnits = inspector.getPermanents({
        column: playerUnit.slot.column,
        rowInFrontOf: {
          row: playerUnit.slot.row,
          perspective: player,
        },
        ownedByOpponentOf: player,
        sort: [
          { type: "frontToBack", perspective: inspector.getOpponentOf(player) },
        ],
      });
      if (targetUnits.length === 0) {
        damage++;
        if (player === Player.P1) {
          extraState.aiDamage += 1;
        } else if (player === Player.P2) {
          extraState.playerDamage += 1;
        }
      } else {
        engine.damage(playerUnit, targetUnits[0], 1);
      }
    }

    const prefix = player === Player.P1 ? "You" : "I";
    ai.speak(`${prefix} dealt ${damage} damage this turn.
My health: ${AI_MAX_HEALTH - extraState.aiDamage}. Your health: ${
      MAX_HEALTH - extraState.playerDamage
    }.`);

    if (
      player === Player.P2 &&
      inspector.getHandForUI(Player.P1).length === 0
    ) {
      engine.addCardToHand(Player.P1, { name: "light-piece" });
    }
  },

  afterInitEngine: (ctx) => {
    ctx.engine.initExtraState({
      aiDamage: 0,
      playerDamage: 0,
    } as Puzzle93ExtraState);
  },
};
