import { GemColor } from "engine/types/card-data";
import { Slot, Player, Permanent } from "engine/types/game-state";
import { AbilityType, EffectOpt } from "engine/types/effects";
import {
  StepType,
  Step,
  SummonStep,
  MoveStep,
  AttackStep,
} from "engine/types/steps";
import { Role } from "engine/types/updates";
import { Inspector } from "engine/Inspector";
import { PermanentQuery } from "engine/PermanentQuery";

export class StepMaker {
  inspector: Inspector;
  player?: Player;
  role?: Role;

  constructor(inspector: Inspector, player?: Player, role?: Role) {
    this.inspector = inspector;
    this.player = player;
    this.role = role;
  }

  getPlayer(player?: Player): Player {
    const playerResolved = player ?? this.player;
    if (playerResolved === undefined) {
      throw new Error("player not provided");
    }

    return playerResolved;
  }

  getRole(role?: Role): Role {
    const roleResolved = role ?? this.role;
    if (roleResolved === undefined) {
      throw new Error("role not provided");
    }

    return roleResolved;
  }

  endTurn(playerOpt?: Player): Step {
    const player = this.getPlayer(playerOpt);
    return {
      type: StepType.END_TURN,
      player,
      stepNumber: this.inspector.getStepNumber(player),
    };
  }

  spawn(cardName: string, slot: Slot): Step;
  spawn(cardName: string, row: number, col: number): Step;
  spawn(
    cardName: string,
    arg1: Slot | number,
    arg2?: number,
    player?: Player
  ): Step;
  spawn(
    cardName: string,
    arg1: Slot | number,
    arg2?: number,
    player?: Player
  ): Step {
    const slot = (() => {
      if (typeof arg1 === "number") {
        if (arg2 === undefined) throw new Error("incorrect type signature");
        return { row: arg1, column: arg2 };
      } else {
        if (arg2 !== undefined) throw new Error("incorrect type signature");
        return arg1;
      }
    })();

    return this.summonOrSpawn({
      player,
      slot,
      cardName,
    });
  }

  summon(handCardId: string, slot: Slot): Step;
  summon(handCardId: string, row: number, col: number): Step;
  summon(
    handCardId: string,
    arg1: Slot | number,
    arg2?: number,
    player?: Player
  ): Step;
  summon(
    handCardId: string,
    arg1: Slot | number,
    arg2?: number,
    player?: Player
  ): Step {
    const slot = (() => {
      if (typeof arg1 === "number") {
        if (arg2 === undefined) throw new Error("incorrect type signature");
        return { row: arg1, column: arg2 };
      } else {
        if (arg2 !== undefined) throw new Error("incorrect type signature");
        return arg1;
      }
    })();

    return this.summonOrSpawn({
      player,
      slot,
      handCardId,
    });
  }

  summonOrSpawn(opts: {
    player?: Player;
    slot: Slot;
    handCardId?: string;
    cardName?: string;
  }): SummonStep {
    const player = this.getPlayer(opts.player);
    return {
      type: StepType.SUMMON,
      ...opts,
      player,
      stepNumber: this.inspector.getStepNumber(player),
    };
  }

  create(permanent: Permanent, gemColors: GemColor[]): Step {
    return {
      type: StepType.CREATE_GEMS,
      permanentId: permanent.id,
      gemColors,
      stepNumber: this.inspector.getStepNumber(permanent.owner),
    };
  }

  move(permanent: Permanent, slot: Slot): MoveStep;
  move(permanent: Permanent, row: number, col: number): MoveStep;
  move(permanent: Permanent, arg1: Slot | number, arg2?: number): MoveStep;
  move(permanent: Permanent, arg1: Slot | number, arg2?: number): MoveStep {
    const slot = (() => {
      if (typeof arg1 === "number") {
        if (typeof arg2 !== "number")
          throw new Error("incorrect type signature");
        return { row: arg1, column: arg2 };
      } else {
        if (arg2 !== undefined) throw new Error("incorrect type signature");
        return arg1;
      }
    })();

    return {
      type: StepType.MOVE,
      permanentId: permanent.id,
      slot: slot,
      stepNumber: this.inspector.getStepNumber(permanent.owner),
    };
  }

  attack(attacker: Permanent, defender: Permanent): AttackStep {
    return {
      type: StepType.ATTACK,
      attackerId: attacker.id,
      defenderId: defender.id,
      stepNumber: this.inspector.getStepNumber(attacker.owner),
    };
  }

  activateAbility(
    permanent: Permanent,
    abilityType: AbilityType,
    effectOpts?: ReadonlyArray<EffectOpt>
  ): Step {
    return {
      type: StepType.ACTIVATE_ABILITY,
      permanentId: permanent.id,
      abilityType: abilityType,
      effectOpts: effectOpts ?? [],
      stepNumber: this.inspector.getStepNumber(permanent.owner),
    };
  }

  draw(playerOpt?: Player): Step {
    const player = this.getPlayer(playerOpt);
    return {
      type: StepType.DRAW,
      player,
      stepNumber: this.inspector.getStepNumber(player),
    };
  }

  /**
   * Returns a default query for attacking for general uses.
   * Please help improve it!
   */
  private makeDefaultAttackQuery(attacker: Permanent): PermanentQuery {
    return {
      sort: [
        "structuresFirst",
        {
          type: "weighted",
          weights: [
            // prioritize lower health, since they are faster to kill off
            { criterion: "health", weight: 1 },
            // prioritize higher power, since they are scarier
            { criterion: "-power", weight: 1 },
          ],
        },
        // tiebreak by nearest first
        { type: "euclideanDistance", slot: attacker.slot },
      ],
    };
  }

  /**
   * Attack the first valid permanent from a filter/sort query.
   * See {@link PermanentQuery} for the query format.
   * If no query is supplied, uses a default query that should work
   * for most cases (please help improve it)!
   */
  attackFirst(
    attacker: Permanent,
    query?: PermanentQuery,
    role?: Role
  ): Step | void {
    for (const defender of this.inspector.getPermanents(
      query ?? this.makeDefaultAttackQuery(attacker)
    )) {
      const attackStep = this.attack(attacker, defender);
      if (this.inspector.isValid(attackStep, this.getRole(role))) {
        return attackStep;
      }
    }
  }

  createCheckpoint(slot: number): Step {
    return { type: StepType.CREATE_CHECKPOINT, slot };
  }
}
