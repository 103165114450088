import { Player, Permanent } from "engine/types/game-state";
import { Check } from "engine/types/action-validation";
import {
  InspectorContext,
  SharedGameSpec,
} from "engine/types/shared-game-specs";

export const SharedPuzzle9Spec: SharedGameSpec = {
  allowCheckpoints: true,
  hasAI: true,
  aiDisableGemAccounting: true,
  basesOverrides: {
    [Player.P1]: ["keep"],
    [Player.P2]: ["keep"],
  },

  disableCardEffectsAdjustAttackChecks: true,
  adjustAttackChecks: (failedChecks, attacker, defender, ctx) => {
    const { inspector } = ctx;
    const attackerFirstLetter = inspector
      .getCardDisplayName(attacker)
      .charAt(0)
      .toUpperCase();

    if ("KQRBNP".includes(attackerFirstLetter)) {
      failedChecks.delete(Check.PROTECTED);
      if (!specialThreatened(attacker, defender, ctx))
        failedChecks.add(Check.GENERIC);
      return;
    }

    // Restore normal card effects check for everyone else
    const effects = inspector.getSharedEffects(attacker);
    const effectsAdjust = effects.adjustAttackChecks;
    if (effectsAdjust)
      effectsAdjust(failedChecks, attacker, defender, inspector.makeContext());
  },
};

export const specialThreatened = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const inspector = ctx.inspector;
  const attackerFirstLetter = inspector
    .getCardDisplayName(attacker)
    .charAt(0)
    .toUpperCase();
  if (attackerFirstLetter === "K") {
    return threatenedByKing(attacker, defender, ctx);
  }
  if (attackerFirstLetter === "Q") {
    return (
      threatenedByRook(attacker, defender, ctx) ||
      threatenedByBishop(attacker, defender, ctx)
    );
  }
  if (attackerFirstLetter === "R") {
    return threatenedByRook(attacker, defender, ctx);
  }
  if (attackerFirstLetter === "B") {
    return threatenedByBishop(attacker, defender, ctx);
  }
  if (attackerFirstLetter === "N") {
    return threatenedByKnight(attacker, defender, ctx);
  }
  if (attackerFirstLetter === "P") {
    return threatenedByPawn(attacker, defender, ctx);
  }
  return false;
};

const threatenedByKing = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const rowDiff = attacker.slot.row - defender.slot.row;
  const colDiff = attacker.slot.column - defender.slot.column;
  return Math.max(Math.abs(rowDiff), Math.abs(colDiff)) == 1;
};

const threatenedByRook = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const rowDiff = attacker.slot.row - defender.slot.row;
  const colDiff = attacker.slot.column - defender.slot.column;
  if (rowDiff * colDiff != 0) {
    return false;
  }
  if (Math.abs(rowDiff) + Math.abs(colDiff) == 1) {
    return true;
  }
  if (Math.abs(rowDiff) + Math.abs(colDiff) == 0) {
    return false;
  }
  // Otherwise, there is at least one space between pieces, which we need to
  // check for obstructions.
  for (let i = 1; i < Math.abs(rowDiff + colDiff); i++) {
    const slot = {
      row: defender.slot.row + (rowDiff * i) / Math.abs(rowDiff + colDiff),
      column:
        defender.slot.column + (colDiff * i) / Math.abs(rowDiff + colDiff),
    };
    if (ctx.inspector.isSlotOccupied(slot)) {
      return false;
    }
  }
  return true;
};

const threatenedByBishop = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const rowDiff = attacker.slot.row - defender.slot.row;
  const colDiff = attacker.slot.column - defender.slot.column;
  if (Math.abs(rowDiff) != Math.abs(colDiff)) {
    return false;
  }
  if (Math.abs(rowDiff) == 0) {
    return false;
  }
  if (Math.abs(rowDiff) == 1) {
    return true;
  }
  // Otherwise, there is at least one space directly between the pieces,
  // which we need to check for obstructions.
  for (let i = 1; i < Math.abs(rowDiff); i++) {
    if (
      ctx.inspector.getPermanentsAt({
        row: defender.slot.row + (rowDiff * i) / Math.abs(rowDiff),
        column: defender.slot.column + (colDiff * i) / Math.abs(colDiff),
      }).length > 0
    ) {
      return false;
    }
  }
  return true;
};

const threatenedByKnight = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const rowDiff = attacker.slot.row - defender.slot.row;
  const colDiff = attacker.slot.column - defender.slot.column;
  return Math.abs(rowDiff) * Math.abs(colDiff) == 2; // ban on fractional positioning pls
};

const threatenedByPawn = (
  attacker: Permanent,
  defender: Permanent,
  ctx: InspectorContext
): boolean => {
  const rowDiff = attacker.slot.row - defender.slot.row;
  const colDiff = attacker.slot.column - defender.slot.column;
  return (
    ctx.inspector.isRowInFrontOf(
      defender.slot.row,
      attacker.slot.row,
      attacker.owner
    ) &&
    Math.abs(rowDiff) === 1 &&
    Math.abs(colDiff) === 1
  );
};
