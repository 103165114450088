import { z } from "zod";

import { ColorSymbol, CardColor } from "engine/types/card-data";
import { PlayerStateStats } from "engine/types/game-state";

export enum Faction {
  BOARS = "boars",
  COWS = "cows",
  BEES = "bees",
  DINOSAURS = "dinosaurs",
  DRYADS = "dryads",
}
export const FactionZod = z.nativeEnum(Faction);

export const makeInitFactionScores = (): { [faction in Faction]: number } => {
  return {
    [Faction.BOARS]: 0,
    [Faction.COWS]: 0,
    [Faction.BEES]: 0,
    [Faction.DINOSAURS]: 0,
    [Faction.DRYADS]: 0,
  };
};

export const makeHuntEndFactionScores = (): {
  [faction in Faction]: number;
} => {
  return {
    [Faction.BOARS]: 1787669,
    [Faction.COWS]: 4446436,
    [Faction.BEES]: 3409702,
    [Faction.DINOSAURS]: 4850820,
    [Faction.DRYADS]: 2082386,
  };
};

export const factionToColor = (faction: Faction | null): ColorSymbol => {
  if (faction === null) return ColorSymbol.ROCK;
  switch (faction) {
    case Faction.BOARS:
      return ColorSymbol.RED;
    case Faction.COWS:
      return ColorSymbol.WHITEBLACK;
    case Faction.BEES:
      return ColorSymbol.YELLOW;
    case Faction.DINOSAURS:
      return ColorSymbol.GREEN;
    case Faction.DRYADS:
      return ColorSymbol.PURPLE;
  }
};

export const doesCardColorBelongToFaction = (
  color: CardColor | null,
  faction: Faction | null
): boolean => {
  if (faction === null || color === null) return false;
  switch (faction) {
    case Faction.BOARS:
      return color === ColorSymbol.RED;
    case Faction.COWS:
      return [
        ColorSymbol.WHITE,
        ColorSymbol.BLACK,
        ColorSymbol.WHITEBLACK,
      ].includes(color);
    case Faction.BEES:
      return color === ColorSymbol.YELLOW;
    case Faction.DINOSAURS:
      return color === ColorSymbol.GREEN;
    case Faction.DRYADS:
      return color === ColorSymbol.PURPLE;
  }
};

// Constants for faction score calculation.
export const FactionScoreConstants = {
  SOLVE_BONUS: 100,
  SUMMONS_MULTIPLIER: 10,
  FACTION_SUMMONS_MULTIPLIER: 30,
  DESTROYS_MULTIPLIER: 10,
  DAMAGE_DEALT_MULTIPLIER: 1,
  DAMAGE_DEALT_CAP: 100,
  PVP_MULTIPLIER: 5,
};

export const calcFactionScore = (
  stats: PlayerStateStats,
  isPvP: boolean,
  isWinner: boolean
): number => {
  // Important: Any changes to this formula should be correspondingly
  // reflected in the faction score calculation display.
  if (isPvP && !isWinner) return 0;
  const baseScore =
    FactionScoreConstants.SOLVE_BONUS +
    stats.summons * FactionScoreConstants.SUMMONS_MULTIPLIER +
    stats.factionSummons * FactionScoreConstants.FACTION_SUMMONS_MULTIPLIER +
    stats.destroys * FactionScoreConstants.DESTROYS_MULTIPLIER +
    Math.min(stats.damageDealt, FactionScoreConstants.DAMAGE_DEALT_CAP) *
      FactionScoreConstants.DAMAGE_DEALT_MULTIPLIER;
  return baseScore * (isPvP ? FactionScoreConstants.PVP_MULTIPLIER : 1);
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const FACTION_RANK_THRESHOLDS = [0, 5000, 30000];

export const getFactionRank = (score: number) => {
  for (let i = 0; i < FACTION_RANK_THRESHOLDS.length; i++) {
    if (score < FACTION_RANK_THRESHOLDS[i]) return i;
  }
  return FACTION_RANK_THRESHOLDS.length;
};
