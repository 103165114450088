import Image from "components/Image";
import { getWrapupGalleryPath } from "images";
import { Carousel } from "react-responsive-carousel";
import "./WrapupGallery.css";

type WrapupGalleryImageSpec = {
  images?: string[];
  video?: string;
  gif?: string;
  caption: string;
  halfwidth?: true;
  thirdwidth?: true;
};

const DATA_0: WrapupGalleryImageSpec[] = [
  {
    images: ["b01-1.png", "b01-2.png"],
    caption: "",
    halfwidth: true,
  },
  {
    images: ["b02-1.png"],
    caption:
      "Gnomaria (the gnomes have teched up, now they are living in buildings)",
    halfwidth: true,
  },
  {
    images: ["b03-1.png"],
    caption: "Dragon cave area, crystal caverns",
    halfwidth: true,
  },
  {
    images: ["b04-1.png"],
    caption: "The cows are now living on pastures on mushrooms on cave walls",
    halfwidth: true,
  },
  {
    images: ["b05-1.png"],
    caption: "",
  },
  {
    images: ["b06-1.png"],
    caption:
      "We considered a number of alternative names for the initial GCC cave",
  },
  {
    // haha
    video: undefined,
    images: ["a01-1.png"],
    caption:
      "One of the cards that got lost along the way was Dark Bramble, who was replaced by Roe Doe Dendron. This was done to help in puzzle balancing and was named in an attempt to keep the art the same. The artist, however, has no chill and was inspired and so new art was created. (Roe Doe Dendron and Dark Bramble are dating.)",
  },
  {
    images: ["nice-cow-old.png"],
    caption: "Unused card art, originally for Nice Cow.",
  },
  {
    images: ["a02-1.png"],
    caption:
      "Old Kero design, “lobster” = long smobster. Intended to be a teaser on the home page background.",
    thirdwidth: true,
  },
  {
    images: ["a03-1.png"],
    caption: "Billy, who got befeated.",
  },
  {
    images: ["a04-1.png"],
    caption: "Boar.",
  },
  {
    images: ["a05-1.png"],
    caption: "Not a Boat. Yeah, that was a card name.",
  },
  {
    images: ["a06-1.png"],
    caption:
      "Glancer, from when the Gnomes were a faction distinguished by silent G’s.",
  },
  {
    images: ["a07-1.png"],
    caption: "Slime.",
  },
  {
    images: ["a08-1.png", "a08-2.png", "a08-3.png", "a08-4.png", "a08-5.png"],
    caption:
      "Crow, Monkey, Nudibranch, Purple Dragon, Purple Gatherer (we had a dragon and a gatherer for each color).",
    thirdwidth: true,
  },
  {
    images: ["a09-1.png", "a09-2.png", "a09-3.png", "a09-4.png", "a09-5.png"],
    caption:
      "Somewhat less old placeholder art for Quail Aboard Fiery Steeds; Yoshisaur Munchakoopas; Slime, Duplication Glitch; B/W Copier; and Spirit of the Vines.",
    thirdwidth: true,
  },
  {
    gif: "a10-1.gif",
    caption: "The birth of New Boarn.",
  },
];

const DATA_1: WrapupGalleryImageSpec[] = [
  {
    images: ["01-1.png"],
    caption: "Tabletop Simulator prototype, early April.",
  },
  {
    images: ["02-1.png"],
    caption: "First web prototype, mid-April.",
  },
  {
    images: ["03-1.png"],
    caption: "Original deckbuilding interface prototype, mid-April.",
  },
  {
    video: "04-1.webm",
    caption: "GCC wireframe, late April.",
  },
  {
    images: ["05-1.png", "05-2.png"],
    caption: "GCC walkthrough, May.",
    halfwidth: true,
  },
  {
    images: ["06-1.png", "06-2.png", "06-3.png"],
    caption: "GCC design, May.",
    halfwidth: true,
  },
  {
    images: ["07-1.png"],
    caption: "GCC story draft, May.",
  },
  {
    images: ["08-1.png"],
    caption: "Prototype for the new game design, late May.",
  },
  {
    video: "09-1.webm",
    caption: "UX design, late May.",
  },
  {
    images: ["factions-slide-old.png"],
    caption: "Factions, mid-June.",
  },
  {
    images: ["10-1.png"],
    caption: "Battle interface during retreat, mid-June.",
  },
  {
    images: ["11-1.png"],
    caption: "Deckbuilding interface during retreat, mid-June.",
  },
  {
    images: ["12-1.png", "12-2.png", "12-3.png", "12-4.png", "12-5.png"],
    caption: `First tutorial stage during retreat, mid-June.
This would evolve into stage 2 of the tutorial we have now.`,
    thirdwidth: true,
  },
  {
    images: ["13-1.png"],
    caption: `Second tutorial stage during retreat, mid-June.
This would evolve into stage 3 of the tutorial we have now.`,
  },
  {
    images: ["14-1.png"],
    caption: "Third tutorial stage during retreat, mid-June.",
  },
  {
    images: ["15-1.png"],
    caption: "Fourth tutorial stage during retreat, mid-June.",
  },
  {
    images: ["16-1.png"],
    caption: `Fifth tutorial stage during retreat, mid-June.
Robot: Can not Create, Move, or Attack freely. At the end of each turn, it Attacks down its column.
Porcupine: Creatures that do damage to this Creature take 1 damage.
Monkey: R, Special: Attack. Swap places with an adjacent Friendly Unit.
Crow: B, Special: Attack a Protected Creature.`,
  },
  {
    images: ["17-1.png", "17-2.png", "17-3.png"],
    caption: "“Secret” sixth tutorial stage during retreat, mid-June.",
    halfwidth: true,
  },
  {
    images: ["18-1.png"],
    caption:
      "Prototype for what would become Blancmange during retreat, mid-June.",
  },
  {
    images: ["19-1.png"],
    caption: "New battle interface, early July.",
  },
  {
    images: ["20-1.png"],
    caption: "New deckbuilding interface, early July.",
  },
  {
    images: ["21-1.png"],
    caption: "Deck selection page prototype, early July.",
  },
  {
    images: ["22-1.png"],
    caption: "Deck selection interface prototype, late July.",
  },
  {
    images: ["23-1.png"],
    caption: "Mastery tree prototype, early August.",
  },
  {
    images: ["24-1.png"],
    caption: "What would become Moonick, early August.",
  },
  {
    images: ["25-1.png"],
    caption: `What would become Miss Yu, early August.
Due to puzzle requirements, it was the first puzzle with puzzle-specific art.`,
  },
  {
    images: ["masteries-brainstorming.png"],
    caption: "Brainstorming mastery effects, mid-August.",
  },
  {
    images: ["26-1.png"],
    caption: "Opening flow, mid-August.",
  },
  {
    images: ["27-1.png"],
    caption: "Opening story draft, mid-August.",
    halfwidth: true,
  },
  {
    images: ["28-1.png"],
    caption: `Deck and stage selection interface, early September.
The styles got messed up due to integration with gph-site and it took a while to fix.`,
  },
  {
    images: ["29-1.png"],
    caption: "Map page, early September.",
  },
  {
    images: ["30-1.png"],
    caption: "Cutscene interface prototype, mid-September.",
  },
  {
    images: ["31-1.png"],
    caption: "Battle preparation interface, mid-September.",
  },
  {
    images: ["32-1.png"],
    caption: "Deckbuilding interface, mid-September.",
  },
  {
    images: ["33-1.png"],
    caption: "What would become the Kero battle, mid-September.",
  },
  {
    images: ["34-1.png", "34-2.png"],
    caption: "Prototype Kero transformation, late September.",
    halfwidth: true,
  },
  {
    images: ["35-1.png"],
    caption: "Team page, late September.",
  },
  {
    images: ["36-1.png"],
    caption: "Prototype Kero animation, late September.",
    halfwidth: true,
  },
  {
    images: ["37-1.png", "37-2.png", "37-3.png", "37-4.png", "37-5.png"],
    caption: "Map page during MBTS, end September.",
    thirdwidth: true,
  },
  {
    images: ["38-1.png"],
    caption: "Deckbuilding interface during MBTS, end-September.",
  },
  {
    images: ["39-1.png"],
    caption: "Battle preparation UI during MBTS, end-September.",
  },
  {
    images: ["40-1.png", "40-2.png", "40-3.png", "40-4.png"],
    caption: "Battle interface during MBTS, end-September.",
    halfwidth: true,
  },
  {
    images: ["41-1.png", "41-2.png", "41-3.png", "41-4.png"],
    caption: "Tutorial during MBTS, end September.",
    halfwidth: true,
  },
  {
    images: ["42-1.png"],
    caption: "PvP lobby during MBTS, end September.",
  },
  {
    images: ["43-1.png"],
    caption: "The server health monitoring page.",
  },
];

export function WrapupGallery({ data }: { data: number }) {
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      // eslint-disable-next-line react/no-children-prop
      children={(data === 0 ? DATA_0 : DATA_1).map(
        (
          {
            video,
            gif,
            images,
            caption,
            halfwidth = false,
            thirdwidth = false,
          },
          i
        ) => (
          <div key={i} style={{ height: "100%" }}>
            <div className="wrapup-inner">
              {video ? (
                <video controls width="100%">
                  <source
                    src={`../static/images/wrapup-gallery/${video}`}
                    type="video/webm"
                  />
                </video>
              ) : null}
              {gif ? (
                <Image
                  src={`../static/images/wrapup-gallery/${gif}`}
                  pictureClassName={
                    halfwidth ? "halfwidth" : thirdwidth ? "thirdwidth" : ""
                  }
                />
              ) : null}
              {(images ?? []).map((image) => (
                <Image
                  key={image}
                  src={getWrapupGalleryPath(image)}
                  pictureClassName={
                    halfwidth ? "halfwidth" : thirdwidth ? "thirdwidth" : ""
                  }
                />
              ))}
            </div>
            {caption === "" ? null : (
              <p className="legend">
                {caption.split("\n").map((line) => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </p>
            )}
          </div>
        )
      )}
    />
  );
}
