import { SerializedCheckpointState } from "engine/types/game-state";
import { Faction, makeInitFactionScores } from "engine/types/factions";
import {
  BackendRoom,
  BackendDeck,
  BackendAnswerSubmission,
  BackendUnlock,
  BackendCardUnlock,
  BackendNotification,
  SyncDataBackendReq,
  CheckAnswerBackendReq,
  GetInitDataBackendResp,
  GetInitTeamDataBackendResp,
  CheckAnswerBackendRespType,
  CheckAnswerBackendResp,
  BackendInterface,
  makeBattleSolveAnswerSubmission,
} from "game-server/backend-interface/BackendInterface";
import { MasteryTree } from "game-server/masteries";
import { CardsDB } from "engine/cards/CardsDB";
import {
  PuzzlesDB,
  PUZ_NAME_INTRO,
  PUZ_NAME_PVP,
  BATTLE_GROUP_NAME_INSTANCER,
} from "engine/puzzles/puzzle-data";
import { MasteriesDB } from "engine/puzzles/mastery-data";
import { isCardValidForDeckbuilding } from "game-server/deckbuilding";
import { COMMON_CARD_EFFECTS_SHARED } from "engine/cards/card-effects-shared";

/**
 * Version number of the mock DB schema.
 * The mock database may be saved to persistent storage periodically.
 * Every time the schema (the below type declarations) is modified,
 * the version number must be incremented to invalidate the old mock
 * database. This can also be used to invalidate the entire DB in
 * general during development, such as when new default decks are
 * added.
 */
const SCHEMA_VERSION = 72;

export const BOOTSTRAP_TEAM_ID = "postsolver";
const BAREBONES_TEAM_ID = "BAREBONES_TEST_TEAM";
const MAX_GUESSES_PER_PUZZLE = 20;

type MockDBTeam = {
  displayName: string;
  faction: Faction | null;
  isInactive: boolean;
  isHidden: boolean;
  factionScoreContributions: { [faction in Faction]: number };
  /** puzNames of puzzles viewed by the team. */
  viewTimes: { [puzName: string]: number };
  unlocks: { [puzName: string]: BackendUnlock };
  cardUnlocks: { [cardName: string]: BackendCardUnlock };
  answerSubmissions: { [puzName: string]: BackendAnswerSubmission[] };
  /** Decks owned by the team. */
  decks: BackendDeck[];
  /** The mastery tree owned by the team, if any. */
  masteryTree: MasteryTree | null;
  /** Slot number of the preferred deck for each puzzle. */
  preferredDecks: { [puzName: string]: number };
  /** Puzzle selections for instancer rooms. */
  puzzleSelections: { [puzName: string]: string };
  /** Checkpoints. */
  checkpoints: {
    [puzName: string]: { [slot: number]: SerializedCheckpointState };
  };
  /** Teams blocked in PvP. */
  blockedTeams: { [teamId: string]: boolean };
  /** Position of the team in the PvP ring, if the team is in the ring. */
  isInRing: boolean;
};

type MockDB = {
  version: number;
  nextGameServerId: number;
  teams: { [teamId: string]: MockDBTeam };
  /** Rooms by room ID. */
  rooms: { [roomId: string]: BackendRoom };
  notifs: BackendNotification[];
};

const normalizeAnswer = (answer: string): string => {
  return [...answer.normalize("NFKD")]
    .flatMap((c) => {
      if (!c.match(/[a-z]/i)) return [];
      return [c.toUpperCase()];
    })
    .join("");
};

const makeDefaultDeck = (
  cardsDB: CardsDB,
  teamId: string,
  slot: number,
  displayName: string
): BackendDeck => {
  const defaultDeckCards: { [cardName: string]: number } = {};
  const cardUnlocks = new Set(Object.keys(cardsDB));
  for (const [cardName, cardData] of Object.entries(cardsDB)) {
    if (!isCardValidForDeckbuilding(cardName, cardUnlocks, cardsDB)) continue;
    if (COMMON_CARD_EFFECTS_SHARED[cardName]?.isLegendary ?? false) continue;
    if (Object.keys(defaultDeckCards).length >= 30) break;
    defaultDeckCards[cardName] = 1;
  }

  return {
    teamId,
    slot,
    deck: {
      displayName,
      cards: { ...defaultDeckCards },
    },
  };
};

const makeBootstrapDecks = (cardsDB: CardsDB): BackendDeck[] => {
  return [
    makeDefaultDeck(cardsDB, BOOTSTRAP_TEAM_ID, 0, "Default deck"),
    makeDefaultDeck(cardsDB, BOOTSTRAP_TEAM_ID, 1, "Default opponent deck"),
    // A small, initial test deck.
    {
      teamId: BOOTSTRAP_TEAM_ID,
      slot: 2,
      deck: {
        displayName: "Test deck",
        cards: { "two-bees": 1, "switch-boar-d": 2 },
      },
    },
  ];
};

const makeBootstrapDB = (
  puzzlesDB: PuzzlesDB,
  cardsDB: CardsDB,
  masteriesDB: MasteriesDB,
  // Create initial unlocks.
  initUnlocks: boolean,
  // The default bootstrap backend state is now the postsolve initial
  // state. Setting isDev recovers the old dev bootstrap state.
  isDev: boolean
): MockDB => {
  const timeNow = new Date().getTime();
  const teamAnswerSubmissions: {
    [puzName: string]: BackendAnswerSubmission[];
  } =
    isDev && initUnlocks
      ? {
          [PUZ_NAME_INTRO]: [
            makeBattleSolveAnswerSubmission(
              BOOTSTRAP_TEAM_ID,
              PUZ_NAME_INTRO,
              new Date().getTime()
            ),
          ],
        }
      : {};
  const teamDecks = isDev && initUnlocks ? makeBootstrapDecks(cardsDB) : [];

  const makeAllPuzzleUnlocks = (teamId: string) => {
    if (!initUnlocks) return {};

    // Only unlock the battle groups for the test team, so that
    // we can test sub-battle unlocks on staging.
    const teamUnlocks = Object.fromEntries(
      [...puzzlesDB.battleGroups.keys()]
        .filter((puzName) => {
          if (isDev) return true;
          const puzData = puzzlesDB.puzzles.get(puzName);
          if (puzData === undefined)
            throw new Error(
              `could not find puz data entry for battle group ${puzName}`
            );
          const { isFullPuzzle, isCutscene } = puzData;
          if (isFullPuzzle) return true;
          if (isCutscene) return true;
          if ([PUZ_NAME_PVP, BATTLE_GROUP_NAME_INSTANCER].includes(puzName))
            return true;
          return false;
        })
        .map((puzName) => [
          puzName,
          {
            teamId,
            puzName,
            timestamp: timeNow,
          },
        ])
    );
    return teamUnlocks;
  };

  const teamCardUnlocks: { [cardName: string]: BackendCardUnlock } = {};
  for (const [cardName, cardData] of Object.entries(cardsDB)) {
    if (!initUnlocks) continue;
    if (cardData.cardUnlockGroupId === null) continue;
    if (
      !isDev &&
      [
        "master-bramble",
        "kero--floppy",
        "professor-galactic",
        "stalactica",
        "test-card",
      ].includes(cardName)
    )
      continue;
    teamCardUnlocks[cardName] = {
      teamId: BOOTSTRAP_TEAM_ID,
      puzName: null,
      cardName,
      timestamp: timeNow,
    };
  }

  const factionScoreContributions = makeInitFactionScores();

  const teams: { [teamId: string]: MockDBTeam } = {
    [BOOTSTRAP_TEAM_ID]: {
      displayName: "Postsolver",
      isInactive: false,
      isHidden: false,
      faction: null,
      factionScoreContributions: { ...factionScoreContributions },
      answerSubmissions: teamAnswerSubmissions,
      viewTimes: {},
      unlocks: makeAllPuzzleUnlocks(BOOTSTRAP_TEAM_ID),
      cardUnlocks: teamCardUnlocks,
      decks: teamDecks,
      masteryTree: {
        placements: {},
        connectedAnswers: [],
        masteriesListOverride:
          isDev && initUnlocks ? Object.keys(masteriesDB) : undefined,
      },
      preferredDecks: {},
      puzzleSelections: {},
      checkpoints: {},
      blockedTeams: {},
      isInRing: false,
    },
  };

  if (isDev) {
    teams[BAREBONES_TEAM_ID] = {
      displayName: "barebones team",
      isInactive: false,
      isHidden: false,
      faction: null,
      factionScoreContributions: { ...factionScoreContributions },
      answerSubmissions: {},
      viewTimes: {},
      unlocks: {},
      cardUnlocks: {},
      decks: [],
      masteryTree: null,
      preferredDecks: {},
      puzzleSelections: {},
      checkpoints: {},
      blockedTeams: {},
      isInRing: false,
    };

    for (let i = 0; i < 30; i++) {
      const teamId = `solver${i}`;
      teams[teamId] = {
        displayName: `pvp test team ${i}`,
        isInactive: false,
        isHidden: false,
        faction: null,
        factionScoreContributions: { ...factionScoreContributions },
        answerSubmissions: {},
        viewTimes: {},
        unlocks: makeAllPuzzleUnlocks(teamId),
        cardUnlocks: {},
        decks: [makeDefaultDeck(cardsDB, teamId, 0, "Default deck")],
        masteryTree: {
          placements: {},
          connectedAnswers: [],
          masteriesListOverride: Object.keys(masteriesDB),
        },
        preferredDecks: {
          [PUZ_NAME_PVP]: 0,
        },
        puzzleSelections: {},
        checkpoints: {},
        blockedTeams: {},
        isInRing: false,
      };
    }
  }

  return {
    version: SCHEMA_VERSION,
    nextGameServerId: 0,
    teams,
    rooms: {},
    notifs: [],
  };
};

export class MockBackendInterface implements BackendInterface {
  puzzlesDB: PuzzlesDB;
  saveDbFunc: (db: MockDB) => Promise<void>;
  nextRoomUid: number;
  db: MockDB;
  /** If an update was made to the database, marks it for saving. */
  isDirty: boolean;
  /**
   * Whether there is already a job running to save the mock DB
   * to persistent storage.
   */
  isSaving: boolean;

  constructor(
    puzzlesDB: PuzzlesDB,
    cardsDB: CardsDB,
    masteriesDB: MasteriesDB,
    savedDB: MockDB | null,
    saveDbFunc: (db: MockDB) => Promise<void>,
    initUnlocks?: boolean
  ) {
    this.puzzlesDB = puzzlesDB;
    this.saveDbFunc = saveDbFunc;
    this.nextRoomUid = 0;

    this.db =
      savedDB === null || savedDB.version < SCHEMA_VERSION
        ? makeBootstrapDB(
            puzzlesDB,
            cardsDB,
            masteriesDB,
            initUnlocks ?? false,
            false
          )
        : savedDB;

    this.isDirty = false;
    this.isSaving = false;
  }

  private genGameServerId() {
    const gameServerId = `gameServer_${this.db.nextGameServerId}`;
    this.db.nextGameServerId++;
    this.isDirty = true;
    return gameServerId;
  }

  async getInitData(): Promise<GetInitDataBackendResp> {
    return {
      gameServerId: this.genGameServerId(),
      teams: Object.fromEntries(
        await Promise.all(
          Object.keys(this.db.teams).map((teamId) =>
            (async () => [teamId, await this.getInitTeamData(teamId)])()
          )
        )
      ),
      factionHistory: {},
      maxGuessesPerPuzzle: MAX_GUESSES_PER_PUZZLE,
      huntStartTime: new Date(2023, 5, 1).getTime(),
      huntEndTime: new Date(2023, 12, 1).getTime(),
      notifsNextIndex: this.db.notifs.length,
      errata: {},
      unclaimedHints: 0,
    };
  }

  async getInitTeamData(teamId: string): Promise<GetInitTeamDataBackendResp> {
    const existingTeamData = this.db.teams[teamId];
    if (existingTeamData === undefined) {
      // Dev only, to allow solvers to arbitrarily create new teams.
      // Do not implement this in Django.
      console.log(`creating new team ${teamId}`);
      this.db.teams[teamId] = {
        displayName: teamId,
        isInactive: false,
        isHidden: false,
        faction: null,
        factionScoreContributions: makeInitFactionScores(),
        answerSubmissions: {},
        viewTimes: {},
        unlocks: {},
        cardUnlocks: {},
        decks: [],
        masteryTree: null,
        preferredDecks: {},
        puzzleSelections: {},
        checkpoints: {},
        blockedTeams: {},
        isInRing: false,
      };
    }

    const {
      displayName,
      isInactive,
      isHidden,
      faction,
      factionScoreContributions,
      answerSubmissions,
      viewTimes,
      unlocks,
      cardUnlocks,
      decks,
      masteryTree,
      preferredDecks,
      puzzleSelections,
      checkpoints,
      blockedTeams,
      isInRing,
    } = this.db.teams[teamId];

    const numWrongGuesses: { [puzName: string]: number } = {};
    for (const [puzName, submissions] of Object.entries(answerSubmissions)) {
      const numWrongGuessesForPuzzle = submissions.filter(
        ({ isCorrect }) => !isCorrect
      ).length;
      if (numWrongGuessesForPuzzle > 0)
        numWrongGuesses[puzName] = numWrongGuessesForPuzzle;
    }

    const speedrunTimes: { [puzName: string]: number } = {};
    const rooms: { [puzName: string]: BackendRoom } = {};
    for (const [roomId, room] of Object.entries(this.db.rooms)) {
      const { puzName, hostPuzName, gameEndInfo, p1TeamId, p2TeamId } = room;
      if (
        !(
          (p1TeamId !== undefined && room.p1TeamId === teamId) ||
          (p2TeamId !== undefined && room.p2TeamId === teamId)
        )
      )
        continue;
      rooms[hostPuzName] = room;
      if (gameEndInfo === undefined) continue;
      const { isSolved, totalTime } = gameEndInfo;
      if (!gameEndInfo.isSolved) continue;
      if (
        speedrunTimes[puzName] === undefined ||
        totalTime < speedrunTimes[puzName]
      )
        speedrunTimes[puzName] = totalTime;
    }

    const solveTimes = Object.fromEntries(
      Object.entries(answerSubmissions).flatMap(([puzName, puzSubmissions]) => {
        const correctSubmissions = puzSubmissions.filter(
          ({ isCorrect }) => isCorrect
        );
        if (correctSubmissions.length === 0) return [];
        return [[puzName, correctSubmissions[0].timestamp]];
      })
    );

    return {
      userId: teamId,
      displayName,
      isInactive,
      isHidden,
      faction,
      factionScoreContributions,
      members: [],
      rooms,
      views: Object.keys(viewTimes),
      cardUnlocks: Object.fromEntries(
        Object.values(cardUnlocks).map(({ cardName, puzName }) => [
          cardName,
          puzName,
        ])
      ),
      unlockTimes: Object.fromEntries(
        Object.values(unlocks).map(({ puzName, timestamp }) => [
          puzName,
          timestamp,
        ])
      ),
      solveTimes,
      speedrunTimes,
      numWrongGuesses,
      decks,
      masteryTree,
      preferredDecks,
      puzzleSelections,
      checkpoints,
      blockedTeams,
      isInRing,
      backendOwned: {
        hintsTotal: 0,
        hintsUsed: [],
        extraGuesses: {},
      },
    };
  }

  private getTeamData(teamId: string): MockDBTeam {
    if (this.db.teams[teamId] === undefined)
      throw new Error(`unknown team ${teamId}`);
    return this.db.teams[teamId];
  }

  async syncData(req: SyncDataBackendReq) {
    const { rooms, teamState } = req;
    for (const [teamId, teamUpdates] of Object.entries(teamState ?? {})) {
      const {
        isInactive,
        isHidden,
        displayName,
        faction,
        factionScoreContributions,
        decks,
        masteryTree,
        answerSubmissions,
        unlocks,
        cardUnlocks,
        viewTimes,
        preferredDecks,
        puzzleSelections,
        checkpoints,
        blockedTeams,
        isInRing,
      } = teamUpdates;
      const teamData = this.getTeamData(teamId);
      if (isInactive !== undefined) {
        teamData.isInactive = isInactive;
        this.isDirty = true;
      }
      if (isHidden !== undefined) {
        teamData.isHidden = isHidden;
        this.isDirty = true;
      }
      if (displayName !== undefined) {
        teamData.displayName = displayName;
        this.isDirty = true;
      }
      if (faction !== undefined) {
        teamData.faction = faction;
        this.isDirty = true;
      }
      for (const [kFaction, contribution] of Object.entries(
        factionScoreContributions ?? {}
      )) {
        const faction = kFaction as Faction;
        teamData.factionScoreContributions[faction] = contribution;
      }
      for (const deck of Object.values(decks ?? {})) {
        const { slot } = deck;
        const teamDecks = teamData.decks;
        const existingIndex = teamDecks.findIndex(
          ({ slot: existingSlot }) => existingSlot === slot
        );
        if (existingIndex === -1) {
          teamDecks.push(deck);
        } else {
          teamDecks[existingIndex] = deck;
        }
        this.isDirty = true;
      }
      if (masteryTree !== undefined) {
        teamData.masteryTree = masteryTree;
        this.isDirty = true;
      }
      for (const [puzName, puzSubmissions] of Object.entries(
        answerSubmissions ?? {}
      )) {
        if (puzSubmissions === null) {
          // Remove only the correct answer submissions.
          teamData.answerSubmissions[puzName] = teamData.answerSubmissions[
            puzName
          ].filter((submission) => !submission.isCorrect);
        } else {
          teamData.answerSubmissions[puzName] ??= [];
          for (const submission of Object.values(puzSubmissions)) {
            teamData.answerSubmissions[puzName].push(submission);
          }
        }
        this.isDirty = true;
      }
      for (const [puzName, unlock] of Object.entries(unlocks ?? {})) {
        if (unlock === null) delete teamData.unlocks[puzName];
        else teamData.unlocks[puzName] = unlock;
        this.isDirty = true;
      }
      for (const [cardName, cardUnlock] of Object.entries(cardUnlocks ?? {})) {
        if (cardUnlock === null) delete teamData.cardUnlocks[cardName];
        else teamData.cardUnlocks[cardName] = cardUnlock;
        this.isDirty = true;
      }
      for (const [puzName, viewTime] of Object.entries(viewTimes ?? {})) {
        teamData.viewTimes[puzName] = viewTime;
        this.isDirty = true;
      }
      for (const [puzName, slot] of Object.entries(preferredDecks ?? {})) {
        teamData.preferredDecks[puzName] = slot;
        this.isDirty = true;
      }
      for (const [puzName, selection] of Object.entries(
        puzzleSelections ?? {}
      )) {
        if (selection === null) delete teamData.puzzleSelections[puzName];
        else teamData.puzzleSelections[puzName] = selection;
        this.isDirty = true;
      }
      for (const [puzName, puzCheckpoints] of Object.entries(
        checkpoints ?? {}
      )) {
        teamData.checkpoints[puzName] = puzCheckpoints;
        this.isDirty = true;
      }
      for (const [targetTeamId, isBlocked] of Object.entries(
        blockedTeams ?? {}
      )) {
        if (!isBlocked) delete teamData.blockedTeams[targetTeamId];
        else teamData.blockedTeams[targetTeamId] = true;
        this.isDirty = true;
      }
      if (isInRing !== undefined) {
        teamData.isInRing = isInRing;
        this.isDirty = true;
      }
    }
    for (const [roomId, room] of Object.entries(rooms ?? {})) {
      const { p1TeamId, p2TeamId, puzName } = room;
      this.db.rooms[roomId] = {
        ...(this.db.rooms[roomId] ?? {}),
        ...room,
      };
      this.isDirty = true;
    }

    if (this.isDirty && !this.isSaving) {
      this.isDirty = false;
      this.isSaving = true;
      await this.saveDbFunc(this.db).finally(() => {
        this.isSaving = false;
      });
    }

    return {};
  }

  async checkAnswer(
    req: CheckAnswerBackendReq
  ): Promise<CheckAnswerBackendResp> {
    const { teamId, puzName, answer } = req;
    const puzSpec = this.puzzlesDB.puzzles.get(puzName);
    if (puzSpec === undefined) throw new Error(`unknown puzName ${puzName}`);
    if (puzSpec.answer === null)
      throw new Error(`no answer exists for ${puzName}`);
    const normalizedAnswer = normalizeAnswer(answer);
    const isCorrect = normalizedAnswer === normalizeAnswer(puzSpec.answer);

    // post-hunt puzzle messages
    if (puzName === "animal_shelter" && normalizedAnswer === "RESIDENCE") {
      return {
        type: CheckAnswerBackendRespType.PUZZLE_MESSAGES,
        messages: ["Almost! Check your last letter."],
      };
    }
    if (puzName === "mastery_tree" && normalizedAnswer === "CAPTAINPI") {
      return {
        type: CheckAnswerBackendRespType.PUZZLE_MESSAGES,
        messages: ["Almost!"],
      };
    }

    return {
      type: CheckAnswerBackendRespType.SUCCESS,
      submittedAnswer: normalizedAnswer,
      isCorrect,
    };
  }
}
