import { memo } from "react";
import "./CardDisplay.css";
import FaceUpCardDisplay from "./FaceUpCardDisplay";
import { Permanent } from "engine/types/game-state";
import { CardSize } from "./types";

interface CardProps {
  cardName?: string;
  // The permanent associated with this card, if any.
  // (If it doesn't exist, the card can be in hand or in deck.)
  permanent?: Permanent;
  isOwner: boolean;
  isSneaky: boolean;
  size: CardSize;
  highlighted: boolean;
  keyboardFocused: boolean;
  canZoom: boolean;
  showCost: boolean;
  faceDown: boolean;
  isOwnerTurnPending: boolean;
  hasActions: boolean;
  disableScroll: boolean;
  disableHoverZoom: boolean;
  showDelta?: boolean;
}

const defaultProps: CardProps = {
  isOwner: false,
  isSneaky: false,
  size: CardSize.NORMAL,
  highlighted: false,
  keyboardFocused: false,
  canZoom: true,
  showCost: true,
  faceDown: true,
  isOwnerTurnPending: false,
  hasActions: true,
  disableScroll: false,
  disableHoverZoom: false,
};

/**
 * card displays shouldn't be "smart"; onclick handlers should go on elements
 * that contain this, not this itself
 */
function CardDisplay(props: CardProps) {
  const {
    cardName,
    permanent,
    isOwner,
    isSneaky,
    size,
    highlighted,
    keyboardFocused,
    canZoom,
    showCost,
    faceDown,
    isOwnerTurnPending,
    hasActions,
    disableScroll,
    disableHoverZoom,
    showDelta,
  } = props;

  if (cardName === undefined) {
    return (
      <div
        className={`game-card ${size} ${faceDown ? "face-down" : "blank"}`}
      ></div>
    );
  } else {
    return (
      <FaceUpCardDisplay
        cardName={cardName}
        permanent={permanent}
        isOwner={isOwner}
        isSneaky={isSneaky}
        size={size}
        highlight={highlighted}
        keyboardFocus={keyboardFocused}
        canZoom={canZoom}
        showCost={showCost}
        isOwnerTurnPending={isOwnerTurnPending}
        hasActions={hasActions}
        disableScroll={disableScroll}
        disableHoverZoom={disableHoverZoom}
        showDelta={showDelta}
      />
    );
  }
}
CardDisplay.defaultProps = defaultProps;
CardDisplay.displayName = "CardDisplay";

export default memo(CardDisplay);
