import { AnimatePresence, DOMKeyframesDefinition, motion } from "framer-motion";
import {
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import "./PermanentsDisplay.css";

import CardDisplay, { CardSize } from "components/CardDisplay";
import { Permanent, Slot } from "engine/types/game-state";
import {
  useAdjustedAnimate,
  useAnimationSpeed,
  useAnimationStore,
  useGetTranslate,
} from "stores/AnimationStore";
import {
  useClientGameStore,
  useInspector,
  usePlayerPerspective,
} from "stores/ClientGameStore";
import {
  usePendingForm,
  useSelectedPermanent,
  useTurnStore,
} from "stores/TurnStore";
import { UpdateType } from "engine/types/updates";
import { cssIdFor, selectorFor } from "engine/types/keyframes";
import {
  useResetSelectedCard,
  useSelectFieldPermanent,
} from "stores/UserGameActionHandlers";
import { useKeyboardCursorStore } from "stores/KeyboardCursorStore";

interface PermanentProps {
  permanent: Permanent;
  isOwner: boolean;
  isSneaky: boolean;
  size: CardSize;
  selectable: boolean;
  resettable: boolean;
  highlighted: boolean;
  keyboardFocused?: boolean;
  onSelect: (id: string) => void;
  onReset: () => void;
  isOwnerTurnPending: boolean;
  hasActions: boolean;
  cssTransform?: string;
}

function PermanentDisplayNoMemo(props: PermanentProps) {
  const {
    permanent,
    isOwner,
    isSneaky,
    size,
    selectable,
    resettable,
    highlighted,
    keyboardFocused = false,
    onSelect,
    onReset,
    isOwnerTurnPending,
    hasActions,
    cssTransform,
  } = props;
  const speed = useAnimationSpeed();

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      if (selectable || resettable) {
        e.stopPropagation();
        if (selectable) onSelect(permanent.id);
        if (resettable) onReset();
      }
    },
    [onReset, onSelect, permanent.id, resettable, selectable]
  );

  const { initial, animate, exit, transition } = useMemo(
    () => ({
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: speed === 0 ? 0 : 0.3 / speed },
    }),
    [speed]
  );

  return (
    <motion.div
      className="permanent"
      id={cssIdFor.permanent(permanent)}
      onClick={onClick}
      style={{
        cursor: selectable || resettable ? "pointer" : "",
        transform: cssTransform,
      }}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <CardDisplay
        cardName={permanent.card.name}
        permanent={permanent}
        isOwner={isOwner}
        isSneaky={isSneaky}
        size={size}
        showCost={false}
        highlighted={highlighted}
        keyboardFocused={keyboardFocused}
        isOwnerTurnPending={isOwnerTurnPending}
        hasActions={hasActions}
        showDelta={true}
      />
    </motion.div>
  );
}

const PermanentDisplay = memo(PermanentDisplayNoMemo);

interface PermanentDisplayProps {
  smaller: boolean;
}

function PermanentsDisplay(props: PermanentDisplayProps) {
  const { smaller } = props;

  const inspector = useInspector();
  const playerPerspective = usePlayerPerspective();

  const pendingForm = usePendingForm();
  const selectedCard = useTurnStore((state) => state.selectedCard);
  const selectedPermanent = useSelectedPermanent();
  const setSelectedCardPermanent = useSelectFieldPermanent();
  const resetSelectedCard = useResetSelectedCard();
  const roomId = useClientGameStore((state) => state.roomId);

  const register = useAnimationStore((state) => state.register);
  const slotTrackers = useAnimationStore((state) => state.slotTrackers);
  const updateSlotOffsets = useAnimationStore(
    (state) => state.updateSlotOffsets
  );
  const resetSlotOffsets = useAnimationStore((state) => state.resetSlotOffsets);
  const getTranslate = useGetTranslate();
  const [scope, animate] = useAdjustedAnimate();
  const focusedSlot = useKeyboardCursorStore((state) =>
    state.getFieldSlotWithFocus()
  );
  const speed = useAnimationSpeed();

  const elt = (id: string) => selectorFor.permanentId(id);

  useEffect(() => {
    updateSlotOffsets();
    window.addEventListener("resize", updateSlotOffsets, { passive: true });
    return () => {
      resetSlotOffsets();
      window.removeEventListener("resize", updateSlotOffsets);
    };
  }, [updateSlotOffsets, resetSlotOffsets]);

  useEffect(() => {
    if (slotTrackers.length <= 0) return;
    updateSlotOffsets();
  }, [updateSlotOffsets, slotTrackers]);

  useEffect(() => {
    if (speed !== 0) return;
    for (const permanent of inspector.getAllPermanents()) {
      const latestTransform = getTranslate(permanent.slot).transform;
      if (latestTransform !== undefined)
        animate(elt(permanent.id), { transform: latestTransform }).complete();
    }
  }, [speed, inspector, animate, getTranslate]);

  useEffect(() => {
    const locSlot = (slot: Slot) =>
      getTranslate(slot) as DOMKeyframesDefinition;
    const loc = (id: string) => locSlot(inspector.getPermanent(id).slot);

    if (speed === 0) return;

    return register(async (upd) => {
      switch (upd.type) {
        case UpdateType.OVERRIDE_STATE: {
          const { roomId: newRoomId, state } = upd;
          if (newRoomId !== roomId) break;
          await Promise.all(
            inspector.getAllPermanents().flatMap((permanent) => {
              const newPermanent = state.permanents[permanent.id];
              if (newPermanent === undefined) return [];
              return [animate(elt(permanent.id), locSlot(newPermanent.slot))];
            })
          );
          break;
        }
        case UpdateType.DAMAGE_PERMANENT: {
          const { attackerId, permanentId: defenderId } = upd;
          // is this the right way to do this?????
          const attackerElt =
            attackerId && document.querySelector(elt(attackerId));
          if (attackerElt) {
            (attackerElt as HTMLDivElement).style.zIndex = "99";
          }
          await (attackerId && animate(elt(attackerId), loc(defenderId)));
          await Promise.all([
            attackerId && animate(elt(attackerId), loc(attackerId)),
            animate(elt(defenderId), { opacity: 0 }),
          ]);
          if (attackerElt) {
            (attackerElt as HTMLDivElement).style.zIndex = "";
          }
          await animate(elt(defenderId), { opacity: 1 });
          break;
        }
        case UpdateType.MOVE: {
          const { permanentId, slot } = upd;
          await animate(elt(permanentId), locSlot(slot));
          break;
        }
        case UpdateType.SWAP: {
          const { permanent1Id, permanent2Id } = upd;
          const slot1 = inspector.getPermanent(permanent1Id).slot;
          const slot2 = inspector.getPermanent(permanent2Id).slot;
          await Promise.all([
            animate(elt(permanent1Id), locSlot(slot2)),
            animate(elt(permanent2Id), locSlot(slot1)),
          ]);
          break;
        }
      }
    });
  }, [register, animate, scope, getTranslate, inspector, roomId, speed]);

  const permanentsDom = inspector.getAllPermanents().map((permanent) => {
    const selectable = !pendingForm && selectedCard === null;
    const highlighted = permanent.id === selectedPermanent?.id;
    const resettable = !pendingForm && highlighted;
    const keyboardFocused =
      !!focusedSlot &&
      focusedSlot.row === permanent.slot.row &&
      focusedSlot.column === permanent.slot.column;

    return (
      <PermanentDisplay
        key={`${roomId ?? "none"}_${permanent.id}`}
        permanent={permanent}
        isOwner={permanent.owner === playerPerspective}
        isSneaky={inspector.isSneaky(permanent)}
        size={smaller ? CardSize.SMALL_FIELD : CardSize.NORMAL}
        selectable={selectable}
        resettable={resettable}
        highlighted={highlighted}
        keyboardFocused={keyboardFocused}
        onSelect={setSelectedCardPermanent}
        onReset={resetSelectedCard}
        isOwnerTurnPending={inspector.isTurnPending(permanent.owner)}
        hasActions={inspector.hasActions(permanent)}
        cssTransform={getTranslate(permanent.slot).transform}
      />
    );
  });

  return (
    <div ref={scope}>
      <AnimatePresence>{permanentsDom}</AnimatePresence>
    </div>
  );
}

export default PermanentsDisplay;
