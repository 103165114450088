import Dropdown from "components/Dropdown";
import { RoutedLink } from "components/Link";
import { PVP_MASTERY_ID } from "engine/puzzles/mastery-data";
import {
  BATTLE_GROUP_NAME_TUTORIAL,
  BATTLE_GROUP_NAME_INSTANCER,
  PUZ_NAME_INTRO,
  PUZ_NAME_KEROS_NOTES_UNLOCK,
  PUZ_NAME_MASTERY_TREE,
  PUZ_NAME_PVP,
} from "engine/puzzles/puzzle-data";
import settings from "settings";
import {
  useSubscribedTeam,
  useClientGlobalStateStore,
} from "stores/ClientGlobalStateStore";
import { useNavStore } from "stores/NavStore";
import {
  useShouldDisplayAdmin,
  useServerInteractionStore,
} from "stores/ServerInteractionStore";
import {
  useIsAdminEnabledStore,
  useAdminPreferencesStore,
} from "stores/UserPreferencesStore";
import { useGlobalSubscriptionStore } from "stores/GlobalSubscriptionStore";
import { useEffect } from "react";
import "./SiteNavDisplay.css";
import VolumeDropdown from "./VolumeDropdown";
import BattleUnlockModal from "components/BattleUnlockModal";
import MasteryUnlockModal from "components/MasteryUnlockModal";
import Twemoji from "react-twemoji";

function SiteNavDisplay() {
  const { djangoBaseUrl, hasAdminAccess } = settings;
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const subscribedTeam = useSubscribedTeam();
  const { puzzles, masteries } = subscribedTeam ?? {};
  const hasMasteryTree = puzzles?.[PUZ_NAME_MASTERY_TREE] !== undefined;
  const hasInstancer = puzzles?.[BATTLE_GROUP_NAME_INSTANCER] !== undefined;
  const hasKerosNotes =
    puzzles !== undefined &&
    (puzzles[PUZ_NAME_KEROS_NOTES_UNLOCK] !== undefined ||
      // For posthunt, unlock kero's notes as long as anything beyond
      // the tutorial is unlocked, to support solving battles
      // individually.
      Object.entries(puzzles).filter(
        ([puzName, v]) =>
          v !== undefined &&
          ![
            PUZ_NAME_PVP,
            PUZ_NAME_INTRO,
            BATTLE_GROUP_NAME_TUTORIAL,
            BATTLE_GROUP_NAME_INSTANCER,
          ].includes(puzzles[puzName].battleGroupName)
      ).length > 0);
  const hasPVP = masteries?.[PVP_MASTERY_ID]?.isEnabled ?? false;

  const isAdminEnabled = useIsAdminEnabledStore(
    (state) => state.isAdminEnabled
  );
  const setIsAdminEnabled = useIsAdminEnabledStore(
    (state) => state.setIsAdminEnabled
  );
  const setIsAdminEnabledPref = useAdminPreferencesStore(
    (state) => state.setIsAdminEnabledPref
  );
  const isSpectating = useIsAdminEnabledStore((state) => state.isSpectating);
  const setIsSpectating = useIsAdminEnabledStore(
    (state) => state.setIsSpectating
  );
  const setIsSpectatingPref = useAdminPreferencesStore(
    (state) => state.setIsSpectatingPref
  );
  const { setNeedAdmin } = useGlobalSubscriptionStore();

  const unclaimedHints = useClientGlobalStateStore(
    (state) => state.globalState.unclaimedHints
  );
  const serverInterfaceController = useServerInteractionStore(
    (state) => state.getServerInterfaceController
  )();

  useEffect(() => {
    if (!shouldDisplayAdmin) return;
    setNeedAdmin(true);
  }, [shouldDisplayAdmin, setNeedAdmin]);

  const navPuzName = useNavStore((state) => state.navPuzName);
  const setBattleUnlockModalPuzName = useNavStore(
    (state) => state.setBattleUnlockModalPuzName
  );
  const setIsMasteryUnlockModalOpen = useNavStore(
    (state) => state.setIsMasteryUnlockModalOpen
  );
  const navIsMasteryTreePage = useNavStore(
    (state) => state.navIsMasteryTreePage
  );
  const navPuzData =
    navPuzName === null ? null : subscribedTeam?.puzzles[navPuzName] ?? null;
  const navPuzIsBattle = navPuzData !== null && !navPuzData.hasAnswer;

  const puzLinks = [
    hasInstancer
      ? [
          <RoutedLink
            key="instancer"
            path={`/game/prep/${BATTLE_GROUP_NAME_INSTANCER}`}
          >
            Instancer
          </RoutedLink>,
        ]
      : [],
    hasKerosNotes
      ? [
          <RoutedLink key="keros-notes" path="/game/keros_notes">
            Kero’s Notes
          </RoutedLink>,
        ]
      : [],
    hasMasteryTree
      ? [
          <RoutedLink key="mastery-tree" path="/game/mastery">
            Mastery Tree
          </RoutedLink>,
        ]
      : [],
    hasPVP
      ? [
          <RoutedLink key="pvp" path={`/game/prep/${PUZ_NAME_PVP}`}>
            PvP Arena
          </RoutedLink>,
        ]
      : [],
  ].flat();
  const nonPuzLinks = (
    <>
      <RoutedLink path="/teams">Teams</RoutedLink>
      <a key="errata" href={`${djangoBaseUrl}errata`}>
        Updates
      </a>
      <a key="about" href={`${djangoBaseUrl}about`}>
        About
      </a>
      <a key="wrapup" href={`${djangoBaseUrl}wrapup`}>
        Wrap-Up
      </a>
      <a key="archive" href="https://galacticpuzzlehunt.com/archive">
        Archive
      </a>
    </>
  );

  return (
    <>
      {hasAdminAccess && serverInterfaceController !== null ? (
        <Dropdown
          trigger={
            <button>
              {shouldDisplayAdmin ? (unclaimedHints ? "🚨️" : "😶‍🌫️️") : "🫥"}
            </button>
          }
          className="admin-dropdown-content"
          triggerClassName="admin-dropdown-trigger"
          closeOnClick={false}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const newIsAdminEnabled = !isAdminEnabled;
              setIsAdminEnabled(newIsAdminEnabled);
              setIsAdminEnabledPref(newIsAdminEnabled);
              serverInterfaceController.setIsAdmin(newIsAdminEnabled);
            }}
          >
            {isAdminEnabled ? "✅️" : "🔲️"} Admin
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const newIsSpectating = !isSpectating;
              setIsSpectating(newIsSpectating);
              setIsSpectatingPref(newIsSpectating);
              serverInterfaceController.isSpectating = newIsSpectating;
            }}
          >
            {isSpectating ? "✅️" : "🔲️"} Spectate
          </a>
          {shouldDisplayAdmin ? (
            <>
              <RoutedLink path="/game/admin">Game Admin</RoutedLink>
              <RoutedLink path="/game/health">Game Health</RoutedLink>
              <RoutedLink path="/game/bigboard">Big Board</RoutedLink>
              <a href={`${djangoBaseUrl}admin`}>Django Admin</a>
              <a href={`${djangoBaseUrl}bridge`}>Email</a>
              <a href={`${djangoBaseUrl}hints`}>Hints ({unclaimedHints})</a>
            </>
          ) : null}
        </Dropdown>
      ) : null}
      <RoutedLink path="/game/map" href={`${djangoBaseUrl}puzzles`}>
        Map
      </RoutedLink>
      <VolumeDropdown />
      {puzLinks.length > 0 ? (
        <Dropdown trigger={<button>🧩</button>}>{puzLinks}</Dropdown>
      ) : null}
      <Dropdown trigger={<button>🔗</button>}>{nonPuzLinks}</Dropdown>
      {settings.isPosthunt ? (
        <Dropdown trigger={<button>🔓</button>} closeOnClick={false}>
          <RoutedLink path="/game/unlock">Hunt unlocks</RoutedLink>
          {navPuzName !== null && !navIsMasteryTreePage && navPuzIsBattle ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setBattleUnlockModalPuzName(navPuzName);
              }}
            >
              Battle unlocks
            </a>
          ) : null}
          {navIsMasteryTreePage ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsMasteryUnlockModalOpen(true);
              }}
            >
              Mastery tree unlocks
            </a>
          ) : null}
        </Dropdown>
      ) : null}
      <a href={`${djangoBaseUrl}donate`}>
        <span title="Donate">
          <Twemoji>🪙</Twemoji>
        </span>
      </a>
      <BattleUnlockModal />
      <MasteryUnlockModal />
    </>
  );
}

export default SiteNavDisplay;
