import {
  PUZ_NAME_FIRST_LEGENDARY,
  PUZ_NAME_FIRST_ANSWER,
  PUZ_NAME_PRE_FINAL_BATTLE,
  PUZ_NAME_FINAL_BATTLE,
} from "engine/puzzles/puzzle-data";
import { getKeroLayers } from "engine/cards/card-effects-shared";
import { Puzzle76SharedExtraState } from "engine/game-specs/puzzle-76-shared";

import { getBattlerArtPath, getBattlerSliceArtPaths } from "images";
import { useClientGameStore } from "stores/ClientGameStore";
import { useSubscribedTeam } from "stores/ClientGlobalStateStore";
import "./BattlerImage.css";
import Image, { KeroImage } from "./Image";

interface BattlerImageProps {
  battleGroupName: string;
  isInBattle?: boolean;
}

const BattlerImage = (props: BattlerImageProps) => {
  const { battleGroupName, isInBattle = false } = props;
  const gameState = useClientGameStore((state) => state.gameState);

  const subscribedTeam = useSubscribedTeam();

  if (subscribedTeam === null) return null;

  const { cardUnlocks } = subscribedTeam;
  const useKeroDos =
    [
      PUZ_NAME_FIRST_LEGENDARY,
      PUZ_NAME_FIRST_ANSWER,
      PUZ_NAME_PRE_FINAL_BATTLE,
    ].includes(battleGroupName) ||
    (battleGroupName === PUZ_NAME_FINAL_BATTLE && isInBattle);
  const keroPhase = useKeroDos
    ? // There shouldn't be race conditions but put in contingencies
      // just to be safe...
      (gameState.sharedExtraState as Puzzle76SharedExtraState)?.keroPhase ?? 0
    : 0;
  const keroLayers = getKeroLayers(Object.keys(cardUnlocks), keroPhase);

  return useKeroDos ? (
    <KeroImage className="battler-image" keroLayers={keroLayers} />
  ) : battleGroupName === "infinitely_many_hogs_and_logs" ? (
    <div className="battler-image battler-image-special">
      <div>
        {getBattlerSliceArtPaths(battleGroupName).map((src, i) => (
          <Image src={src} key={i} />
        ))}
      </div>
    </div>
  ) : (
    <Image className="battler-image" src={getBattlerArtPath(battleGroupName)} />
  );
};

export default BattlerImage;
